import {Fragment, useContext, useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {privateRoutes, publicRoutes, PrivateRoutes} from "~/routes";
import {DefaultLayout} from "~/layout";
import {GlobalHistory} from "./routes/GlobalHistory";
import {useCurrentUser} from "./hooks";

function App() {

  const userCurrent = useCurrentUser();

  useEffect(() => { document.title = "Super Express"; }, [])

  return (
      <Router>
        <GlobalHistory />
        <div className="App">
          <Routes>
            {
              publicRoutes.map((route) => {
                let Layout;

                if(route.layout) {
                  Layout = route.layout;
                }
                else if(route.layout === null) {
                  Layout = Fragment;
                }
                else {
                  Layout = DefaultLayout;
                }

                const Page = route.component;

                return (
                    <Route key={route.path} path={route.path} element={<Layout><Page /></Layout>}/>
                )
              })
            }
            {
              privateRoutes.map((route) => {
                let Layout;

                if(route.layout) {
                  Layout = route.layout;
                }
                else if(route.layout === null) {
                  Layout = Fragment;
                }
                else {
                  Layout = DefaultLayout;
                }

                const Page = route.component;

                return (
                    <Route key={route.path} path={route.path} element={<PrivateRoutes><Layout><Page /></Layout></PrivateRoutes>}/>
                )
              })
            }
          </Routes>
        </div>
      </Router>
  );
}

export default App;
