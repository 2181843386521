import {Badge, Tabs} from "antd";
import {globalNavigate} from "~/routes/GlobalHistory";
import {useEffect, useState} from "react";
import {apiError, handleRequest} from "~/utils";

function TicketTabs({active, filter, ticketApi}) {

	const [count, setCount] = useState({
		pending: 0,
		wait: 0,
		success: 0,
		cancel: 0,
	})

	useEffect(() => {
		async function fetchData() {
			let [error, response] = await handleRequest(ticketApi.countStatus(filter));
			let message = apiError(`Tính số lượng đơn thất bại`, error, response);
			if (!message) {
				setCount(response.data)
			}
		}
		fetchData().then();
	}, [filter]);

	const itemsTab = [
		{
			key: '',
			label: 'Tất cả',
		},
		{
			key: 'pending',
			label: <p>Chưa xử lý <Badge count={count.pending} /></p>,
		},
		{
			key: 'wait',
			label: <p>Đang xử lý <Badge count={count.wait} /></p>,
		},
		{
			key: 'success',
			label: <p>Đã xử lý <Badge count={count.success} /></p>,
		},
		{
			key: 'cancel',
			label: <p>Không xử lý <Badge count={count.cancel} /></p>,
		},
	];

	const handleTabChange = (key) => {
		globalNavigate(`/ticket/${key}`);
	};

	return (
		<Tabs
			defaultActiveKey={active}
			items={itemsTab}
			onChange={handleTabChange}
		/>
	)
}
export default TicketTabs;