import {Button, Icon} from "~/components";
import {useState} from "react";
import {Tooltip} from "antd";

function ReportAffiliateTableAction({item, handleEvent}) {

	const [loadingExportOrder, setLoadingExportOrder] = useState(false)
	const [loadingExportUser, setLoadingExportUser] = useState(false)

	const handleButtonClick = {
		exportOrder: async () => {
			setLoadingExportOrder(true)
			await handleEvent.exportOrder(item);
			setLoadingExportOrder(false)
		},
		exportUser: async () => {
			setLoadingExportUser(true)
			await handleEvent.exportUser(item);
			setLoadingExportUser(false)
		},
	}

	return (
		<>
			<Tooltip title="Xuất đơn hàng thành excel">
				<Button leftIcon={ loadingExportOrder ? Icon.loading : Icon.export} small background white onClick={handleButtonClick.exportOrder} disabled={loadingExportOrder}>Đơn</Button>
			</Tooltip>
			<Tooltip title="Xuất thành viên trực thuộc">
				<Button leftIcon={ loadingExportUser ? Icon.loading : Icon.export} small background white onClick={handleButtonClick.exportUser} disabled={loadingExportUser}>User</Button>
			</Tooltip>
			<Tooltip title="Thống kê">
				<Button to={`/report/affiliate/${item.id}`} small background blue>{Icon.chart}</Button>
			</Tooltip>
		</>
	)
}

export default ReportAffiliateTableAction;