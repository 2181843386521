import {
	Button,
	Icon
} from "~/components";
import {Tooltip} from "antd";
import {useState} from "react";

function UserBlockOrderTableAction({item, handleEvent, can}) {

	const [loading, setLoading] = useState(false)

	const handleButtonClick = {
		unBlock: async () => {
			setLoading(true)
			await handleEvent.unBlock(item);
			setLoading(false)
		},
	}

	return (
		<>
			{
				(item.isBlockOrder === 1) && <Tooltip title="Mở khóa tài khoản">
					<Button small background green onClick={handleButtonClick.unBlock} disabled={loading}>
						{loading ? Icon.loading : Icon.success}
					</Button>
				</Tooltip>
			}
		</>
	)
}

export default UserBlockOrderTableAction;