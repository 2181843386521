import { saveAs } from 'file-saver';
import {
	reportCoinActions,
	reportCoinErrorSelector,
	reportCoinFilterSelector,
	reportCoinItemsSelector,
	reportCoinLoadingSelector,
	reportCoinPaginationSelector
} from "../reportCoinSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {notification} from "antd";
import {
	Loading,
	Modal
} from "~/components";
import {
	ReportCoinSearch,
	ReportCoinTable
} from "../components";
import {
	DATA_LIMIT_KEY
} from "~/app/constants";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	reportApi
} from "~/api";
import {OrderFormExport} from "../../Order/components";
import _ from "lodash";

function ReportCoin() {

	const dispatch = useDispatch();

	const items = useSelector(reportCoinItemsSelector);

	const loading = useSelector(reportCoinLoadingSelector);

	const error = useSelector(reportCoinErrorSelector);

	const pagination = useSelector(reportCoinPaginationSelector);

	const filter = useSelector(reportCoinFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [fileDownload, setFileDownload] = useState(false);

	//Modal show
	const [openModal, setOpenModal] = useState({
		export : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		export: async (type) => {
			if (type == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn kiểu xuất'});
				return;
			}

			let params = {
				type: type,
				listData: [],
				filter: []
			};

			if(type == 'pageCurrent') {
				if(_.isEmpty(items)) {
					notification.error({message: 'Lỗi', description: 'Trang hiện không có thống kê nào'});
					return;
				}
				for (const [index, item] of Object.entries(items)) {
					params.listData.push(item.id)
				}
			}

			if(type == 'checked') {
				if(_.isEmpty(selectedRowKeys)) {
					notification.error({message: 'Lỗi', description: 'Bạn chưa chọn thống kê nào'});
					return;
				}
				params.listData = selectedRowKeys;
			}

			if(type == 'search') {
				params.filter = filter;
			}

			let [error, response] = await handleRequest(reportApi.reportCoinsExport(params));
			let message = apiError(`Xuất thống kê thất bại`, error, response);
			if(!message) {
				setFileDownload(response.data.file)
			}
		},
		exportOrder: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa thống kế cộng tác viên'});
				return;
			}
			let [error, response] = await handleRequest(reportApi.reportCoinsExportOrder(item.id));
			let message = apiError(`Xuất đơn đối soát thất bại`, error, response);
			if(!message) {
				saveAs(response.data.file);
			}
		},
		exportUser: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa thống kế cộng tác viên'});
				return;
			}
			let [error, response] = await handleRequest(reportApi.reportCoinsExportUser(item.id));
			let message = apiError(`Xuất đơn đối soát thất bại`, error, response);
			if(!message) {
				saveAs(response.data.file);
			}
		},
		changePagination : (page) => {
			dispatch(reportCoinActions.setFilter({...filter, page }));
		},
		changeLimit : (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(reportCoinActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changeSearch : (newFilter) => {
			dispatch(reportCoinActions.fetchData(newFilter));
		}
	}

	//Load data
	useEffect(() => {
		dispatch(reportCoinActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				<div className="d-flex gap-1">
					<ReportCoinSearch filter={filter} onSearchChange={handleEvent.changeSearch}/>
				</div>
			</section>
			<section>
				{loading && <Loading noFixed/>}
				{items && <ReportCoinTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
				/>}
			</section>
			{
				(openModal.export) &&
				<Modal title="Xuất" visible={openModal.export} onCancel={() => {handleEvent.closeModal('export')}}>
					<OrderFormExport label="thống kê" fileDownload={fileDownload} onHandleSubmit={handleEvent.export} />
				</Modal>
			}
		</div>
	)
}

export default ReportCoin;