import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import {
	GroupRadioField,
} from "~/components/Forms";
import {
	LIST_ORDER_STATUS
} from "~/app/constants";

function OrderFormStatus({item, onHandleSubmit}) {

	const statusOptions = [];

	for (const [key, value] of Object.entries(LIST_ORDER_STATUS)) {
		statusOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.title}</StatusLabel>})
	}

	const initialValues = {
		status: '',
	}

	const validationSchema = Yup.object().shape({
		status: Yup.string().required('Trạng thái đơn hàng không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.status = item.status;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data.status, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="status" render={({field}) => (
				<GroupRadioField options={statusOptions} errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Chuyển trạng thái</Button>
			</div>
		</form>
	)
}

export default OrderFormStatus;