import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
	LIST_USER_ROLE
} from "~/app/constants";
import {
	UserVerify,
	StatusLabel
} from "~/components";
import {Link} from "react-router-dom";
import UserVerifyTableAction from "./UserVerifyTableAction";

function UserVerifyTable({items = [], pagination, setItemEdit, handleEvent, can}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Tên', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold"><Link to={'/users/edit/'+item?.id}>{item?.username}</Link></p>
				<p className="font-bold"><Link to={'/users/edit/'+item?.id}>{item?.firstname + ' ' + item?.lastname}</Link></p>
				{
					item?.referralCode && <StatusLabel small type="blue" border>{item?.referralCode}</StatusLabel>
				}
				<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
			</>
		)},
		{ title: 'Liên hệ', dataIndex: 'contact', key: "contact", width: '100px', render: (_, item) => (
			<>
				<p><Link to={'/admin/users/edit/'+item?.id}>{item?.email}</Link></p>
				<p><Link to={'/admin/users/edit/'+item?.id}>{item?.phone}</Link></p>
			</>
		)},
		{ title: 'Chức vụ', dataIndex: 'role', key: "role", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold">{LIST_USER_ROLE[item?.role]?.title}</p>
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", render: (_, item) => (
			<>
				<UserVerify user={item} />
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '220px', render: (_, item) => (
			<UserVerifyTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default UserVerifyTable;