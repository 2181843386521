import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {Link} from "react-router-dom";
import {numberFormat} from "../../../../utils";

function ReportRegionTable({items = []}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Tỉnh thành', dataIndex: 'city', key: "city", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.city}</p>
				{
					item?.referralCode && <StatusLabel small type="blue" border>{item?.referralCode}</StatusLabel>
				}
			</>
		)},
		{ title: 'Tổng đơn', dataIndex: 'total', key: "total", width: '100px', render: (_, item) => (
			<StatusLabel small type="blue" border>{numberFormat(item?.total)}</StatusLabel>
		)},
		{ title: 'Tổng Cước', dataIndex: 'shipping', key: "shipping", width: '100px', render: (_, item) => (
			<StatusLabel small type="red" border>{numberFormat(item?.shipping)}</StatusLabel>
		)},
		{ title: 'Tổng Cod', dataIndex: 'cod', key: "cod", width: '100px', render: (_, item) => (
			<StatusLabel small type="white" border>{numberFormat(item?.cod)}</StatusLabel>
		)},
	];

	let summary = {
		total: 0,
		shipping: 0,
		cod: 0,
	}

	items.map(function (item) {
		summary.total += item.total*1;
        summary.shipping += item.shipping*1;
        summary.cod += item.cod*1;
	});

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table columns={columns}
					   dataSource={dataSource}
					   scroll={{ y:tableHeight, x: 'max-content' }}
					   pagination={false}
					   summary={() => (
						   <Table.Summary fixed>
							   <Table.Summary.Row>
								   <Table.Summary.Cell index={0}></Table.Summary.Cell>
								   <Table.Summary.Cell index={1}><b>{numberFormat(summary.total)}</b></Table.Summary.Cell>
								   <Table.Summary.Cell index={2}><b>{numberFormat(summary.shipping)}</b></Table.Summary.Cell>
								   <Table.Summary.Cell index={3}><b>{numberFormat(summary.cod)}</b></Table.Summary.Cell>
							   </Table.Summary.Row>
						   </Table.Summary>
					   )}
				/>
			</div>
		</div>
	)
}

export default ReportRegionTable;