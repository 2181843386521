import style from '../../style/Order.module.scss';
import className from 'classnames/bind';
import {
	useContext
} from "react";
import {
	Button,
	Icon
} from "~/components";
import {
	Table, Tooltip
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";

const cn = className.bind(style);

function OrderTableUpload({items = [], type, changeLabel, changeKey, onSubmit, onDelete}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Mã đơn', dataIndex: 'trackingNumber', key: "code", width: '200px', render: (_, item) => (
			<>
				<p className="color-red font-bold">{item?.trackingNumber}</p>
				{
					item?.errors && <Tooltip title={item?.errors}>
						<Button background red type="button" small>Chi tiết lỗi</Button>
					</Tooltip>
				}
			</>
		)},
		{ title: changeLabel, dataIndex: 'changeData', key: "changeData", render: (_, item) => (
			<p className="mb-0">{item[changeKey]}</p>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '100px', render: (_, item) => (
				<Button leftIcon={Icon.delete} background primary onClick={() => onDelete(item)}>Xóa</Button>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className={cn("order-table", "table-box")}>
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="text-right">
				{ type == 'passed' && <Button background primary onClick={onSubmit}>Cập nhật</Button> }
				{ type == 'failed' && <Button background primary onClick={onSubmit}>Xóa tất cả</Button>}
			</div>
		</div>
	)
}

export default OrderTableUpload;