import {memo} from "react";
import _ from "lodash"
import {
	SelectField,
	DateRangeField,
	InputField
} from "~/components/Forms";
import {Button} from "~/components";
import {strToTime} from "~/utils";
import dayjs from "dayjs";
import {Controller, useForm} from "react-hook-form";

function OrderHistorySearch({ filter, onSearchChange }) {

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			page: 1,
			keyword: data?.keyword,
			phone: data?.phone,
			role: data?.role,
		};
		if(!_.isEmpty(data.time)) {
			let timeNew = [...data.time]
			timeNew[0] = strToTime(timeNew[0]);
			timeNew[1] = strToTime(timeNew[1]);
			newFilter.time = {
				start: timeNew[0],
				end: timeNew[1],
			}
		}
		onSearchChange(newFilter);
	}

	return (
		<form className="form form-search d-flex justify-content-between gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="keyword" render={({field}) => (
				<InputField placeholder={'Mã đơn hàng'} defaultValue={filter?.keyword} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="phone" render={({field}) => (
				<InputField placeholder={'Số điện thoại'} defaultValue={filter?.phone} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="role" render={({field}) => (
				<SelectField placeholder="Loại user" defaultValue={filter.role} options={[
					{value: 'all', label: 'Tất cả' },
					{value: 0, label: 'Khách hàng' },
					{value: 1, label: 'Admin' },
					{value: 2, label: 'Webhook' },
				]} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="time" render={({field}) => (
				<DateRangeField defaultValue={[dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')]} showTime errors={errors} {...field} />
			)}/>
			<Button background primary>Áp dụng</Button>
		</form>
	)
}

export default memo(OrderHistorySearch);