import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
} from "~/components";
import {
	TextAreaField,
} from "~/components/Forms";

function UserFormVerifyCancel({item, onHandleSubmit}) {

	const initialValues = {
		message: '',
	}

	const validationSchema = Yup.object().shape({
		message: Yup.string().required('Bạn chưa điền lý do không duyệt'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data.message, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="message" render={({field}) => (
				<TextAreaField label={'Lý do'} errors={errors} {...field} rows={10}/>
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Xác nhận</Button>
			</div>
		</form>
	)
}

export default UserFormVerifyCancel;