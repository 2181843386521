import style from '../style/Account.module.scss';
import AccountHeader from "../components/AccoutHeader";
import className from 'classnames/bind';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Loading,
	Button
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	InputField,
	SelectField
} from "~/components/Forms";
import {
	authApi,
	userApi
} from "~/api";
import {notification} from "antd";
import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "~/context/AppProvider";
import {useCurrentUser} from "~/hooks";

const cn = className.bind(style);

function AccountBanks() {

	const currentUser = useCurrentUser();

	const {banksData} = useContext(AppContext);

	const banksOptions = useMemo(() => {
		return [...Object.entries(banksData).map(([value, label]) => {
			return {value, label};
		})];
	}, []);

	const [loading, setLoading] = useState(true);

	const initialValues = {
		number: undefined,
		account:undefined,
		name: undefined,
		branch: undefined,
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape({
			number: Yup.string().required('Bạn chưa điền số tài khoản'),
			account: Yup.string().required('Bạn chưa điền tên chủ tài khoản'),
			name: Yup.string().required('Bạn chưa chọn ngân hàng'),
			branch: Yup.string().required('Bạn chưa chi nhánh'),
		}))
	});

	useEffect(() => {
		loadBanks().then()
	}, [currentUser?.id]);

	const loadBanks = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(userApi.getBanks(currentUser?.id));
		let message = apiError(`Lấy thông tin ngân hàng thất bại`, error, response);
		if(!message) {
			initialValues.number = response.data?.number;
			initialValues.account = response.data?.account;
			initialValues.name = response.data?.name;
			initialValues.branch = response.data?.branch;
			reset(initialValues)
			setLoading(false)
		}
		setLoading(false)
	}

	const handleSave = async (data) => {
		let [error, response] = await handleRequest(authApi.banks(data));
		let message = apiError(`Cập nhật mật khẩu thất bại`, error, response);
		if (!message) {
			notification.success({
				message: 'Thành công', description: `Đổi mật khẩu thành công`
			});
		}
	}

	return (
		<div className="container">
			<AccountHeader active={'banks'}/>
			<section className="content pd-2 w-100 text-left">
				<p className="heading">Đổi thông tin ngân hàng</p>
				<form className="form" onSubmit={handleSubmit(handleSave)}>
					{(isSubmitting || loading) && <Loading noFixed />}
					<Controller control={control} name="number" render={({ field }) => (
						<InputField label="Số tài khoản" errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="account" render={({ field }) => (
						<InputField label="Tên chủ tài khoản" errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="name" render={({ field }) => (
						<SelectField label="Ngân hàng" options={banksOptions} className={'mb-1'} errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="branch" render={({ field }) => (
						<InputField label="Chi nhánh" errors={errors} {...field}/>
					)}/>
					<div className="form-group d-flex justify-content-end">
						<Button primary type="submit">Lưu thông tin</Button>
					</div>
				</form>
			</section>
		</div>
	)
}

export default AccountBanks;