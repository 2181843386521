import request from "~/utils/http";

const orderApi = {
	gets : async (params) => {
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		const url = 'v1/order';
		return await request.post(url, params);
	},
	cashFlow : async (params) => {
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		const url = `v1/order/cash-flow/${params.type}`;
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `v1/order/${id}`;
		return await request.get(url);
	},
	sync : async (id) => {
		const url = `v1/order/sync/${id}`;
		return await request.post(url);
	},
	setTracking : async (params) => {
		const url = `v1/order/tracking/${params.id}`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	cancel : async (id) => {
		const url = `v1/order/cancel/${id}`;
		return await request.post(url);
	},
	cancels : async (params) => {
		const url = `v1/order/cancel`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	add : async (params) => {
		const url = 'v1/order/add';
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `v1/order/${params.id}`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	return : async (params) => {
		const url = `v1/order/return/${params.id}`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	delete : async (id) => {
		const url = `v1/order/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `v1/order/status/${params.id}`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	fee : async (params) => {
		const url = `v1/order/fee`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	history : async (id) => {
		const url = `v1/order/history/${id}`;
		return await request.get(url);
	},
	export : async (params) => {
		const url = `v1/order/export`;
		return await request.post(url, params);
	},
	importExcel : async (params) => {
		const url = `v1/order/import/excel`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	importList : async () => {
		const url = `v1/order/import`;
		return await request.get(url);
	},
	importGet : async (id) => {
		const url = `v1/order/import/${id}`;
		return await request.get(url);
	},
	importDelete : async (id) => {
		const url = `v1/order/import/${id}`;
		return await request.delete(url);
	},
	importUpload : async (params) => {
		const url = `v1/order/import/${params.id}`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	import : async (params) => {
		const url = `v1/order/import`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	importClear : async () => {
		const url = `v1/order/import/clear`;
		return await request.post(url);
	},
	importCodExcel : async (params) => {
		const url = `v1/order/import-cod/excel`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	importCodList : async () => {
		const url = `v1/order/import-cod`;
		return await request.get(url);
	},
	importCodDelete : async (id) => {
		const url = `v1/order/import-cod/${id}`;
		return await request.delete(url);
	},
	importCodUpload : async () => {
		const url = `v1/order/import-cod`;
		return await request.post(url);
	},
	importCodClear : async () => {
		const url = `v1/order/import-cod/clear`;
		return await request.post(url);
	},
	importWeightExcel : async (params) => {
		const url = `v1/order/import-weight/excel`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	importWeightList : async () => {
		const url = `v1/order/import-weight`;
		return await request.get(url);
	},
	importWeightDelete : async (id) => {
		const url = `v1/order/import-weight/${id}`;
		return await request.delete(url);
	},
	importWeightUpload : async () => {
		const url = `v1/order/import-weight`;
		return await request.post(url);
	},
	importWeightClear : async () => {
		const url = `v1/order/import-weight/clear`;
		return await request.post(url);
	},

	importFeeExcel : async (params) => {
		const url = `v1/order/import-fee/excel`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	importFeeList : async () => {
		const url = `v1/order/import-fee`;
		return await request.get(url);
	},
	importFeeDelete : async (id) => {
		const url = `v1/order/import-fee/${id}`;
		return await request.delete(url);
	},
	importFeeUpload : async () => {
		const url = `v1/order/import-fee`;
		return await request.post(url);
	},
	importFeeClear : async () => {
		const url = `v1/order/import-fee/clear`;
		return await request.post(url);
	},

	multipleStatus: async (params) => {
		const url = `v1/order/multiple-status`;
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
};

export default orderApi;