import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useEffect,
	useState
} from "react";
import {
	notification,
} from "antd";
import {
	SearchMobile,
	Button,
	Loading,
	Modal
} from "~/components";
import {
	DiscountFormStatus,
	DiscountSearch,
	DiscountTable,
	DiscountTabs
} from "../components";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {
	apiError,
	handleRequest
} from "~/utils";
import {useCan} from "~/hooks";
import {discountApi} from "~/api";
import {
	discountActions,
	discountItemsSelector,
	discountLoadingSelector,
	discountErrorSelector,
	discountPaginationSelector,
	discountFilterSelector
} from "../discountSlice";
import {useParams} from "react-router-dom";

function Discount() {
	const {status} = useParams();

	const can = {
		add: useCan('shop_discount_add'),
		status: useCan('shop_discount_status'),
	}

	const dispatch = useDispatch();

	const items = useSelector(discountItemsSelector);

	const loading = useSelector(discountLoadingSelector);

	const error = useSelector(discountErrorSelector);

	const pagination = useSelector(discountPaginationSelector);

	const filter = useSelector(discountFilterSelector);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [searchBar, setSearchBar] = useState(false)

	//Modal show
	const [openModal, setOpenModal] = useState({
		status : false,
		detail : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		status: async (status, item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có discount để cập nhật'});
				return;
			}
			let [error, response] = await handleRequest(discountApi.status({
				id: item.id,
				status: status
			}));
			let message = apiError(`Cập nhật trạng thái discount thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Cập nhật trạng thái discount thành công`});
				item.status         = response.data.statusNew;
				dispatch(discountActions.update(item));
				handleEvent.closeModal('status')
			}
		},
		stop: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có discount để cập nhật'});
				return;
			}
			if(item.status == 'stop') {
				notification.error({message: 'Lỗi', description: 'Mã giảm giá này hiện đang tạm dừng'});
				return;
			}
			if(item.status == 'cancel') {
				notification.error({message: 'Lỗi', description: 'Mã giảm giá này đã hết hạn sử dụng'});
				return;
			}
			let [error, response] = await handleRequest(discountApi.stop({
				id: item.id,
			}));
			let message = apiError(`Cập nhật trạng thái discount thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Cập nhật trạng thái discount thành công`});
				item.status         = response.data.statusNew;
				dispatch(discountActions.update(item));
			}
		},
		running: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có discount để cập nhật'});
				return;
			}
			if(item.status == 'cancel') {
				notification.error({message: 'Lỗi', description: 'Mã giảm giá này đã hết hạn sử dụng'});
				return;
			}
			let [error, response] = await handleRequest(discountApi.running({
				id: item.id,
			}));
			let message = apiError(`Cập nhật trạng thái discount thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Cập nhật trạng thái discount thành công`});
				item.status         = response.data.statusNew;
				dispatch(discountActions.update(item));
			}
		},
		search: (newFilter) => {
			dispatch(discountActions.setFilterWithDebounce(newFilter));
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(discountActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(discountActions.setFilter({...filter, page }));
		},
		changeSearch: (newFilter) => {
			dispatch(discountActions.setFilterWithDebounce(newFilter));
			setSearchBar(false)
		}
	}

	//Load data
	useEffect(() => {
		if(status != filter.status) {
			let newFilter = {...filter};
			newFilter.status = status;
			newFilter.page = 1;
			dispatch(discountActions.setFilter(newFilter));
		}
	}, [status]);

	useEffect(() => {
		dispatch(discountActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					<SearchMobile searchBar={searchBar} setSearchBar={setSearchBar}>
						<DiscountSearch filter={filter} onSearchChange={handleEvent.changeSearch}/>
					</SearchMobile>
					{ can.add && <Button background blue className="ml-0" to={'/discount/add'}>Tạo mã khuyến mãi</Button> }
				</div>
				<DiscountTabs active={status} filter={filter} discountApi={discountApi} />
				{items && <DiscountTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
					can={can}
				/>}
			</section>
			{
				(can.status &&itemEdit?.id && openModal.status) &&
				<Modal title="Thai đổi trạng thái" visible={openModal.status} onCancel={() => {handleEvent.closeModal('status')}}>
					<DiscountFormStatus item={itemEdit} onHandleSubmit={handleEvent.status} />
				</Modal>
			}
		</div>
	)
}
export default Discount;