import AccountHeader from "../components/AccoutHeader";
import {
	useCurrentUser,
} from "~/hooks";
import {useEffect, useState} from "react";
import {apiError, handleRequest, numberFormat, renderDate} from "../../../utils";
import {coinApi} from "../../../api";
import AccountCoinHistoryTable from "../components/Tables/AccountCoinHistoryTable";

function AccountCoin() {

	const currentUser = useCurrentUser();

	const [histories, setHistories] = useState([]);

	const [coinExpire, setCoinExpire] = useState({
		time: null,
		coin: 0
	});

	const [paginationHistory, setPaginationHistory] = useState({
		page: 1,
		limit: 50,
		totalRows:0
	});

	const handleHistory = {
		changePagination: async (page) => {
			let [error, response] = await handleRequest(coinApi.histories({
				...paginationHistory,
				page: page
			}));

			let message = apiError(`Load lịch sử thất bại`, error, response);
			if (!message) {
				setHistories([
					...response.data
				])
				setPaginationHistory({
					...response.pagination
				})
			}
		},
	}

	const handleCoinExpire = async () => {
		let [error, response] = await handleRequest(coinApi.soonExpire());
		let message = apiError(`Load lịch sử thất bại`, error, response);
		if (!message) {
			setCoinExpire(response.data)
		}
	}

	useEffect(() => {
		handleHistory.changePagination(1).then()
		handleCoinExpire().then()
	}, [currentUser?.id]);

	return (
		<div className="container">
			<AccountHeader active={'coin'} />
			<section>
				<div className="d-flex justify-content-center gap-3">
					<div>
						<p className={'color-red text-lg mb-1 text-center'}>Xu</p>
						<p className={'color-red font-bold text-lg mb-1 text-center'}><span
							style={{fontSize: '30px'}}>{numberFormat(currentUser.coin)}</span></p>
						<p className={'text-center color-grey text-xs mb-3'}>loại xu không có hạn sử dụng</p>
					</div>
					<div>
						<p className={'color-blue text-lg mb-1 text-center'}>Xu thường</p>
						<p className={'color-blue font-bold text-lg mb-1 text-center'}><span
							style={{fontSize: '30px'}}>{numberFormat(currentUser.coinSale)}</span></p>
						<p className={'text-center color-grey text-xs mb-3'}>loại xu có hạn sử dụng</p>
					</div>
					<div>
						<p className={'color-grey text-lg mb-1 text-center'}>Xu sắp hết hạn</p>
						<p className={'color-grey font-bold text-lg mb-1 text-center'}><span
							style={{fontSize: '30px'}}>{numberFormat(coinExpire.coin)}</span></p>
						<p className={'text-center color-grey text-xs mb-3'}>{(coinExpire.time) ? 'Hết hạn '+renderDate(coinExpire.time) : ''}</p>
					</div>
				</div>


			</section>
			<section>
				<p className={'heading'}>Lịch sử xu</p>
				<AccountCoinHistoryTable items={histories} handleEvent={handleHistory} pagination={paginationHistory}/>
			</section>
		</div>
	)
}

export default AccountCoin;