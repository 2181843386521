import {call, put, takeLatest,} from "redux-saga/effects";
import {importHistoryOrderActions} from "./importHistoryOrderSlice";
import {importHistoryApi} from "~/api";

function* fetchImportHistoryOrderList(action) {
	try {
		const response = yield call(importHistoryApi.orders, action.payload);
		yield put(importHistoryOrderActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch importHistory list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(importHistoryOrderActions.fetchDataFailed(message));
	}
}

export default function* importHistoryOrderSaga() {
	yield takeLatest(importHistoryOrderActions.fetchData.type, fetchImportHistoryOrderList);
}