import {Badge} from "antd";
import {Icon} from "~/components";
function ImportHistoryItem({item, onClickItem}) {
	return (
		<div className="d-flex justify-content-between border-b-1 border-l-0 border-r-0 border-t-0 border-gray-100 border-solid mb-1 pdb-1 pointer" onClick={() => onClickItem(item)}>
			<div className="name d-flex gap-1 text-base">
				<span className="color-green text-lg">{Icon.excel}</span>
				<span>{item.name}</span>
			</div>
			<div className="number">
				<Badge count={item.total} showZero />
			</div>
		</div>
	)
}
function ImportHistoryList({items, onClickItem}) {
	return Object.entries(items).map(([index, item], i) => {
		return (<ImportHistoryItem key={index} item={item} onClickItem={onClickItem}/>)
	})
}
export default ImportHistoryList;