import {
	useEffect,
	useState
} from "react";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	Button,
	Loading,
	RoleError
} from "~/components";
import {
	userApi
} from "~/api";
import {useCan} from "~/hooks";
import {
	UserBlockOrderTable
} from "../components";

function UserBlockOrder() {

	const can = {
		setUser: true
	}

	const [loading, setLoading] = useState(true);

	const [users, setUsers] = useState([]);

	const [filterUser, setFilterUser] = useState({
		keyword: ''
	});

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 50,
		totalRows:0
	});

	const handleEvent = {
		searchUser : async (data) => {
			setLoading(true);
			const filter = {
				keyword: data?.keyword,
				...pagination
			};
			let [error, response] = await handleRequest(userApi.getsBlockOrder(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {
				setUsers([
					...response.data
				])
				setPagination({
					...response.pagination
				})
				setFilterUser({
					keyword: data?.keyword
				})
				setLoading(false);
			}
		},
		changePagination: async (page) => {
			const filter = {
				keyword: filterUser?.keyword,
				...pagination,
				page: page
			};
			let [error, response] = await handleRequest(userApi.getsBlockOrder(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {
				setUsers([
					...response.data
				])
				setPagination({
					...response.pagination
				})
			}
		},
		unBlock : async (item) => {
			let [error, response] = await handleRequest(userApi.unBlockOrder(item.id));

			let message = apiError(`Mở khóa tài khoản thất bại`, error, response);

			if (!message) {

				setLoading(false);

				let usersNew = users.filter(function(object) {
					return object.id !== item.id
				})

				setUsers(usersNew)
			}
		},
	}

	useEffect(() => {
		if(can.setUser) {
			handleEvent.searchUser({}).then()
		}
	}, []);

	if(!can.setUser) {
		return (
			<RoleError link={'/admin'}/>
		)
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1"></div>
				{users && <UserBlockOrderTable
					items={users}
					pagination={pagination}
					handleEvent={handleEvent}
					can={can}
				/>}
			</section>
		</div>
	)
}

export default UserBlockOrder;