import AccountHeader from "../components/AccoutHeader";
import {
	Image
} from "~/components";
import {
	InputPriceField
} from "~/components/Forms";
import {Alert, Col, Row} from "antd";
import {useState} from "react";
import {useCurrentUser} from "~/hooks";
import AccountFormDiscount from "../components/Forms/AccountFormDiscount";
function AccountRecharge() {

	const currentUser = useCurrentUser();

	const apiQrBanks = 'https://img.vietqr.io/image/';

	const bankNote = `Nap xu tai khoan: ${currentUser.username}`;
	const bankId        = '970422';
	const bankAccount   = '3858688888868';
	const bankName      = `Trương Ngọc Minh`;

	const [amount, setAmount] = useState(1000000);

	const [qrCode, setQrCode] = useState(`${apiQrBanks}${bankId}-${bankAccount}-print.png?amount=${amount}&addInfo=${bankNote}&accountName=${bankName}`)

	const handleCreatedOr = (value) => {
		setAmount(value)
		setQrCode(`${apiQrBanks}${bankId}-${bankAccount}-print.png?amount=${value}&addInfo=${bankNote}&accountName=${bankName}`)
	}

	return (
		<div className="container">
			<AccountHeader active={'recharge'}/>
			<section className="content pd-2 w-100 text-left">
				<div className="form">
					<Row gutter={10}>
						<Col span={12}>
							<Col span={24}><p className="heading">Thông tin ngân hàng chuyển khoản</p></Col>
							<Row className="mb-2">
								<Col span={8}>Ngân hàng</Col>
								<Col span={16}>Ngân hàng TMCP Quân đội (MBBank)</Col>
							</Row>
							<Row className="mb-2">
								<Col span={8}>Số tài khoản</Col>
								<Col span={16}>{bankAccount}</Col>
							</Row>
							<Row className="mb-2">
								<Col span={8}>Chủ tài khoản</Col>
								<Col span={16}>{bankName}</Col>
							</Row>
							<Row className="mb-2">
								<Col span={8}>Nội dung chuyển khoản</Col>
								<Col span={16}>{bankNote}</Col>
							</Row>
							<Row className="mb-2">
								<Col span={8}>Số tiền</Col>
								<Col span={16}>
									<div className="form">
										<InputPriceField name={'amount'}
										                 className={'d-block'} defaultValue={amount}
										                 onChange={(value) => handleCreatedOr(value)}></InputPriceField>
									</div>
								</Col>
							</Row>
							<Alert
								message="Lưu ý"
								description="vui lòng chuyển khoản theo nội dung trên, hệ thống sẽ tự động cộng tiền sau khi bạn chuyển khoản thành công"
								type="success"
								showIcon
							/>
							<AccountFormDiscount currentUser={currentUser} />
						</Col>
						<Col span={12}>
							<Image src={qrCode}/>
						</Col>
					</Row>

				</div>
			</section>
		</div>
	)
}

export default AccountRecharge;