import request from "~/utils/http";

const ticketApi = {
	gets : async (params) => {
		const url = 'v1/ticket';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'v1/ticket';
		return await request.post(url, params);
	},
	countStatus : async (params) => {
		const url = `v1/ticket/count-status`;
		return await request.get(url, {params});
	},
	status : async (params) => {
		const url = `v1/ticket/status/${params.id}`;
		return await request.post(url, params);
	},
	export : async (params) => {
		const url = `v1/ticket/export`;
		return await request.post(url, params);
	},
	getsComment : async (id) => {
		const url = `v1/ticket/comment/${id}`;
		return await request.get(url);
	},
	comment : async (params) => {
		const url = `v1/ticket/comment/${params.id}`;
		return await request.post(url, params);
	},
};

export default ticketApi;