import style from './DefaultLayout.module.scss';
import className from 'classnames/bind';
import Header from '~/layout/Header'
import NavMobile from "../NavBar/NavMobile";
import NavBar from "../NavBar";
import {useContext} from "react";
import {AppContext} from "~/context/AppProvider";

const cn = className.bind(style);

function DefaultLayout({children}) {

	const {isMobile, isDesktop, isTablet} = useContext(AppContext);

	return (
		<div className={cn('wrapper')}>
			<div className={cn('wrapper-inner')}>
				<Header />
				<main>
					{(isDesktop || isTablet) && <NavBar />}
					<div className={cn('content')}>
						{children}
					</div>
				</main>
				{isMobile && <NavMobile />}
			</div>
		</div>
	);
}

export default DefaultLayout;