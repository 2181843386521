import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {importHistoryActions} from "./importHistorySlice";
import {importHistoryApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(importHistoryApi.gets, action.payload);
		yield put(importHistoryActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch importHistory list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(importHistoryActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(importHistoryActions.setFilter(action.payload));
}

export default function* importHistorySaga() {
	yield takeLatest(importHistoryActions.fetchData.type, fetchDemoList);
	yield debounce(500, importHistoryActions.setFilterWithDebounce.type, handleSearchDebounce)
}