import request from "~/utils/http";

const customerApi = {
	gets : async (params) => {
		const url = 'v1/customer';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `v1/customer/${id}`;
		return await request.get(url);
	},
	getByPhone : async (params) => {
		const url = 'v1/customer/phone';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'v1/customer';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `v1/customer/${params.id}`;
		return await request.post(url, params);
	},

	importExcel : async (params) => {
		const url = `v1/customer/import/excel`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	importList : async () => {
		const url = `v1/customer/import`;
		return await request.get(url);
	},
	importDelete : async (id) => {
		const url = `v1/customer/import/${id}`;
		return await request.delete(url);
	},
	importUpload : async (params) => {
		const url = `v1/customer/import/${params.id}`;
		return await request.post(url, params);
	},
	import : async (params) => {
		const url = `v1/customer/import`;
		return await request.post(url, params);
	},
	importClear : async () => {
		const url = `v1/customer/import/clear`;
		return await request.post(url);
	},
};

export default customerApi;