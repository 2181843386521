import style from '../../style/Order.module.scss';
import className from 'classnames/bind';
import {
	useContext
} from "react";
import {
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";

const cn = className.bind(style);

function OrderTableImportResult({items = []}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Mã đơn', dataIndex: 'trackingNumber', key: "code", width: '200px', render: (_, item) => (
			<>
				<p className="color-red font-bold">{item?.trackingNumber}</p>
			</>
		)},
		{ title: 'Lỗi', dataIndex: 'error', key: "error", render: (_, item) => (
			<p className="mb-0">{item?.message}</p>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className={cn("order-table", "table-box")}>
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
		</div>
	)
}

export default OrderTableImportResult;