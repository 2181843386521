import { useState } from "react";
import _ from "lodash"
import Tippy from '@tippyjs/react/headless';
import {Button} from "~/components";
import {PopperWrapper} from "~/components/Popper";

function OrderAction({openModal, can, fileDownload, setFileDownload, selectedRowKeys}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const handleButtonClick = {
		export: () => {
			closeActionButton();
			if(fileDownload !== false) {
				setFileDownload(false)
			}
			openModal('export');
		},
		print: () => {
			openModal('print');
		},
		cancels: () => {
			openModal('cancels');
		},
	}

	const renderActionButton = () => {
		return (
			<PopperWrapper className={'action-wrapper'}>
				{can.export && <Button type="button" onClick={handleButtonClick.export}>Xuất Excel</Button>}
				{can.uploadsCod && <Button to={'/order/update-cod'}>Cập nhật COD</Button>}
				{can.uploadsWeight && <Button to={'/order/update-weight'}>Cập nhật cân nặng</Button>}
				{can.uploadsFee && <Button to={'/order/update-fee'}>Cập nhật phí ship</Button>}
				{can.uploadsStatus && <Button to={'/order/update-status'}>Cập nhật trạng thái</Button>}
			</PopperWrapper>
		)
	}

	return (
		<>
			<Tippy
				interactive
				visible={showActionBox}
				render={renderActionButton}
				onClickOutside={closeActionButton}
				placement="bottom" offset={[35, 5]}
			><Button type="button" onClick={() => setShowActionBox(true)} background blue className={'action-btn'}>Hành động</Button></Tippy>
			{!_.isEmpty(selectedRowKeys) && <Button type="button" onClick={handleButtonClick.print} background white className={'action-btn ml-0'}>In đơn</Button>}
			{(!_.isEmpty(selectedRowKeys) && can.cancel) && <Button type="button" onClick={handleButtonClick.cancels} background red className={'action-btn ml-0'}>Hủy đơn</Button>}
		</>
	)
}

export default OrderAction;