import {Badge, Tabs} from "antd";
import {globalNavigate} from "~/routes/GlobalHistory";
import {useEffect, useState} from "react";
import {apiError, handleRequest} from "~/utils";

function DiscountTabs({active, filter, discountApi}) {

	const [count, setCount] = useState({
		pending: 0,
		wait: 0,
		success: 0,
		cancel: 0,
	})

	useEffect(() => {
		async function fetchData() {
			let [error, response] = await handleRequest(discountApi.countStatus(filter));
			let message = apiError(`Tính số lượng đơn thất bại`, error, response);
			if (!message) {
				setCount(response.data)
			}
		}
		fetchData().then();
	}, [filter]);

	const itemsTab = [
		{
			key: 'running',
			label: <p>Đang chạy <Badge count={count.running} /></p>,
		},
		{
			key: 'wait',
			label: <p>Đang đợi <Badge count={count.wait} /></p>,
		},
		{
			key: 'stop',
			label: <p>Tạm dừng <Badge count={count.stop} /></p>,
		},
		{
			key: 'cancel',
			label: <p>Hết hạn <Badge count={count.cancel} /></p>,
		},
	];

	const handleTabChange = (key) => {
		globalNavigate(`/discount/${key}`);
	};

	return (
		<Tabs
			defaultActiveKey={active}
			items={itemsTab}
			onChange={handleTabChange}
		/>
	)
}
export default DiscountTabs;