import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Loading,
	Button
} from "~/components";
import {
	InputField,
	TextAreaField,
	GroupRadioField
} from "~/components/Forms";
function UserFormCoinPlus({item, onHandleSubmit}) {

	const { control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: {
			coin: 0,
			type: 'coin',
			message: ''
		},
		resolver: yupResolver(Yup.object().shape({
			coin: Yup.number().required('Bạn chưa điền số xu muốn cộng thêm'),
			type: Yup.string().required('Bạn chưa chọn loại xu muốn cộng thêm'),
			message: Yup.string().required('Bạn chưa điền lý do muốn cộng xu'),
		}))
	});

	const handleCoinPlus = async (data) => {
		await onHandleSubmit(data, item)
	}

	return (
		<form className="form" onSubmit={handleSubmit(handleCoinPlus)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="coin" render={({ field }) => (
				<InputField label="Số xu muốn cộng thêm" type={'number'} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="type" render={({field}) => (
				<GroupRadioField options={[
					{value: 'coin', label: 'Xu vĩnh viển'},
					{value: 'sale', label: 'Xu thường'},
				]} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="message" render={({ field }) => (
				<TextAreaField label="Lý do muốn cộng thêm" errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Cộng thêm</Button>
			</div>
		</form>
	)
}

export default UserFormCoinPlus;