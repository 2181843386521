import {
	useContext,
	useEffect,
	useMemo,
} from "react";
import {
	Col,
	Row
} from "antd";
import {
	Loading,
	Button,
} from "~/components";
import {
	InputField,
	InputPriceField,
	SelectField
} from "~/components/Forms";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";
import {AppContext} from "~/context/AppProvider";

function ShippingPriceForm({item, onHandleSubmit}) {

	const {shippingCompanies} = useContext(AppContext);

	const shippingOptions = useMemo(() => {
		return [...Object.entries(shippingCompanies).map(([key, ship]) => {
			return {
				value: key,
				label: ship.label
			};
		})];
	}, []);

	//Form
	const initialValues = {
		name: item?.name,
		weightTrigger: item?.weightTrigger,
		shipping: item?.shipping,
		items: [],
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Không được bỏ trống tên bảng giá'),
		shipping: Yup.string().required('Không được bỏ trống đơn vị vận chuyển'),
		weightTrigger: Yup.number().required('Không được bỏ trống cân nặng nhãy bậc'),
		items: Yup.array().of(
			Yup.object().shape({
				from: Yup.number().min(0, 'không thể nhỏ hơn 0'),
				to  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				price  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				callback  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				cost  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				trigger : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				returnFee : Yup.number().min(0, 'không thể nhỏ hơn 0')
			})
		),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "items"
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item?.name
			initialValues.shipping = item?.shipping
			initialValues.weightTrigger = item?.weightTrigger
			initialValues.items = [];
			if(Object.keys(item.items).length !== 0) {
				for (const [index, obj] of Object.entries(item.items)) {
					initialValues.items.push({
						id: obj.id,
						from: obj.from,
						to: obj.to,
						price:obj.price,
						callback:obj.callback,
						cost:obj.cost,
						trigger:obj.trigger,
						returnFee:obj.returnFee,
					})
				}
			}
			reset(initialValues)
		}
	}, [item?.id]);

	const handleAdd = () => {
		append({ id: uuidv4(), from: 0,
			to: 0,
			price:0,
			callback:0,
			cost:0,
			trigger:0,
			returnFee:0
		});
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading noFixed/>}
			<section>
				<p className={'heading'}>Thông tin</p>
				<Row gutter={10}>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="name" render={({field}) => (
							<InputField label="Tên bảng giá" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="weightTrigger" render={({field}) => (
							<InputField label="Cân nặng nhãy bậc" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="shipping" render={({field}) => (
							<SelectField label="Đơn vị vận chuyển" options={shippingOptions} errors={errors} {...field} disabled={item?.id}/>
						)}/>
					</Col>
				</Row>
			</section>
			<section>
				<p className={'heading'}>Bảng giá</p>
				<Row gutter={10}>
					<Col className="gutter-row" span={2}>
						<label htmlFor="">Từ (kg)</label>
					</Col>
					<Col className="gutter-row" span={2}>
						<label htmlFor="">Đến (kg)</label>
					</Col>
					<Col className="gutter-row" span={2}>
						<label htmlFor="">Phí gốc</label>
					</Col>
					<Col className="gutter-row" span={4}>
						<label htmlFor="">Phí vận chuyển</label>
					</Col>
					<Col className="gutter-row" span={4}>
						<label htmlFor="">Phí vận chuyển (Thu hồi)</label>
					</Col>
					<Col className="gutter-row" span={4}>
						<label htmlFor="">Phí hoàn</label>
					</Col>
					<Col className="gutter-row" span={3}>
						<label htmlFor="">Phí nhãy bậc</label>
					</Col>
					<Col className="gutter-row" span={3}></Col>
				</Row>
				{fields.map(({ id, from, to, price, callback, cost, trigger, returnFee }, index) => {
					return (
						<Row gutter={10} key={id}>
							<Col className="gutter-row" span={2}>
								<Controller control={control} name={`items[${index}].from`} render={({ field }) => (
									<InputPriceField defaultValue={from} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={2}>
								<Controller control={control} name={`items[${index}].to`} render={({ field }) => (
									<InputPriceField defaultValue={to} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={2}>
								<Controller control={control} name={`items[${index}].cost`} render={({ field }) => (
									<InputPriceField defaultValue={cost} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={4}>
								<Controller control={control} name={`items[${index}].price`} render={({ field }) => (
									<InputPriceField defaultValue={price} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={4}>
								<Controller control={control} name={`items[${index}].callback`} render={({ field }) => (
									<InputPriceField defaultValue={callback} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={4}>
								<Controller control={control} name={`items[${index}].returnFee`} render={({ field }) => (
									<InputPriceField defaultValue={returnFee} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={3}>
								<Controller control={control} name={`items[${index}].trigger`} render={({ field }) => (
									<InputPriceField defaultValue={trigger} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={3}>
								<Button primary type="button" className={'w-100'} onClick={() => remove(index)}>Xóa</Button>
							</Col>
						</Row>
					);
				})}
				<div className="d-flex justify-content-end">
					<Button white background type="button" onClick={handleAdd}>Thêm khoản giá</Button>
				</div>
			</section>
			<div className="text-right mt-1">
				<Button background blue type="submit">{(item?.id) ? 'Cập nhật bảng giá' : 'Tạo bảng giá'}</Button>
			</div>
		</form>
	)
}

export default ShippingPriceForm;