import _ from "lodash"
import {
	useEffect,
	useState
} from "react";
import {
	Col,
	notification,
	Row
} from "antd";
import {
	Loading,
	Button,
} from "~/components";
import {
	InputPriceField,
} from "~/components/Forms";
import {
	apiError,
	handleRequest,
	numberFormat
} from "~/utils";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";
import {coinApi} from "~/api";

function UserEditCoin({user}) {

	const [loading, setLoading]   = useState(false)
	//Form
	const initialValues = {
		items: [],
	}

	const validationSchema = Yup.object().shape({
		items: Yup.array().of(
			Yup.object().shape({
				from: Yup.number().min(0, 'không thể nhỏ hơn 0'),
				to  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				percent : Yup.number().min(0, 'Hoa hồng xu nhận được không thể nhỏ hơn 0').max(100, 'Hoa hồng xu không thể lớn hơn 100')
			})
		),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "items"
	});

	useEffect(() => {
		loadCoinPercentUser().then()
	}, [user?.id]);

	const loadCoinPercentUser = async () => {
		let [error, response] = await handleRequest(coinApi.getPercent(user.id));
		let message = apiError(`Lấy thông tin hoa hồng xu thâ bại`, error, response);
		if (!message) {
			let items = [];
			if (Object.keys(response.data.items).length !== 0) {
				for (const [index, item] of Object.entries(response.data.items)) {
					items.push({id: item.id, from: item.from, to: item.to, percent: item.percent})
				}
			}
			setValue('items', items)
		}
	}

	const handleCoinAddPercent = () => {
		append({ id: uuidv4(), from: 0, to: 0, percent: 0});
	}

	const handleSave = async (data) => {
		data.id = user.id
		let [error, response] = await handleRequest(coinApi.updatePercent(data));
		let message = apiError(`Cập nhật thông tin hoa hồng tính xu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: 'Cập nhật thông tin hoa hồng tính xu thành công'});
		}
	}

	return (
		<section>
			<form className="form" onSubmit={handleSubmit(handleSave)}>
				{(isSubmitting || loading) && <Loading noFixed/>}
				<Row gutter={10}>
					<Col className="gutter-row" span={6}>
						<p className={'heading'}>Xu hiện có</p>
						<p className={'color-red font-bold text-lg mb-1'}><span style={{fontSize:'30px'}}>{numberFormat(user.coin)}</span> xu vĩnh viển</p>
						<p className={'color-blue font-bold text-lg'}><span style={{fontSize:'30px'}}>{numberFormat(user.coinSale)}</span> xu khuyến mãi</p>
					</Col>
					<Col className="gutter-row" span={18}>
						<p className={'heading'}>Hoa hồng tính xu</p>
						<Row gutter={10}>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Từ</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Đến</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">% Hoa hồng</label>
							</Col>
							<Col className="gutter-row" span={3}></Col>
						</Row>
						{fields.map(({ id, from, to, percent }, index) => {
							return (
								<Row gutter={10} key={id}>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`items[${index}].from`} render={({ field }) => (
											<InputPriceField defaultValue={from} errors={(!_.isEmpty(errors) && errors?.items.hasOwnProperty(index)) && errors?.items[index]?.from} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`items[${index}].to`} render={({ field }) => (
											<InputPriceField defaultValue={to} errors={(!_.isEmpty(errors) && errors?.items.hasOwnProperty(index)) && errors?.items[index]?.to} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`items[${index}].percent`} render={({ field }) => (
											<InputPriceField defaultValue={percent} errors={(!_.isEmpty(errors) && errors?.items.hasOwnProperty(index)) && errors?.items[index]?.percent} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Button primary type="button" className={'w-100'} onClick={() => remove(index)}>Xóa</Button>
									</Col>
								</Row>
							);
						})}
						<div className="d-flex justify-content-end">
							<Button white background type="button" onClick={handleCoinAddPercent}>Thêm khoản hoa hồng</Button>
						</div>
					</Col>
				</Row>
				<div className="text-right mt-1">
					<hr />
					<Button background blue type="submit">Lưu thông tin</Button>
				</div>
			</form>
		</section>
	)
}

export default UserEditCoin;