import request from "~/utils/http";

const authApi = {
	token : async (username, password) => {
		const url = 'v1/auth/login';
		return await request.post(url, {username, password});
	},
	register : async (params) => {
		const url = 'v1/auth/register';
		return await request.post(url, params);
	},
	current : async () => {
		const url = 'v1/auth/current';
		return await request.get(url);
	},
	refresh : async (params) => {
		const url = 'v1/auth/refreshToken';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = 'v1/auth/update';
		return await request.post(url, params);
	},
	banks : async (params) => {
		const url = 'v1/auth/bank';
		return await request.post(url, params);
	},
	password : async (params) => {
		const url = 'v1/auth/password';
		return await request.post(url, params);
	},
	getPermission : async (params) => {
		const url = (params?.role) ? `v1/auth/permission/${params?.role}` : 'v1/auth/permission';
		return await request.get(url, {params});
	},
	updatePermission : async (params) => {
		const url = `v1/auth/permission/${params?.role}`;
		return await request.post(url, params);
	},
	verify : async (params) => {
		const url = `v1/auth/verify`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
};

export default authApi;