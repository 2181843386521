import className from 'classnames/bind';
import style from '../style/Auth.module.scss';
import {useSelector} from "react-redux";
import {notification} from "antd";
import {Link} from "react-router-dom";
import {AuthLoginForm} from "~/features/Auth/components";
import {authErrorSelector} from "~/features/Auth/authSlice";
import {globalNavigate} from "~/routes/GlobalHistory";
import {
	Image
} from "~/components";
const cn = className.bind(style);

function Login() {

	const error = useSelector(authErrorSelector);

	if (error) {
		notification.error({message: 'Thất bại', description: error});
	}

	const isLoggedIn = Boolean(localStorage.getItem('access_token'));

	if (isLoggedIn) {
		globalNavigate("/");
	}

	return (
		<div className={cn('login')}>
			<div className={cn('login-wrapper')}>
				<div className={cn('login-form')}>
					<div className="text-center">
						<Image src={'https://media-cdn.superexpress.vn/uploads/file-logo/super-express-logo-192x192.png'} style={{height:'100px'}} />
					</div>
					<p className={cn('name', 'text-center')}><span>SUPEREXPRESS</span></p>
					<h3>Đăng nhập</h3>
					<AuthLoginForm />
					<p className={'mt-2 text-center text-xs'}>Bạn không có tài khoản? <Link to={'/register'} className={'font-bold color-red'}>Đăng ký ngay</Link></p>
				</div>
			</div>
		</div>
	)
}

export default Login;