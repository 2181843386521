import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {
	useEffect,
	useState} from "react";
import {
	Badge, Breadcrumb,
	Col,
	notification,
	Row,
	Tabs
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	orderApi
} from "~/api";
import {
	Loading,
	RoleError
} from "~/components";
import {
	FileUpload
} from "~/components/Forms";
import {
	OrderTableUpload,
	OrderTableImportResult
} from "../components";
import {useCan} from "~/hooks";
import {Link} from "react-router-dom";
const cn = className.bind(style);

function OrderWeight() {

	const can = {
		uploadsWeight: useCan('shop_orderEdit_weight'),
	}

	const [loading, setLoading] = useState(false)

	const [itemsPassed, setItemsPassed] = useState([])
	const [countPassed, setCountPassed] = useState(0)

	const [itemsFailed, setItemsFailed] = useState([])
	const [countFailed, setCountFailed] = useState(0)

	const [countResultPassed, setCountResultPassed] = useState(0)
	const [countResultFailed, setCountResultFailed] = useState(0)
	const [resultFailed, setResultFailed] = useState([])

	useEffect(() => {
		if(can.uploadsWeight) {
			handleLoad()
		}
	}, []);

	const handleUpload = async (data) => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.importWeightExcel(data));
		let message = apiError(`Upload File thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
			await handleLoad()
		}
		setLoading(false)
	}

	const handleLoad = async (data) => {
		let [error, response] = await handleRequest(orderApi.importWeightList());
		let message = apiError(`Load dữ liệu xem trước thất bại`, error, response);
		if(!message) {
			setItemsPassed(response.data.passed);
			setItemsFailed(response.data.failed);
			setCountPassed(response.data.passedTotal);
			setCountFailed(response.data.failedTotal);
		}
	}

	const handleSave = async (data) => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.importWeightUpload());
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			setCountResultPassed(response.data.passedTotal);
			setCountResultFailed(response.data.failedTotal);
			setResultFailed(response.data.failed);
			await handleLoad()
		}
		setLoading(false)
	}

	const handleDeleteItem = async (item) => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.importWeightDelete(item.id));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			let itemsPassedNew = itemsPassed.filter(function(obj) {
				return obj.id !== item.id
			})
			let itemsFailedNew = itemsFailed.filter(function(obj) {
				return obj.id !== item.id
			})
			setItemsPassed(itemsPassedNew);
			setItemsFailed(itemsFailedNew);
		}
		setLoading(false)
	}

	const handleDeleteAll = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.importWeightClear());
		let message = apiError(`Xóa dữ liệu thất bại`, error, response);
		if(!message) {
			setItemsFailed([]);
			setCountFailed(0);
		}
		setLoading(false)
	}

	const tabItems = [
		{
			key: 'passed',
			label: <p>Hợp lệ <Badge count={countPassed}></Badge></p>,
			children: <OrderTableUpload changeLabel="Cân nặng" changeKey="weight" items={itemsPassed} onDelete={handleDeleteItem} onSubmit={handleSave} type="passed" />,
		},
		{
			key: 'failed',
			label: <p>Không hợp lệ <Badge count={countFailed}></Badge></p>,
			children: <OrderTableUpload changeLabel="Cân nặng" changeKey="weight" items={itemsFailed} onDelete={handleDeleteItem} onSubmit={handleDeleteAll} type="failed" />,
		},
	];

	if(!can.uploadsWeight) {
		return (<RoleError link={'/order'} />)
	}

	return (
		<div className={cn('order-gird')}>
			<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/order'}>Đơn hàng</Link>,},
				            {title: 'Cập nhật cân nặng',},
			            ]}
			/>
			<Row gutter={10}>
				<Col span={8}>
					<section>
						<p className="heading">Tải file excel lên hệ thống</p>
						<FileUpload
							onChange={handleUpload}
							description={'Chấp nhận file .xls và .xlsx'}
							links={'file-demo/mau-cap-nhat-weight-v1.xlsx'}
						/>
					</section>
					<section>
						<p className="heading">Kết quả cập nhật</p>
						<p className="mb-1">Cập nhật thành công <span className="color-green font-bold">{countResultPassed}</span> đơn</p>
						<p className="mb-1">Cập nhật thất bại <span className="color-red font-bold">{countResultFailed}</span> đơn</p>
						<OrderTableImportResult items={resultFailed} />
					</section>
				</Col>
				<Col span={16}>
					<section>
						<p className="heading">Xem trước</p>
						{loading && <Loading noFixed />}
						<Tabs defaultActiveKey="passed" items={tabItems}/>
					</section>
				</Col>
			</Row>
		</div>
	)
}
export default OrderWeight;