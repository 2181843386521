import {useContext, useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
} from "~/components";
import {
	GroupRadioField,
} from "~/components/Forms";
import {AppContext} from "~/context/AppProvider";

function UserFormRole({item, onHandleSubmit}) {

	const {rolesData} = useContext(AppContext);

	const rolesOptions = useMemo(() => {
		return [...Object.entries(rolesData).map(([key, value]) => {
			return {
				value: key,
				label: value.label
			};
		})];
	}, []);

	const initialValues = {
		role: item?.role,
	}

	const validationSchema = Yup.object().shape({
		role: Yup.string().required('Chức vụ thành viên không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.role = item.role;
			reset(initialValues);
		}

	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="role" render={({field}) => (
				<GroupRadioField options={rolesOptions} errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Chuyển chức vụ</Button>
			</div>
		</form>
	)
}

export default UserFormRole;