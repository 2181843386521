import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
	Icon
} from "~/components";
import {
	GroupRadioField,
} from "~/components/Forms";
import className from "classnames/bind";
import style from "../../style/Order.module.scss";
const cn = className.bind(style);
function OrderFormExport({fileDownload, onHandleSubmit, label = 'Đơn hàng'}) {

	const typeOptions = [
		{value : 'pageCurrent', label : 'Tất cả '+label+' ở trang hiện tại'},
		{value : 'checked', label : 'Tất cả '+label+' đã chọn'},
		{value : 'search', label : 'Tất cả '+label+' theo bộ lọc'},
	];

	const initialValues = {
		type: 'pageCurrent',
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Loại xuất đơn hàng không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data.type);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			{
				fileDownload !== false ? (
					<div className="d-flex justify-content-center">
						<div className="text-center">
							<p className="font-bold text-lg mb-2">Tạo File Excel thành công</p>
							<Button href={fileDownload} download blue background leftIcon={Icon.download}>Download File</Button>
						</div>
					</div>
				) : (
					<div className={cn('group-radio-block')}>
						<Controller control={control} name="type" render={({field}) => (
							<GroupRadioField options={typeOptions} errors={errors} {...field}/>
						)}/>
						<div className="form-group d-flex justify-content-end modal-bottom">
							<Button primary type="submit">Xuất đơn</Button>
						</div>
					</div>
				)
			}
		</form>
	)
}

export default OrderFormExport;