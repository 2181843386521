import request from "~/utils/http";

const shippingApi = {
	gets : async (params) => {
		const url = 'v1/shipping-api';
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'v1/shipping-api/add';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = 'v1/shipping-api/update';
		return await request.post(url, params);
	},
	closed : async (params) => {
		const url = 'v1/shipping-api/closed';
		return await request.post(url, params);
	},
	using : async (params) => {
		const url = 'v1/shipping-api/using';
		return await request.post(url, params);
	}
};

export default shippingApi;