import style from './FileUpload.module.scss';
import className from 'classnames/bind';
import {forwardRef} from "react";
import {
	Button,
	Icon
} from "../../index";
const cn = className.bind(style);

const FileUpload = forwardRef(({ name, links, description, onChange, files, setFiles, ...props }, ref) => {

	const uploadHandler = (event) => {

		const fileList = event.target.files;

		if(!fileList) return;

		// upload file
		const formData = new FormData();

		for (const [i, file] of Object.entries(fileList)) {
			formData.append(`file`, file, file.name);
		}

		onChange(formData)

		if(setFiles !== undefined && !setFiles) {
			setFiles([...files, ...fileList])
		}

		event.target.value = '';
	}

	return (
		<>
			<div className={cn("file-card")}>
				<div className={cn("file-inputs")}>
					<input name={name} type="file" onChange={uploadHandler} {...props} ref={ref} multiple />
					<button><span>{Icon.plus}</span> Upload</button>
				</div>
				{
					description &&  <p className="color-grey mb-2">{description}</p>
				}
				{
					links &&
					<Button background blue small leftIcon={Icon.download} href={`https://media-cdn.superexpress.vn/uploads/${links}`} download>Tài file excel mẫu</Button>
				}
			</div>
		</>
	)
});

export default FileUpload