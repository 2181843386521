import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {reportUserActions} from "./reportUserSlice";
import {reportApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(reportApi.users, action.payload);
		yield put(reportUserActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch reportUser list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(reportUserActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(reportUserActions.setFilter(action.payload));
}

export default function* reportUserSaga() {
	yield takeLatest(reportUserActions.fetchData.type, fetchDemoList);
	yield debounce(500, reportUserActions.setFilterWithDebounce.type, handleSearchDebounce)
}