import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {branchActions} from "./branchSlice";
import {branchApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(branchApi.gets, action.payload);
		yield put(branchActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch branch list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(branchActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(branchActions.setFilter(action.payload));
}

export default function* branchSaga() {
	yield takeLatest(branchActions.fetchData.type, fetchDemoList);
	yield debounce(500, branchActions.setFilterWithDebounce.type, handleSearchDebounce)
}