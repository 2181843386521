import style from './Comments.module.scss';
import className from 'classnames/bind';
import {useState, useRef, useEffect} from "react";
import _ from "lodash";
import {Empty, notification} from "antd";
import {apiError, handleRequest} from "~/utils";
import {ticketApi} from "~/api";
import {
	Button,
	FontAwesomeIcon,
	Loading
} from "../../../../components";
import Message from "./Message";
import {useCurrentUser} from "../../../../hooks";

const cn = className.bind(style);

function Comments({type, objectId}) {
	const currentUser = useCurrentUser();
	let [comments, setComments] = useState([]);
	let [commentLoading, setCommentLoading] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const inputRef      = useRef();
	const roomRef       = useRef(null);

	const handleLoadComments = async () => {
		setCommentLoading(true);
		let [error, response] = await handleRequest(ticketApi.getsComment(objectId));
		let message = apiError(`Load phản hồi thất bại`, error, response);
		if(!message) {
			setComments(response.data)
		}
		if (roomRef.current) {
			roomRef.current.scrollTop = roomRef.current.scrollHeight;
		}
		setCommentLoading(false);
	}

	useEffect(() => {
		handleLoadComments().then();
	}, [objectId, type])

	// Handle input change
	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	// Press "Enter" to send messages
	const handleKeyUp = (e) => {
		if (e.key === "Enter") {
			handleOnSubmit().then();
		}
	};

	// Clear preview
	const handleClearPreview = () => {
		//clear input
		inputRef.current.focus();
	};

	const handleOnSubmit = async () => {
		// Send text only
		let [error, response] = await handleRequest(ticketApi.comment({
			id : objectId,
			objectType: type,
			message: inputValue,
		}));
		let message = apiError(`Không tải tệp lên được`, error, response);
		if (!message) {
			comments = [...comments, response.data.item];
			setComments(comments);
		}
		handleClearPreview();
		setInputValue("");
		if (roomRef.current) {
			roomRef.current.scrollTop = roomRef.current.scrollHeight;
		}
	}

	return (
		<div className={cn("container")}>
			<div className={cn("wrapper")}>
				<div className={cn("area")} ref={roomRef}>
					{
						commentLoading && <Loading noFixed style={{width:'100%'}} />
					}
					{
						(_.isEmpty(comments)) && <Empty />
					}
					{
						Object.keys(comments).map((itemKey) => {
							let comment     = comments[itemKey];
							let preComment  = 0;
							if(itemKey != 0) {
								preComment = comments[itemKey - 1].user.id
							}
							return (
								<Message currentUserId={currentUser?.id} key={`message-${comment.id}`} item={comment} preCommentUser={preComment} />
							)
						})
					}
				</div>
				<div className={cn("typing-area-wrapper")}>
					<div className={cn("typing-area")}>
						<div className={cn("input-wrapper")}>
							<input
								ref={inputRef}
								onChange={handleInputChange}
								onKeyUp={handleKeyUp}
								value={inputValue}
								type="text"
								spellCheck="false"
								placeholder="Aa"
								className={cn("input")}
							/>
						</div>
						<div className={cn("send-wrapper")}>
							<Button background theme rounded noneBorder className={cn('btnSend')} onClick={handleOnSubmit}><FontAwesomeIcon icon="fa-light fa-paper-plane" /></Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Comments;