import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useContext,
	useEffect,
	useState
} from "react";
import {
	notification,
} from "antd";
import {
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import {
	UserFormPass,
	UserFormStatus,
	UserFormRole,
	UserFormCoinPlus,
	UserFormCoinMinus,
	UserSearch,
	UserTable,
	UserTabs
} from "../components";
import _ from "lodash";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {
	apiError,
	handleRequest
} from "~/utils";
import {useCan} from "~/hooks";
import {OrderFormExport} from "../../Order/components";
import {
	userApi,
	coinApi
} from "~/api";
import {
	userActions,
	userItemsSelector,
	userLoadingSelector,
	userErrorSelector,
	userPaginationSelector,
	userFilterSelector
} from "../userSlice";
import {AppContext} from "../../../context/AppProvider";

function User() {

	const can = {
		edit: useCan('shop_user_edit'),
		password: useCan('shop_user_password'),
		affiliate: useCan('shop_aff_active'),
		affiliateCode: useCan('shop_aff_code'),
		affiliatePercent: useCan('shop_aff_percent'),
		role: useCan('shop_user_roles'),
		export: useCan('shop_user_excel'),
		status: useCan('shop_user_status'),
		loginAs: useCan('shop_user_login_as'),
		coinPlus: useCan('shop_coin_plus'),
		coinMinus: useCan('shop_coin_minus'),
	}

	const dispatch = useDispatch();

	const items = useSelector(userItemsSelector);

	const loading = useSelector(userLoadingSelector);

	const error = useSelector(userErrorSelector);

	const pagination = useSelector(userPaginationSelector);

	const filter = useSelector(userFilterSelector);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [fileDownload, setFileDownload] = useState(false);

	const {rolesData} = useContext(AppContext);

	//Modal show
	const [openModal, setOpenModal] = useState({
		password : false,
		role : false,
		affiliateEnable : false,
		affiliateDisable : false,
		coinPlus : false,
		coinMinus : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		active : async (item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa thành viên'});
				return;
			}
			let [error, response] = await handleRequest(userApi.status({
				id : item.id,
				status : 'public'
			}));

			let message = apiError(`Thay đổi trạng thái thành viên thất bại`, error, response);
			if (!message) {
				dispatch(userActions.update({
					...item,
					status: 'public'
				}));
			}
		},
		block : async (item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa thành viên'});
				return;
			}
			let [error, response] = await handleRequest(userApi.status({
				id : item.id,
				status : 'block'
			}));

			let message = apiError(`Thay đổi trạng thái thành viên thất bại`, error, response);
			if (!message) {
				dispatch(userActions.update({
					...item,
					status: 'block'
				}));
			}
		},
		password: async (data, item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin thành viên để cập nhật'});
				return;
			}

			data.id = item.id;

			let [error, response] = await handleRequest(userApi.password(data));

			let message = apiError(`Cập nhật mật khẩu thất bại`, error, response);

			if (!message) {
				notification.success({
					message: 'Thành công', description: `Đổi mật khẩu thành công`
				});
				handleEvent.closeModal('password');
			}
		},
		role: async (data, item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin thành viên để cập nhật'});
				return;
			}

			data.id = item.id;

			let [error, response] = await handleRequest(userApi.role(data));

			let message = apiError(`Cập nhật chức vụ bại`, error, response);

			if (!message) {
				notification.success({
					message: 'Thành công', description: `Đổi chức vụ thành công`
				});
				dispatch(userActions.update({
					...item,
					role: data.role
				}))
				handleEvent.closeModal('role');
			}
		},
		loginAs: async (item) => {
			localStorage.setItem('access_token_as', item.id);
			window.location.reload(false)
		},
		export: async (type) => {
			if (type === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn kiểu xuất'});
				return;
			}

			let params = {
				type: type,
				listData: [],
				filter: []
			};

			if(type === 'pageCurrent') {
				if(_.isEmpty(items)) {
					notification.error({message: 'Lỗi', description: 'Trang hiện không có user nào'});
					return;
				}
				for (const [index, item] of Object.entries(items)) {
					params.listData.push(item.id)
				}
			}

			if(type === 'checked') {
				if(_.isEmpty(selectedRowKeys)) {
					notification.error({message: 'Lỗi', description: 'Bạn chưa chọn user nào'});
					return;
				}
				params.listData = selectedRowKeys;
			}

			if(type === 'search') {
				params.filter = filter;
			}

			let [error, response] = await handleRequest(userApi.export(params));
			let message = apiError(`Xuất user thất bại`, error, response);
			if(!message) {
				setFileDownload(response.data.file)
			}
		},
		affiliateEnable: async () => {
			if (itemEdit?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có user để cập nhật'});
				return;
			}
			let [error, response] = await handleRequest(userApi.affiliateStatus({
				id: itemEdit.id,
				active: true
			}));
			let message = apiError(`kích hoạt cộng tác viên thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Kích hoạt cộng tác viên thành công`});
				itemEdit.aff = response.data.aff;
				itemEdit.referralCode = response.data.referralCode;
				dispatch(userActions.update(itemEdit));
				handleEvent.closeModal('affiliateEnable')
			}
		},
		affiliateDisable: async () => {
			if (itemEdit?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có user để cập nhật'});
				return;
			}
			let [error, response] = await handleRequest(userApi.affiliateStatus({
				id: itemEdit.id,
				active: false
			}));
			let message = apiError(`Hủy cộng tác viên thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Hủy cộng tác viên thành công`});
				itemEdit.aff = 0;
				itemEdit.referralCode = '';
				dispatch(userActions.update(itemEdit));
				handleEvent.closeModal('affiliateDisable')
			}
		},
		search: (newFilter) => {
			dispatch(userActions.setFilterWithDebounce(newFilter));
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(userActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(userActions.setFilter({...filter, page }));
		},
		changeSearch: (newFilter) => {
			dispatch(userActions.setFilterWithDebounce(newFilter));
		},
		coinPlus: async (data, item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin thành viên để cập nhật'});
				return;
			}

			data.id = item.id;

			let [error, response] = await handleRequest(coinApi.plus(data));

			let message = apiError(`Thêm xu thất bại thất bại`, error, response);

			if (!message) {

				notification.success({
					message: 'Thành công', description: `Thêm xu thành công`
				});

				if(response.data.type == 'coin') {
					dispatch(userActions.update({
						...item,
						coin: response.data.coinAfter
					}))
				}

				if(response.data.type == 'sale') {
					dispatch(userActions.update({
						...item,
						coinSale: response.data.coinAfter
					}))
				}

				handleEvent.closeModal('coinPlus');
			}
		},
		coinMinus: async (data, item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin thành viên để cập nhật'});
				return;
			}

			data.id = item.id;

			let [error, response] = await handleRequest(coinApi.minus(data));

			let message = apiError(`Trừ xu thất bại thất bại`, error, response);

			if (!message) {

				notification.success({
					message: 'Thành công', description: `Trừ xu thành công`
				});

				if(response.data.type == 'coin') {
					dispatch(userActions.update({
						...item,
						coin: response.data.coinBefore
					}))
				}

				if(response.data.type == 'sale') {
					dispatch(userActions.update({
						...item,
						coinSale: response.data.coinBefore
					}))
				}

				handleEvent.closeModal('coinMinus');
			}
		},
	}

	//Load data
	useEffect(() => {
		dispatch(userActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					<UserSearch filter={filter} rolesData={rolesData} onSearchChange={handleEvent.changeSearch}/>
					{can.export && <Button background white className="mb-1" onClick={() => handleEvent.openModal('export')}>Xuất đơn</Button>}
				</div>
				{items && <UserTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
					can={can}
				/>}
			</section>
			{
				(can.export && openModal.export) &&
				<Modal title="Xuất đơn hàng" visible={openModal.export} onCancel={() => {handleEvent.closeModal('export')}}>
					<OrderFormExport fileDownload={fileDownload} onHandleSubmit={handleEvent.export} label={'User'} />
				</Modal>
			}
			{
				(can.status && itemEdit?.id && openModal.status) &&
				<Modal title="Thai đổi trạng thái" visible={openModal.status} onCancel={() => {handleEvent.closeModal('status')}}>
					<UserFormStatus item={itemEdit} onHandleSubmit={handleEvent.status} />
				</Modal>
			}
			{
				(can.role && itemEdit?.id && openModal.role) &&
				<Modal title="Thai đổi chức vụ" visible={openModal.role} onCancel={() => {handleEvent.closeModal('role')}}>
					<UserFormRole item={itemEdit} onHandleSubmit={handleEvent.role} />
				</Modal>
			}
			{
				(can.password && openModal.password && itemEdit?.id) && <Modal title="Đổi mật khẩu" visible={openModal.password} onCancel={() => handleEvent.closeModal('password')}>
					<UserFormPass item={itemEdit} onHandleSubmit={handleEvent.password} />
				</Modal>
			}
			{
				(can.coinPlus && openModal.coinPlus && itemEdit?.id) && <Modal title="Cộng thêm xu" visible={openModal.coinPlus} onCancel={() => handleEvent.closeModal('coinPlus')}>
					<UserFormCoinPlus item={itemEdit} onHandleSubmit={handleEvent.coinPlus} />
				</Modal>
			}
			{
				(can.coinMinus && openModal.coinMinus && itemEdit?.id) && <Modal title="Trừ xu" visible={openModal.coinMinus} onCancel={() => handleEvent.closeModal('coinMinus')}>
					<UserFormCoinMinus item={itemEdit} onHandleSubmit={handleEvent.coinMinus} />
				</Modal>
			}
			{
				(can.affiliate && openModal.affiliateEnable && itemEdit?.id) && <Modal title="Kích hoạt" visible={openModal.affiliateEnable} onCancel={() => {handleEvent.closeModal('affiliateEnable')}}>
					<p>Kích hoạt tài khoản <b>{itemEdit?.lastname}</b> thành cộng tác viên?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleEvent.closeModal('affiliateEnable')}}> Đóng </Button>
						<Button background blue leftIcon={Icon.success} onClick={handleEvent.affiliateEnable}> Xác nhận </Button>
					</div>
				</Modal>
			}
			{
				(can.affiliate && openModal.affiliateDisable && itemEdit?.id) && <Modal title="Kích hoạt" visible={openModal.affiliateDisable} onCancel={() => {handleEvent.closeModal('affiliateDisable')}}>
					<p>Hủy chức năng cộng tác viên của tài khoản tài khoản <b>{itemEdit?.lastname}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleEvent.closeModal('affiliateDisable')}}> Đóng </Button>
						<Button background red leftIcon={Icon.success} onClick={handleEvent.affiliateDisable}> Xác nhận </Button>
					</div>
				</Modal>
			}
		</div>
	)
}
export default User;