import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {shippingPriceActions} from "./shippingPriceSlice";
import {shippingPriceApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(shippingPriceApi.gets, action.payload);
		yield put(shippingPriceActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch shippingPrice list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(shippingPriceActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(shippingPriceActions.setFilter(action.payload));
}

export default function* shippingPriceSaga() {
	yield takeLatest(shippingPriceActions.fetchData.type, fetchDemoList);
	yield debounce(500, shippingPriceActions.setFilterWithDebounce.type, handleSearchDebounce)
}