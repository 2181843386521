import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
} from "~/components";
import {
	GroupRadioField,
} from "~/components/Forms";
function OrderCodFormAdd({onHandleSubmit, label = 'Đơn hàng'}) {

	const typeOptions = [
		{value : 'pageCurrent', label : 'Tất cả '+label+' ở trang hiện tại'},
		{value : 'checked', label : 'Tất cả '+label+' đã chọn'},
		{value : 'search', label : 'Tất cả '+label+' theo bộ lọc'},
	];

	const initialValues = {
		type: 'pageCurrent',
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Loại lọc đơn hàng không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data.type);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<div className={'group-radio-block'}>
				<Controller control={control} name="type" render={({field}) => (
					<GroupRadioField options={typeOptions} errors={errors} {...field}/>
				)}/>
				<div className="form-group d-flex justify-content-end modal-bottom">
					<Button primary type="submit">Tạo đối soát</Button>
				</div>
			</div>
		</form>
	)
}

export default OrderCodFormAdd;