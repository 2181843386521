import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {discountActions} from "./discountSlice";
import {discountApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(discountApi.gets, action.payload);
		yield put(discountActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch discount list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(discountActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(discountActions.setFilter(action.payload));
}

export default function* discountSaga() {
	yield takeLatest(discountActions.fetchData.type, fetchDemoList);
	yield debounce(500, discountActions.setFilterWithDebounce.type, handleSearchDebounce)
}