import {Button, Icon, FontAwesomeIcon} from "~/components";
import {Tooltip} from "antd";
import {useState} from "react";

function DiscountTableAction({item, setItemEdit, handleEvent, can}) {

	const [loading, setLoading] = useState(false)

	const handleButtonClick = {
		stop: async () => {
			setLoading(true)
			await handleEvent.stop(item);
			setLoading(false)
		},
		running: async () => {
			setLoading(true)
			await handleEvent.running(item);
			setLoading(false)
		},
	}

	return (
		<>
			{
				(can.status && item.status === 'running') && <Tooltip title="Tạm dừng">
					<Button small background green onClick={handleButtonClick.stop} disabled={loading}>
						{loading ? Icon.loading : <FontAwesomeIcon icon="fa-duotone fa-stop" />}
					</Button>
				</Tooltip>
			}
			{
				(can.status && item.status === 'stop') && <Tooltip title="Chạy">
					<Button small background red onClick={handleButtonClick.running} disabled={loading}>
						{loading ? Icon.loading : <FontAwesomeIcon icon="fa-duotone fa-play" />}
					</Button>
				</Tooltip>
			}

		</>
	)
}

export default DiscountTableAction;