import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {postOfficeActions} from "./postOfficeSlice";
import {postOfficeApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(postOfficeApi.gets, action.payload);
		yield put(postOfficeActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch postOffice list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(postOfficeActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(postOfficeActions.setFilter(action.payload));
}

export default function* postOfficeSaga() {
	yield takeLatest(postOfficeActions.fetchData.type, fetchDemoList);
	yield debounce(500, postOfficeActions.setFilterWithDebounce.type, handleSearchDebounce)
}