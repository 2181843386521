import {memo} from "react";
import {
	InputField
} from "~/components/Forms";
import {Button} from "~/components";
import {Controller, useForm} from "react-hook-form";
function DiscountSearch({ filter, onSearchChange }) {

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {
		type: ''
	}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			page: 1,
			keyword: data?.keyword,
		};
		onSearchChange(newFilter);
	}

	return (
		<form className="form form-search d-flex justify-content-between gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="keyword" render={({field}) => (
				<InputField placeholder={'Mã khuyến mãi'} defaultValue={filter?.keyword} errors={errors} {...field} />
			)}/>
			<Button background primary>Áp dụng</Button>
		</form>
	)
}

export default memo(DiscountSearch);