import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {orderCashActions} from "./orderCashSlice";
import {orderApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(orderApi.cashFlow, action.payload);
		yield put(orderCashActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch orderCash list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(orderCashActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(orderCashActions.setFilter(action.payload));
}

export default function* orderCashCashSaga() {
	yield takeLatest(orderCashActions.fetchData.type, fetchDemoList);
	yield debounce(500, orderCashActions.setFilterWithDebounce.type, handleSearchDebounce)
}