import images from '~/assets/images';
import {Image} from "~/components";
import {renderAddress, numberFormat, isEmpty} from "~/utils";
import {QRCode} from "antd";
import Barcode from "react-barcode";
function OrderPrintItem({item, DeliveryInstructions, printHidden, country}) {
    return (
        <>
            <div style={{border:'1px solid #000',padding:'10px'}}>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '20%'}}>
                            <Image src={images.logoPrint} style={{height: '50px'}}/>
                            <p style={{lineHeight: '10px', fontSize: '13px'}}><strong>{item.code}</strong></p>
                        </td>
                        <td style={{width: '20%'}}>
                            <div style={{display: 'inline-block'}}>
                                <Barcode value={item?.trackingNumber} height={50}/>
                            </div>
                        </td>
                        <td className="info-company" style={{width: '60%', paddingLeft: '10px', textAlign: 'center'}}>
                            <div style={{display: 'inline-block'}}>
                                <QRCode type="canvas" value={item?.trackingNumber} size={80} bordered={false}/>
                            </div>
                            <p style={{
                                fontSize: '12px',
                                lineHeight: '10px',
                                fontWeight: 'bold'
                            }}>{item?.trackingNumber}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <table className="customer-info" style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '50%', border: '1px solid #ccc', padding: '10px', verticalAlign:'top'}}>
                            <h4 style={{marginBottom:'10px', fontSize:'13px'}}><b>Bên gửi</b></h4>
                            {!printHidden.form.includes('name') && <p style={{lineHeight:'20px', fontSize:'10px'}}><span>{item.fromName}</span></p>}
                            {!printHidden.form.includes('phone') && <p style={{lineHeight:'20px', fontSize:'10px'}}><span>{item.fromPhone}</span></p>}
                            {!printHidden.form.includes('address') && <p style={{lineHeight:'20px', fontSize:'10px'}}><span>{(!isEmpty(item?.fromAddress2)) ? item?.fromAddress2 : item?.fromAddress + ',' + renderAddress(item.fromCity, item.fromDistrict, item.fromWard, country)}</span></p>}
                        </td>
                        <td style={{width:'50%', border:'1px solid #ccc', padding:'10px', verticalAlign:'top'}}>
                            <h4 style={{marginBottom:'10px', fontSize:'13px'}}><b>Bên nhận</b></h4>
                            {!printHidden.to.includes('name') && <p style={{lineHeight:'20px', fontSize:'10px'}}><span>{item.toName}</span></p>}
                            {!printHidden.to.includes('phone') && <p style={{lineHeight:'20px', fontSize:'10px'}}><span>{item.toPhone}</span></p>}
                            {!printHidden.to.includes('address') && <p style={{lineHeight:'20px', fontSize:'10px'}}><span>{item.toAddress + ',' + renderAddress(item.toCity, item.toDistrict, item.toWard, country)}</span></p>}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table className="customer-info" style={{width:'100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width:'50%', padding:'5px 0', verticalAlign:'top'}}>
                            <p style={{lineHeight:'10px', fontSize:'12px'}}><strong>COD</strong> <span>{numberFormat(item.total)} vnđ</span></p>
                        </td>
                        <td style={{width:'50%', padding:'5px 0', verticalAlign:'top'}}>
                            <p style={{lineHeight:'10px', fontSize:'10px'}}>
                                {!printHidden.order.includes('weight') && <span>{item.weight} Kg</span>}
                                {!printHidden.order.includes('size') && <span>{item.long}cm x {item.width}cm x {item.height}cm</span>}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{border:'1px solid #ccc', padding:'5px'}}>
                    {<DeliveryInstructions item={item} />}
                </div>
            </div>
            <div className="break-page-print"></div>
        </>
    )
}
export default OrderPrintItem;