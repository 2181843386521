import {useContext, useEffect, useMemo, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
} from "~/components";
import {
	GroupRadioField,
	InputPriceField,
	TextAreaField,
	InputField,
	SelectField,
	GroupCheckBoxField
} from "~/components/Forms";
import {Col, Row} from "antd";
import {AppContext} from "~/context/AppProvider";

function OrderFormTicket({item, onHandleSubmit}) {

	const {country} = useContext(AppContext);

	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const typeOptions = [
		{ value: 'cod',         label : 'Yêu cầu chỉnh sửa COD'},
		{ value: 'resend',      label : 'Yêu cầu phát lại'},
		{ value: 'changeReceive', label : 'Chỉnh sửa thông tin người nhận'},
		{ value: 'complain',    label : 'Khiếu nại/Đền bù'},
		{ value: 'returnOrder', label : 'Yêu cầu duyệt hoàn đơn'},
		{ value: 'orther',      label : 'Khác'}
	];

	const complainOptions = [
		{ value: 'complain_orther', label : 'Đền bù phí giao hàng'},
		{ value: 'complain_cod',    label : 'Đền bù COD'},
		{ value: 'complain_fee',    label : 'Khác'},
	];

	const initialValues = {
		type: 'cod',
		cod: item.cod,
		toName: item?.toName,
		toPhone: item?.toPhone,
		toAddress: item?.toAddress,
		toCity: item?.toCity,
		toDistrict: item?.toDistrict,
		toWard: item?.toWard,
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Loại khiếu nại không được để trống'),
	})

	const { control, watch, setValue, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	let typeWatch = watch('type')

	useEffect(() => {
		if(typeWatch == 'changeReceive') {
			setValue('toName', item?.toName)
			setValue('toPhone', item?.toPhone)
			setValue('toAddress', item?.toAddress)
			setValue('toCity', item?.toCity)
			setValue('toDistrict', item?.toDistrict)
			setValue('toWard', item?.toWard)

			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == item.toCity) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == item.toDistrict) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
	}, [item?.id, typeWatch])

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="type" render={({field}) => (
				<GroupRadioField options={typeOptions} errors={errors} {...field}/>
			)}/>
			{
				typeWatch == 'cod' && <>
					<p className="font-bold color-red text-sm mb-1">(*) Hãy nhập COD, đây là trường bắt buộc nhập.</p>
					<Controller control={control} name="cod" render={({field}) => (
						<InputPriceField errors={errors} {...field}/>
					)}/>
				</>
			}
			{
				typeWatch == 'changeReceive' &&
				<>
					<p className="font-bold color-red text-sm mb-1">(*) Hãy nhập giá trị thông tin khách hàng mới vào đây.</p>
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="toName" render={({field}) => (
								<InputField label="Họ tên" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="toPhone" render={({ field }) => (
								<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="toAddress" render={({ field }) => (
								<InputField label="Địa chỉ" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="toCity" render={({ field }) => (
								<SelectField label="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
									handleCityChange(value);
									field.onChange(value, event);
								}}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="toDistrict" render={({ field }) => (
								<SelectField label="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
									handleDistrictChange(value);
									field.onChange(value, event);
								}}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="toWard" render={({ field }) => (
								<SelectField label="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				</>
			}
			{
				typeWatch == 'complain' && <>
					<p className="font-bold color-red text-sm mb-1">(*) Hãy chọn 1 loại đền bù</p>
					<Controller control={control} name="complain" render={({field}) => (
						<GroupCheckBoxField options={complainOptions} errors={errors} {...field}/>
					)}/>
				</>
			}
			<p className="font-bold color-red text-sm mb-1">(*) Vui lòng nhập thêm nội dung mô tả tại đây.</p>
			<Controller control={control} name="message" render={({field}) => (
				<TextAreaField rows={10} errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Thêm Khiếu Nại</Button>
			</div>
		</form>
	)
}

export default OrderFormTicket;