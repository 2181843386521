import style from '../style/Account.module.scss';
import className from 'classnames/bind';
import {
	useCurrentUser,
	useCan
} from "~/hooks";
import {globalNavigate} from "../../../routes/GlobalHistory";
import {Tabs} from "antd";
import UserVerify from "../../../components/UserVerify/UserVerify";
import {useDispatch} from "react-redux";
import {authActions} from "../../Auth/authSlice";
import {
	Button,
	Icon
} from "~/components";
const cn = className.bind(style);

function AccountHeader({active}) {

	const can = {
		api: useCan('shop_api')
	}

	const currentUser = useCurrentUser();

	const itemsTab = [
		{
			key: 'info',
			label: 'Thông tin',
		},
		{
			key: 'banks',
			label: 'Tài khoản ngân hàng',
		},
		{
			key: 'coin',
			label: 'Xu tích lũy',
		},
		{
			key: 'recharge',
			label: 'Nạp xu',
		},
		{
			key: 'password',
			label: 'Mật khẩu',
		},
	];

	if(can.api) {
		itemsTab.push({
			key: 'api',
			label: 'Api',
		})
	}

	const dispatch = useDispatch();

	const handleLogout = () => {
		localStorage.clear();
		dispatch(authActions.logout());
		globalNavigate("/login");
	}

	const handleTabChange = (key) => {
		globalNavigate(`/account/${key}`);
	};

	return (
		<section className={cn('profile-heading')}>
			<div className={cn('profile-name')}>
				<p className={cn('profile-name-main', 'text-xl')}>{currentUser.firstname} {currentUser.lastname}</p>
				<p className={cn('profile-name-mini', 'text-sm')}>@{currentUser.username}</p>
				<p className={cn('profile-name-mini', 'text-sm')}><UserVerify user={currentUser} /></p>
			</div>
			<div className="text-center">
				<Button background red leftIcon={Icon.logout} onClick={handleLogout}>Đăng xuất</Button>
			</div>
			<Tabs
				defaultActiveKey={active}
				items={itemsTab}
				onChange={handleTabChange}
			/>
		</section>
	)
}

export default AccountHeader;