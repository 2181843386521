import style from '../../style/Order.module.scss';
import className from 'classnames/bind';
import {
	useContext
} from "react";
import {
	Button,
	Icon
} from "~/components";
import {
	Table, Tooltip
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	renderAddress,
	numberFormat
} from "~/utils";
import DOMPurify from "dompurify";

const cn = className.bind(style);

function OrderTableImport({items = [], type, onSubmit, onDelete, onModalOpen, setItemEdit}) {

	const {country} = useContext(AppContext);

	const {tableHeight} = useContext(AppContext);

	function onClickEditAddress(item) {
		onModalOpen('formAddress')
		setItemEdit(item)
	}

	const tooltipContent = (note) => {
		return (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${note}`) }} />)
	}

	let columns = [
		{ title: 'Bên nhận', dataIndex: 'to', key: "to", width: '250px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				{
					(type == 'failed' && item?.errors) && <Tooltip title={tooltipContent(item?.errors)}>
						<Button background red type="button" small>Chi tiết lỗi</Button>
					</Tooltip>
				}
			</>
		)},
		{ title: 'Địa chỉ nhận', dataIndex: 'address', key: "address", width: '400px', render: (_, item) => (
			<>
				<p>{item?.toAddress}</p>
				<p className="italic color-red">{renderAddress(item?.toCity, item?.toDistrict, item?.toWard, country)}</p>
				<Button small background blue onClick={()=>onClickEditAddress(item)}>{Icon.edit}</Button>
			</>
		)},
		{ title: 'Cân nặng', dataIndex: 'weight', key: "weight", render: (_, item) => (
			<p className="mb-0">{item?.weight} Kg</p>
		)},
		{ title: 'Cod', dataIndex: 'cod', key: "cod", render: (_, item) => (
			<p className="mb-0">{numberFormat(item?.cod)}</p>
		)},
		{ title: 'Bảo hiểm', dataIndex: 'insurance', key: "insurance", render: (_, item) => (
			<p className="mb-0">{numberFormat(item?.insurance)}</p>
		)},
		{ title: 'Cước phí', dataIndex: 'shipping', key: "shipping", render: (_, item) => (
			<>
				<p className="mb-0">{numberFormat(item?.shipping)}</p>
				<p className="mb-0">{(item?.paymentType && item?.paymentType == 1) ? 'Bên gửi hàng trả phí' : 'Bên nhận hàng trả phí'}</p>
			</>

		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '130px', render: (_, item) => (
			<>
				<Button leftIcon={Icon.edit} small background blue to={`/order/import/${item.id}`}></Button>
				<Button leftIcon={Icon.delete} small background primary onClick={() => onDelete(item)}></Button>
			</>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className={cn("order-table", "table-box")}>
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="text-right">
				{ type == 'passed' && <Button background primary onClick={onSubmit}>Tạo đơn hàng</Button> }
				{ type == 'failed' && <Button background primary onClick={onSubmit}>Xóa tất cả</Button>}
			</div>
		</div>
	)
}

export default OrderTableImport;