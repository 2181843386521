import request from "~/utils/http";

const orderHistoryApi = {
	gets : async (params) => {
		const url = 'v1/order-history';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'v1/order-history';
		return await request.post(url, params);
	},
	countStatus : async (params) => {
		const url = `v1/order-history/count-status`;
		return await request.get(url, {params});
	},
	status : async (params) => {
		const url = `v1/order-history/status/${params.id}`;
		return await request.post(url, params);
	},
	export : async (params) => {
		const url = `v1/order-history/export`;
		return await request.post(url, params);
	},
};

export default orderHistoryApi;