import request from "~/utils/http";

const rolesApi = {
	gets : async (params) => {
		const url = 'v1/role';
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'v1/role/add';
		return await request.post(url, params);
	},
	permissionByRole : async (params) => {
		const url = `v1/role/${params}`;
		return await request.get(url);
	},
	permissionList : async (params) => {
		const url = 'v1/role/permission';
		return await request.get(url);
	},
	permissionUpdate : async (params) => {
		const url = `v1/role/permission/${params.role}`;
		return await request.post(url, params);
	},
};

export default rolesApi;