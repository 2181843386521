import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {
	useContext,
	useEffect,
	useState
} from "react";
import {
	Badge,
	Col,
	notification,
	Row,
	Tabs
} from "antd";
import {
	apiError,
	handleRequest,
	renderAddress,
	isEmpty
} from "~/utils";
import {
	orderApi,
	branchApi
} from "~/api";
import {
	Loading,
	Button,
	Modal,
	Icon,
	Image,
	RoleError
} from "~/components";
import {
	FileUpload,
	GroupRadioButton
} from "~/components/Forms";
import {
	OrderTableImport,
	BranchItem,
	OrderFormAddress
} from "../components";
import {AppContext} from "../../../context/AppProvider";
import _ from "lodash";
import {BranchFormAddEdit} from "../../Branch/components";
import {useCurrentUser} from "../../../hooks";

const cn = className.bind(style);

function OrderImport() {
	const userCurrent = useCurrentUser();
	const {country, shippingSupport, shippingCompanies} = useContext(AppContext);
	const [loading, setLoading] = useState(false)
	const [branchMain, setBranchMain] = useState({});
	const [branchList, setBranchList] = useState({});
	const [shippingUnit, setShippingUnit] = useState('');
	const [itemsPassed, setItemsPassed] = useState([])
	const [countPassed, setCountPassed] = useState(0)
	const [itemsFailed, setItemsFailed] = useState([])
	const [countFailed, setCountFailed] = useState(0)

	const [itemEdit, setItemEdit] = useState([])

	let shippingUnitOptions = [];

	const ShippingCart = ({item}) => {
		return (
			<div className={cn('card')}>
				<Image src={item.logo} />
			</div>
		)
	}

	if(Object.keys(shippingSupport).length !== 0) {
		for (const [key, shippingKey] of Object.entries(shippingSupport)) {
			shippingUnitOptions.push({
				value : shippingKey,
				label : <ShippingCart key={shippingKey} item={shippingCompanies[shippingKey]} />
			})
		}
	}

	//Modal show
	const [openModal, setOpenModal] = useState({
		branchList : false,
		branchAdd : false,
		formAddress: false
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	useEffect(() => {
		loadBrand().then()
		handleLoad().then()
	}, []);

	const handleButtonClick = {
		branchList: () => {
			handleModalOpen('branchList');
		},
		branchAdd: () => {
			handleModalOpen('branchAdd');
		},
		branchChoices: (item) => {
			setBranchMain(item);
			handleModalClose('branchList')
		},
	}

	const loadBrand = async () => {
		let [error, response] = await handleRequest(branchApi.gets());
		let message = apiError(`Lấy danh sách điểm lấy hàng thất bại`, error, response);
		if(!message) {
			setBranchList(response.data)
			if(!_.isEmpty(response.data) && Object.keys(response.data).length !== 0) {
				for (const [index, branch] of Object.entries(response.data)) {
					if(branch.default  == 1) {
						setBranchMain(branch);
					}
				}
			}
		}
	}

	const handleBranchAdd = async (data) => {
		let [error, response] = await handleRequest(branchApi.add(data));
		let message = apiError(`Thêm mới điểm lấy hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm mới điểm lấy hàng thành công`});
			await loadBrand()
			handleModalClose('branchAdd')
		}
	}

	const handleUpload = async (data) => {
		setLoading(true)
		if(isEmpty(shippingUnit)) {
			notification.error({
				message: 'Lỗi',
				description: `Bạn chưa chọn đơn vị vận chuyển`
			});
			setLoading(false)
			return;
		}
		data.append('shippingUnit', shippingUnit);
		let [error, response] = await handleRequest(orderApi.importExcel(data));
		let message = apiError(`Upload File thất bại`, error, response);
		if(!message) {
			notification.success({
				message: 'Thành công',
				description: `Upload File thành công`
			});
			await handleLoad()
		}
		setLoading(false)
	}

	const handleLoad = async () => {
		let [error, response] = await handleRequest(orderApi.importList());
		let message = apiError(`Load dữ liệu xem trước thất bại`, error, response);
		if(!message) {
			setItemsPassed(response.data.passed);
			setItemsFailed(response.data.failed);
			setCountPassed(response.data.passedTotal);
			setCountFailed(response.data.failedTotal);
		}
	}

	const handleFormAddressSave = async (data, item) => {
		data.id = item.id;
		let [error, response] = await handleRequest(orderApi.importUpload(data));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			let objIndex = itemsFailed.findIndex((obj => obj?.id == item?.id));
			if(objIndex !== undefined && objIndex !== false && objIndex !== -1) {
				itemsFailed[objIndex] = {...itemsFailed[objIndex], ...data};
				if(response.data.type == 'passed') {
					itemsPassed.push({...itemsFailed[objIndex]})
					delete itemsFailed[objIndex]
					setItemsPassed(itemsPassed)
					setItemsFailed(itemsFailed)
					setCountPassed(countPassed+1);
					setCountFailed(countFailed-1);
				}
				else {
					setItemsFailed(itemsFailed)
				}
			}
			else {
				objIndex = itemsPassed.findIndex((obj => obj.id == item.id));
				if(objIndex !== undefined && objIndex !== false && objIndex !== -1) {
					itemsPassed[objIndex] = {...itemsPassed[objIndex], ...data};
					setItemsPassed(itemsPassed)
				}
			}
			handleModalClose('formAddress')
		}
	}

	const handleSave = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.import({
			shopId: branchMain.id
		}));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			await handleLoad()
		}
		setLoading(false)
	}

	const handleDeleteItem = async (item) => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.importDelete(item.id));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			let itemsPassedNew = itemsPassed.filter(function(obj) {
				return obj.id !== item.id
			})
			let itemsFailedNew = itemsFailed.filter(function(obj) {
				return obj.id !== item.id
			})
			setItemsPassed(itemsPassedNew);
			setItemsFailed(itemsFailedNew);
		}
		setLoading(false)
	}

	const handleDeleteAll = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(orderApi.importClear());
		let message = apiError(`Xóa dữ liệu thất bại`, error, response);
		if(!message) {
			setItemsFailed([]);
			setCountFailed(0);
		}
		setLoading(false)
	}

	const tabItems = [
		{
			key: 'passed',
			label: <p>Hợp lệ <Badge count={countPassed}></Badge></p>,
			children: <OrderTableImport items={itemsPassed} onModalOpen={handleModalOpen} setItemEdit={setItemEdit} onDelete={handleDeleteItem} onSubmit={handleSave} type="passed" />,
		},
		{
			key: 'failed',
			label: <p>Không hợp lệ <Badge count={countFailed}></Badge></p>,
			children: <OrderTableImport items={itemsFailed} onModalOpen={handleModalOpen} setItemEdit={setItemEdit} onDelete={handleDeleteItem} onSubmit={handleDeleteAll} type="failed" />,
		},
	];

	if(userCurrent?.isBlockOrder === 1) {
		return (<RoleError link={'/order'} />)
	}

	return (
		<div className={cn('order-gird')}>
			<Row gutter={10} className={'mb-1'}>
				<Col md={9} xs={24}>
					<section className={'h-100'}>
						<div className="d-flex justify-content-between">
							<h2 className={'heading'}>Bên Gửi</h2>
							<div>
								<Button small white className={'mb-2'} onClick={handleButtonClick.branchList}>Điểm lấy hàng khác</Button>
								<Button small background green className={'mb-2'} onClick={handleButtonClick.branchAdd}>{Icon.plus}</Button>
							</div>
						</div>

						<p className={'mb-1 color-red'}><strong>{branchMain?.name}</strong></p>
						<p className={'mb-1 color-red'}>{branchMain?.phone}</p>
						<p className={'mb-1'}>{
							branchMain?.address && branchMain?.address +','+renderAddress(
								branchMain?.city,
								branchMain?.district,
								branchMain?.ward,
								country)
						}</p>

						<p className="italic">* chọn sau khi up excel và trước khi bấm nút tạo đơn hàng</p>
					</section>
				</Col>
				<Col md={7} xs={24}>
					<section className={'h-100'}>
						<h2 className={'heading'}>Đơn vị vận chuyển</h2>
						<GroupRadioButton name="shippingUnit" value={shippingUnit} options={shippingUnitOptions} onChange={(event) => setShippingUnit(event.target.value)} />
					</section>
				</Col>
				<Col md={8} xs={24}>
					<section className={'h-100'}>
						<p className="heading">Tải file đơn hàng lên hệ thống</p>
						<FileUpload
							description={'Chấp nhận file .xls và .xlsx'}
							links={'file-demo/mau-dang-don-hang-v2.xlsx'}
							onChange={handleUpload}
						/>
					</section>
				</Col>
			</Row>

			<Row gutter={10}>
				<Col span={24}>
					<section>
						<p className="heading">Xem trước</p>
						{loading && <Loading noFixed />}
						<Tabs defaultActiveKey="passed" items={tabItems}/>
					</section>
				</Col>
			</Row>
			{
				(!_.isEmpty(branchList) && Object.keys(branchList).length !== 0) &&
				<Modal title="Danh sách điểm lấy hàng" visible={openModal.branchList} onCancel={() => {handleModalClose('branchList')}}>
					{branchList.map((branch, index) => {
						return (<BranchItem key={branch.id}
						                    item={branch}
						                    country={country}
						                    branchMain={branchMain}
						                    onChoices={handleButtonClick.branchChoices}
						/>);
					})}
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('branchList')}}> Đóng </Button>
					</div>
				</Modal>
			}
			{openModal.branchAdd &&
				<Modal title="Địa điểm lấy hàng" visible={openModal.branchAdd} onCancel={() => {handleModalClose('branchAdd')}}>
					<BranchFormAddEdit country={country} onHandleSubmit={handleBranchAdd} />
				</Modal>
			}
			{
				(itemEdit && openModal.formAddress) &&
				<Modal title="Địa chỉ người nhận" visible={openModal.formAddress} onCancel={() => {handleModalClose('formAddress')}}>
					<OrderFormAddress item={itemEdit} country={country} onHandleSubmit={handleFormAddressSave} />
				</Modal>
			}

		</div>
	)
}
export default OrderImport;