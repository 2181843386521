import {Tabs} from "antd";
import {globalNavigate} from "~/routes/GlobalHistory";
import {useCan} from "~/hooks";

function CodTabs({active}) {

	const can = {
		tabOrder: useCan('shop_cod_order'),
		tabWaiting: useCan('shop_cod_waiting_pay'),
		tabSuccess: useCan('shop_cod_success_pay'),
	}

	const itemsTab = [];

	if(can.tabOrder) {
		itemsTab.push({
			key: 'order',
			label: 'Chưa đối soát',
		})
	}
	if(can.tabWaiting) {
		itemsTab.push({
			key: 'waiting-pay',
			label: 'Chờ trả tiền',
		})
		itemsTab.push({
			key: 'owe',
			label: 'Nợ tồn',
		})
	}
	if(can.tabSuccess) {
		itemsTab.push({
			key: 'success-pay',
			label: 'Đã trả tiền',
		})
	}

	const handleTabChange = (key) => {
		globalNavigate(`/cod/${key}`);
	};

	return (
		<Tabs
			defaultActiveKey={active}
			items={itemsTab}
			onChange={handleTabChange}
		/>
	)
}
export default CodTabs;