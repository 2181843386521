import {
	useContext
} from "react";
import {
	Image,
	StatusLabel
} from "~/components";
import {
	SelectField
} from "~/components/Forms";
import {
	isEmpty,
	renderDate
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
} from "~/app/constants";
import {ShippingPriceTableAction} from "../index";
import {Link} from "react-router-dom";

function ShippingPriceTable({items = [], pagination, setItemEdit, handleEvent, can}) {

	const {tableHeight, shippingCompanies} = useContext(AppContext);

	let columns = [
		{ title: 'Mã', dataIndex: 'cod', key: "cod", width: '40px', render: (_, item) => (
			<p className="font-bold"><Link to={'/admin/shipping-price/edit/'+item?.id}>#{item?.id}</Link></p>
		)},
		{ title: 'Đơn vị', dataIndex: 'shipping', key: "shipping", width: '70px', render: (_, item) => (
			<>
				{(!isEmpty(item?.shipping) && shippingCompanies.hasOwnProperty(item.shipping)) && <p><Image src={shippingCompanies[item.shipping]?.logo} style={{width:'60px'}} /></p>}
			</>
		)},
		{ title: 'Người tạo', dataIndex: 'user', key: "user", width: '100px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
			</>
		)},
		{ title: 'Tên bảng giá', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<p className="font-bold"><Link to={'/admin/shipping-price/edit/'+item?.id}>{item?.name}</Link></p>
		)},
		{ title: 'Cân nặng nhãy bậc', dataIndex: 'weightTrigger', key: "weightTrigger", width: '100px', render: (_, item) => (
			<p>{item?.weightTrigger}</p>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '100px', render: (_, item) => (
			<>
				<StatusLabel small type={(item?.status == 'public') ? 'green' : 'yellow'} border>{(item?.status == 'public') ? 'Đã duyệt' : 'đợi duyệt'}</StatusLabel>
			</>
		)},
		{ title: 'Ngày tạo', dataIndex: 'object', key: "object", width: '200px', render: (_, item) => (
			<>
				{(item.default == 1) && <p className={'color-red font-bold mb-1'}>Bảng giá mặc định</p>}
				<p className="color-grey">{renderDate(item?.created)}</p>
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '100px', render: (_, item) => (
			<ShippingPriceTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default ShippingPriceTable;