import {
	useEffect,
	useState
} from "react";
import {
	Badge, Breadcrumb,
	Col,
	notification,
	Row,
	Tabs
} from "antd";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	customerApi
} from "~/api";
import {
	Loading,
} from "~/components";
import {
	FileUpload,
	GroupRadioButton
} from "~/components/Forms";
import {
	CustomerTableImport,
} from "../components";
import {Link} from "react-router-dom";

function CustomerImport() {

	const [loading, setLoading] = useState(false)
	const [itemsPassed, setItemsPassed] = useState([])
	const [countPassed, setCountPassed] = useState(0)
	const [itemsFailed, setItemsFailed] = useState([])
	const [countFailed, setCountFailed] = useState(0)
	const [countResultPassed, setCountResultPassed] = useState(0)
	const [countResultFailed, setCountResultFailed] = useState(0)
	const [itemEdit, setItemEdit] = useState([])


	useEffect(() => {
		handleLoad()
	}, []);

	const handleUpload = async (data) => {
		setLoading(true)
		let [error, response] = await handleRequest(customerApi.importExcel(data));
		let message = apiError(`Upload File thất bại`, error, response);
		if(!message) {
			notification.success({
				message: 'Thành công',
				description: `Upload File thành công`
			});
			await handleLoad()
		}
		setLoading(false)
	}

	const handleLoad = async (data) => {
		let [error, response] = await handleRequest(customerApi.importList());
		let message = apiError(`Load dữ liệu xem trước thất bại`, error, response);
		if(!message) {
			setItemsPassed(response.data.passed);
			setItemsFailed(response.data.failed);
			setCountPassed(response.data.passedTotal);
			setCountFailed(response.data.failedTotal);
		}
	}

	const handleSave = async (data) => {
		setLoading(true)
		let [error, response] = await handleRequest(customerApi.import());
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			setCountResultPassed(response.data.passedTotal);
			setCountResultFailed(response.data.failedTotal);
			await handleLoad()
		}
		setLoading(false)
	}

	const handleDeleteItem = async (item) => {
		setLoading(true)
		let [error, response] = await handleRequest(customerApi.importDelete(item.id));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			let itemsPassedNew = itemsPassed.filter(function(obj) {
				return obj.id !== item.id
			})
			let itemsFailedNew = itemsFailed.filter(function(obj) {
				return obj.id !== item.id
			})
			setItemsPassed(itemsPassedNew);
			setItemsFailed(itemsFailedNew);
		}
		setLoading(false)
	}

	const handleDeleteAll = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(customerApi.importClear());
		let message = apiError(`Xóa dữ liệu thất bại`, error, response);
		if(!message) {
			setItemsFailed([]);
			setCountFailed(0);
		}
		setLoading(false)
	}

	const tabItems = [
		{
			key: 'passed',
			label: <p>Hợp lệ <Badge count={countPassed}></Badge></p>,
			children: <CustomerTableImport items={itemsPassed} onDelete={handleDeleteItem} onSubmit={handleSave} type="passed" />,
		},
		{
			key: 'failed',
			label: <p>Không hợp lệ <Badge count={countFailed}></Badge></p>,
			children: <CustomerTableImport items={itemsFailed} onDelete={handleDeleteItem} onSubmit={handleDeleteAll} type="failed" />,
		},
	];

	return (
		<div className="container">
			<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/customer'}>Khách hàng</Link>,},
				            {title: 'Tạo khách hàng',},
			            ]}
			/>
			<Row gutter={10} className={'mb-1'}>
				<Col span={12}>
					<section className={'h-100'}>
						<p className="heading">Tải file bưu cục lên hệ thống</p>
						<FileUpload
							description={'Chấp nhận file .xls và .xlsx'}
							links={'file-demo/mau-dang-khach-hang-v1.xlsx'}
							onChange={handleUpload}
						/>
					</section>
				</Col>
				<Col span={12}>
					<section className={'h-100'}>
						<p className="heading">Kết quả</p>
						<p className="mb-1">Cập nhật thành công <span className="color-green font-bold">{countResultPassed}</span> đơn</p>
						<p className="mb-1">Cập nhật thất bại <span className="color-red font-bold">{countResultFailed}</span> đơn</p>
					</section>
				</Col>
			</Row>

			<Row gutter={10}>
				<Col span={24}>
					<section>
						<p className="heading">Xem trước</p>
						{loading && <Loading noFixed />}
						<Tabs defaultActiveKey="passed" items={tabItems}/>
					</section>
				</Col>
			</Row>
		</div>
	)
}
export default CustomerImport;