import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
	codActions,
	codErrorSelector,
	codFilterSelector,
	codItemsSelector,
	codLoadingSelector,
	codPaginationSelector
} from "../codSlice";
import {useEffect, useState} from "react";
import {useCan} from "~/hooks";
import {notification} from "antd";
import {Button, Icon, Loading, Modal} from "~/components";
import {CodFormCoin, CodMerge, CodTable, CodTabs} from "../components";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {apiError, handleRequest} from "~/utils";
import {codApi, coinApi} from "~/api";
import { saveAs } from 'file-saver';
import _ from "lodash";
function Cod() {
	const {status} = useParams();

	const can = {
		add: useCan('shop_cod_add'),
		export: useCan('shop_cod_excel'),
		merge: useCan('shop_cod_merge'),
		success: useCan('shop_cod_success'),
	}

	const dispatch = useDispatch();

	const items = useSelector(codItemsSelector);

	const loading = useSelector(codLoadingSelector);

	const error = useSelector(codErrorSelector);

	const pagination = useSelector(codPaginationSelector);

	const filter = useSelector(codFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [selectedMergeKeys, setSelectedMergeKeys] = useState({});

	//Modal show
	const [openModal, setOpenModal] = useState({
		pay : false,
		merge : false,
		export : false,
		coin : false,
	});

	//Load data
	useEffect(() => {
		if(status != filter.status) {
			let newFilter = {...filter};
			newFilter.status = status;
			newFilter.page = 1;
			dispatch(codActions.setFilter(newFilter));
		}
	}, [status]);

	useEffect(() => {
		dispatch(codActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		export: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa đơn đối soát'});
				return;
			}
			let [error, response] = await handleRequest(codApi.export(item.id));
			let message = apiError(`Xuất đơn đối soát thất bại`, error, response);
			if(!message) {
				saveAs(response.data.file);
			}
		},
		merge: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa đơn đối soát'});
				return;
			}
			if(_.isEmpty(selectedMergeKeys)) {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn đối soát nào'});
				return;
			}
			let [error, response] = await handleRequest(codApi.merge({
				id: item.id,
				listData: selectedMergeKeys
			}));
			let message = apiError(`Gộp đơn dối soát thất bại`, error, response);
			if(!message) {
				handleEvent.closeModal('merge')
				dispatch(codActions.fetchData({
					...filter,
					status: status,
					page: 1
				}));
			}
		},
		reload: async (item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa đơn đối soát'});
				return;
			}

			let [error, response] = await handleRequest(codApi.calculate(item.id));
			let message = apiError(`Gộp đơn dối soát thất bại`, error, response);
			if(!message) {
				item.numberOrder = response.data.numberOrder
				item.shipping = parseInt(response.data.shipping)
				item.cod = response.data.cod
				item.insurance = response.data.insurance
				item.returnFee = response.data.returnFee
				dispatch(codActions.update(item));
			}
		},
		pay: async () => {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa đơn đối soát'});
				return;
			}
			let [error, response] = await handleRequest(codApi.success(itemEdit?.id));
			let message = apiError(`Thanh toán cho đơn đối soát thất bại`, error, response);
			if(!message) {
				handleEvent.closeModal('pay')
				dispatch(codActions.delete(itemEdit?.id));
			}
		},
		payCoin: async (item, data) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có đơn đối soát nào để cập nhật'});
				return;
			}
			let [error, response] = await handleRequest(coinApi.paymentCod({
				id: item.id,
				type: data.type,
			}));
			let message = apiError(`Thanh toán thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Thanh toán thành công`});
				item.paymentCoin = response.data.paymentCoin;
				item.user = {
					...item.user,
					coin:response.data.user.coin,
					coinSale:response.data.user.coinSale,
				}
				dispatch(codActions.update(item));
				handleEvent.closeModal('coin')
			}
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(codActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(codActions.setFilter({...filter, page }));
		}
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					{can.add && <Button background green className="mb-2 ml-0" to={'/cod/add'}>Tạo đối soát (File)</Button>}
					{can.addFilter && <Button background blue className="mb-2 ml-0" to={'/cod/add'}>Tạo đối soát</Button>}
				</div>
				<CodTabs active={status} />
				{items && <CodTable
					items={items}
					setItemEdit={setItemEdit}
					pagination={pagination}
					handleEvent={handleEvent}
					can={can}
				/>}
			</section>
			{
				(itemEdit?.id && openModal.coin) &&
				<Modal title="Thanh toán bằng xu" visible={openModal.coin} onCancel={() => {handleEvent.closeModal('coin')}}>
					<CodFormCoin item={itemEdit} onHandleSubmit={handleEvent.payCoin} />
				</Modal>
			}
			{
				(can.success && itemEdit?.id && openModal.pay) &&
				<Modal title="Thanh toán" visible={openModal.pay} onCancel={() => {handleEvent.closeModal('pay')}}>
					<p>Xác nhận đã thanh toán tất cả đơn hàng trong cho đơn đối soát <b>{itemEdit?.id}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-2">
						<Button white leftIcon={Icon.close} onClick={() => {handleEvent.closeModal('pay')}}> Đóng </Button>
						<Button background blue leftIcon={Icon.success} onClick={handleEvent.pay}> Xác nhận </Button>
					</div>
				</Modal>
			}
			{
				(can.merge && itemEdit?.id && openModal.merge) &&
				<Modal title="Gộp đơn" visible={openModal.merge} onCancel={() => {handleEvent.closeModal('merge')}}>
					<CodMerge
						handleEvent={handleEvent}
						item={itemEdit}
						selectedMergeKeys={selectedMergeKeys}
						setSelectedMergeKeys={setSelectedMergeKeys}
					/>
				</Modal>
			}
		</div>
	)
}
export default Cod;