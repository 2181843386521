import _ from "lodash"
import {
	useState,
	useEffect
} from "react";
import {notification} from "antd";
import {
	rolesApi
} from "~/api";
import {
	handleRequest,
	apiError
} from "~/utils";
import {useCan} from "~/hooks";
import {Modal} from "~/components";
import PermissionForm from "../components/Forms/PermissionForm";
import PermissionFormAdd from "../components/Forms/PermissionFormAdd";

function Permission() {

	let [roles, setRole] = useState([]);
	let [permission, setPermission] = useState([]);
	let [permissionCurrent, setPermissionCurrent] = useState([]);

	const [openModal, setOpenModal] = useState({
		add : false,
	});

	const can = {
		add: useCan('shop_roles_add'),
	}

	useEffect(() => {
		handleLoadRoles().then();
		handleLoadPermission().then();
		handleLoadPermissionCurrent().then();
	}, []);

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}
	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}
	const handleLoadRoles = async (data) => {
		let [error, response] = await handleRequest(rolesApi.gets());
		let message = apiError(`Load danh sách quyền`, error, response);
		if (!message) setRole(response.data);
	}
	const handleLoadPermission = async (data) => {
		let [error, response] = await handleRequest(rolesApi.permissionList());
		let message = apiError(`Load danh sách quyền`, error, response);
		if (!message) setPermission(response.data);
	}
	const handleLoadPermissionCurrent = async (data) => {
		let [error, response] = await handleRequest(rolesApi.permissionByRole('administrator'));
		let message = apiError(`Load danh sách quyền`, error, response);
		if (!message) setPermissionCurrent(response.data.capabilities);
	}
	const handleSubmit = async (data) => {
		let permission = {
			role:data.role,
			capabilities: data
		}
		let [error, response] = await handleRequest(rolesApi.permissionUpdate(permission));
		let message = apiError(`Cập nhật thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thành công`});
		}
	}
	const handleAdd = async (data) => {
		let [error, response] = await handleRequest(rolesApi.add(data));
		let message = apiError(`Thêm mới thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Thêm mới thành công`});
			handleLoadRoles().then();
			handleModalClose('add');
		}
	}

	return (
		<div className={'container'}>
			{(!_.isEmpty(roles) && !_.isEmpty(permission) && !_.isEmpty(permissionCurrent)) && <PermissionForm
				onSubmit={handleSubmit}
				roles={roles}
				setRole={setRole}
				permission={permission}
				permissionCurrent={permissionCurrent}
				setPermissionCurrent={setPermissionCurrent}
				onOpenModal={handleModalOpen}
				can={can}
			/>}

			{
				(can.add && openModal.add) && <Modal title="Thêm Chức Vụ" visible={openModal.add} onCancel={() => {handleModalClose('add')}}>
					<PermissionFormAdd onHandleSubmit={handleAdd} />
				</Modal>
			}
		</div>
	)
}

export default Permission;