import {useForm, Controller} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Icon,
	Loading
} from "~/components";
import {
	InputField,
} from "~/components/Forms";
import {useState} from "react";

function OrderFormSync({item, onSync, onUpTracking}) {

	const [loadingSync, setLoadingSync] = useState(false)

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: {
			trackingNumber: '',
		},
		resolver: yupResolver(Yup.object().shape({
			trackingNumber: Yup.string().required('Mã tracking đơn hàng không được để trống'),
		}))
	});

	const onclickSync = async () => {
		setLoadingSync(true)
		await onSync(item)
		setLoadingSync(false)
	}

	const onSubmit = async (data) => {
		await onUpTracking(item, data);
	}

	return (
		<div className={'text-center'}>
			<p className={'mb-1'}>Click button dưới đây để thử đồng bộ lại</p>
			<Button background red onClick={onclickSync} disabled={loadingSync}>{(loadingSync) ? Icon.loading : 'Đồng bộ lại'}</Button>
			<p className={'mt-2 mb-1'}>Hoặc</p>
			<p className={'mb-1 text-sm'}>Gán mã tracking cho đơn hàng nếu có</p>
			{isSubmitting && <Loading noFixed />}
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<Controller control={control} name="trackingNumber" render={({field}) => (
					<InputField placeholder={'Mã tracking'} errors={errors} {...field}/>
				)}/>
				<Button primary type="submit" className={'mt-1'}>Cập nhật mã tracking</Button>
			</form>
		</div>

	)
}

export default OrderFormSync;