import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
	LIST_USER_ROLE,
	LIST_USER_STATUS,
} from "~/app/constants";
import {StatusLabel, UserVerify, FontAwesomeIcon} from "~/components";
import {UserTableAction} from "../index";
import {Link} from "react-router-dom";

function UserTable({items = [], pagination, setItemEdit, handleEvent, can, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Tên', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold"><Link to={'/users/edit/'+item?.id}>{item?.username}</Link></p>
				<p className="font-bold"><Link to={'/users/edit/'+item?.id}>{item?.firstname + ' ' + item?.lastname}</Link></p>
				{
					item?.referralCode && <StatusLabel small type="blue" border>{item?.referralCode}</StatusLabel>
				}
				<p className="color-grey text-xs mt-1 mb-1">{renderDate(item?.created)}</p>
				<UserVerify user={item} />
			</>
		)},
		{ title: 'Thông tin', dataIndex: 'contact', key: "contact", width: '100px', render: (_, item) => (
				<>
					<p><Link to={'/users/edit/' + item?.id}>{item?.email}</Link></p>
					<p><Link to={'/users/edit/' + item?.id}>{item?.phone}</Link></p>
				</>
			)
		},
		{ title: 'Xu', dataIndex: 'coin', key: "coin", width: '130px', render: (_, item) => (
				<>
					<p className={'color-yellow'}><FontAwesomeIcon icon="fa-duotone fa-circle-dollar" /> Xu: {numberFormat(item?.coin)}</p>
					<p className={'color-grey'}><FontAwesomeIcon icon="fa-duotone fa-circle-dollar" /> Xu thường: {numberFormat(item?.coinSale)}</p>
					<p className={'color-red'}>Tổng: {numberFormat(item?.coin + item?.coinSale)}</p>
				</>
			)
		},
		{
			title: 'Chức vụ', dataIndex: 'role', key: "role", width: '120px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.roleName}</p>
			</>
		)},
		{ title: 'Mã GT', dataIndex: 'object', key: "object", width: '80px', render: (_, item) => (
			<>
				<p>{item?.affParentCode}</p>
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '100px', render: (_, item) => (
			<>
				<StatusLabel small type={LIST_USER_STATUS[item?.status]?.color} border>{LIST_USER_STATUS[item?.status]?.title}</StatusLabel>
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '220px', render: (_, item) => (
			<UserTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default UserTable;