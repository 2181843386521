import {memo, useState} from "react";
import _ from "lodash";
import {Button, Icon, Loading} from "~/components";

function ShippingPriceUserList({users, type, onDelete, onSave}) {
	const [loading, setLoading] = useState(false)
	const onSubmit = async () => {
		setLoading(true)
		await onSave();
		setLoading(false)
	}
	return (
		<div style={{position:'relative'}}>
			{loading && <Loading />}
			<div style={{overflow:'auto', maxHeight:'550px'}}>
			{
				(!_.isEmpty(users)) && users.map((item) => {
					return <div key={`user-${type}-${item.id}`} className="d-flex gap align-items justify-content-between mb-1 w-100">
						<div>
							<p><b>{item?.firstname + ' ' + item?.lastname}</b></p>
							<p className={'mb-1'}>{item?.username}</p>
						</div>
						<div>
							<Button leftIcon={Icon.delete} background red onClick={() => onDelete(item)}>Xoá</Button>
						</div>
					</div>
				})
			}
			</div>
			<hr />
			<div className={'text-right'}>
				<Button leftIcon={Icon.save} background blue onClick={() => onSubmit()}>Lưu</Button>
			</div>
		</div>
	)
}

export default memo(ShippingPriceUserList);