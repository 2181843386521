import style from '../../style/User.module.scss'
import className from 'classnames/bind';
import {AppContext} from "~/context/AppProvider";
import {shippingPriceApi} from "~/api";
import {
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import {
	Col, notification,
	Row
} from "antd";
import {
	Loading,
	Button,
	Image
} from "~/components";
import {
	InputField,
	CheckBoxField,
	SelectField,
	GroupCheckBoxField
} from "~/components/Forms";
import {
	apiError,
	handleRequest
} from "~/utils";
const cn = className.bind(style);
function UserEditShipping({user, userApi, can}) {

	const {shippingCompanies} = useContext(AppContext);

	const companies = {};

	const selectsId = {};

	Object.entries(shippingCompanies).forEach(([key]) => {
		companies[key] = [
			{value:0 , label: 'Chọn bảng giá'}
		];
		selectsId[key] = 0;
	});

	const [shippingPriceList, setShippingPriceList] = useState(companies)
	const [shippingPriceIn, setShippingPriceIn]     = useState(selectsId)
	const [shippingPriceOut, setShippingPriceOut]   = useState(selectsId)
	const [shippingPriceRegional, setShippingPriceRegional]   = useState(selectsId)
	const [loading, setLoading]   = useState(false)
	const [shippingSupportValue, setShippingSupportValue]   = useState([])

	const shippingSupportOptions = useMemo(() => {
		return [...Object.entries(shippingCompanies).map(([key, shipping]) => {
			return {
				value: key,
				label: <Image src={shipping.logo} style={{width:'150px'}} className={'ml-2'} />
			};
		})];
	}, [shippingCompanies]);

	useEffect(() => {
		loadShippingSupport().then()
		loadShippingPriceList().then()
		loadShippingPriceUser().then()
	}, [user?.id]);

	const loadShippingPriceList = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(shippingPriceApi.gets());
		let message = apiError(`Lấy thông tin bảng giá thất bại`, error, response);
		if(!message) {
			const companies = {};

			Object.entries(shippingCompanies).forEach(([key]) => {
				companies[key] = [{value:0 , label: 'Chọn bảng giá'}];
			});

			Object.entries(response.data).forEach(([key, shipping]) => {
				companies[shipping.shipping].push({
					value: shipping.id,
					label: shipping.name
				})
			});

			setShippingPriceList(companies);
		}
		setLoading(false)
	}

	const loadShippingPriceUser = async () => {
		let [error, response] = await handleRequest(userApi.getShippingPrice(user.id));
		let message = apiError(`Lấy thông tin bảng giá thất bại`, error, response);
		if(!message) {

			setShippingPriceIn({
				...shippingPriceIn,
				...response.data.sameProvince
			});

			setShippingPriceOut({
				...shippingPriceOut,
				...response.data.differentProvince
			});

			setShippingPriceRegional({
				...shippingPriceRegional,
				...response.data.intraRegional
			});
		}
	}

	const handleChaneIn = (value, shippingKey) => {
		shippingPriceIn[shippingKey] = value;
		setShippingPriceIn({...shippingPriceIn})
	}

	const handleChaneOut = (value, shippingKey) => {
		shippingPriceOut[shippingKey] = value;
		setShippingPriceOut({...shippingPriceOut})
	}

	const handleChaneRegional = (value, shippingKey) => {
		shippingPriceRegional[shippingKey] = value;
		setShippingPriceRegional({...shippingPriceRegional})
	}

	const handleSave = async () => {
		setLoading(true)
		let result = handleShippingSupportSave()
		if(result) {
			let [error, response] = await handleRequest(userApi.updateShippingPrice({
				id: user.id,
				sameProvince : {
					...shippingPriceIn
				},
				differentProvince : {
					...shippingPriceOut
				},
				intraRegional : {
					...shippingPriceRegional
				}
			}));
			let message = apiError(`Cập nhật thông tin bảng giá cho thành viên thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: 'Cập nhật thông tin bảng giá cho thành viên thành công'});
			}
		}
		setLoading(false)
	}

	const loadShippingSupport = async () => {
		setLoading(true)
		let [error, response] = await handleRequest(userApi.getShippingSupport(user.id));
		let message = apiError(`Lấy thông tin đơn vị vận chuyển thất bại`, error, response);
		if(!message) {
			setShippingSupportValue(response.data);
		}
		setLoading(false)
	}

	const handleChaneShippingSupport = (value) => {
		setShippingSupportValue(value)
	}

	const handleShippingSupportSave = async () => {
		let [error, response] = await handleRequest(userApi.updateShippingSupport({
			id: user.id,
			shippingSupport : {
				...shippingSupportValue
			},
		}));
		let message = apiError(`Cập nhật đơn vị vận chuyển cho thành viên thất bại`, error, response);
		if(!message) {
			return true
		}
		return false
	}

	return (
		<section>
			{loading && <Loading noFixed />}

			<Row>
				{
					can.shippingPrice && <Col span={5}>
						<p className={'heading'}>Đơn vị vận chuyển</p>
						<div key={'shipping_price_unit'} className={cn('shipping-support-wrap', 'd-flex', 'align-items-center')}>
							<GroupCheckBoxField options={shippingSupportOptions} value={shippingSupportValue} onChange={handleChaneShippingSupport} />
						</div>
					</Col>
				}
				<Col span={6}>
					<p className={'heading'}>Bảng giá ngoại thành</p>
					{
						Object.entries(shippingCompanies).map(([key, shipping]) => {
							return (
								<>
									<div key={'shipping_price_ngoaithanh_'+key} className="border-1 border-solid border-gray-100 pd-2">
										<div className="img mb-1">
											<p className={'text-base color-red font-bold text-center'}>{shipping?.label}</p>
										</div>
										<div className="select">
											<SelectField options={shippingPriceList[key]} value={shippingPriceOut[key]} onChange={(value) => handleChaneOut(value, key)} className={'min-w-300px'} />
										</div>
									</div>
								</>
							)
						})
					}
				</Col>
				<Col span={6}>
					<p className={'heading'}>Bảng giá nội thành</p>
					{
						Object.entries(shippingCompanies).map(([key, shipping]) => {
							return (
								<>
									<div key={'shipping_price_noithanh_'+key} className="border-1 border-solid border-gray-100 pd-2">
										<div className="img text-center mb-1">
											<p className={'text-base color-red font-bold text-center'}>{shipping?.label}</p>
										</div>
										<div className="select">
											<SelectField options={shippingPriceList[key]} value={shippingPriceIn[key]} onChange={(value) => handleChaneIn(value, key)} className={'min-w-300px'} />
										</div>
									</div>
								</>
							)
						})
					}
				</Col>
				<Col span={6}>
					<p className={'heading'}>Bảng giá nội vùng</p>
					{
						Object.entries(shippingCompanies).map(([key, shipping]) => {
							return (
								<>
									<div className="border-1 border-solid border-gray-100 pd-2">
										<div className="img mb-1">
											<p className={'text-base color-red font-bold text-center'}>{shipping?.label}</p>
										</div>
										<div className="select">
											<SelectField options={shippingPriceList[key]} value={shippingPriceRegional[key]} onChange={(value) => handleChaneRegional(value, key)} className={'min-w-300px'} />
										</div>
									</div>
								</>
							)
						})
					}
				</Col>
			</Row>
			<div className="text-right mt-1">
				<Button background blue onClick={handleSave}>Lưu thông tin</Button>
			</div>
		</section>
	)
}
export default UserEditShipping;