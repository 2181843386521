import _ from "lodash"
import {
	useEffect,
	useState
} from "react";
import {
	Alert,
	Col, notification,
	Row
} from "antd";
import {
	Loading,
	Button,
} from "~/components";
import {
	InputField,
	InputPriceField,
} from "~/components/Forms";
import {
	apiError,
	handleRequest
} from "~/utils";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";

function UserEditAffiliate({user, userApi, can}) {

	const [loading, setLoading]   = useState(false)
	//Form
	const initialValues = {
		affParentCode: user?.affParentCode,
		items: [],
	}

	const validationSchema = Yup.object().shape({
		items: Yup.array().of(
			Yup.object().shape({
				from: Yup.number().min(0, 'không thể nhỏ hơn 0'),
				to  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				percent : Yup.number().min(0, 'Hoa hồng cộng tác viên không thể nhỏ hơn 0').max(100, 'Hoa hồng cộng tác viên không thể lớn hơn 100')
			})
		),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "items"
	});

	useEffect(() => {
		loadAffiliateUser().then()
	}, [user?.id]);

	const loadAffiliateUser = async () => {
		if(can.affiliatePercent && user?.aff === 1) {
			let [error, response] = await handleRequest(userApi.getAffiliate(user.id));
			let message = apiError(`Lấy thông tin cộng tác viên thất bại`, error, response);
			if (!message) {
				let items = [];
				if (Object.keys(response.data.items).length !== 0) {
					for (const [index, item] of Object.entries(response.data.items)) {
						items.push({id: item.id, from: item.from, to: item.to, percent: item.percent})
					}
				}
				setValue('items', items)
			}
		}
	}

	const handleAffiliateAddPercent = () => {
		append({ id: uuidv4(), from: 0, to: 0, percent: 0});
	}

	const handleSave = async (data) => {
		data.id = user.id
		let [error, response] = await handleRequest(userApi.updateAffiliate(data));
		let message = apiError(`Cập nhật thông tin cộng tác viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: 'Cập nhật thông tin cộng tác viên thành công'});
		}
	}


	return (
		<section>
			<form className="form" onSubmit={handleSubmit(handleSave)}>
				{(isSubmitting || loading) && <Loading noFixed/>}
				<Row gutter={10}>
					{
						can.affiliateCode && <Col className="gutter-row" span={6}>
							<p className={'heading'}>Cộng tác viên</p>
							<Controller control={control} name="affParentCode" render={({field}) => (
								<InputField label="Mã giới thiệu" errors={errors} {...field}/>
							)}/>
							<p className="italic mb-2">Đơn hàng phát sinh từ thành viên này sẽ được tính hoa hồng cho cộng tác viên có mã giới thiệu được điền vào đây</p>
						</Col>
					}
					{
						can.affiliatePercent && <Col className="gutter-row" span={18}>
							<p className={'heading'}>Hoa hồng cộng tác viên</p>
							{
								user?.aff === 1 && <>
									<Row gutter={10}>
										<Col className="gutter-row" span={7}>
											<label htmlFor="">Từ</label>
										</Col>
										<Col className="gutter-row" span={7}>
											<label htmlFor="">Đến</label>
										</Col>
										<Col className="gutter-row" span={7}>
											<label htmlFor="">% Hoa hồng</label>
										</Col>
										<Col className="gutter-row" span={3}></Col>
									</Row>
									{fields.map(({ id, from, to, percent }, index) => {
										return (
											<Row gutter={10} key={id}>
												<Col className="gutter-row" span={7}>
													<Controller control={control} name={`items[${index}].from`} render={({ field }) => (
														<InputPriceField defaultValue={from} errors={(!_.isEmpty(errors) && errors?.items.hasOwnProperty(index)) && errors?.items[index]?.from} {...field}/>
													)}/>
												</Col>
												<Col className="gutter-row" span={7}>
													<Controller control={control} name={`items[${index}].to`} render={({ field }) => (
														<InputPriceField defaultValue={to} errors={(!_.isEmpty(errors) && errors?.items.hasOwnProperty(index)) && errors?.items[index]?.to} {...field}/>
													)}/>
												</Col>
												<Col className="gutter-row" span={7}>
													<Controller control={control} name={`items[${index}].percent`} render={({ field }) => (
														<InputPriceField defaultValue={percent} errors={(!_.isEmpty(errors) && errors?.items.hasOwnProperty(index)) && errors?.items[index]?.percent} {...field}/>
													)}/>
												</Col>
												<Col className="gutter-row" span={3}>
													<Button primary type="button" className={'w-100'} onClick={() => remove(index)}>Xóa</Button>
												</Col>
											</Row>
										);
									})}
									<div className="d-flex justify-content-end">
										<Button white background type="button" onClick={handleAffiliateAddPercent}>Thêm khoản hoa hồng</Button>
									</div>
								</>
							}
							{
								user?.aff === 0 && <>
									<Alert message="Thành viên chưa phải là cộng tác viên" type="warning" showIcon></Alert>
								</>
							}
						</Col>
					}
				</Row>
				<div className="text-right mt-1">
					<hr />
					<Button background blue type="submit">Lưu thông tin</Button>
				</div>
			</form>
		</section>
	)
}

export default UserEditAffiliate;