import _ from "lodash"
import {
	useEffect,
	useState
} from "react";
import {
	Alert,
	Col, notification,
	Row
} from "antd";
import {
	Loading,
	Button,
} from "~/components";
import {
	InputField,
	InputPriceField,
} from "~/components/Forms";
import {
	apiError,
	handleRequest
} from "~/utils";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";

function UserEditShippingBase({title, type, user, userApi, can, handleSave}) {

	const [loading, setLoading]   = useState(false)
	//Form
	const initialValues = {
		shippingPriceBase: [],
	}

	const validationSchema = Yup.object().shape({
		shippingPriceBase: Yup.array().of(
			Yup.object().shape({
				from: Yup.number().min(0, 'không thể nhỏ hơn 0'),
				to  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				price  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				callback  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				cost  : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				trigger : Yup.number().min(0, 'không thể nhỏ hơn 0'),
				returnFee : Yup.number().min(0, 'không thể nhỏ hơn 0')
			})
		),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "shippingPriceBase"
	});

	useEffect(() => {
		loadShippingPriceBaseUser().then()
	}, [user?.id]);

	const loadShippingPriceBaseUser = async () => {
		if(can.affiliatePercent && user?.aff === 1) {
			let [error, response] = await handleRequest(userApi.getShippingPriceBase({
				id: user.id,
				type: type
			}));

			let message = apiError(`Lấy thông tin bảng giá gốc thất bại`, error, response);

			if (!message) {

				let items = [];

				let shippingPriceBase = [];

				if(type === 'ninjavan') {
					shippingPriceBase = response.data?.ninjavan;
				}

				if(type === 'ghn') {
					shippingPriceBase = response.data?.ghn;
				}

				if (Object.keys(shippingPriceBase).length !== 0) {
					for (const [index, item] of Object.entries(shippingPriceBase)) {
						items.push({
							id: item.id,
							from: item.from,
							to: item.to,
							price:item.price,
							callback:item.return,
							cost:item.cost,
							trigger:item.trigger,
							returnFee:item.returnFee
						})
					}
				}
				setValue('shippingPriceBase', items)
			}
		}
	}

	const handleAdd = () => {
		append({
			id: uuidv4(),
			from: 0,
			to: 0,
			price:0,
			callback:0,
			cost:0,
			trigger:0,
			returnFee:0
		});
	}

	const handleSaveForm = async (data) => {
		handleSave(data, type);
	}

	return (
		<section>
			<form className="form" onSubmit={handleSubmit(handleSaveForm)}>
				{(isSubmitting || loading) && <Loading noFixed/>}
				<Row gutter={10}>
					<Col className="gutter-row" span={24}>
						<p className={'heading'}>{title}</p>
						<Row gutter={10}>
							<Col className="gutter-row" span={2}>
								<label htmlFor="">Từ (kg)</label>
							</Col>
							<Col className="gutter-row" span={2}>
								<label htmlFor="">Đến (kg)</label>
							</Col>
							<Col className="gutter-row" span={2}>
								<label htmlFor="">Phí gốc</label>
							</Col>
							<Col className="gutter-row" span={4}>
								<label htmlFor="">Phí vận chuyển</label>
							</Col>
							<Col className="gutter-row" span={4}>
								<label htmlFor="">Phí vận chuyển (Thu hồi)</label>
							</Col>
							<Col className="gutter-row" span={4}>
								<label htmlFor="">Phí hoàn</label>
							</Col>
							<Col className="gutter-row" span={3}>
								<label htmlFor="">Phí nhãy bậc</label>
							</Col>
							<Col className="gutter-row" span={3}></Col>
						</Row>
						{fields.map(({ id, from, to, price, callback, cost, trigger, returnFee }, index) => {
							return (
								<Row gutter={10} key={'shipping_price_base_njv_' + id}>
									<Col className="gutter-row" span={2}>
										<Controller control={control} name={`shippingPriceBase[${index}].from`} render={({ field }) => (
											<InputPriceField defaultValue={from} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={2}>
										<Controller control={control} name={`shippingPriceBase[${index}].to`} render={({ field }) => (
											<InputPriceField defaultValue={to} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={2}>
										<Controller control={control} name={`shippingPriceBase[${index}].cost`} render={({ field }) => (
											<InputPriceField defaultValue={cost} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={4}>
										<Controller control={control} name={`shippingPriceBase[${index}].price`} render={({ field }) => (
											<InputPriceField defaultValue={price} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={4}>
										<Controller control={control} name={`shippingPriceBase[${index}].callback`} render={({ field }) => (
											<InputPriceField defaultValue={callback} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={4}>
										<Controller control={control} name={`shippingPriceBase[${index}].returnFee`} render={({ field }) => (
											<InputPriceField defaultValue={returnFee} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Controller control={control} name={`shippingPriceBase[${index}].trigger`} render={({ field }) => (
											<InputPriceField defaultValue={trigger} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Button primary type="button" className={'w-100'} onClick={() => remove(index)}>Xóa</Button>
									</Col>
								</Row>
							);
						})}
						<div className="d-flex justify-content-end">
							<Button white background type="button" onClick={handleAdd}>Thêm</Button>
						</div>
					</Col>
				</Row>
				<div className="text-right mt-1">
					<hr />
					<Button background blue type="submit">Lưu thông tin</Button>
				</div>
			</form>
		</section>
	)
}

export default UserEditShippingBase;