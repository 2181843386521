import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useEffect,
	useState
} from "react";
import {
	notification,
} from "antd";
import {
	SearchMobile,
	Button,
	Loading
} from "~/components";
import {
	OrderHistorySearch,
	OrderHistoryTable,
} from "../components";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {
	orderHistoryActions,
	orderHistoryItemsSelector,
	orderHistoryLoadingSelector,
	orderHistoryErrorSelector,
	orderHistoryPaginationSelector,
	orderHistoryFilterSelector
} from "../orderHistorySlice";
import {useParams} from "react-router-dom";

function OrderHistory() {
	const {status} = useParams();

	const can = {}

	const dispatch = useDispatch();

	const items = useSelector(orderHistoryItemsSelector);

	const loading = useSelector(orderHistoryLoadingSelector);

	const error = useSelector(orderHistoryErrorSelector);

	const pagination = useSelector(orderHistoryPaginationSelector);

	const filter = useSelector(orderHistoryFilterSelector);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [searchBar, setSearchBar] = useState(false)

	//Modal show
	const [openModal, setOpenModal] = useState({
		status : false,
		export : false,
		detail : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		search: (newFilter) => {
			dispatch(orderHistoryActions.setFilterWithDebounce(newFilter));
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(orderHistoryActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(orderHistoryActions.setFilter({...filter, page }));
		},
		changeSearch: (newFilter) => {
			dispatch(orderHistoryActions.setFilterWithDebounce(newFilter));
			setSearchBar(false)
		}
	}

	//Load data
	useEffect(() => {
		if(status != filter.status) {
			let newFilter = {...filter};
			newFilter.status = status;
			newFilter.page = 1;
			dispatch(orderHistoryActions.setFilter(newFilter));
		}
	}, [status]);

	useEffect(() => {
		dispatch(orderHistoryActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					<SearchMobile searchBar={searchBar} setSearchBar={setSearchBar}>
						<OrderHistorySearch filter={filter} onSearchChange={handleEvent.changeSearch}/>
					</SearchMobile>
				</div>
				{items && <OrderHistoryTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
					can={can}
				/>}
			</section>
		</div>
	)
}
export default OrderHistory;