import {Fragment, memo} from "react";
import DOMPurify from 'dompurify';
import {Col, Row} from "antd";
import {
	renderDate,
} from "~/utils";
import {Comments} from "../index";
import {
	LIST_ORDER_STATUS,
	LIST_TICKET_TYPE
} from "~/app/constants";
import {StatusLabel} from "~/components";

function TicketFormInfo({item}) {
	return (
		<div className="form">
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" xs={24} sm={24} md={14}>
					<p className="font-bold mb-2 text-lg color-red">Người gửi</p>
					<p className={'mb-1'}>Username: <b>{item?.user?.username}</b></p>
					<p className={'mb-1'}>Họ và tên: {item?.user?.firstname + ' ' + item?.user?.lastname}</p>
					<p className={'mb-2'}>Ngày gửi: {renderDate(item?.created)}</p>
					<hr />
					<p className="font-bold mb-2 text-lg color-red">Yêu cầu</p>
					<p className="mb-1 font-bold color-blue">{LIST_TICKET_TYPE[item?.type]?.title}</p>
					<p className="color-grey text-base mb-2">{item?.message}</p>
					{
						item.objectType == 'order' && <>
							<hr />
							<p className="font-bold mb-2 text-lg color-red">Đơn hàng</p>
							<p className="font-bold mb-1">{item?.orderCode} ({item?.numOrder}) <span className="color-grey text-xs mt-1"> - {renderDate(item?.order?.created)}</span></p>
							<p className="mb-1"><StatusLabel small type={LIST_ORDER_STATUS[item?.order.status]?.color} border>{LIST_ORDER_STATUS[item?.order.status]?.title}</StatusLabel></p>

						</>
					}
					<hr />
					<p className="font-bold mb-2 text-lg color-red">Chi Tiết</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" xs={24} sm={24} md={12}>
							<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.detailOld)}} />
						</Col>
						<Col className="gutter-row" xs={24} sm={24} md={12}>
							<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.detailNew)}} />
						</Col>
					</Row>

				</Col>
				<Col className="gutter-row" xs={24} sm={24} md={10}>
					<Comments objectId={item.id} />
				</Col>
			</Row>
			<hr className="mb-0"/>
			<div className="form-group d-flex justify-content-center modal-bottom"></div>
		</div>
	)
}

export default memo(TicketFormInfo);