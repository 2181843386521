import request from "~/utils/http";

const codApi = {
	get : async (id) => {
		const url = `v1/cod/${id}`;
		return await request.get(url);
	},
	gets : async (params) => {
		const url = 'v1/cod';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'v1/cod';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `v1/cod/${params.id}`;
		return await request.post(url, params);
	},
	delete : async (id) => {
		const url = `v1/cod/${id}`;
		return await request.delete(url);
	},
	getsMerge : async (id) => {
		const url = `v1/cod/merge/${id}`;
		return await request.get(url);
	},
	merge : async (params) => {
		const url = `v1/cod/merge/${params.id}`;
		return await request.post(url, params);
	},
	export : async (id) => {
		const url = `v1/cod/export/${id}`;
		return await request.post(url);
	},
	calculate : async (id) => {
		const url = `v1/cod/calculate/${id}`;
		return await request.post(url);
	},
	success : async (id) => {
		const url = `v1/cod/success/${id}`;
		return await request.post(url);
	},
	getsOrder : async (params) => {
		const url = 'v1/cod/order';
		return await request.get(url, {params});
	},
	exportOrder : async (params) => {
		const url = `v1/cod/order/export`;
		return await request.post(url, params);
	},
};

export default codApi;