import {Col, Row} from "antd";
import {
	Button,
	Loading,
} from "~/components";
import {
	InputField
} from "~/components/Forms";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

function AccountApiAdd({handleEvent}) {

	//Form
	const initialValues = {
		id: '',
		client_id: '',
		client_secret: '',
		prefix: '',
		serviceLevel: '',
		from: 0,
		to: 0,
	}

	const validationSchema = Yup.object().shape({
		id: Yup.string().required('Id tài khoản không được để trống'),
		serviceLevel: Yup.string().required('serviceLevel tài khoản không được để trống'),
		client_id: Yup.string().required('client_id không được để trống'),
		client_secret: Yup.string().required('client_secret không được để trống'),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleSave = async (data) => {
		await handleEvent.addNinjaVan(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(handleSave)}>
			{isSubmitting && <Loading noFixed />}
			<Row gutter={10}>
				<Col className="gutter-row" md={24} xs={12}>
					<Controller control={control} name="id" render={({field}) => (
						<InputField label="ID tài khoản" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={12} xs={12}>
					<Controller control={control} name="client_id" render={({field}) => (
						<InputField label="Client id" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={12} xs={12}>
					<Controller control={control} name="client_secret" render={({ field }) => (
						<InputField label="Client secret" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={12} xs={12}>
					<Controller control={control} name="prefix" render={({ field }) => (
						<InputField label="Prefix" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={12} xs={12}>
					<Controller control={control} name="serviceLevel" render={({ field }) => (
						<InputField label="Service Level" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={12} xs={12}>
					<Controller control={control} name="from" render={({ field }) => (
						<InputField label="KL từ" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={12} xs={12}>
					<Controller control={control} name="to" render={({ field }) => (
						<InputField label="KL đến" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button background blue>Lưu thông tin</Button>
			</div>
		</form>
	)
}

export default AccountApiAdd;