import {
	useContext, useEffect, useState
} from "react";
import {
	renderDate,
	numberFormat,
	apiError,
	handleRequest
} from "~/utils";
import {
	notification,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {codApi} from "~/api";
import {Button, Loading} from "~/components";

function CodMerge({item, handleEvent, selectedMergeKeys, setSelectedMergeKeys}) {

	const [loading, setLoading] = useState(true)

	const [items, setItems] = useState([])

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Mã', dataIndex: 'code', key: "code", width: '50px', render: (_, item) => (
			<>
				<p className="color-red font-bold">{item?.id}</p>
			</>
		)},
		{ title: 'Bên gửi', dataIndex: 'from', key: "from", render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p className="color-grey text-xs mt-1">{renderDate(item?.verifyTime)}</p>
			</>
		)},
		{ title: 'Cod', dataIndex: 'cod', key: "cod", render: (_, item) => (
			<p className="mb-0">{numberFormat(item.cod)} đ</p>
		)},
		{ title: 'Tiền thu về', dataIndex: 'insurance', key: "insurance", render: (_, item) => (
			<p className="mb-0">{numberFormat(item.cod - item.shipping)} đ</p>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedMergeKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedMergeKeys,
		onChange: onSelectChange,
	};

	const loadCodMerge = async () => {
		setLoading(true)
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Bạn chưa đơn đối soát'});
			return;
		}
		let [error, response] = await handleRequest(codApi.getsMerge(item.id));
		let message = apiError(`Load đơn đối soát liên quan thất bại`, error, response);
		if (!message) {
			setItems(response.data)
		}
		setLoading(false)
	}

	const handleCodMerge = async () => {
		setLoading(true)
		await handleEvent.merge(item)
		setLoading(false)
	}

	useEffect(() => {
		loadCodMerge();
	}, [item?.id]);

	return (
		<div className="">
			{loading && <Loading />}
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="form-group d-flex justify-content-end modal-bottom pd-2">
				<Button background primary onClick={handleCodMerge}>Gộp đơn</Button>
			</div>
		</div>
	)
}

export default CodMerge;