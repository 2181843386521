import {useEffect, useState} from "react";
import {Breadcrumb, Col, Empty, Row} from "antd";
import {
	Button,
	Loading,
} from "~/components";
import {
	apiError,
	handleRequest,
	renderDate
} from "~/utils";
import {
	reportApi
} from "~/api";
import {Link, useParams} from "react-router-dom";
import {Bar} from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);
function ReportAffiliateChart() {

	const {id} = useParams();

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [chartDataCount, setChartDataCount] = useState({
		labels: [],
		datasets : [
			{
				label: 'Số lượng đơn',
				backgroundColor: 'rgb(255, 99, 132)',
				gradientStart: 'rgba(255, 99, 132, 0.3)',
				gradientEnd: 'rgba(255, 99, 132, 1)',
			},
		]
	});

	const [chartDataTotal, setChartDataTotal] = useState({
		labels: [],
		datasets : [
			{
				label: 'Doanh số',
				backgroundColor: 'rgb(99 30 235)',
			},
		]
	});

	const [user, setUser] = useState({});

	const options = {
		responsive: true,
		scales: {
			y: {
				grid: {
					display:false
				}
			},
			x: {
				grid: {
					display:false
				}
			},
		},
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Biểu đồ thu chi',
			},
		},
	};

	const handleEvent = {
		loadChartData: async () => {
			let [error, response] = await handleRequest(reportApi.affiliatesChart(id));
			let message = apiError(`Load data thất bại`, error, response);
			if(!message) {
				let data = {
					labels: [],
					valuesCount: [],
					valuesTotal: [],
				}
				Object.entries(response.data.chart).map(([key, item], i) => {
					data.labels.push(item.day);
					data.valuesCount.push(item.count);
					data.valuesTotal.push(item.total);
				})
				setChartDataCount({
					labels: data.labels,
					datasets: [
						{
							...chartDataCount.datasets[0],
							data: data.valuesCount,
						},
					],
				})
				setChartDataTotal({
					labels: data.labels,
					datasets: [
						{
							...chartDataTotal.datasets[0],
							data: data.valuesTotal,
						},
					],
				})
				setUser(response.data.item)
				setErrorLoad(false)
			}
			else {
				setErrorLoad(true)
			}
			setLoading(false)
		},
	}

	//Load data
	useEffect(() => {
		handleEvent.loadChartData()
	}, [id]);

	if(loading) {
		return (
			<div className={'container'}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={'container'}>
				<Empty description={<span>Không có thông tin</span>}><Button to={'/report/affiliate'}>Quay lại danh sách báo cáo</Button></Empty>
			</div>
		)
	}

	return (
		<div className={'container'}>
			<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: 'Báo Cáo',},
				            {title: <Link to={'/report/affiliate'}>Báo cáo CTV</Link>,},
				            {title: 'Biểu đồ',},
			            ]}
			/>
			<section className="mb-2">
				<p className="font-bold text-lg mb-1">{user?.user?.firstname+' '+user?.user?.lastname}</p>
				<p className="mb-1">{user?.user?.username}</p>
				<p className="text-xs color-grey">{renderDate(user?.created)}</p>
			</section>
			<section style={{maxHeight:'500px'}}>
				<Row>
					<Col lg={12} md={24}>
						<Bar options={options} data={chartDataCount} />
					</Col>
					<Col lg={12} md={24}>
						<Bar options={options} data={chartDataTotal} />
					</Col>
				</Row>
			</section>
		</div>
	)
}

export default ReportAffiliateChart;