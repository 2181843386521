import {createContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
	checkAuthorization,
	apiError,
	handleRequest
} from "~/utils";
import {Loading} from "../components";
import {useViewport} from "~/hooks";
import {authApi, masterApi} from "../api";
import {authActions} from "../features/Auth/authSlice";

const AppContext = createContext(undefined);

// APP GLOBAL DATA
function AppProvider({ children }) {

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);

	const [onlineUsers, setOnlineUsers] = useState([]);

	const [loginAsUsers, setLoginAsUsers] = useState([]);

	//Danh sách tỉnh thành quận huyện
	const [country, setCountry] = useState({
		'city' 		: [],
		'district' 	: [],
		'ward' 		: [],
	});

	const [shippingSupport, setShippingSupport] = useState({});

	const [shippingCompanies, setShippingCompanies] = useState({});

	const [banksData, setBanksData] = useState([]);

	const [rolesData, setRolesData] = useState([]);

	const [userLogin, setUserLogin] = useState(false);

	const [tableHeight, setTableHeight] = useState('60vh');

	useEffect(() => {
		if(checkAuthorization()) {
			(async () => {
				//Kiểm tra login
				let [errorUser, responseUser] = await handleRequest(authApi.current());
				let messageUser = apiError(`Lấy thông tin tài khoản thất bại`, errorUser, responseUser);
				if (!messageUser) {
					dispatch(authActions.loginSuccess(responseUser.data));

					setShippingSupport(responseUser.data.shippingSupport);

					/* Các Thông tin khác */
					let loadKey = localStorage.getItem('utilities-key');
					if (responseUser.data.utilitiesKey != loadKey) {
						let [error, response] = await handleRequest(masterApi.gets());
						let message = apiError(`Load thông tin thất bại`, error, response);
						if (!message) {
							localStorage.setItem('utilities-key', responseUser.data.utilitiesKey);
							localStorage.setItem('cityList', JSON.stringify(response.data.cities));
							localStorage.setItem('districtList', JSON.stringify(response.data.districts));
							localStorage.setItem('wardList', JSON.stringify(response.data.wards));
							localStorage.setItem('shippingCompanies', JSON.stringify(response.data.shippingCompanies));
							localStorage.setItem('banksData', JSON.stringify(response.data.banks));
							localStorage.setItem('rolesData', JSON.stringify(response.data.roles));

							country.city = response.data.cities;
							country.district = response.data.districts;
							country.ward = response.data.wards;
							setCountry(country);
							setShippingCompanies(response.data.shippingCompanies);
							setBanksData(response.data.banks);
							setRolesData(response.data.roles);
						}
					} else {
						country.city     = JSON.parse(localStorage.getItem('cityList'));
						country.district = JSON.parse(localStorage.getItem('districtList'));
						country.ward     = JSON.parse(localStorage.getItem('wardList'));
						setCountry(country);
						setShippingCompanies(JSON.parse(localStorage.getItem('shippingCompanies')));
						setBanksData(JSON.parse(localStorage.getItem('banksData')));
						setRolesData(JSON.parse(localStorage.getItem('rolesData')));
					}
				}
				setLoading(false);
			})();
		}
		else {
			setLoading(false);
		}
	}, [userLogin]);

	// Handle responsive
	const viewport = useViewport();
	const [isMobile, setIsMobile] = useState(false);
	const [isTablet, setIsTablet] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		if (viewport.width < 768) {
			setIsMobile(true);
			setIsTablet(false);
			setIsDesktop(false);
			setTableHeight('60vh');
		}
		else if (viewport.width < 1300) {
			setIsMobile(false);
			setIsTablet(true);
			setIsDesktop(false);
			setTableHeight('50vh');
		}
		else {
			setIsMobile(false);
			setIsTablet(false);
			setIsDesktop(true);
			setTableHeight('60vh');
		}
	}, [viewport.width]);

	if (loading) return <Loading />

	return (
		<AppContext.Provider value={{
			country,
			setCountry,
			isMobile,
			isDesktop,
			isTablet,
			onlineUsers,
			setOnlineUsers,
			loginAsUsers,
			setLoginAsUsers,
			userLogin,
			setUserLogin,
			tableHeight,
			shippingSupport,
			setShippingSupport,
			shippingCompanies,
			banksData,
			rolesData,
		}}>
			{children}
		</AppContext.Provider>
	)
}

export { AppContext };
export default AppProvider;