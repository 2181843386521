import {
	Loading,
	Button,
} from "~/components";
import {
	InputField,
} from "~/components/Forms";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";

function ShippingPriceFormCopy({item, onHandleSubmit}) {

	//Form
	const initialValues = {
		name: item?.name,
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Không được bỏ trống tên bảng giá'),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading noFixed/>}
			<Controller control={control} name="name" render={({field}) => (
				<InputField label="Tên bảng giá mới" errors={errors} {...field}/>
			)}/>
			<div className="text-right mt-1 modal-bottom pd-2">
				<Button background blue type="submit">Nhân bản bảng giá</Button>
			</div>
		</form>
	)
}

export default ShippingPriceFormCopy;