import style from '../style/Account.module.scss';
import AccountHeader from "../components/AccoutHeader";
import className from 'classnames/bind';
import {
	Loading,
	Button,
	Icon,
	RoleError,
	Modal
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	authApi,
	userApi,
	shippingApi
} from "~/api";
import {useEffect, useState} from "react";
import {useCurrentUser, useCan} from "~/hooks";
import AccountApiTable from "../components/Tables/AccountApiTable";
import AccountApiAdd from "../components/Forms/AccountApiAdd";
import AccountApiEdit from "../components/Forms/AccountApiEdit";

const cn = className.bind(style);

function AccountApi() {

	const can = {
		setApi: useCan('shop_api'),
	}
	const currentUser = useCurrentUser();
	const [loading, setLoading] = useState(true);
	const [errorLoad, setErrorLoad] = useState(false);
	const [apiNinjaVanOut, setApiNinjaVanOut] = useState([]);
	const [apiNinjaVanIn, setApiNinjaVanIn] = useState([]);
	const [apiNinjaVanRegional, setApiNinjaVanRegional] = useState([]);
	const [apiGHN, setApiGHN] = useState([]);
	const [apiSPX, setApiSPX] = useState([]);
	const [addNinjaVanKey, setAddNinjaVanKey] = useState('');
	const [itemEdit, setItemEdit] = useState({});
	//Modal show
	const [openModal, setOpenModal] = useState({
		add : false,
		edit : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		loadApi: async () => {
			let [error, response] = await handleRequest(shippingApi.gets());
			let message = apiError(`Lấy thông tin api thất bại`, error, response);
			if(!message) {
				setApiNinjaVanOut(response.data.ninjavan)
				setApiNinjaVanIn(response.data.ninjavanNT)
				setApiNinjaVanRegional(response.data.ninjavanRegional)
				setApiGHN(response.data.ghn)
				setApiSPX(response.data.spx)
			}
			else {
				setErrorLoad(true)
			}
			setLoading(false)
		},
		addNinjaVan: async (data) => {
			data.shippingUnit = addNinjaVanKey;

			let [error, response] = await handleRequest(shippingApi.add(data));

			let message = apiError(`Lấy thông tin api thất bại`, error, response);

			if(!message) {
				if(addNinjaVanKey == 'ninjavanOut') {
					setApiNinjaVanOut(response.data)
				}
				if(addNinjaVanKey == 'ninjavanIn') {
					setApiNinjaVanIn(response.data)
				}
				if(addNinjaVanKey == 'ninjavanRegional') {
					setApiNinjaVanRegional(response.data)
				}
				handleEvent.closeModal('add');
			}
		},
		editNinjaVan: async (data, item) => {

			data.shippingUnit = addNinjaVanKey;

			data.id = item.id;

			let [error, response] = await handleRequest(shippingApi.update(data));

			let message = apiError(`Lấy thông tin api thất bại`, error, response);

			if(!message) {
				if(addNinjaVanKey == 'ninjavanOut') {
					setApiNinjaVanOut(response.data)
				}
				if(addNinjaVanKey == 'ninjavanIn') {
					setApiNinjaVanIn(response.data)
				}
				if(addNinjaVanKey == 'ninjavanRegional') {
					setApiNinjaVanRegional(response.data)
				}
				handleEvent.closeModal('edit');
			}
		},
		closedNinjaVan: async (item) => {

			let [error, response] = await handleRequest(shippingApi.closed({
				shippingUnit : addNinjaVanKey,
				id : item.id,
			}));

			let message = apiError(`Lấy thông tin api thất bại`, error, response);

			if(!message) {
				if(addNinjaVanKey == 'ninjavanOut') {
					setApiNinjaVanOut(response.data)
				}
				if(addNinjaVanKey == 'ninjavanIn') {
					setApiNinjaVanIn(response.data)
				}
				if(addNinjaVanKey == 'ninjavanRegional') {
					setApiNinjaVanRegional(response.data)
				}
			}
		},
		usingNinjaVan: async (item) => {

			let [error, response] = await handleRequest(shippingApi.using({
				shippingUnit : addNinjaVanKey,
				id : item.id,
			}));

			let message = apiError(`Lấy thông tin api thất bại`, error, response);

			if(!message) {
				if(addNinjaVanKey == 'ninjavanOut') {
					setApiNinjaVanOut(response.data)
				}
				if(addNinjaVanKey == 'ninjavanIn') {
					setApiNinjaVanIn(response.data)
				}
				if(addNinjaVanKey == 'ninjavanRegional') {
					setApiNinjaVanRegional(response.data)
				}
			}
		},
	}

	useEffect(() => {
		if(can.setApi) {
			handleEvent.loadApi().then()
		}
	}, [currentUser?.id]);


	if(!can.setApi) {
		return (
			<RoleError link={'/account'}/>
		)
	}

	if(loading) {
		return (
			<div className={'container'}>
				<Loading/>
			</div>
		)
	}
	return (
		<div className="container">
			<AccountHeader active={'api'}/>
			<section className="content pd-2 w-100 text-left">
				<div className="d-flex justify-content-between align-items-center">
					<p className="heading">NINJAVAN NGOẠI THÀNH</p>
					<Button blue background leftIcon={Icon.plus} onClick={() => {
						handleEvent.openModal('add')
						setAddNinjaVanKey('ninjavanOut')
					}}>Thêm Tài Khoản</Button>
				</div>
				{
					apiNinjaVanOut &&
					<AccountApiTable items={apiNinjaVanOut} type={'ninjavanOut'} setAddNinjaVanKey={setAddNinjaVanKey}
									 setItemEdit={setItemEdit} handleEvent={handleEvent}/>
				}
			</section>
			<section className="content pd-2 w-100 text-left">
				<div className="d-flex justify-content-between align-items-center">
					<p className="heading">NINJAVAN NỘI THÀNH</p>
					<Button blue background leftIcon={Icon.plus} onClick={() => {
						handleEvent.openModal('add')
						setAddNinjaVanKey('ninjavanIn')
					}}>Thêm Tài Khoản</Button>
				</div>
				{
					apiNinjaVanIn &&
					<AccountApiTable items={apiNinjaVanIn} type={'ninjavanIn'} setAddNinjaVanKey={setAddNinjaVanKey}
									 setItemEdit={setItemEdit} handleEvent={handleEvent}/>
				}
			</section>
			<section className="content pd-2 w-100 text-left">
				<div className="d-flex justify-content-between align-items-center">
					<p className="heading">NINJAVAN NỘI VÙNG</p>
					<Button blue background leftIcon={Icon.plus} onClick={() => {
						handleEvent.openModal('add')
						setAddNinjaVanKey('ninjavanRegional')
					}}>Thêm Tài Khoản</Button>
				</div>
				{
					apiNinjaVanRegional && <AccountApiTable items={apiNinjaVanRegional} type={'ninjavanRegional'}
															setAddNinjaVanKey={setAddNinjaVanKey}
															setItemEdit={setItemEdit} handleEvent={handleEvent}/>
				}
			</section>

			<section className="content pd-2 w-100 text-left">
				<div className="d-flex justify-content-between align-items-center">
					<p className="heading">GHN</p>
				</div>
				{
					apiGHN && <AccountApiTable items={apiGHN} type={'ghn'} setAddNinjaVanKey={setAddNinjaVanKey}
											   setItemEdit={setItemEdit} handleEvent={handleEvent}/>
				}
			</section>

			<section className="content pd-2 w-100 text-left">
				<div className="d-flex justify-content-between align-items-center">
					<p className="heading">SPX</p>
				</div>
				{
					apiSPX && <AccountApiTable items={apiSPX} type={'spx'} setAddNinjaVanKey={setAddNinjaVanKey}
											   setItemEdit={setItemEdit} handleEvent={handleEvent}/>
				}
			</section>

			{
				(openModal.add) && <Modal title="Thêm Tài Khoản Ninjavan" visible={openModal.add} onCancel={() => {
					handleEvent.closeModal('add')
				}}>
					<AccountApiAdd handleEvent={handleEvent}/>
				</Modal>
			}
			{
				(openModal.edit && itemEdit?.id) &&
				<Modal title="Cập nhật Khoản Ninjavan" visible={openModal.edit} onCancel={() => {
					handleEvent.closeModal('edit')
				}}>
					<AccountApiEdit item={itemEdit} handleEvent={handleEvent}/>
				</Modal>
			}
		</div>
	)
}

export default AccountApi;