import style from '../../style/User.module.scss'
import className from 'classnames/bind';
import {useEffect, useState} from "react";
import {
	Col,
	Row,
	Image,
	Alert
} from "antd";
import {
	Loading,
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	userApi
} from "~/api";

const cn = className.bind(style);

function UserEditVerify({currentUser}) {

	const [loading, setLoading] = useState(true);
	const [previewImageBefore, setPreviewImageBefore] = useState('');
	const [previewImageAfter, setPreviewImageAfter] = useState('');
	const [rejectedNote, setRejectedNote] = useState();

	const loadUser = async () => {
		let [error, response] = await handleRequest(userApi.getIdentification(currentUser?.id));
		let message = apiError(`Lấy thông tin thành viên thất bại`, error, response);
		if(!message) {
			setPreviewImageBefore(response.data?.identificationBefore)
			setPreviewImageAfter(response.data?.identificationAfter)
			setRejectedNote(response.data?.rejectedNote)
		}
		setLoading(false)
	}

	useEffect(() => {
		loadUser().then()
	}, [currentUser?.id]);

	return (
		<section>
			<p className={'heading'}>Xác thực tài khoản</p>
			{loading && <Loading noFixed/>}
			{
				currentUser.isVerify == 'unverified' && (<>
					<Alert
						key={'account-unverified'}
						message="Chưa xác thực"
						description="Tài khoản này chưa upload giấy tờ để được xác thực thông tin"
						type="warning"
						showIcon
					/>
				</>)
			}
			{
				currentUser.isVerify == 'underReview' && (<>
					<Alert
						key={'account-underReview'}
						message="Đợi xác thực"
						description="Đã gửi yêu cầu xác thự đang trong tình trạng đợi."
						type="info"
						showIcon
					/>
				</>)
			}
			{
				currentUser.isVerify == 'verified' && (<>
					<Alert
						key={'account-verified'}
						message="Đã xác thực"
						description="Tài khoản đã xác thực"
						type="success"
						showIcon
					/>
				</>)
			}
			{
				currentUser.isVerify == 'rejected' && (<>
					<Alert
						key={'account-rejected'}
						message="Từ chối"
						description={"Tài khoản đã bị từ chối xác thực." + rejectedNote}
						type="error"
						className={'mb-1'}
						showIcon
					/>
				</>)
			}
			{
				(currentUser.isVerify != 'unverified') && (<>
					<Row gutter={10} className={'mt-1'}>
						<Col className="gutter-row" md={6} xs={24}>
							<div className={cn('upload-card')} key={'upload-card-cccd-before'}>
								<div className={cn('upload-preview', 'mb-2')}>
									{previewImageBefore && <Image src={previewImageBefore}/>}
								</div>
							</div>
						</Col>
						<Col className="gutter-row" md={6} xs={24}>
							<div className={cn('upload-card')} key={'upload-card-cccd-after'}>
								<div className={cn('upload-preview', 'mb-2')}>
									{previewImageAfter && <Image src={previewImageAfter}/>}
								</div>
							</div>
						</Col>
					</Row>
				</>)
			}
		</section>
	)
}

export default UserEditVerify;