import {Drawer} from "antd";
import {useContext, useState} from "react";
import className from "classnames/bind";
import style from "./SearchMobile.module.scss";
import {Button, Icon} from "../index";
import {AppContext} from "../../context/AppProvider";
const cn = className.bind(style);
function SearchMobile({children, searchBar, setSearchBar}) {
	const {isMobile} = useContext(AppContext);
	if(!isMobile) {
		return (<>{children}</>)
	}
	return (<>
		<Button leftIcon={Icon.filter} background white onClick={()=>setSearchBar(true)}>Lọc</Button>
		<Drawer title="Bộ lọc" placement="right" onClose={() => setSearchBar(false)} open={searchBar}>
			<div className={cn('search-mobile', 'form-search-mobile')}>{children}</div>
		</Drawer>
	</>)
}

export default SearchMobile;
