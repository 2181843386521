import style from './Comments.module.scss';
import className from 'classnames/bind';
import {useMemo} from "react";
import {Avatar, Image} from "antd";
import {useCurrentUser} from "~/hooks";
import {renderDate} from "~/utils";
import DOMPurify from "dompurify";
const cn = className.bind(style);

function Message({item, currentUserId, preCommentUser}) {

	const {id, created, userId, message, user} = item;

	function addLinks(text) {
		let urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function(url) {
			return '<a href="' + url + '" target="_blank">' + url + '</a>';
		});
	}

	// Handle display message
	const renderMessageContent = useMemo(() => {
		if (message.includes("http://") || message.includes("https://") || message.includes("www.")) {
			return (
				<p className={cn("text-inner")} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addLinks(message)) }} />
			);
		} else {
			return <p className={cn("text-inner")}>{message}</p>;
		}
	}, [message]);

	return (
		<div className={cn("message-wrapper", {
			'reverse' : (currentUserId == userId),
			'group' : (userId === preCommentUser),
		})}>
			<div className={cn("profile-picture")}>
				<Avatar size="small">{user.avatar}</Avatar>
			</div>
			<div className={cn("message-content")}>
				<p className={cn("name")}>{user.firstname} {user.lastname}</p>
				{renderMessageContent}
			</div>
		</div>
	)
}

export default Message;