import className from 'classnames/bind';
import style from '../style/Auth.module.scss';
import {useState} from "react";
import {Link} from "react-router-dom";
import {notification} from "antd";
import {globalNavigate} from "~/routes/GlobalHistory";
import {authApi} from "~/api";
import {
	AuthRegisterForm,
	RegisterSuccess
} from "~/features/Auth/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	Image
} from "~/components";
const cn = className.bind(style);

function Register() {

	const [registerSuccess, setRegisterSuccess] = useState(false);

	const isLoggedIn = Boolean(localStorage.getItem('access_token'));

	if (isLoggedIn) {
		globalNavigate("/");
	}

	const registerHandle = async (data) => {

		let [error, response] = await handleRequest(authApi.register(data));

		let message = apiError(`Đăng ký thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Đăng ký tài khoản thành công`});
			setRegisterSuccess(true);
		}
	}

	return (
		<div className={cn('login')}>
			<div className={cn('login-wrapper')}>
				<div className={cn('login-form')}>
					<div className="text-center">
						<Image src={'https://media-cdn.superexpress.vn/uploads/file-logo/super-express-logo-192x192.png'} style={{height:'100px'}} />
					</div>
					<p className={cn('name', 'text-center')}><span>SUPEREXPRESS</span></p>
					<h3>Đăng ký</h3>
					{
						!registerSuccess ? <AuthRegisterForm onHandleSubmit={registerHandle} /> : <RegisterSuccess />
					}
					<p className={'mt-2 text-center text-xs'}>Bạn đã có tài khoản? <Link to={'/login'} className={'font-bold color-red'}>Đăng nhập ngay</Link></p>
				</div>
			</div>
		</div>
	)
}

export default Register;