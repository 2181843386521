import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {Link, useParams} from "react-router-dom";
import {
	useContext,
	useEffect,
	useState
} from "react";
import {
	Breadcrumb,
	Col, Empty,
	notification,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
	renderAddress,
	numberFormat,
	isEmpty
} from "~/utils";
import {
	useCan
} from "~/hooks";
import {
	orderApi
} from "~/api";
import {AppContext} from "~/context/AppProvider";
import {globalNavigate} from "~/routes/GlobalHistory";
import {
	Button,
	Loading,
	RoleError
} from "~/components";
import {
	InputField,
	TextAreaField
} from "~/components/Forms";
import * as Yup from "yup";
import {
	Controller,
	useFieldArray,
	useForm
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const cn = className.bind(style);

function OrderReturn() {

	const {id} = useParams();

	const can = {
		add: useCan('shop_order_add'),
	}

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [order, setOrder] = useState({});
	
	const {country} = useContext(AppContext);

	const [branchMain, setBranchMain] = useState({});

	const [fee, setFee] = useState(0);

	//Form
	const initialValues = {
		products: [],
		weight: 0.1,
		long: 10,
		height: 10,
		width: 10,
		note: undefined
	}

	const validationSchema = Yup.object().shape({
		products: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required('Tên sản phẩm không được bỏ trống'),
				quantity  : Yup.number().required('số lượng không được bỏ trống'),
				weight : Yup.number().required('Cân nặng không được bỏ trống')
			})
		),
		weight: Yup.number().required('Khối lượng đơn hàng không được để trống'),
		long: Yup.number().required('Chiều dài đơn hàng không được để trống'),
		height: Yup.number().required('Chiều cao đơn hàng không được để trống'),
		width: Yup.number().required('Chiều rộng đơn hàng không được để trống'),
	})

	const {control, watch, handleSubmit, formState: { isSubmitting, errors }, reset, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "products"
	});

	const loadOrder = async () => {
		let [error, response] = await handleRequest(orderApi.getById(id));
		let message = apiError(`Lấy thông tin đơn hàng thất bại`, error, response);
		if(!message) {
			initialValues.weight= (Number.isInteger(response.data?.weight)) ? response.data?.weight : parseFloat(response.data?.weight.toFixed(2));
			initialValues.long= response.data?.long;
			initialValues.height= response.data?.height;
			initialValues.width= response.data?.width;
			initialValues.products = [];
			if(Object.keys(response.data.items).length !== 0) {
				for (const [index, item] of Object.entries(response.data.items)) {
					initialValues.products.push({ id: item.id, title: item.title, quantity: item.quantity, weight:item.weight})
				}
			}
			reset(initialValues)
			setOrder(response.data)
			if(response.data.shop?.id) {
				setBranchMain(response.data.shop);
			}
			else {
				setBranchMain({
					name: response.data.fromName,
					phone: response.data.fromPhone,
					city: response.data.fromCity,
					district: response.data.fromDistrict,
					ward: response.data.fromWard,
					address: response.data.fromAddress,
				});
			}

			setErrorLoad(false)
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	const handleShippingPrice = async () => {

		if(!order?.shippingUnit) return false;

		const fieldsFee = [
			order?.shippingUnit,
			getValues("weight"),
			order?.insurance,
			order?.toCity,
		]

		if(isEmpty(fieldsFee[0]) || isEmpty(fieldsFee[1]) || isEmpty(fieldsFee[3])) return false;

		if(isEmpty(fieldsFee[2])) fieldsFee[2] = 0;

		let [error, response] = await handleRequest(orderApi.fee({
			shippingUnit: fieldsFee[0],
			weight: fieldsFee[1],
			insurance: fieldsFee[2],
			from: branchMain.city,
			to: fieldsFee[3],
			serviceType: 'return'
		}));

		let message = apiError(`Tính phí ship thất bại`, error, response);
		if (!message) {
			setFee(response.data.shipping)
		}
	}

	const handleWeightTotal = () => {
		const products = watch("products");
		let weight = 0;
		if(Object.keys(products).length !== 0) {
			for (const [index, item] of Object.entries(products)) {
				weight += item.quantity*item.weight;
			}
		}
		setValue('weight', (Number.isInteger(weight)) ? weight : parseFloat(weight.toFixed(2)))
	}

	useEffect(() => {
		loadOrder().then(() => handleShippingPrice())
	}, [id]);

	useEffect(() => {
		handleShippingPrice().then();
	}, [watch("weight")]);

	useEffect(() => {
		handleWeightTotal()
	}, [watch("products")]);

	const handleOrderAdd = async (data) => {
		if (order?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để lưu'});
			return;
		}
		data.id = order.id;
		let [error, response] = await handleRequest(orderApi.return(data));
		let message = apiError(`Thêm đơn hàng thu hồi thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Thêm đơn hàng thành công`});
			globalNavigate('/order');
		}
	}

	if(!can.add) {
		return (<RoleError link={'/order'} />)
	}

	if(loading) {
		return (
			<div className={cn('order-gird')}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={cn('order-gird')}>
				<Empty description={<span>Không có đơn hàng</span>}><Button to={'/order'}>Quay lại danh sách đơn hàng</Button></Empty>
			</div>
		)
	}

	return (
		<div className={cn('order-gird')}>
			<form className="form" onSubmit={handleSubmit(handleOrderAdd)}>
				{isSubmitting && <Loading/>}
				<div className={cn('order-left')}>
					<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/order'}>Đơn hàng</Link>,},
				            {title: 'Tạo đơn thu hồi',},
			            ]}
					/>
					<section className="mb-2">
						<p className="font-bold text-lg mb-2">TẠO ĐƠN THU HỒI CHO ĐƠN HÀNG {order?.code} - {order?.trackingNumber}</p>
					</section>

					<section>
						<h2 className={'heading'}>Bên Gửi</h2>
						<p className={'mb-1 color-red'}><strong>{branchMain?.name}</strong></p>
						<p className={'mb-1 color-red'}>{branchMain?.phone}</p>
						<p className={'mb-2'}>{
							branchMain?.address && branchMain?.address +','+renderAddress(
								branchMain?.city,
								branchMain?.district,
								branchMain?.ward,
								country)
						}</p>
					</section>

					<section>
						<h2 className={'heading'}>Bên nhận</h2>
						<p className={'mb-1 color-red'}><strong>{order?.toName}</strong></p>
						<p className={'mb-1 color-red'}>{order?.toPhone}</p>
						<p className={'mb-2'}>{
							order?.toAddress && order?.toAddress +','+renderAddress(
								order?.toCity,
								order?.toDistrict,
								order?.toWard,
								country)
						}</p>
					</section>

					<section>
						<h2 className="heading">Sản phẩm</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Tên sản phẩm</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Khối lượng (Kg)</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Số lượng</label>
							</Col>
							<Col className="gutter-row" span={3}></Col>
						</Row>
						{fields.map(({ id, title, quantity, weight }, index) => {
							return (
								<Row gutter={10} key={id}>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].title`} render={({ field }) => (
											<InputField defaultValue={title} errors={errors} {...field} />
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].quantity`} render={({ field }) => (
											<InputField defaultValue={quantity} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}} />
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].weight`} render={({ field }) => (
											<InputField defaultValue={weight} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}} />
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Button primary type="button" className={'w-100'} onClick={() => remove(index)} >Xóa</Button>
									</Col>
								</Row>
							);
						})}

						<h2 className="heading">Thông tin gói hàng</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Tổng khối lượng</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Dài (cm)</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Rộng (cm)</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Cao (cm)</label>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="weight" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="long" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="height" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="width" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Ghi chú</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={24}>
								<Controller control={control} name="note" render={({ field }) => (
									<TextAreaField label="Ghi chú" errors={errors} {...field} />
								)}/>
							</Col>
						</Row>
					</section>
				</div>
				<div className={cn('order-right')}>
					<div className={cn('order-bottom')}>
						{
							fee !== 0 && <div className="text-right mb-4">
								<p className="color-red">Phí giao hàng</p>
								<p className="color-red font-bold">{numberFormat(fee)} vnđ</p>
							</div>
						}
						<div className="text-center">
							{
								can.add && <Button type="submit" background blue className="w-100 ml-0">Tạo đơn thu hồi</Button>
							}
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}
export default OrderReturn;