import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useEffect,
	useState
} from "react";
import {
	notification,
} from "antd";
import {
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import {
	ShippingPriceTable,
	ShippingPriceFormCopy
} from "../components";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {
	apiError,
	handleRequest
} from "~/utils";
import {useCan} from "~/hooks";
import {shippingPriceApi} from "~/api";
import {
	shippingPriceActions,
	shippingPriceItemsSelector,
	shippingPriceLoadingSelector,
	shippingPriceErrorSelector,
	shippingPricePaginationSelector,
	shippingPriceFilterSelector
} from "../shippingPriceSlice";
import {userActions} from "../../User/userSlice";

function ShippingPrice() {

	const can = {
		add: useCan('shop_shipping_price_add'),
		edit: useCan('shop_shipping_price_edit'),
		delete: useCan('shop_shipping_price_delete'),
		setUser: useCan('shop_shipping_price_user'),
		default: useCan('shop_shipping_price_default'),
		status: useCan('shop_shipping_price_status'),
	}

	const dispatch = useDispatch();

	const items = useSelector(shippingPriceItemsSelector);

	const loading = useSelector(shippingPriceLoadingSelector);

	const error = useSelector(shippingPriceErrorSelector);

	const pagination = useSelector(shippingPricePaginationSelector);

	const filter = useSelector(shippingPriceFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Modal show
	const [openModal, setOpenModal] = useState({
		delete : false,
		default : false,
		duplicate : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		default : async () => {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn bảng giá'});
				return;
			}
			let [error, response] = await handleRequest(shippingPriceApi.default(itemEdit.id));
			let message = apiError(`Thay đổi trạng thái bảng giá thất bại`, error, response);
			if (!message) {
				Object.entries(items).map(([key, obj]) => {
					if(obj.shipping == itemEdit.shipping) {
						if(obj.default == 1 && obj.id !== itemEdit.id) {
							dispatch(shippingPriceActions.update({
								...obj,
								default: 0
							}));
						}
					}
				})
				dispatch(shippingPriceActions.update({
					...itemEdit,
					default: 1
				}));
				handleEvent.closeModal('default');
			}
		},
		duplicate: async (data, item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn bảng giá'});
				return;
			}
			data.id = item.id
			let [error, response] = await handleRequest(shippingPriceApi.duplicate(data));
			let message = apiError(`Nhân bản bảng giá thất bại`, error, response);
			if (!message) {
				notification.success({message: 'Thành công', description: `Nhân bản bảng giá thành công`});
				dispatch(shippingPriceActions.add(response.data));
				handleEvent.closeModal('duplicate');
			}
		},
		delete : async () => {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn bảng giá'});
				return;
			}
			let [error, response] = await handleRequest(shippingPriceApi.delete(itemEdit.id));
			let message = apiError(`Xóa bảng giá thất bại`, error, response);
			if (!message) {
				notification.success({message: 'Thành công', description: 'Xóa bảng giá thành công'});
				dispatch(shippingPriceActions.delete(itemEdit.id));
				handleEvent.closeModal('delete');
			}
		},
		search: (newFilter) => {
			dispatch(shippingPriceActions.setFilterWithDebounce(newFilter));
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(shippingPriceActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(shippingPriceActions.setFilter({...filter, page }));
		},
		changeSearch: (newFilter) => {
			dispatch(shippingPriceActions.setFilterWithDebounce(newFilter));
		},
		active : async (item) => {

			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa thành viên'});
				return;
			}
			let [error, response] = await handleRequest(shippingPriceApi.status({
				id : item.id,
				status : 'public'
			}));

			let message = apiError(`Thay đổi trạng thái bảng giá thất bại`, error, response);
			if (!message) {
				dispatch(shippingPriceActions.update({
					...item,
					status: 'public'
				}));
			}
		},
	}

	//Load data
	useEffect(() => {
		dispatch(shippingPriceActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1 mb-1">
					{can.add && <Button background green to={'/admin/shipping-price/add'}>Thêm bảng giá</Button>}
				</div>
				{items && <ShippingPriceTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					can={can}
				/>}
			</section>
			{
				(can.edit && openModal.default && itemEdit?.id) && <Modal title="Đặt làm mặc định" visible={openModal.default} onCancel={() => {handleEvent.closeModal('default')}}>
					<p>Đặt bảng giá <b>{itemEdit?.name}</b> làm bảng giá mặc định?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleEvent.closeModal('default')}}> Đóng </Button>
						<Button background blue leftIcon={Icon.success} onClick={handleEvent.default}> Xác nhận </Button>
					</div>
				</Modal>
			}
			{
				(can.delete && openModal.delete && itemEdit?.id) && <Modal title="Xóa bảng giá" visible={openModal.delete} onCancel={() => {handleEvent.closeModal('delete')}}>
					<p>Xóa bảng giá <b>{itemEdit?.name}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleEvent.closeModal('delete')}}> Đóng </Button>
						<Button background red leftIcon={Icon.delete} onClick={handleEvent.delete}> Xác nhận </Button>
					</div>
				</Modal>
			}
			{
				(can.add && openModal.duplicate && itemEdit?.id) && <Modal title="Nhân bảng giá" visible={openModal.duplicate} onCancel={() => {handleEvent.closeModal('duplicate')}}>
					<ShippingPriceFormCopy item={itemEdit} onHandleSubmit={handleEvent.duplicate} />
				</Modal>
			}
		</div>
	)
}
export default ShippingPrice;