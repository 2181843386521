import {Link, useParams} from "react-router-dom";
import {
	useEffect,
	useState
} from "react";
import {
	Breadcrumb,
	Col,
	Empty,
	notification,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	Button,
	Loading,
	RoleError
} from "~/components";
import {
	userApi,
	shippingPriceApi
} from "~/api";
import {useCan} from "~/hooks";
import {
	ShippingPriceUserSearch,
	ShippingPriceUserList
} from "../components";
import _ from "lodash";

function ShippingPriceUser() {

	const {id} = useParams();

	const can = {
		setUser: useCan('shop_user_shipping_price'),
	}

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [shippingPrice, setShippingPrice] = useState({});

	const [users, setUser] = useState([]);

	const [usersOut, setUsersOut] = useState([]);

	const [usersIn, setUsersIn] = useState([]);

	const [usersRegional, setUsersRegional] = useState([]);

	const [filterUser, setFilterUser] = useState({
		keyword: ''
	});

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 50,
		totalRows:0
	});

	const handleEvent = {
		loadShippingPrice: async () => {
			let [error, response] = await handleRequest(shippingPriceApi.getById(id));
			let message = apiError(`Lấy thông tin bảng giá thất bại`, error, response);
			if(!message) {
				setShippingPrice(response.data)
			}
			else {
				setErrorLoad(true)
			}
			setLoading(false)
		},
		searchUser : async (data) => {
			const filter = {
				keyword: data?.keyword,
				...pagination
			};
			let [error, response] = await handleRequest(userApi.getsLoginAs(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {
				setUser([
					...response.data
				])
				setPagination({
					...response.pagination
				})
				setFilterUser({
					keyword: data?.keyword
				})
			}
		},
		changePagination: async (page) => {
			const filter = {
				keyword: filterUser?.keyword,
				...pagination,
				page: page
			};
			let [error, response] = await handleRequest(userApi.getsLoginAs(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {
				setUser([
					...response.data
				])
				setPagination({
					...response.pagination
				})
			}
		},
		setUserOut : (item) => {
			let objIndex = usersOut.findIndex((obj => obj.id == item.id));
			if(objIndex === -1) {
				usersOut.unshift(item);
				setUsersOut([...usersOut])
			}
		},
		setUserIn: (item) => {
			let objIndex = usersIn.findIndex((obj => obj.id == item.id));
			if(objIndex === -1) {
				usersIn.unshift(item);
				setUsersIn([...usersIn])
			}
		},
		setUserRegional: (item) => {
			let objIndex = usersRegional.findIndex((obj => obj.id == item.id));
			if(objIndex === -1) {
				usersRegional.unshift(item);
				setUsersRegional([...usersRegional])
			}
		},
		removeOut: (item) => {
			let users = usersOut.filter(function(object) {
				return object.id !== item.id
			})
			setUsersOut(users)
		},
		removeIn: (item) => {
			let users = usersIn.filter(function(object) {
				return object.id !== item.id
			})
			setUsersIn(users)
		},
		removeRegional: (item) => {
			let users = usersRegional.filter(function(object) {
				return object.id !== item.id
			})
			setUsersRegional(users)
		},
		saveOut: async () => {
			if (_.isEmpty(usersOut)) {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn thành viên nào để cập nhật bảng giá'});
				return;
			}

			let params = {
				id: shippingPrice.id,
				type: 'differentProvince',
				listUser: [],
			};

			for (const [index, item] of Object.entries(usersOut)) {
				params.listUser.push(item.id)
			}

			let [error, response] = await handleRequest(shippingPriceApi.updateUsers(params));

			let message = apiError(`Cập nhật thất bại`, error, response);
			if (!message) {
				notification.success({ message: 'Thành công', description: 'Cập nhật thành công' });
				setUsersOut([])
			}
		},
		saveIn: async () => {
			if (_.isEmpty(usersIn)) {
				notification.error({ message: 'Lỗi', description: 'Bạn chưa chọn thành viên nào để cập nhật bảng giá' });
				return;
			}

			let params = {
				id: shippingPrice.id,
				type: 'sameProvince',
				listUser: [],
			};

			for (const [index, item] of Object.entries(usersIn)) {
				params.listUser.push(item.id)
			}

			let [error, response] = await handleRequest(shippingPriceApi.updateUsers(params));

			let message = apiError(`Cập nhật thất bại`, error, response);
			if (!message) {
				notification.success({ message: 'Thành công', description: 'Cập nhật thành công' });
				setUsersIn([])
			}
		},
		saveRegional: async () => {
			if (_.isEmpty(usersRegional)) {
				notification.error({ message: 'Lỗi', description: 'Bạn chưa chọn thành viên nào để cập nhật bảng giá' });
				return;
			}

			let params = {
				id: shippingPrice.id,
				type: 'intraRegional',
				listUser: [],
			};

			for (const [index, item] of Object.entries(usersRegional)) {
				params.listUser.push(item.id)
			}

			let [error, response] = await handleRequest(shippingPriceApi.updateUsers(params));

			let message = apiError(`Cập nhật thất bại`, error, response);
			if (!message) {
				notification.success({ message: 'Thành công', description: 'Cập nhật thành công' });
				setUsersRegional([])
			}
		}
	}

	useEffect(() => {
		if(can.setUser) {
			handleEvent.loadShippingPrice().then()
		}
	}, [id]);

	if(!can.setUser) {
		return (
			<RoleError link={'/admin/shipping-price'}/>
		)
	}

	if(loading) {
		return (
			<div className={'container'}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={'container'}>
				<Empty description={<span>Không có bảng giá</span>}><Button to={'/admin/shipping-price'}>Quay lại danh sách bảng giá</Button></Empty>
			</div>
		)
	}

	return (
		<div className={'container'}>
			<Breadcrumb className={'mb-1'}
	            items={[
		            {title: 'Dashboard',},
		            {title: <Link to={'/admin/shipping-price'}>Bảng giá</Link>,},
		            {title: 'Cập nhật bảng giá',},
	            ]}
			/>
			<div className="d-flex mb-2 justify-content-between">
				<div>
					<p className="font-bold text-lg mb-2">Bảng giá {shippingPrice?.name}</p>
				</div>
			</div>
			<Row gutter={10}>
				<Col md={9} xs={24}>
					<ShippingPriceUserSearch users={users} handleEvent={handleEvent} pagination={pagination}/>
				</Col>
				<Col md={5} xs={24}>
					<section>
						<p className={'heading'}>Nội thành</p>
						<ShippingPriceUserList users={usersIn} type={'in'} onDelete={handleEvent.removeIn} onSave={handleEvent.saveIn}/>
					</section>
				</Col>
				<Col md={5} xs={24}>
					<section>
						<p className={'heading'}>Ngoại thành</p>
						<ShippingPriceUserList users={usersOut} type={'out'} onDelete={handleEvent.removeOut} onSave={handleEvent.saveOut} />
					</section>
				</Col>
				<Col md={5} xs={24}>
					<section>
						<p className={'heading'}>Nội Vùng</p>
						<ShippingPriceUserList users={usersRegional} type={'regional'} onDelete={handleEvent.removeRegional} onSave={handleEvent.saveRegional} />
					</section>
				</Col>
			</Row>
		</div>
	)
}
export default ShippingPriceUser;