import {memo} from "react";
import _ from "lodash";
import {
	InputField
} from "~/components/Forms";
import {Button, Icon, Loading} from "~/components";
import {Controller, useForm} from "react-hook-form";
import {Pagination} from "antd";

function ShippingPriceUserSearch({filter, users, handleEvent, pagination}) {

	const {control, handleSubmit, formState: { isSubmitting, errors }} = useForm({defaultValues: {keyword: ''}});

	const onSubmit = async (data) => {
		await handleEvent.searchUser(data)
	}

	return (
		<section>
			<form className="form d-flex gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<p className={'heading'}>Thành viên</p>
				<Controller control={control} name="keyword" render={({field}) => (
					<InputField placeholder={'Số điện thoại, Tên đăng nhập, mã giới thiệu'} defaultValue={filter?.keyword} errors={errors} {...field} />
				)}/>
				<Button background primary className="mb-1">{Icon.search}</Button>
			</form>
			<hr />
			{isSubmitting && <Loading noFixed/>}
			<div style={{overflow:'auto', maxHeight:'600px'}}>
			{
				(!_.isEmpty(users)) && users.map((item) => {
					return <div key={`login-as-${item.id}`} className="d-flex gap align-items justify-content-between mb-1 w-100">
						<div>
							<p><b>{item?.firstname + ' ' + item?.lastname}</b></p>
							<p className={'mb-1'}>{item?.username}</p>
						</div>
						<div>
							<Button background blue onClick={() => handleEvent.setUserIn(item)}>Nội</Button>
							<Button background green onClick={() => handleEvent.setUserOut(item)}>Ngoại</Button>
							<Button background yellow onClick={() => handleEvent.setUserRegional(item)}>Vùng</Button>
						</div>
					</div>
				})
			}
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
		</section>
	)
}

export default memo(ShippingPriceUserSearch);