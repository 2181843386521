import {memo, useState} from "react";
import {
	SelectField
} from "~/components/Forms";
import {Button} from "~/components";
import {Controller, useForm} from "react-hook-form";

function PostOfficeSearch({filter, country, onSearchChange}) {

	let cityOptions = [{
		label : 'Chọn tỉnh thành phố',
		value : ''
	}];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([{
		label : 'Chọn quận huyện',
		value : ''
	}]);

	let [wardOptions, setWardOptions] = useState([{
		label : 'Chọn phường xã',
		value : ''
	}]);

	const {control, handleSubmit, setValue, formState: { errors }} = useForm({defaultValues: {}});

	//Thay đổi tỉnh thành
	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			city: data.city,
			district: data.district,
			ward: data.ward,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	return (
		<form className="form d-flex gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} defaultValue={filter?.city} name="city" render={({ field }) => (
				<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
					handleCityChange(value);
					field.onChange(value, event);
				}}/>
			)}/>
			<Controller control={control} defaultValue={filter?.district} name="district" render={({ field }) => (
				<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
					handleDistrictChange(value);
					field.onChange(value, event);
				}}/>
			)}/>
			<Controller control={control} defaultValue={filter?.ward} name="ward" render={({ field }) => (
				<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
			)}/>
			<Button background primary className="mb-1">Áp dụng</Button>
		</form>
	)
}

export default memo(PostOfficeSearch);