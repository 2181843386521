import {Button} from "~/components";
import {Icon} from "../../../../components";
import {Tooltip} from "antd";
import {useState} from "react";

function CodTableAction({item, setItemEdit, handleEvent, can}) {

	const [loadingExport, setLoadingExport] = useState(false)
	const [loadingReload, setLoadingReload] = useState(false)

	const handleButtonClick = {
		export: async () => {
			setLoadingExport(true)
			await handleEvent.export(item);
			setLoadingExport(false)
		},
		pay: () => {
			setItemEdit(item)
			handleEvent.openModal('pay')
		},
		merge:  () => {
			setItemEdit(item)
			handleEvent.openModal('merge')
		},
		reload: async () => {
			setLoadingReload(true)
			await handleEvent.reload(item);
			setLoadingReload(false)
		},
		coin: () => {
			setItemEdit(item)
			handleEvent.openModal('coin')
		},
	}

	return (
		<>
			{can.export &&
				<Tooltip title="Xuất thành excel">
					<Button small background white onClick={handleButtonClick.export} disabled={loadingExport}>
						{ loadingExport ? Icon.loading : Icon.export}
					</Button>
				</Tooltip>
			}
			{(item.verifyStatus !== 'success-pay') &&
				<Tooltip title="Thanh toán bằng xu">
					<Button small background yellow onClick={handleButtonClick.coin}>{Icon.coin}</Button>
				</Tooltip>
			}
			{(item.verifyStatus !== 'success-pay' && can.success) &&
				<Tooltip title="Xác nhận đã thanh toán">
					<Button small background green onClick={handleButtonClick.pay}>{Icon.pay}</Button>
				</Tooltip>
			}
			{item.verifyStatus !== 'success-pay' && can.merge &&
				<Tooltip title="Gộp đơn">
					<Button small background blue onClick={handleButtonClick.merge}>{Icon.merge}</Button>
				</Tooltip>
			}
			<Tooltip title="Tính lại">
				<Button small background red onClick={handleButtonClick.reload} disabled={loadingReload}>
					{loadingReload ? Icon.loading : Icon.reload}
				</Button>
			</Tooltip>
		</>
	)
}

export default CodTableAction;