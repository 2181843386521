import {
	useState
} from "react";
import {
	Breadcrumb,
	Col,
	notification,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	userApi,
	discountApi
} from "~/api";
import {
	Loading,
	Button,
} from "~/components";
import {
	TextAreaField,
	DateField,
	GroupRadioField,
	InputField,
	InputPriceField
} from "~/components/Forms";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Link} from "react-router-dom";
import {globalNavigate} from "~/routes/GlobalHistory";
import {
	DiscountUserList,
	DiscountUserSearch
} from "../components";

function DiscountAdd() {

	const [users, setUser] = useState([]);

	const [usersChose, setUsersChose] = useState([]);

	const [filterUser, setFilterUser] = useState({
		keyword: ''
	});

	const [paginationUser, setPaginationUser] = useState({
		page: 1,
		limit: 50,
		totalRows:0
	});

	const handleEventUser = {
		searchUser : async () => {
			const filter = {
				keyword: filterUser?.keyword,
				...paginationUser
			};
			let [error, response] = await handleRequest(userApi.getsLoginAs(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {
				setUser([
					...response.data
				])
				setPaginationUser({
					...response.pagination
				})
			}
		},
		changePagination: async (page) => {
			const filter = {
				keyword: filterUser?.keyword,
				...paginationUser,
				page: page
			};
			let [error, response] = await handleRequest(userApi.getsLoginAs(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {
				setUser([
					...response.data
				])
				setPaginationUser({
					...response.pagination
				})
			}
		},
		setUserChose : (item) => {
			let objIndex = usersChose.findIndex((obj => obj.id == item.id));
			if(objIndex === -1) {
				usersChose.unshift(item);
				setUsersChose([...usersChose])
			}
		},
		removeChose: (item) => {
			let users = usersChose.filter(function(object) {
				return object.id !== item.id
			})
			setUsersChose(users)
		},
	}

	const initialValues = {
		code: '',
		infinity: 0,
		timeStart: undefined,
		timeEnd: undefined,
		type: 'coinSale',
		value: 0,
		condition:'all',
		conditionValue: 0,
		conditionUser: 'all',
		limitUse: 'none',
		limit: 0
	}

	const validationSchema = Yup.object().shape({
		code: Yup.string().required('Mã giảm giá không được để trống'),
		infinity: Yup.number().required('Mã giảm giá không được để trống'),
		timeStart: Yup.date().required('Ngày bắt đầu không được để trống'),
		timeEnd: Yup.date().nullable().when('infinity', {
			is: 0,
			then: Yup.date().required('Ngày kết thúc không được để trống'),
		}),
		type: Yup.string().required('Loại khuyến mãi không được để trống'),
		value: Yup.number().min(1).required('Số xu khuyến mãi không được để trống'),
		condition: Yup.string().required('Yêu cầu không được để trống'),
		conditionValue: Yup.number().nullable().when('condition', {
			is: 'sales',
			then: Yup.number().required('Bạn chưa điền doanh số tối thiểu'),
		}),
		conditionUser: Yup.string().required('Đối tượng không được để trống'),
		limitUse: Yup.string().required('Loại Giới hạn không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleSave = async (data) => {
		data.listUser = [];

		for (const [index, item] of Object.entries(usersChose)) {
			data.listUser.push(item.id)
		}
		let [error, response] = await handleRequest(discountApi.add(data));
		let message = apiError(`Thêm mã khuyến mãi thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Thêm mã khuyến mãi thành công`});
			//globalNavigate('/discount/detail/' + response.data.id);
			globalNavigate('/discount/runnung');
		}
	}

	return (
		<div className="container">
			<form className="form" onSubmit={handleSubmit(handleSave)}>
				{isSubmitting && <Loading/>}
				<Breadcrumb className={'mb-1'} items={[
		            {title: 'Dashboard',},
		            {title: <Link to={'/discount/running'}>Khuyến mãi</Link>,},
		            {title: 'Tạo mã khuyến mãi',},
	            ]}/>
				<Row gutter={10}>
					<Col className="gutter-row" md={16} xs={24}>
						<section>
							<p className={'heading'}>Mã giảm giá</p>
							<Controller control={control} name="code" render={({field}) => (
								<InputField label="Mã giảm giá" errors={errors} {...field}/>
							)}/>
							<Controller control={control} name="excerpt" render={({ field }) => (
								<TextAreaField label="Mô tả ngắn" errors={errors} {...field}/>
							)}/>
						</section>

						<section>
							<p className={'heading'}>Thời gian áp dụng</p>
							<Controller control={control} name="infinity" render={({ field }) => (
								<GroupRadioField
									options={[
										{value:0, label:'Có thời gian kết thúc'},
										{value:1, label:'Không bao giờ kết thúc'},
									]}
									errors={errors} {...field}
								/>
							)}/>
							<Row gutter={10}>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="timeStart" render={({field}) => (
										<DateField label="Thời gian bắt đầu" errors={errors} {...field}/>
									)}/>
								</Col>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="timeEnd" render={({ field }) => (
										<DateField label="Thời gian kết thúc" errors={errors} {...field}/>
									)}/>
								</Col>
							</Row>
						</section>

						<section>
							<p className={'heading'}>Loại khuyến mãi</p>
							<Controller control={control} name="type" render={({ field }) => (
								<GroupRadioField
									options={[
										{value:'coin', label:'Cộng vào xu vĩnh viển'},
										{value:'coinSale', label:'Cộng vào xu khuyến mãi'},
									]}
									errors={errors} {...field}
								/>
							)}/>
							<Controller control={control} name="value" render={({field}) => (
								<InputPriceField label="Số Xu" errors={errors} {...field}/>
							)}/>
						</section>

						<section>
							<p className={'heading'}>Yêu cầu</p>
							<Controller control={control} name="condition" render={({ field }) => (
								<GroupRadioField
									options={[
										{value:'all', label:'Không yêu cầu'},
										{value:'sales', label:'Đạt doanh số tối thiểu'},
										{value:'quantity', label:'Đạt số đơn tối thiểu'},
									]}
									errors={errors} {...field}
								/>
							)}/>
							<Controller control={control} name="conditionValue" render={({field}) => (
								<InputPriceField label="Doanh số tối thiểu" errors={errors} {...field}/>
							)}/>
						</section>

						<section>
							<p className={'heading'}>Đối tượng áp dụng</p>
							<div className="group-radio-block">
							<Controller control={control} name="conditionUser" render={({ field }) => (
								<GroupRadioField
									options={[
										{value:'all', label:'Không yêu cầu'},
										{value:'isConfirm', label:'Thành viên đã xác thực'},
										{value:'points', label:'Tùy chọn thành viên'},
									]}
									errors={errors} {...field}
								/>
							)}/>
							</div>
						</section>

						<section>
							<p className={'heading'}>Giới hạn sử dụng</p>
							<div className="group-radio-block">
								<Controller control={control} name="limitUse" render={({ field }) => (
									<GroupRadioField
										options={[
											{value:'none', label:'Không giới hạn'},
											{value:'all', label:'Giới hạn tổng số lần có thể sử dụng khuyến mãi'},
											{value:'user', label:'Giới hạn số lần sử dụng cho mỗi thành viên'},
										]}
										errors={errors} {...field}
									/>
								)}/>
							</div>
							<Controller control={control} name="limit" render={({field}) => (
								<InputPriceField label="Số lần giới hạn" errors={errors} {...field}/>
							)}/>
						</section>
					</Col>
					<Col className="gutter-row" md={8} xs={24}>
						<section>
							<DiscountUserSearch users={users} filter={filterUser} setFilter={setFilterUser} handleEvent={handleEventUser} pagination={paginationUser}/>
							<p className={'heading'}>Đã chọn</p>
							<DiscountUserList users={usersChose} onDelete={handleEventUser.removeChose}/>

							<div className="text-center mt-2">
								<Button type="submit" background primary className="w-100">Tạo Mã Giảm Giá</Button>
							</div>
						</section>
					</Col>
				</Row>

			</form>
		</div>
	)
}
export default DiscountAdd;