import _ from "lodash"
import {
	orderActions,
	orderErrorSelector,
	orderFilterSelector,
	orderItemsSelector,
	orderLoadingSelector,
	orderPaginationSelector
} from "../orderSlice";
import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useEffect, useRef,
	useState
} from "react";
import {
	useParams
} from "react-router-dom";
import {
	notification,
	Tabs
} from "antd";
import {
	Loading,
	Modal,
	Button,
	Icon,
	SearchMobile
} from "~/components";
import {
	useCan,
	useCurrentUser
} from "~/hooks";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	OrderFormExport,
	OrderFormStatus,
	OrderSearch,
	OrderTable,
	OrderAction,
	OrderFormPrint,
	OrderPrintTemplate,
	OrderFormTicket,
	OrderFormSync, OrderFormCoin
} from "../components";

import {
	globalNavigate
} from "~/routes/GlobalHistory";
import {
	DATA_LIMIT_KEY,
	ORDER_RETURN
} from "~/app/constants";
import {
	orderApi,
	ticketApi,
	coinApi
} from "~/api";
import {useReactToPrint} from "react-to-print";

function Order() {

	const {status} = useParams();

	const currentUser = useCurrentUser();

	const can = {
		isAdmin: useCan('shop_order_all'),
		add: useCan('shop_order_add'),
		edit: useCan('shop_order_edit'),
		status: useCan('shop_order_status'),
		cancel: useCan('shop_order_cancel'),
		import: useCan('shop_order_import'),
		export: useCan('shop_order_excel'),
		uploadsCod: useCan('shop_orderEdit_cod'),
		uploadsWeight: useCan('shop_orderEdit_weight'),
		uploadsFee: useCan('shop_orderEdit_shipping'),
		uploadsStatus: useCan('shop_orderEdit_status'),
	}

	const printRef = useRef(null);

	const dispatch = useDispatch();

	const items = useSelector(orderItemsSelector);

	const loading = useSelector(orderLoadingSelector);

	const error = useSelector(orderErrorSelector);

	const pagination = useSelector(orderPaginationSelector);

	const filter = useSelector(orderFilterSelector);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [fileDownload, setFileDownload] = useState(false);

	const [printHidden, setPrintHidden] = useState({
		form : [],
		to : [],
		order : [],
	});

	const [printActive, setPrintActive] = useState(false);

	const [printItems, setPrintItems] = useState([]);

	const [searchBar, setSearchBar] = useState(false)

	//Modal show
	const [openModal, setOpenModal] = useState({
		status : false,
		ticket : false,
		export : false,
		print : false,
		cancels : false,
		sync : false,
		paymentCoin : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		if(status != filter.status) {
			let newFilter = {...filter};
			newFilter.status = status;
			newFilter.page = 1;
			dispatch(orderActions.setFilter(newFilter));
		}
	}, [status]);

	useEffect(() => {
		dispatch(orderActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleTicketAdd = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng để khiếu nại'});
			return;
		}
		let params = {
			type: data.type,
			objectId: item.id,
			change: {
				cod : 0,
				toName: "",
				toPhone: "",
				toAddress: "",
				toCity: "",
				toDistrict: "",
				toWard: "",
				complain: []
			},
			message: data.message,
		}
		if(data.type == 'cod') params.change.cod = data.cod;
		if(data.type == 'changeReceive') {
			params.change.toName = data.toName;
			params.change.toPhone = data.toPhone;
			params.change.toAddress = data.toAddress;
			params.change.toCity = data.toCity;
			params.change.toDistrict = data.toDistrict;
			params.change.toWard = data.toWard;
		}
		if(data.type == 'complain') params.change.complain = data.complain;

		let [error, response] = await handleRequest(ticketApi.add(params));
		let message = apiError(`Xuất đơn hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm khiếu nại thành công`});
			handleModalClose('ticket')
		}
	}

	const handleStatus = async (status, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(orderApi.status({
			id: item.id,
			status: status
		}));
		let message = apiError(`Cập nhật trạng thái đơn hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái đơn hàng thành công`});
			item.status         = response.data.statusNew;
			if(status == ORDER_RETURN) {
				item.cod = 0;
				item.total = 0;
				item.returnFee = response.data.returnFee;
			}
			dispatch(orderActions.update(item));
			handleModalClose('status')
		}
	}

	const handleExport = async (type) => {
		if (type == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Bạn chưa chọn kiểu xuất'});
			return;
		}

		let params = {
			type: type,
			listData: [],
			filter: []
		};


		if(type == 'pageCurrent') {
			if(_.isEmpty(items)) {
				notification.error({message: 'Lỗi', description: 'Trang hiện không có đơn hàng nào'});
				return;
			}
			for (const [index, item] of Object.entries(items)) {
				params.listData.push(item.id)
			}
		}

		if(type == 'checked') {
			if(_.isEmpty(selectedRowKeys)) {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
				return;
			}
			params.listData = selectedRowKeys;
		}

		if(type == 'search') {
			params.filter = filter;
		}

		let [error, response] = await handleRequest(orderApi.export(params));
		let message = apiError(`Xuất đơn hàng thất bại`, error, response);
		if(!message) {
			setFileDownload(response.data.file)
		}
	}

	const handleSync = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(orderApi.sync(item.id));
		let message = apiError(`Đồng bộ đơn hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Đồng bộ đơn hàng thành công`});
			item.trackingNumber         = response.data.trackingNumber;
			dispatch(orderActions.update(item));
			handleModalClose('sync')
		}
	}

	const handleUpdateTracking = async (item, data) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để cập nhật'});
			return;
		}
		data.id = item.id
		let [error, response] = await handleRequest(orderApi.setTracking(data));
		let message = apiError(`Đồng bộ đơn hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Đồng bộ đơn hàng thành công`});
			item.trackingNumber         = response.data.trackingNumber;
			dispatch(orderActions.update(item));
			handleModalClose('sync')
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	const handlePrintProcess = async (data) => {
		if(_.isEmpty(selectedRowKeys)) {
			notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
			return;
		}
		setPrintItems(items.filter(item => selectedRowKeys.includes(item.id)));
		setPrintHidden(data)
		setPrintActive(true)
	}

	const handleCancels = async () => {
		if(_.isEmpty(selectedRowKeys)) {
			notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
			return;
		}
		let [error, response] = await handleRequest(orderApi.cancels({
			listData: selectedRowKeys
		}));
		let message = apiError(`Hủy đơn hàng thất bại`, error, response);
		if(!message) {
			Object.entries(selectedRowKeys).map(([index, id], i) => {
				dispatch(orderActions.delete(id))
			})
			handleModalClose('cancels');
		}
	}

	const handlePaymentCoin = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(coinApi.paymentOrder({id: item.id}));
		let message = apiError(`Thanh toán thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thanh toán thành công`});
			item.paymentCoin = item.shipping + item.returnFee;
			dispatch(orderActions.update(item));
			handleModalClose('paymentCoin')
		}
	}

	const handlePaginationChange = (page) => {
		dispatch(orderActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(orderActions.setFilterWithDebounce(newFilter));
		setSearchBar(false);
	};

	const handleLimitChange = (value) => {
		localStorage.setItem(DATA_LIMIT_KEY, value)
		dispatch(orderActions.setFilter({
			...filter,
			limit: value,
			page: 1
		}));
	};

	const itemsTab = [
		{
			key: '',
			label: 'Tất cả',
		},
		{
			key: 'pending',
			label: 'Chờ lấy hàng',
		},
		{
			key: 'pickup-fail',
			label: 'Lấy hàng thất bại',
		},
		{
			key: 'confirm',
			label: 'Đã lấy hàng',
		},
		{
			key: 'shipping',
			label: 'Đang vận chuyển',
		},
		{
			key: 'waiting-return',
			label: 'Chờ giao lại',
		},
		{
			key: 'return-trigger',
			label: 'Đợi hoàn',
		},
		{
			key: 'return',
			label: 'Đơn hoàn',
		},
		{
			key: 'completed',
			label: 'Hoàn thành',
		},
		{
			key: 'lost',
			label: 'Thất lạc - hư hỏng',
		},
		{
			key: 'cancelled',
			label: 'Đã hủy',
		},
	];

	const handleTabChange = (key) => {
		globalNavigate(`/order/${key}`);
	};

	useEffect(() => {
		if(printActive) {
			handlePrint();
			setPrintActive(false);
		}
	}, [printActive]);

	return (
		<div className={'container'}>
			<section>
				<div className="d-flex justify-content-start gap-1">
					<div>
						<SearchMobile searchBar={searchBar} setSearchBar={setSearchBar}>
							<OrderSearch
								filter={filter}
								onSearchChange={handleSearchChange}
							/>
						</SearchMobile>
					</div>
					<OrderAction
						can={can}
						openModal={handleModalOpen}
						fileDownload={fileDownload}
						setFileDownload={setFileDownload}
						selectedRowKeys={selectedRowKeys}
					/>
				</div>
				{loading && <Loading noFixed />}
				<Tabs
					defaultActiveKey={status}
					items={itemsTab}
					onChange={handleTabChange}
				/>
				{items && <OrderTable
					items={items}
					onPaginationChange={handlePaginationChange}
					onLimitChange={handleLimitChange}
					setItemEdit={setItemEdit}
					openModal={handleModalOpen}
					pagination={pagination}
					can={can}
					currentUser={currentUser}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
				/>}
			</section>
			{
				(itemEdit?.id && can.status && openModal.status) &&
				<Modal title="Trạng thái đơn hàng" visible={openModal.status} onCancel={() => {handleModalClose('status')}}>
					<OrderFormStatus item={itemEdit} onHandleSubmit={handleStatus} />
				</Modal>
			}
			{
				(can.export && openModal.export) &&
				<Modal title="Xuất đơn hàng" visible={openModal.export} onCancel={() => {handleModalClose('export')}}>
					<OrderFormExport fileDownload={fileDownload} onHandleSubmit={handleExport} />
				</Modal>
			}
			{
				(openModal.print) &&
				<Modal title="In đơn hàng" visible={openModal.print} onCancel={() => {handleModalClose('print')}}>
					<OrderFormPrint printRef={printRef} onHandleSubmit={handlePrintProcess} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.ticket) &&
				<Modal title={"Gửi phản hồi đơn hàng "+itemEdit.trackingNumber} visible={openModal.ticket} onCancel={() => {handleModalClose('ticket')}}>
					<OrderFormTicket item={itemEdit} onHandleSubmit={handleTicketAdd} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.sync) &&
				<Modal title={"Đồng bộ đơn hàng"} visible={openModal.sync} onCancel={() => {handleModalClose('sync')}}>
					<OrderFormSync item={itemEdit} onSync={handleSync} onUpTracking={handleUpdateTracking} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.paymentCoin) &&
				<Modal title={"Thanh toán bằng xu"} visible={openModal.paymentCoin} onCancel={() => {handleModalClose('paymentCoin')}}>
					<OrderFormCoin item={itemEdit} onHandleSubmit={handlePaymentCoin} />
				</Modal>
			}
			{
				(openModal.cancels) &&
				<Modal title="Hủy đơn hàng" visible={openModal.cancels} onCancel={() => {handleModalClose('cancels')}}>
					<p>Bạn muốn hủy tất cả đơn hàng đã chọn?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('cancels')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleCancels}> Hủy </Button>
					</div>
				</Modal>
			}
			{printItems && <OrderPrintTemplate key="print" items={printItems} printHidden={printHidden} ref={printRef} />}
		</div>
	)
}

export default Order;