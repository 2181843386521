import style from './NavBar.module.scss';
import className from 'classnames/bind';
import {memo, useContext, useEffect, useState} from "react";
import {NavBarData} from "./NavBarData";
import {Button, Icon, Image} from "~/components";
import { Menu } from 'antd'
import Sider from "antd/es/layout/Sider";
import {useCan} from "~/hooks";
import {useLocation} from "react-router-dom";
import {AppContext} from "../../context/AppProvider";
import {useSelector} from "react-redux";
import {currentUserSelector} from "../../features/Auth/authSlice";
import {numberFormat} from "../../utils";
import {FontAwesomeIcon} from "../../components";
const cn = className.bind(style);

function NavBar() {
	const currentUser = useSelector(currentUserSelector);
	const {isTablet} = useContext(AppContext);
	const location = useLocation();
	const pathname = location.pathname;
	const moduleMappings = [
		{ path: '/order', moduleGroup: 'orderGroup', module: 'order' },
		{ path: '/order/import', moduleGroup: 'orderGroup', module: 'import' },
		{ path: '/order/import-history', moduleGroup: 'orderGroup', module: 'importHistory' },
		{ path: '/order/history', moduleGroup: 'orderGroup', module: 'orderHistory' },
		{ path: '/branch', moduleGroup: 'orderGroup', module: 'branch' },
		{ path: '/cod', moduleGroup: 'cod', module: 'cod' },
		{ path: '/customer', moduleGroup: 'customer', module: 'customer' },
		{ path: '/post-office', moduleGroup: 'post-office', module: 'postOffice' },
		{ path: '/ticket', moduleGroup: 'ticket', module: 'ticket' },
		{ path: '/admin/shipping-price', moduleGroup: 'adminGroup', module: 'shippingPrice' },
		{ path: '/users', moduleGroup: 'adminGroup', module: 'users' },
		{ path: '/users/verify', moduleGroup: 'adminGroup', module: 'usersVerify' },
		{ path: '/admin/roles', moduleGroup: 'adminGroup', module: 'roles' },
		{ path: '/admin/discount', moduleGroup: 'adminGroup', module: 'discount' },
		{ path: '/report/affiliate', moduleGroup: 'reportGroup', module: 'affiliate' },
		{ path: '/report/users', moduleGroup: 'reportGroup', module: 'reportUser' },
		{ path: '/report/ops', moduleGroup: 'reportGroup', module: 'reportOps' },
		{ path: '/report/cash-flow', moduleGroup: 'reportGroup', module: 'reportMoney' },
	];

	let moduleActive = 'home';
	let moduleGroupActive = 'home';

	moduleMappings.forEach((mapping) => {
		if (pathname.includes(mapping.path)) {
			moduleActive = mapping.module;
			moduleGroupActive = mapping.moduleGroup;
		}
	});

	const navBarList = NavBarData();

	const [collapsed, setCollapsed] = useState(!!isTablet);

	useEffect(() => {
		setCollapsed(!!isTablet)
	}, [isTablet]);

	return (
		<div className={cn('sidebar')}>
			<Sider trigger={null} collapsible={collapsed} collapsed={collapsed} width={'100%'}>
				{
					(!collapsed) && <>
						{
							(currentUser.isVerify === 'unverified') ?
								<div className={cn('account-verify', 'pd-1')}>
									<div className={cn('account-verify-inner', 'text-center', 'pd-2')}>
										<p className={'color-blue font-bold mb-2'}>{currentUser.firstname + ' ' + currentUser.lastname}</p>
										<p className={''}>Số dư</p>
										<p className={'mb-1 color-red font-bold'}><FontAwesomeIcon
											icon="fa-duotone fa-coins"/> {numberFormat(currentUser.coin + currentUser.coinSale)} xu
										</p>
										<p className={'font-bold'}>Xác thực tài khoản</p>
										<p className={'mb-1'}>Tận hưởng các quyền lợi đặc biệt nhé shop ơi!</p>
										<Button green background to={'/account/info'}
										        className={'w-100 text-sm'}>{'Xác thực ngay'}</Button>
									</div>
								</div>
								:
								<div className={cn('account-verify', 'pd-1')}>
									<div className={cn('account-verify-inner', 'text-center', 'pd-2')}>
										<p className={'color-blue font-bold mb-2'}>{currentUser.firstname + ' ' + currentUser.lastname}</p>
										<p className={''}>Số dư</p>
										<p className={'mb-1 color-red font-bold'}><FontAwesomeIcon
											icon="fa-duotone fa-coins"/> {numberFormat(currentUser.coin + currentUser.coinSale)} xu
										</p>
									</div>
								</div>
						}
					</>

				}
				{
					(useCan('shop_order_add') && currentUser.isBlockOrder === 0) &&
					<div className={cn('button-action')}>
						<Button leftIcon={Icon.plusCircle} primary to={'/order/add'} className={'w-100 text-sm'}>{
							!collapsed && 'Thêm đơn hàng'}</Button>
					</div>
				}
				<Menu
					defaultSelectedKeys={[moduleActive]}
					defaultOpenKeys={[moduleGroupActive]}
					mode="inline" items={navBarList}/>
			</Sider>
		</div>
	);
}

export default memo(NavBar)