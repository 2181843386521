import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {codActions} from "./codSlice";
import {codApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(codApi.gets, action.payload);
		yield put(codActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch order list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(codActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(codActions.setFilter(action.payload));
}

export default function* codSaga() {
	yield takeLatest(codActions.fetchData.type, fetchDemoList);
	yield debounce(500, codActions.setFilterWithDebounce.type, handleSearchDebounce)
}