import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Row } from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	InputField,
	SelectField
} from "~/components/Forms";

function OrderFormAddress({country, item, onHandleSubmit}) {

	let cityOptions = [];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const initialValues = {
		toAddress: undefined,
		toCity: undefined,
		toDistrict: undefined,
		toWard: undefined,
	}

	const validationSchema = Yup.object().shape({
		toAddress: Yup.string().required('Địa chỉ không được để trống'),
		toCity: Yup.string().required('Tỉnh thành không được để trống'),
		toDistrict: Yup.string().required('Quận huyện không được để trống'),
		toWard: Yup.string().required('Phường xã không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	//Thay đổi tỉnh thành
	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('toDistrict', '');
		setValue('toWard', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('toWard', '');
		setWardOptions(wardOptions);
	}

	useEffect(() => {
		if(item?.id) {

			initialValues.toAddress  = item.toAddress;
			initialValues.toCity      = item.toCity;
			initialValues.toDistrict  = item.toDistrict;
			initialValues.toWard      = item.toWard;

			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == item.toCity) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == item.toDistrict) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col className="gutter-row" span={24}>
					<Controller control={control} name="toAddress" render={({ field }) => (
						<InputField label="Địa chỉ" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="toCity" render={({ field }) => (
						<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
							handleCityChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="toDistrict" render={({ field }) => (
						<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
							handleDistrictChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="toWard" render={({ field }) => (
						<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default OrderFormAddress;