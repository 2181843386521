import {
	useEffect, useRef,
	useState
} from "react";
import {
	Col,
	notification, Pagination,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	orderApi,
} from "~/api";
import {
	Loading,
	Modal,
	Icon,
	Button
} from "~/components";
import {
	OrderTableImportHistory,
	OrderPrintTemplate,
	OrderFormExport,
	ImportHistoryList, OrderFormPrint
} from "../components";
import _ from "lodash";
import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	importHistoryActions,
	importHistoryItemsSelector,
	importHistoryLoadingSelector,
	importHistoryErrorSelector,
	importHistoryPaginationSelector,
	importHistoryFilterSelector
} from "../importHistorySlice";
import {
	importHistoryOrderActions,
	importHistoryOrderItemsSelector,
	importHistoryOrderLoadingSelector,
	importHistoryOrderErrorSelector,
	importHistoryOrderPaginationSelector,
	importHistoryOrderFilterSelector
} from "../importHistoryOrderSlice";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {useReactToPrint} from "react-to-print";
import {useCan} from "~/hooks";

function OrderImportHistory() {

	const can = {
		export: useCan('shop_order_excel'),
	}

	const dispatch = useDispatch();

	const printRef = useRef(null);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [fileDownload, setFileDownload] = useState(false);

	const [printHidden, setPrintHidden] = useState({
		form : [],
		to : [],
		order : [],
	});

	const [printActive, setPrintActive] = useState(false);

	const [printItems, setPrintItems] = useState([]);

	const items = useSelector(importHistoryItemsSelector);
	const itemsOrder = useSelector(importHistoryOrderItemsSelector);

	const loading = useSelector(importHistoryLoadingSelector);
	const loadingOrder = useSelector(importHistoryOrderLoadingSelector);

	const error = useSelector(importHistoryErrorSelector);
	const errorOrder = useSelector(importHistoryOrderErrorSelector);

	const pagination = useSelector(importHistoryPaginationSelector);
	const paginationOrder = useSelector(importHistoryOrderPaginationSelector);

	const filter = useSelector(importHistoryFilterSelector);
	const filterOrder = useSelector(importHistoryOrderFilterSelector);

	//Modal show
	const [openModal, setOpenModal] = useState({
		export : false,
		print : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	const handleImportHistoryEvent = {
		onChangePagination: (page) => {
			dispatch(importHistoryActions.fetchData({...filter, page}));
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	const handleImportHistoryOrderEvent = {
		onLoad: (item) => {
			setItemEdit(item);
			dispatch(importHistoryOrderActions.fetchData({...filterOrder, excelId: item.id, page: 1}));
		},
		onChangePagination: (page) => {
			dispatch(importHistoryOrderActions.fetchData({...filterOrder, excelId: itemEdit.id, page}));
		},
		onChangeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			let filterOrderNew = {
				...filterOrder,
				excelId: itemEdit.id,
				limit: value,
				page: 1
			}
			dispatch(importHistoryOrderActions.setFilter(filterOrderNew));
			dispatch(importHistoryOrderActions.fetchData(filterOrderNew))
		},
		export: async (type) => {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn file đã up'});
				return;
			}
			if (type == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn kiểu xuất'});
				return;
			}

			let params = {
				type: type,
				listData: [],
				filter: []
			};

			if(type == 'pageCurrent') {
				if(_.isEmpty(itemsOrder)) {
					notification.error({message: 'Lỗi', description: 'Trang hiện không có đơn hàng nào'});
					return;
				}
				for (const [index, item] of Object.entries(itemsOrder)) {
					params.listData.push(item.id)
				}
			}

			if(type == 'checked') {
				if(_.isEmpty(selectedRowKeys)) {
					notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
					return;
				}
				params.listData = selectedRowKeys;
			}

			if(type == 'search') {
				params.filter = {
					excelId : itemEdit.id
				};
			}

			let [error, response] = await handleRequest(orderApi.export(params));
			let message = apiError(`Xuất đơn hàng thất bại`, error, response);
			if(!message) {
				setFileDownload(response.data.file)
			}
		},
		printProcess: async (data) => {
			if(_.isEmpty(selectedRowKeys)) {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
				return;
			}
			setPrintItems(itemsOrder.filter(item => selectedRowKeys.includes(item.id)));
			setPrintHidden(data)
			setPrintActive(true)
		}
	}

	//Load data
	useEffect(() => {
		dispatch(importHistoryActions.fetchData(filter));
	}, [filter]);

	useEffect(() => {
		if(printActive) {
			handlePrint();
			setPrintActive(false);
		}
	}, [printActive]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}
	if (errorOrder) {
		notification.error({message: 'Lỗi', description: errorOrder});
	}

	const handleButtonClick = {
		export: () => {
			if(fileDownload !== false) {
				setFileDownload(false)
			}
			handleModalOpen('export');
		},
		print: () => {
			handleModalOpen('print');
		},
	}

	return (
		<div className='container'>
			<Row gutter={10}>
				<Col xs={24} md={5}>
					<section>
						{loading&& <Loading noFixed />}
						<h2 className={'heading'}>File đã up</h2>
						<div>
							{items && <ImportHistoryList items={items} onClickItem={handleImportHistoryOrderEvent.onLoad} />}
						</div>
						<Pagination
							current={pagination.page}
							defaultCurrent={1}
							defaultPageSize={20}
							pageSize={pagination.limit}
							total={pagination.totalRows}
							onChange={handleImportHistoryEvent.onChangePagination}
							showSizeChanger={false}
						/>
					</section>
				</Col>
				<Col xs={24} md={19}>
					<section>
						{loadingOrder && <Loading noFixed />}
						<div className="d-flex mb-1">
							{can.export && <Button leftIcon={Icon.export} type="button" background white onClick={handleButtonClick.export}>Xuất Excel</Button>}
							{!_.isEmpty(selectedRowKeys) && <Button leftIcon={Icon.print} type="button" onClick={handleButtonClick.print} background white>In đơn</Button>}
						</div>
						{items && <OrderTableImportHistory
							items={itemsOrder}
							onPaginationChange={handleImportHistoryOrderEvent.onChangePagination}
							onLimitChange={handleImportHistoryOrderEvent.onChangeLimit}
							pagination={paginationOrder}
							selectedRowKeys={selectedRowKeys}
							setSelectedRowKeys={setSelectedRowKeys}
						/>}
					</section>
				</Col>
			</Row>
			{
				(openModal.print) &&
				<Modal title="In đơn hàng" visible={openModal.print} onCancel={() => {handleModalClose('print')}}>
					<OrderFormPrint printRef={printRef} onHandleSubmit={handleImportHistoryOrderEvent.printProcess} />
				</Modal>
			}
			{
				(can.export && openModal.export) &&
				<Modal title="Xuất đơn hàng" visible={openModal.export} onCancel={() => {handleModalClose('export')}}>
					<OrderFormExport fileDownload={fileDownload} onHandleSubmit={handleImportHistoryOrderEvent.export} />
				</Modal>
			}
			{printItems && <OrderPrintTemplate key="print" items={printItems} printHidden={printHidden} ref={printRef} />}
		</div>
	)
}
export default OrderImportHistory;