import {
	useContext
} from "react";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	LIST_USER_ROLE,
} from "~/app/constants";
import {StatusLabel, UserVerify, FontAwesomeIcon} from "~/components";
import {Link} from "react-router-dom";
import {UserTableAction} from "../index";
import UserBlockOrderTableAction from "./UserBlockOrderTableAction";

function UserBlockOrderTable({items = [], pagination, handleEvent, can}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Tên', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold"><Link to={'/users/edit/'+item?.id}>{item?.username}</Link></p>
				<p className="font-bold"><Link to={'/users/edit/'+item?.id}>{item?.firstname + ' ' + item?.lastname}</Link></p>
				{
					item?.referralCode && <StatusLabel small type="blue" border>{item?.referralCode}</StatusLabel>
				}
				<p className="color-grey text-xs mt-1 mb-1">{renderDate(item?.created)}</p>
				<UserVerify user={item} />
			</>
		)},
		{ title: 'Thông tin', dataIndex: 'contact', key: "contact", width: '100px', render: (_, item) => (
				<>
					<p><Link to={'/users/edit/' + item?.id}>{item?.email}</Link></p>
					<p><Link to={'/users/edit/' + item?.id}>{item?.phone}</Link></p>
				</>
			)
		},
		{ title: 'Xu', dataIndex: 'coin', key: "coin", width: '130px', render: (_, item) => (
				<>
					<p className={'color-yellow'}><FontAwesomeIcon icon="fa-duotone fa-circle-dollar" /> Xu: {numberFormat(item?.coin)}</p>
					<p className={'color-grey'}><FontAwesomeIcon icon="fa-duotone fa-circle-dollar" /> Xu thường: {numberFormat(item?.coinSale)}</p>
					<p className={'color-red'}>Tổng: {numberFormat(item?.coin + item?.coinSale)}</p>
				</>
			)
		},
		{
		title: 'Chức vụ', dataIndex: 'role', key: "role", width: '120px', render: (_, item) => (
			<>
				<p className="font-bold">{LIST_USER_ROLE[item?.role]?.title}</p>
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '100px', render: (_, item) => (
			<UserBlockOrderTableAction item={item} handleEvent={handleEvent} can={can} />
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
			</div>
		</div>
	)
}

export default UserBlockOrderTable;