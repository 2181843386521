import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Loading,
	Button
} from "~/components";
import {InputField} from "~/components/Forms";
function UserFormPass({item, onHandleSubmit}) {

	const { control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: {
			passNew: '',
		},
		resolver: yupResolver(Yup.object().shape({
			passNew: Yup.string().required('Bạn chưa điền mật khẩu mới'),
		}))
	});

	const handleChangePassword = async (data) => {
		await onHandleSubmit(data, item)
	}

	return (
		<form className="form" onSubmit={handleSubmit(handleChangePassword)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="passNew" render={({ field }) => (
				<InputField label="Mật khẩu mới" type={'password'} errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Đổi mật khẩu</Button>
			</div>
		</form>
	)
}

export default UserFormPass;