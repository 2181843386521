import {Tabs} from "antd";
import {globalNavigate} from "~/routes/GlobalHistory";

function HomeTabs({active}) {

	const itemsTab = [
		{
			key: '',
			label: 'Thống kê',
		},
		{
			key: 'cash-flow',
			label: 'Dòng tiền',
		},
	];

	const handleTabChange = (key) => {
		globalNavigate(`${key}`);
	};

	return (
		<Tabs
			defaultActiveKey={active}
			items={itemsTab}
			onChange={handleTabChange}
		/>
	)
}
export default HomeTabs;