import {createSlice} from "@reduxjs/toolkit";
import {DATA_LIMIT} from "../../app/constants";

const importHistory = createSlice({
	name: "importHistory",
	initialState: {
		loading: false,
		error: null,
		filter:{
			page : 1,
			limit : 10,
		},
		items: [],
		pagination : {
			page : 1,
			limit : 10,
			totalRows: 0
		}
	},
	reducers: {
		fetchData(state) {
			state.loading = true;
			state.error = null;
			return state;
		},
		fetchDataSuccess(state, action) {
			state.loading = false;
			state.items = action.payload.data;
			state.pagination = action.payload.pagination;
			state.error = null;
			return state;
		},
		fetchDataFailed(state, action) {
			state.loading = false;
			state.error = action.payload;
			return state;
		},
		add(state, action) {
			state.items.unshift(action.payload);
			return state;
		},
		update(state, action) {
			let objIndex = state.items.findIndex((item => item.id == action.payload.id));
			state.items[objIndex] = {...state.items[objIndex], ...action.payload};
			return state;
		},
		delete(state, action) {
			state.items = state.items.filter(function(item) {
				return item.id !== action.payload
			})
		},
		//Action search
		setFilter(state, action) {
			state.filter = action.payload;
			return state;
		},
		setFilterWithDebounce(state, action) {}
	}
})
//Action
export const importHistoryActions = importHistory.actions;
//Reducers
export const importHistoryReducer = importHistory.reducer;
//Selectors
export const importHistoryItemsSelector = (state) => state.importHistory.items;
export const importHistoryLoadingSelector = (state) => state.importHistory.loading;
export const importHistoryErrorSelector = (state) => state.importHistory.error;
export const importHistoryFilterSelector = (state) => state.importHistory.filter;
export const importHistoryPaginationSelector = (state) => state.importHistory.pagination;