import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {useContext, useEffect, useState} from "react";
import {
	Breadcrumb,
	Col,
	notification,
	Row
} from "antd";
import {
	apiError,
	handleRequest,
	renderAddress,
	numberFormat,
} from "~/utils";
import {
	branchApi,
	customerApi,
	orderApi
} from "~/api";
import _ from "lodash"
import {AppContext} from "~/context/AppProvider";
import {
	Button,
	Icon,
	Image,
	Loading,
	Modal,
	StatusLabel,
	RoleError
} from "~/components";
import {BranchItem} from "../components";
import {BranchFormAddEdit} from "../../Branch/components";
import * as Yup from "yup";
import {
	Controller,
	useFieldArray,
	useForm
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
	GroupRadioButton,
	InputField,
	InputPriceField,
	SelectField,
	TextAreaField
} from "~/components/Forms";
import {v4 as uuidv4} from 'uuid';
import {globalNavigate} from "~/routes/GlobalHistory";
import {useCan} from "~/hooks";
import {Link} from "react-router-dom";
import {useCurrentUser} from "../../../hooks";
const cn = className.bind(style);
function OrderAdd() {

	const can = {
		add: useCan('shop_order_add')
	}

	const userCurrent = useCurrentUser();

	const {country, shippingSupport, shippingCompanies, currentUser} = useContext(AppContext);

	const [branchLoading, setBranchLoading] = useState(true);

	const [branchMain, setBranchMain] = useState({});

	const [branchList, setBranchList] = useState({});

	const [fee, setFee] = useState(0);

	const [percent, setPercent] = useState(undefined);

	let shippingUnitOptions = [];

	const ShippingCart = ({item}) => {
		return (
			<div className={cn('card')}>
				<Image src={item.logo} />
			</div>
		)
	}

	if(Object.keys(shippingSupport).length !== 0 && Object.keys(shippingCompanies).length) {
		for (const [key, shippingKey] of Object.entries(shippingSupport)) {
			shippingUnitOptions.push({
				value : shippingKey,
				label : <ShippingCart key={shippingKey} item={shippingCompanies[shippingKey]} />
			})
		}
	}

	const ToPhoneLabel = ({percent}) => {
		return (
			<>
				<span className="d-inline-block mr-1">Số điện thoại</span>
				{percent !== undefined && (
					<>
						{percent <= 3 && <StatusLabel small background type="green">An toàn</StatusLabel>}
						{percent > 3 && percent <= 10 && <StatusLabel small background type="yellow">Cẩn thận</StatusLabel>}
						{percent > 10 && <StatusLabel small background type="red">Nguy hiểm</StatusLabel>}
					</>
				)}
			</>
		)
	}

	//Modal show
	const [openModal, setOpenModal] = useState({
		branchList : false,
		branchAdd : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	const loadBrand = async () => {
		setBranchLoading(true)
		let [error, response] = await handleRequest(branchApi.gets());
		let message = apiError(`Lấy danh sách điểm lấy hàng thất bại`, error, response);
		if(!message) {
			setBranchList(response.data)
			if(!_.isEmpty(response.data) && Object.keys(response.data).length !== 0) {
				for (const [index, branch] of Object.entries(response.data)) {
					if(branch.default  == 1) {
						setBranchMain(branch);
					}
				}
			}
			setBranchLoading(false)
		}
	}

	useEffect(() => {
		if(can.add) loadBrand().then()
	}, []);

	const handleButtonClick = {
		branchList: () => {
			handleModalOpen('branchList');
		},
		branchAdd: () => {
			handleModalOpen('branchAdd');
		},
		branchChoices: (item) => {
			setBranchMain(item);
			handleModalClose('branchList')
		},
		productsAdd: () => {
			append({ id: uuidv4(), title: undefined, quantity: 1, weight: 0.1});
		}
	}

	//Add Edit
	const handleBranchAdd = async (data) => {
		let [error, response] = await handleRequest(branchApi.add(data));
		let message = apiError(`Thêm mới điểm lấy hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm mới điểm lấy hàng thành công`});
			await loadBrand()
			handleModalClose('branchAdd')
		}
	}

	//Form
	const initialValues = {
		code: undefined,
		toName: undefined,
		toPhone: undefined,
		toAddress: undefined,
		toCity: undefined,
		toDistrict: undefined,
		toWard: undefined,
		products: [{ id: uuidv4(), title: undefined, quantity: 1, weight: 0.1}],
		weight: 0.1,
		long: 10,
		height: 10,
		width: 10,
		shippingUnit: undefined,
		cod: 0,
		insurance: 0,
		requiredNote: 'KHONGCHOXEMHANG',
		paymentType: 1,
		note: undefined
	}

	const validationSchema = Yup.object().shape({
		toName: Yup.string().required('Tên người nhận không được để trống'),
		toPhone: Yup.string().required('Số điện thoại người nhận không được để trống').matches(
			/(84|0[3|5|7|8|9])+([0-9]{8})\b/g,
			"Số điện thoại không đúng"
		),
		toAddress: Yup.string().required('Địa chỉ người nhận không được để trống'),
		toCity: Yup.string().required('Tỉnh thành người nhận không được để trống'),
		toDistrict: Yup.string().required('Quận huyện người nhận không được để trống'),
		toWard: Yup.string().required('Phường xã người nhận không được để trống'),
		products: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required('Tên sản phẩm không được bỏ trống'),
				quantity  : Yup.number().required('số lượng không được bỏ trống'),
				weight : Yup.number().required('Cân nặng không được bỏ trống')
			})
		),
		weight: Yup.number().required('Khối lượng đơn hàng không được để trống'),
		long: Yup.number().required('Chiều dài đơn hàng không được để trống'),
		height: Yup.number().required('Chiều cao đơn hàng không được để trống'),
		width: Yup.number().required('Chiều rộng đơn hàng không được để trống'),
		shippingUnit: Yup.string().required('Bạn chưa chọn đơn vị vận chuyển'),
	})

	const {control, watch, handleSubmit, formState: { isSubmitting, errors }, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "products"
	});

	//Xử lý địa chỉ
	let cityOptions = [];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleShippingPrice = async () => {

		const fieldsFee = getValues(["shippingUnit", "weight", "insurance", "toCity", "long", "height", "width"])

		if (fieldsFee[0] === undefined) return false;

		if (fieldsFee[1] === undefined) return false;

		if (fieldsFee[2] === undefined) fieldsFee[2] = 0;

		if (fieldsFee[3] === undefined) return false;

		if (fieldsFee[4] === undefined) fieldsFee[4] = 10;

		if (fieldsFee[5] === undefined) fieldsFee[5] = 10;

		if (fieldsFee[6] === undefined) fieldsFee[6] = 10;

		let [error, response] = await handleRequest(orderApi.fee({
			shippingUnit: fieldsFee[0],
			weight: fieldsFee[1],
			insurance: fieldsFee[2],
			from: branchMain.city,
			to: fieldsFee[3],
			long: fieldsFee[4],
			height: fieldsFee[5],
			width: fieldsFee[6]
		}));

		let message = apiError(`Tính phí ship thất bại`, error, response);
		if (!message) {
			setFee(response.data.shipping)
		}
	}

	const handlePhoneSearch = async () => {

		const toPhone = watch("toPhone");

		const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

		if(toPhone !== undefined && toPhone.match(regexPhoneNumber)) {
			let [error, response] = await handleRequest(customerApi.getByPhone({
				phone: toPhone,
			}));
			let message = apiError(`Tính phí ship thất bại`, error, response);
			if (!message) {
				if(response.data?.name) {
					setValue('toName', response.data.name)
					setValue('toPhone', response.data.phone)
					setValue('toAddress', response.data.address)
					setValue('toCity', response.data.city)
					setValue('toDistrict', response.data.district)
					setValue('toWard', response.data.ward)

					for (const [cityKey, districtTemp] of Object.entries(country.district)) {
						if(cityKey == response.data.city) {
							districtOptions = [{
								label : 'Chọn quận huyện',
								value : ''
							}];
							for (const [value, label] of Object.entries(districtTemp)) {
								districtOptions.push({value, label})
							}
							break;
						}
					}
					setDistrictOptions(districtOptions);

					for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
						if(districtKey == response.data.district) {
							wardOptions = [{
								label : 'Chọn phường xã',
								value : ''
							}];
							for (const [value, label] of Object.entries(wardTemp)) {
								wardOptions.push({value, label})
							}
							break;
						}
					}
					setWardOptions(wardOptions);

					setPercent(response.data.percent)
				}
			}
		}
		else if(percent !== undefined) {
			setPercent(undefined)
		}
	}

	const handleWeightTotal = () => {
		const products = watch("products");
		let weight = 0;
		if(Object.keys(products).length !== 0) {
			for (const [index, item] of Object.entries(products)) {
				weight += item.quantity*item.weight;
			}
		}
		setValue('weight', (Number.isInteger(weight)) ? weight : parseFloat(weight.toFixed(2)))
	}

	useEffect(() => {
		handleShippingPrice().then();
	}, [
		watch("weight"),
		watch("shippingUnit"),
		watch("toCity"),
		watch("insurance"),
		watch("long"),
		watch("height"),
		watch("width"),
	]);

	useEffect(() => {
		handleWeightTotal()
	}, [watch("products")]);

	useEffect(() => {
		setValue('insurance', watch("cod"))
	}, [watch("cod")]);

	useEffect(() => {
		handlePhoneSearch().then();
	}, [watch("toPhone")]);

	const handleOrderAdd = async (data) => {
		data.shopId = branchMain.id;
		let [error, response] = await handleRequest(orderApi.add(data));
		let message = apiError(`Thêm đơn hàng thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Thêm đơn hàng thành công`});
			globalNavigate('/order');
		}
	}

	if(!useCan('shop_order_add') || userCurrent?.isBlockOrder === 1) {
		return (<RoleError link={'/order'} />)
	}

	return (
		<div className={cn('order-gird')}>
			<form className="form" onSubmit={handleSubmit(handleOrderAdd)}>
				{isSubmitting && <Loading/>}
				<div className={cn('order-left')}>
					<Breadcrumb className={'mb-1'}
					            items={[
						            {title: 'Dashboard',},
						            {title: <Link to={'/order'}>Đơn hàng</Link>,},
						            {title: 'Thêm đơn hàng',},
					            ]}
					/>
					<section>
						{branchLoading && <Loading noFixed />}
						<h2 className={'heading'}>Bên Gửi</h2>
						<p className={'mb-1 color-red'}><strong>{branchMain?.name}</strong></p>
						<p className={'mb-1 color-red'}>{branchMain?.phone}</p>
						<p className={'mb-2'}>{
							branchMain?.address && branchMain?.address +','+renderAddress(
								branchMain?.city,
								branchMain?.district,
								branchMain?.ward,
								country)
						}</p>
						<Button white onClick={handleButtonClick.branchList}>Điểm lấy hàng khác</Button>
						<Button white onClick={handleButtonClick.branchAdd}>Thêm</Button>
					</section>

					<section>
						<h2 className={'heading'}>Bên Nhận</h2>
						<Row gutter={10}>
							<Col className="gutter-row" md={6} xs={24}>
								<Controller control={control} name="toName" render={({field}) => (
									<InputField label="Họ tên" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={12} xs={12}>
								<Controller control={control} name="toPhone" render={({ field }) => (
									<InputField type="tel" label={<ToPhoneLabel percent={percent} />} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="code" render={({ field }) => (
									<InputField label="Mã đơn hàng" errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="toAddress" render={({ field }) => (
									<InputField label="Địa chỉ" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="toCity" render={({ field }) => (
									<SelectField label="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
										handleCityChange(value);
										field.onChange(value, event);
									}}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="toDistrict" render={({ field }) => (
									<SelectField label="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
										handleDistrictChange(value);
										field.onChange(value, event);
									}}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="toWard" render={({ field }) => (
									<SelectField label="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className="heading">Sản phẩm</h2>
						{
							<>
								<Row gutter={10}>
									<Col className="gutter-row" span={7}>
										<label htmlFor="">Tên sản phẩm</label>
									</Col>
									<Col className="gutter-row" span={7}>
										<label htmlFor="">Khối lượng (Kg)</label>
									</Col>
									<Col className="gutter-row" span={7}>
										<label htmlFor="">Số lượng</label>
									</Col>
									<Col className="gutter-row" span={3}></Col>
								</Row>
								{fields.map(({ id, title, quantity, weight }, index) => {
									return (
										<Row gutter={10} key={id}>
											<Col className="gutter-row" span={7}>
												<Controller control={control} name={`products[${index}].title`} render={({ field }) => (
													<InputField defaultValue={title} errors={errors} {...field}/>
												)}/>
											</Col>
											<Col className="gutter-row" span={7}>
												<Controller control={control} name={`products[${index}].weight`} render={({ field }) => (
													<InputField defaultValue={weight} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}}/>
												)}/>
											</Col>
											<Col className="gutter-row" span={7}>
												<Controller control={control} name={`products[${index}].quantity`} render={({ field }) => (
													<InputField defaultValue={quantity} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}}/>
												)}/>
											</Col>
											<Col className="gutter-row" span={3}>
												<Button primary type="button" className={'w-100'} onClick={() => remove(index)}>Xóa</Button>
											</Col>
										</Row>
									);
								})}
								<div className="d-flex justify-content-end">
									<Button blue background type="button" onClick={handleButtonClick.productsAdd}>Thêm sản phẩm</Button>
								</div>
							</>
						}

						<h2 className="heading">Thông tin gói hàng</h2>
						{
							<>
								<Row gutter={10}>
									<Col className="gutter-row" span={6}>
										<label htmlFor="">Tổng khối lượng</label>
									</Col>
									<Col className="gutter-row" span={6}>
										<label htmlFor="">Dài (cm)</label>
									</Col>
									<Col className="gutter-row" span={6}>
										<label htmlFor="">Rộng (cm)</label>
									</Col>
									<Col className="gutter-row" span={6}>
										<label htmlFor="">Cao (cm)</label>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col className="gutter-row" md={6} xs={12}>
										<Controller control={control} name="weight" render={({ field }) => (
											<InputField type="number" errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" md={6} xs={12}>
										<Controller control={control} name="long" render={({ field }) => (
											<InputField type="number" errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" md={6} xs={12}>
										<Controller control={control} name="height" render={({ field }) => (
											<InputField type="number" errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" md={6} xs={12}>
										<Controller control={control} name="width" render={({ field }) => (
											<InputField type="number" errors={errors} {...field}/>
										)}/>
									</Col>
								</Row>
							</>
						}
					</section>

					<section>
						<h2 className={'heading'}>Cod</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="cod" render={({ field }) => (
									<InputPriceField label="Tiền thu hộ" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="insurance" render={({ field }) => (
									<InputPriceField label="Giá trị đơn hàng (Bảo hiểm)" errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Lưu ý - ghi chú</h2>
						<Row gutter={10}>
							<Col className="gutter-row" md={12} xs={24}>
								<Controller control={control} name="requiredNote" render={({ field }) => (
									<SelectField label="Lưu ý giao hàng" options={[
										{value: 'KHONGCHOXEMHANG', label: 'Không cho xem hàng'},
										{value: 'CHOXEMHANGKHONGTHU', label: 'Được Đồng kiểm'},
										{value: 'CHOTHUHANG', label: 'Được đồng kiểm, được thử'},
									]} errors={errors} {...field}/>
								)}/>
								<Controller control={control} name="paymentType" render={({ field }) => (
									<SelectField label="Tùy chọn thanh toán" options={[
										{value: 1, label: 'Bên gửi hàng trả phí'},
										{value: 2, label: 'Bên nhận hàng trả phí'},
									]} errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={12} xs={24}>
								<Controller control={control} name="note" render={({ field }) => (
									<TextAreaField label="Ghi chú" errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Đợn vị vận chuyển</h2>
						<Controller control={control} name="shippingUnit" render={({ field }) => (
							<GroupRadioButton defaultValue={''} options={shippingUnitOptions} errors={errors} {...field} />
						)}/>
					</section>
				</div>
				<div className={cn('order-right')}>
					<div className={cn('order-bottom')}>
						{
							fee !== 0 && <div className="text-right mb-4">
								<p className="color-red">Phí giao hàng</p>
								<p className="color-red font-bold">{numberFormat(fee)} vnđ</p>
							</div>
						}
						<div className="text-center">
							<Button type="submit" background primary className="w-100">Tạo đơn</Button>
						</div>
					</div>
				</div>
			</form>
			{
				(!_.isEmpty(branchList) && Object.keys(branchList).length !== 0) &&
				<Modal title="Danh sách điểm lấy hàng" visible={openModal.branchList} onCancel={() => {handleModalClose('branchList')}}>
					{branchList.map((branch, index) => {
						return (<BranchItem key={branch.id}
						                    item={branch}
						                    country={country}
						                    branchMain={branchMain}
						                    onChoices={handleButtonClick.branchChoices}
						/>);
					})}
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('branchList')}}> Đóng </Button>
					</div>
				</Modal>
			}
			<Modal title="Địa điểm lấy hàng" visible={openModal.branchAdd} onCancel={() => {handleModalClose('branchAdd')}}>
				<BranchFormAddEdit country={country} onHandleSubmit={handleBranchAdd} />
			</Modal>
		</div>
	)
}
export default OrderAdd;