import {Link} from "react-router-dom";
import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
	numberFormat,
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
} from "~/app/constants";
import {CodTableAction} from "../index";

function CodTable({items = [], pagination, setItemEdit, handleEvent, can}) {

	const {tableHeight, isMobile} = useContext(AppContext);

	const CodMobile = ({item}) => {
		return (
			<>
				<div className={'d-flex justify-content-between'}>
					<p className=""><Link to={'/cod/detail/'+item.id} className="color-red font-bold">{item?.id}</Link></p>
					<p className="color-grey text-xs">{renderDate(item?.verifyTime)}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="font-bold">{item?.user?.username}</p>
					<p className="font-bold">{item?.userCreated}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="mb-0">Số đơn:</p>
					<p className="mb-0">{item?.numberOrder}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="mb-0">Phí vận chuyển:</p>
					<p className="mb-0">{numberFormat(item?.shipping)}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="mb-0">Phí hoàn:</p>
					<p className="mb-0">{numberFormat(item?.returnFee)}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="mb-0">Cod:</p>
					<p className="mb-0">{numberFormat(item?.cod)}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="mb-0">Bảo hiểm:</p>
					<p className="mb-0">{numberFormat(item?.insurance)}</p>
				</div>
				<div className={'d-flex justify-content-between mb-1'}>
					<p className="mb-0">Tiền thu về:</p>
					<p className="mb-0 font-bold color-red">{numberFormat(item.cod - (item.shipping + item.returnFee - item.paymentCoin - item.paymentCoinOrder))}</p>
				</div>
				<CodTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
			</>
		)
	}

	let columns = [
		{ title: 'Mã ĐS', dataIndex: 'code', key: "code", width: '80px', render: (_, item) => (
		<>
			{
				!isMobile ? (
					<>
						<p className="mb-1"><Link to={'/cod/detail/'+item.id} className="color-red font-bold">{item?.id}</Link></p>
					</>) : <CodMobile item={item} />
			}
		</>
		)},
		{ title: 'Bên gửi', dataIndex: 'from', key: "from", width: '160px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
			</>
		)},
		{ title: 'Người tạo', dataIndex: 'userCreated', key: "userCreated", width: '100px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.userCreated}</p>
			</>
		)},
		{ title: 'Ngày ĐS', dataIndex: 'created', key: "created", width: '100px', render: (_, item) => (
			<p className="color-grey text-xs mt-1">{renderDate(item?.verifyTime)}</p>
		)},
		{ title: 'Số đơn', dataIndex: 'numberOrder', key: "numberOrder", width: '100px', render: (_, item) => (
			<p className="mb-0">{item?.numberOrder}</p>
		)},
		{ title: 'Tiền cước', dataIndex: 'shipping', key: "shipping", width: '100px', render: (_, item) => (
			<p className="mb-0">{numberFormat(item.shipping)} đ</p>
		)},
		{
			title: 'Tiền hoàn', dataIndex: 'return', key: "return", width: '100px', render: (_, item) => (
			<p className="mb-0">{numberFormat(item.returnFee)} đ</p>
		)},
		{ title: 'Xu', dataIndex: 'coin', key: "coin", width: '100px', render: (_, item) => (
				<p className="mb-0">{numberFormat(item.paymentCoin + item.paymentCoinOrder)} đ</p>
			)},
		{ title: 'Cod/BH', dataIndex: 'cod', key: "cod", width: '100px', render: (_, item) => (
				<>
					<p className="mb-1">{numberFormat(item.cod)} đ</p>
					<p className="mb-0 color-grey text-xs">{numberFormat(item.insurance)} đ</p>
				</>
			)
		},
		{ title: 'Tiền thu về', dataIndex: 'money', key: "money", width: '120px', render: (_, item) => (
			<p className="mb-0">{numberFormat(item.cod - (item.shipping + item.returnFee - item.paymentCoin - item.paymentCoinOrder))} đ</p>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '230px', render: (_, item) => (
			<CodTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'from'
				&& currentValue.key != 'userCreated'
				&& currentValue.key != 'created'
				&& currentValue.key != 'numberOrder'
				&& currentValue.key != 'shipping'
				&& currentValue.key != 'return'
				&& currentValue.key != 'cod'
				&& currentValue.key != 'insurance'
				&& currentValue.key != 'money'
				&& currentValue.key != 'action'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default CodTable;