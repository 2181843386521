import {forwardRef} from "react";
import OrderPrintList from "./OrderPrintList";
export const OrderPrintTemplate = forwardRef(({ items, printHidden, ...props }, ref) => {
	return (
		<div style={{display:'none'}}>
			<div ref={ref}>
				<OrderPrintList items={items} printHidden={printHidden}/>
			</div>
		</div>
	)
});

export default OrderPrintTemplate;