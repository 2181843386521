import request from "~/utils/http";

const importHistoryApi = {
	gets : async (params) => {
		const url = 'v1/order-excel';
		return await request.get(url, {params});
	},
	orders : async (params) => {
		const url = `v1/order`;
		return await request.get(url, {params});
	},
};

export default importHistoryApi;