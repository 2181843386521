import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {DiscountTableAction} from "../index";
import {LIST_DISCOUNT_STATUS} from "../../../../app/constants";
import {Link} from "react-router-dom";

function DiscountTable({items = [], pagination, setItemEdit, handleEvent, can, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Khuyến mãi', dataIndex: 'code', key: "code", width: '150px', render: (_, item) => (
			<>
				<p className="font-bold"><Link to={'/discount/info/'+item.id}>{item?.code}</Link></p>
				<p className={'color-grey'}>{item?.excerpt}</p>
			</>
		)},
		{ title: 'Loại discount', dataIndex: 'type', key: "from", width: '100px', render: (_, item) => (
			<>
				{
					(item.type == 'coin') ? <p className="font-bold">Xu vĩnh viển</p> : <p className="font-bold">Xu khuyến mãi</p>
				}
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '130px', render: (_, item) => (
			<>
				<StatusLabel small type={LIST_DISCOUNT_STATUS[item?.status]?.color} border>{LIST_DISCOUNT_STATUS[item?.status]?.title}</StatusLabel>
			</>
		)},
		{ title: 'Đã sử dụng', dataIndex: 'use', key: "use", width: '80px', render: (_, item) => (
			<p>{item?.use}</p>
		)},
		{ title: 'Bắt đầu', dataIndex: 'timeStart', key: "timeStart", width: '100px', render: (_, item) => (
			<p>{renderDate(item?.timeStart)}</p>
		)},
		{ title: 'Kết thúc', dataIndex: 'timeEnd', key: "timeEnd", width: '100px', render: (_, item) => (
			<p>{(item.infinity == 0) ? renderDate(item?.timeEnd) :  '--'}</p>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '120px', render: (_, item) => (
			<DiscountTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default DiscountTable;