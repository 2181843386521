import {useEffect, useState} from "react";
import {
	Button,
	Loading,
	Image,
	Icon
} from "~/components";
import {Col, Row} from "antd";
import {
	apiError,
	handleRequest
} from "~/utils";
import {userApi} from "~/api";

function UserFormVerify({item, onHandleSubmit}) {

	const [loading, setLoading] = useState(true);
	const [errorLoad, setErrorLoad] = useState(false);
	const [identificationBefore, setIdentificationBefore] = useState();
	const [identificationAfter, setIdentificationAfter] = useState();

	const loadUser = async () => {
		let [error, response] = await handleRequest(userApi.getIdentification(item?.id));
		let message = apiError(`Lấy thông tin thành viên thất bại`, error, response);
		if(!message) {
			setIdentificationBefore(response.data?.identificationBefore)
			setIdentificationAfter(response.data?.identificationAfter)
			setErrorLoad(false)
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	useEffect(() => {
		loadUser().then()
	}, [item?.id]);

	const onSubmit = async () => {
		setLoading(true)
		await onHandleSubmit(item)
		setLoading(false)
	}
	return (
		<div className="form">
			{ loading && <Loading />}
			<Row gutter={10}>
				<Col md={12} sm={24}>
					<p className="heading">Thông tin</p>
					<p className="mb-1 font-bold">{item?.username}</p>
					<p className="mb-1 font-bold">{item?.firstname + ' ' + item?.lastname}</p>
					<p className="mb-1">{item?.email}</p>
					<p className="mb-1">{item?.phone}</p>
					<hr />
					<Button leftIcon={Icon.success} green background onClick={onSubmit}>Duyệt tài khoản</Button>
				</Col>
				<Col md={12} sm={24}>
					<p className="heading">Căn cước công dân</p>
					{
						identificationBefore && (
							<>
								<p className={'mb-1'}>Mặt sau căn cước công dân</p>
								<Image src={identificationBefore} className={'w-100'}/>
							</>
						)
					}
					{
						identificationAfter && (
							<>
								<p className={'mb-1'}>Mặt trước căn cước công dân</p>
								<Image src={identificationAfter} className={'w-100'}/>
							</>
						)
					}
				</Col>
			</Row>
		</div>
	)
}

export default UserFormVerify;