import {Button, Icon} from "~/components";

function BranchAction({item, setItemEdit, openModal, can}) {

	const handleButtonClick = {
		edit: () => {
			setItemEdit(item);
			openModal('addEdit');
		},
		default: () => {
			setItemEdit(item);
			openModal('default');
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
		},
	}

	return (
		<div className="table-action d-flex">
			{can.edit && <Button background blue onClick={handleButtonClick.edit}>{Icon.edit}</Button>}
			{can.edit && <Button background green onClick={handleButtonClick.default}>{Icon.success}</Button>}
			{can.delete && <Button background red onClick={handleButtonClick.delete}>{Icon.delete}</Button>}
		</div>
	)
}

export default BranchAction;