import request from "~/utils/http";

const userApi = {
	gets : async (params) => {
		const url = 'v1/user';
		return await request.get(url, {params});
	},
	getsLoginAs : async (params) => {
		const url = 'v1/user/login-as';
		return await request.get(url, {params});
	},
	getsBlockOrder : async (params) => {
		const url = 'v1/user/block-order';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `v1/user/${id}`;
		return await request.get(url);
	},
	status : async (params) => {
		const url = `v1/user/status/${params.id}`;
		return await request.post(url, params);
	},
	unBlockOrder : async (id) => {
		const url = `v1/user/unblock-order/${id}`;
		return await request.post(url);
	},
	update : async (params) => {
		const url = `v1/user/${params.id}`;
		return await request.post(url, params);
	},
	role : async (params) => {
		const url = `v1/user/role/${params.id}`;
		return await request.post(url, params);
	},
	password : async (params) => {
		const url = `v1/user/password/${params.id}`;
		return await request.post(url, params);
	},
	affiliateStatus : async (params) => {
		const url = `v1/user/affiliate/${params.id}`;
		return await request.post(url, params);
	},
	getShippingPrice : async (id) => {
		const url = `v1/user/shipping-price/${id}`;
		return await request.get(url);
	},
	updateShippingPrice : async (params) => {
		const url = `v1/user/shipping-price/${params.id}`;
		return await request.post(url, params);
	},
	getShippingSupport : async (id) => {
		const url = `v1/user/shipping-support/${id}`;
		return await request.get(url);
	},
	updateShippingSupport : async (params) => {
		const url = `v1/user/shipping-support/${params.id}`;
		return await request.post(url, params);
	},

	getAffiliate : async (id) => {
		const url = `v1/user/affiliate-info/${id}`;
		return await request.get(url);
	},
	updateAffiliate : async (params) => {
		const url = `v1/user/affiliate-info/${params.id}`;
		return await request.post(url, params);
	},
	getBanks : async (id) => {
		const url = `v1/user/bank/${id}`;
		return await request.get(url);
	},

	getIdentification : async (id) => {
		const url = `v1/user/identification/${id}`;
		return await request.get(url);
	},
	setVerify : async (params) => {
		const url = `v1/user/verify/${params.id}`;
		return await request.post(url, params);
	},
	export : async (params) => {
		const url = 'v1/user/export';
		return await request.post(url, params);
	},

	getShippingPriceBase : async (params) => {
		const url = `v1/user/shipping-price-base/${params.id}`;
		return await request.get(url, {params});
	},
	updateShippingPriceBase : async (params) => {
		const url = `v1/user/shipping-price-base/${params.id}`;
		return await request.post(url, params);
	},


};

export default userApi;