import {createSlice} from "@reduxjs/toolkit";
import {DATA_LIMIT, DATA_LIMIT_KEY} from "../../app/constants";

const branch = createSlice({
	name: "branch",
	initialState: {
		loading: false,
		error: null,
		filter:{
			page : 1,
			limit : (localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT,
		},
		items: [],
		pagination : {
			page : 1,
			limit : DATA_LIMIT,
			totalRows: 0
		}
	},
	reducers: {
		fetchData(state) {
			state.loading = true;
			state.error = null;
			return state;
		},
		fetchDataSuccess(state, action) {
			state.loading = false;
			state.items = action.payload.data;
			state.pagination = action.payload.pagination;
			state.error = null;
			return state;
		},
		fetchDataFailed(state, action) {
			state.loading = false;
			state.error = action.payload;
			return state;
		},
		add(state, action) {
			state.items.unshift(action.payload);
			return state;
		},
		update(state, action) {
			let objIndex = state.items.findIndex((item => item.id == action.payload.id));
			state.items[objIndex] = {...state.items[objIndex], ...action.payload};
			return state;
		},
		delete(state, action) {
			state.items = state.items.filter(function(item) {
				return item.id !== action.payload
			})
		},
		//Action search
		setFilter(state, action) {
			state.filter = action.payload;
			return state;
		},
		setFilterWithDebounce(state, action) {}
	}
})
//Action
export const branchActions = branch.actions;
//Reducers
export const branchReducer = branch.reducer;
//Selectors
export const branchItemsSelector = (state) => state.branch.items;
export const branchLoadingSelector = (state) => state.branch.loading;
export const branchErrorSelector = (state) => state.branch.error;
export const branchFilterSelector = (state) => state.branch.filter;
export const branchPaginationSelector = (state) => state.branch.pagination;