import {
	Button,
	Icon,
	PopperWrapper
} from "~/components";
import {Tooltip} from "antd";
import Tippy from '@tippyjs/react/headless';
import {useState} from "react";

function ShippingPriceTableAction({item, setItemEdit, handleEvent, can}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const [loading, setLoading] = useState(false)

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const handleButtonClick = {
		default: () => {
			closeActionButton();
			setItemEdit(item);
			handleEvent.openModal('default');
		},
		delete: () => {
			closeActionButton();
			setItemEdit(item);
			handleEvent.openModal('delete');
		},
		duplicate: () => {
			closeActionButton();
			setItemEdit(item);
			handleEvent.openModal('duplicate');
		},
		active: async () => {
			setLoading(true)
			await handleEvent.active(item);
			setLoading(false)
		},
	}

	const renderActionButton = () => {
		return (
			<PopperWrapper className={'action-wrapper'}>
				{(can.default && item.default === 0) && <Button onClick={handleButtonClick.default} leftIcon={Icon.success}> Đăt làm măc định</Button>}
				{can.add && <Button onClick={handleButtonClick.duplicate} leftIcon={Icon.copy}> Nhân bản bảng giá</Button>}
				{can.setUser && <Button to={'/admin/shipping-price/user/'+item?.id} leftIcon={Icon.user}> Xét cho thành viên</Button>}
				{can.delete && <Button outline red onClick={handleButtonClick.delete} leftIcon={Icon.delete}> Xóa bảng giá</Button>}
			</PopperWrapper>
		)
	}

	return (
		<>
			{
				(item.status !== 'public' && can.status) && <Tooltip title="Duyệt bảng giá">
					<Button small background green onClick={handleButtonClick.active} disabled={loading}>
						{loading ? Icon.loading : Icon.success}
					</Button>
				</Tooltip>
			}
			{
				can.edit && <>
					<Tooltip title="Chỉnh sữa">
						<Button small background blue to={'/admin/shipping-price/edit/'+item?.id}>{Icon.edit}</Button>
					</Tooltip>
				</>
			}
			<Tippy
				interactive
				visible={showActionBox}
				render={renderActionButton}
				onClickOutside={closeActionButton}
				placement="bottom" offset={[35, 5]}
			><Button type="button" small onClick={() => setShowActionBox(true)} background white>{Icon.menuVertical}</Button></Tippy>
		</>
	)
}

export default ShippingPriceTableAction;