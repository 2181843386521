import style from './AccountForm.module.scss'
import className from 'classnames/bind';
import {useEffect, useState} from "react";
import {Col, Row, Image, Alert} from "antd";
import {
	Button,
	Loading,
	Icon
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	authApi,
	userApi
} from "~/api";
import {useDispatch} from "react-redux";
import {authActions} from "../../../Auth/authSlice";
const cn = className.bind(style);
const getBase64 = (file) =>
new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = (error) => reject(error);
});
function AccountFormVerify({currentUser}) {

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [previewImageBefore, setPreviewImageBefore] = useState('');
	const [imageBefore, setImageBefore] = useState();

	const [previewImageAfter, setPreviewImageAfter] = useState('');
	const [imageAfter, setImageAfter] = useState();
	const [rejectedNote, setRejectedNote] = useState();

	const [isVerify, setIsVerify] = useState(currentUser.isVerify);

	const onChangeBefore = async (event) => {
		setImageBefore(event.target.files[0]);
		setPreviewImageBefore(await getBase64(event.target.files[0]));
		event.target.value = '';
	}

	const onChangeAfter = async (event) => {
		setImageAfter(event.target.files[0]);
		setPreviewImageAfter(await getBase64(event.target.files[0]));
		event.target.value = '';
	}

	const loadUser = async () => {
		let [error, response] = await handleRequest(userApi.getIdentification(currentUser?.id));
		let message = apiError(`Lấy thông tin thành viên thất bại`, error, response);
		if(!message) {
			setPreviewImageBefore(response.data?.identificationBefore)
			setPreviewImageAfter(response.data?.identificationAfter)
			setRejectedNote(response.data?.rejectedNote)
		}
		setLoading(false)
	}

	const uploadHandler = async () => {

		if (!imageBefore) return;
		if (!imageAfter) return;

		// upload file
		const data = new FormData();
		data.append(`identificationBefore`, imageBefore, imageBefore.name);
		data.append(`identificationAfter`, imageAfter, imageAfter.name);
		let [error, response] = await handleRequest(authApi.verify(data));
		let message = apiError(`Tạo yêu cầu xét duyệt tài khoản không thành công`, error, response);
		if (!message) {
			dispatch(authActions.update({
				...currentUser,
				isVerify: 1,
			}));
			setIsVerify('underReview');
		}
	}

	useEffect(() => {
		loadUser().then()
	}, []);

	return (
		<div>
			<h2 className={'heading'}>Xác thực tài khoản</h2>
			{loading && <Loading noFixed/>}
			{
				isVerify == 'underReview' && (<>
					<Alert
						message="Đợi xác thực"
						description="Đã gửi yêu cầu xác thự đang trong tình trạng đợi."
						type="info"
						showIcon
					/>
				</>)
			}
			{
				isVerify == 'verified' && (<>
					<Alert
						message="Đã xác thực"
						description="Tài khoản đã xác thực"
						type="success"
						showIcon
					/>
				</>)
			}
			{
				isVerify == 'rejected' && (<>
					<Alert
						message="Từ chối"
						description={"Tài khoản đã bị từ chối xác thực." + rejectedNote}
						type="error"
						className={'mb-1'}
						showIcon
					/>
				</>)
			}
			{
				(isVerify == 'unverified' || isVerify == 'rejected') && (<>
					<Row gutter={10}>
						<Col className="gutter-row" md={6} xs={24}>
							<div className={cn('upload-card')}>
								<div className={cn('upload-preview', 'mb-2')}>
									{previewImageBefore && <Image src={previewImageBefore}/>}
								</div>
								<div className={cn('upload-button')}>
									<input name="identificationBefore" onChange={onChangeBefore} type="file"/>
									<button><span>{Icon.plus}</span> CCCD mặt trước</button>
								</div>
							</div>
						</Col>
						<Col className="gutter-row" md={6} xs={24}>

							<div className={cn('upload-card')}>
								<div className={cn('upload-preview', 'mb-2')}>
									{previewImageAfter && <Image src={previewImageAfter}/>}
								</div>
								<div className={cn('upload-button')}>
									<input name="identificationAfter" onChange={onChangeAfter} type="file"/>
									<button><span>{Icon.plus}</span> CCCD mặt trước</button>
								</div>
							</div>
						</Col>
					</Row>
					<div className="text-right mt-1">
						<Button background blue onClick={uploadHandler}>Lưu thông tin</Button>
					</div>
				</>)
			}
		</div>
	)
}

export default AccountFormVerify;