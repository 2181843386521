import {
	Button,
	Icon
} from "~/components";
import {Tooltip} from "antd";

function UserVerifyTableAction({item, setItemEdit, handleEvent, can}) {

	const handleButtonClick = {
		verified: () => {
			setItemEdit(item)
			handleEvent.openModal('verified')
		},
		rejected: () => {
			setItemEdit(item)
			handleEvent.openModal('rejected')
		},
	}

	return (
		<>
			{
				(can.status && (item.isVerify === 'underReview' || item.isVerify === 'rejected')) &&
				<Tooltip title="Duyệt tài khoản">
					<Button small background green onClick={handleButtonClick.verified}>{Icon.success}</Button>
				</Tooltip>
			}
			{
				(can.status && item.isVerify === 'underReview') &&
				<Tooltip title="Khóa tài khoản">
					<Button small background red onClick={handleButtonClick.rejected}>{Icon.block}</Button>
				</Tooltip>
			}
		</>
	)
}

export default UserVerifyTableAction;