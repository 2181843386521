import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	GroupRadioField,
} from "~/components/Forms";
import {
	numberFormat,
	apiError,
	handleRequest
} from "~/utils"

import {coinApi} from "~/api";

function CodFormCoin({item, onHandleSubmit}) {

	const [coin, setCoin] = useState(0)

	const [coinSale, setCoinSale] = useState(0)

	const [loadingCoin, setLoadingCoin] = useState(true)

	const [coinPay, setCoinPay] = useState(((item.shipping + item.returnFee - item.paymentCoin - item.paymentCoinOrder - item.cod) > 0) ? (item.shipping + item.returnFee - item.paymentCoin - item.paymentCoinOrder - item.cod) : 0 )

	const initialValues = {}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại xu muốn thanh toán'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const getCoinUser = async (id) => {
		let [error, response] = await handleRequest(coinApi.user(id));
		let message = apiError(`Lấy dữ liệu thất bại`, error, response);
		if (!message) {
			setCoin(response.data.coin)
			setCoinSale(response.data.coinSale)
			setLoadingCoin(false)
		}
	}

	useEffect(() => {
		getCoinUser(item.userId).then()
	}, [item.userId]);


	const onSubmit = async (data) => {
		await onHandleSubmit(item, data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{(isSubmitting || loadingCoin) && <Loading/>}
			<>
				<div className={'d-flex justify-content-between mb-2'}>
					<p className="mb-0" style={{'color': '#1bb394'}}>Cod:</p>
					<p className="mb-0">{numberFormat(item.cod)} đ</p>
				</div>
				<div className={'d-flex justify-content-between mb-2'}>
					<p className="mb-0" style={{'color': '#1bb394'}}>Phí ship:</p>
					<p className="mb-0">{numberFormat(item.shipping)} đ</p>
				</div>
				{
					item.returnFee !== 0 &&
					<div className={'d-flex justify-content-between mb-2'}>
						<p className="mb-0" style={{'color': '#1bb394'}}>Phí hoàn:</p>
						<p className="mb-0">{numberFormat(item.returnFee)} đ</p>
					</div>
				}
				<div className={'d-flex justify-content-between mb-2'}>
					<p className="mb-0" style={{'color': '#1bb394'}}>Tổng phí dịch vụ:</p>
					<p className="mb-0">{numberFormat(item.shipping + item.returnFee)} đ</p>
				</div>
				<div className={'d-flex justify-content-between mb-2'}>
					<p className="mb-0" style={{'color': '#1bb394'}}>Xu đã thanh toán:</p>
					<p className="mb-0">{numberFormat(item.paymentCoin + item.paymentCoinOrder)} đ</p>
				</div>
				<div className={'d-flex justify-content-between mb-2'}>
					<p className="mb-0" style={{'color': '#b20101'}}>Số xu cần thanh toán:</p>
					<p className="mb-0" style={{'color': '#b20101'}}>{numberFormat(coinPay)} xu</p>
				</div>
			</>
			<hr/>
			<div className={'d-flex justify-content-between mb-2'}>
				<p className="mb-0">Số xu hiện có:</p>
			</div>
			<div className={'d-flex justify-content-between mb-2'}>
				<p className="mb-0">Xu:</p>
				<p className="mb-0"
				   style={{'color': '#b20101'}}>{numberFormat(coin)} xu</p>
			</div>
			<div className={'d-flex justify-content-between mb-2'}>
				<p className="mb-0">Xu thường</p>
				<p className="mb-0"
				   style={{'color': '#b20101'}}>{numberFormat(coinSale)} xu</p>
			</div>
			<hr/>
			<p className="mb-2">Chọn loại xu thanh toán</p>
			<Controller control={control} name="type" render={({field}) => (
				<GroupRadioField options={[
					{value: 'coin', label: 'Xu vĩnh viển'},
					{value: 'sale', label: 'Xu thường'},
				]} errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-center modal-bottom">
				<Button primary type="submit">Thanh toán</Button>
			</div>
		</form>
	)
}

export default CodFormCoin;