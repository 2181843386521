import style from '../../style/Home.module.scss';
import className from 'classnames/bind';
import {
	Image
} from "~/components";
import images from "~/assets/images";
import {Link} from "react-router-dom";
import {useCan} from "~/hooks";
import {useSelector} from "react-redux";
import {currentUserSelector} from "../../../Auth/authSlice";
const cn = className.bind(style);

function MobileHello() {

	const user = useSelector(currentUserSelector);

	// Tạo một đối tượng Date mới
	const currentDate = new Date();

	// Tạo một mảng chứa các tên thứ trong tuần
	const daysOfWeek = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];

	// Lấy tên thứ của ngày hiện tại
	const currentDayOfWeek = daysOfWeek[currentDate.getDay()];

	// Lấy ngày hiện tại
	const currentDayOfMonth = currentDate.getDate();

	// Lấy tên tháng hiện tại
	const currentMonthName = new Intl.DateTimeFormat('vi-VN', { month: 'long' }).format(currentDate);

	// Lấy năm hiện tại
	const currentYear = currentDate.getFullYear();

	// Kết hợp các giá trị này để tạo ra chuỗi thời gian đầy đủ
	const fullTime = `${currentDayOfWeek}, ngày ${currentDayOfMonth} ${currentMonthName} năm ${currentYear}`;


	const can = {
		order       : useCan('shop_order'),
		cod         : useCan('shop_cod'),
		customer    : useCan('shop_customer'),
		ticket      : useCan('shop_ticket'),
		user        : useCan('shop_user'),
		report      : useCan('shop_report_affiliate'),
	}

	const ActionItem = ({name, link, icon}) => {
		return (
			<div className={cn('action-item')}>
				<Link to={link}>
					<div className={cn('icon')}><Image src={icon} /></div>
					<p className={cn('name')}>{name}</p>
				</Link>
			</div>
		)
	}

	return (
		<div className={cn('account-action')}>
			<div className={cn('account')}>
				<div className={cn('account-name')}><p>Hello, {user.lastname}</p></div>
				<p>{fullTime}</p>
			</div>
			<div className={cn('action')}>
				<ActionItem name={'Đơn hàng'} icon={images.iconContract} link={'/order'} />
				{can.cod && <ActionItem name={'Cod'} icon={images.iconRevenue} link={'/cod/waiting-pay'} />}
				{can.customer && <ActionItem name={'Khách hàng'} icon={images.iconCustomer} link={'/customer'}  />}
				{can.ticket && <ActionItem name={'Phản hồi'} icon={images.iconEvent} link={'/ticket'} />}
				{can.user && <ActionItem name={'Thành viên'} icon={images.iconCustomer} link={'/users'} />}
				{can.report && <ActionItem name={'Báo cáo CTV'} icon={images.iconReport} link={'/report/affiliate'} />}
			</div>
		</div>
	)
}

export default MobileHello;