import {Link, useParams} from "react-router-dom";
import {
	useEffect,
	useState
} from "react";
import {
	Alert,
	Breadcrumb,
	Empty,
	notification,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	shippingPriceApi
} from "~/api";
import {
	Button,
	Loading,
	RoleError
} from "~/components";

import {useCan} from "~/hooks";

import {ShippingPriceForm} from "../components";
import {useCurrentUser} from "../../../hooks";

function ShippingPriceEdit() {

	const currentUser = useCurrentUser();

	const {id} = useParams();

	const can = {
		edit: useCan('shop_shipping_price_edit'),
	}

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [shippingPrice, setShippingPrice] = useState({});

	const loadShippingPrice = async () => {
		let [error, response] = await handleRequest(shippingPriceApi.getById(id));
		let message = apiError(`Lấy thông tin bảng giá thất bại`, error, response);
		if(!message) {
			setShippingPrice(response.data)
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	const handleSave = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có bảng giá để lưu'});
			return;
		}
		data.id = item.id;

		let [error, response] = await handleRequest(shippingPriceApi.update(data));

		let message = apiError(`Cập nhật bảng giá thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: 'Cập nhật bảng giá thành công'});
			setShippingPrice(response.data)
		}
	}

	useEffect(() => {
		if(can.edit) {
			loadShippingPrice().then()
		}
	}, [id]);

	if(!can.edit) {
		return (
			<RoleError link={'/admin/shipping-price'}/>
		)
	}

	if(loading) {
		return (
			<div className={'container'}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={'container'}>
				<Empty description={<span>Không có bảng giá</span>}><Button to={'/admin/shipping-price'}>Quay lại danh sách bảng giá</Button></Empty>
			</div>
		)
	}

	if(currentUser.aff == 0) {
		return (
			<div className={'container'}>
				<Breadcrumb className={'mb-1'}
				            items={[
					            {title: 'Dashboard',},
					            {title: <Link to={'/admin/shipping-price'}>Bảng giá</Link>,},
					            {title: 'Cập nhật bảng giá',},
				            ]}
				/>
				<div className="d-flex mb-2 justify-content-between">
					<div>
						<p className="font-bold text-lg mb-2">Bảng giá {shippingPrice?.name}</p>
					</div>
				</div>
				<ShippingPriceForm item={shippingPrice} onHandleSubmit={handleSave}/>
			</div>
		)
	}

	return (
		<div className={'container'}>
			<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/admin/shipping-price'}>Bảng giá</Link>,},
				            {title: 'Cập nhật bảng giá',},
			            ]}
			/>
			<div className="d-flex mb-2 justify-content-between">
				<div>
					<p className="font-bold text-lg mb-2">Bảng giá {shippingPrice?.name}</p>
				</div>
			</div>
			<Alert
				message="Lưu ý"
				description="Bạn không thể cập nhật bản giá vui lòng liên hệ quản trị viên để cập nhật bảng giá"
				type="warning"
			/>
		</div>
	)

}
export default ShippingPriceEdit;