import {Link} from "react-router-dom";
import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
	numberFormat,
	isEmpty,
	renderAddress
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
} from "~/app/constants";

function OrderCodTable({items = [], pagination, onPaginationChange, onLimitChange, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight, country, isMobile} = useContext(AppContext);

	const OrderMobile = ({item}) => {
		return (
			<>
				<div className={'d-flex justify-content-between'}>
					<p className="mb-1"><Link to={'/order/edit/'+item.id} className="color-red font-bold">{!isEmpty(item?.trackingNumber) ? item.trackingNumber : '#'+item.code}</Link></p>
					<p className="color-grey text-xs">T: {renderDate(item?.created)}</p>
					<p className="color-grey text-xs">HT: {renderDate(item?.timeSuccess)}</p>
				</div>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p>{item?.fromPhone}</p>
				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				<p className={'italic text-xs'}>{item?.toAddress}</p>
				<p className="mb-0 color-red font-bold">{numberFormat(item.total)} đ</p>
				<p className="mb-0 italic">{ item.paymentType == 1 ? 'Bên gửi hàng trả phí' : 'Bên nhận hàng trả phí'}</p>
				<p className="mb-0 color-grey" style={{fontSize:'12px', fontWeight: '400'}}>(Bao gồm COD)</p>
			</>
		)
	}

	let columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '250px', render: (_, item) => (
			<>
			{
				!isMobile ? (
					<>
						<p className="mb-1"><Link to={'/order/edit/'+item.id} className="color-red font-bold">{!isEmpty(item?.trackingNumber) ? item.trackingNumber : '#'+item.code}</Link></p>
						<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
					</>) : <OrderMobile item={item} />
			}
			</>
		)},
		{ title: 'Bên gửi', dataIndex: 'from', key: "from", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p>{item?.fromPhone}</p>
			</>
		)},
		{ title: 'Bên nhận', dataIndex: 'to', key: "to", width: '400px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				<p>{item?.toAddress + ', ' + renderAddress(
					item?.toCity,
					item?.toDistrict,
					item?.toWard,
					country
				)}</p>
			</>
		)},
		{ title: 'Ngày gửi', dataIndex: 'created', key: "created", width: '100px', render: (_, item) => (
			<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
		)},
		{ title: 'Ngày nhận', dataIndex: 'success', key: "success", width: '100px', render: (_, item) => (
			<p className="color-grey text-xs mt-1">{renderDate(item?.timeSuccess)}</p>
		)},
		{ title: 'Tiền thu hộ', dataIndex: 'price', key: "price", width: '300px', render: (_, item) => (
			<>
				<p className="mb-0">{numberFormat(item.total)} đ</p>
				<p className="mb-0 italic">{ item.paymentType == 1 ? 'Bên gửi hàng trả phí' : 'Bên nhận hàng trả phí'}</p>
				<p className="mb-0 color-grey" style={{fontSize:'12px', fontWeight: '400'}}>(Bao gồm COD)</p>
			</>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'from'
				&& currentValue.key != 'to'
				&& currentValue.key != 'created'
				&& currentValue.key != 'success'
				&& currentValue.key != 'price'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.trackingNumber, // I added this line
		...row
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = (!selectedRowKeys) ? false : {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
				{
					onLimitChange && <SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
						{value: 50, label: '50 / Trang'},
						{value: 100, label: '100 / Trang'},
						{value: 200, label: '200 / Trang'},
						{value: 500, label: '500 / Trang'},
					]} onChange={onLimitChange} />
				}
			</div>
		</div>
	)
}

export default OrderCodTable;