import {memo} from "react";
import {
	DateField
} from "~/components/Forms";
import {Button} from "~/components";
import {strToTime} from "~/utils";
import {Controller, useForm} from "react-hook-form";

function ReportAffiliateSearch({filter, onSearchChange}) {

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		data.time = strToTime(data.time);
		const newFilter = {
			...filter,
			time: data.time,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	return (
		<form className="form d-flex gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="time" render={({field}) => (
				<DateField picker="month" errors={errors} {...field} />
			)}/>
			<Button background primary className={'mb-1'}>Áp dụng</Button>
		</form>
	)
}

export default memo(ReportAffiliateSearch);