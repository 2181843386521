import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {Link, useParams} from "react-router-dom";
import {
	useContext,
	useEffect, useMemo,
	useState
} from "react";
import {
	Breadcrumb,
	Col, Empty,
	notification,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
	isEmpty,
} from "~/utils";
import {
	orderApi
} from "~/api";
import {AppContext} from "~/context/AppProvider";
import {
	Button,
	Image,
	Loading
} from "~/components";
import {
	InputField,
	InputPriceField,
	SelectField,
	TextAreaField
} from "~/components/Forms";
import * as Yup from "yup";
import {
	Controller,
	useFieldArray,
	useForm
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {v4 as uuidv4} from 'uuid';

const cn = className.bind(style);

const ShippingCart = ({item}) => {
	return (
		<div className={cn('card')}>
			<Image src={item.logo} />
		</div>
	)
}

function OrderImportEdit() {

	const {id} = useParams();

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [order, setOrder] = useState({});

	const {country, shippingCompanies} = useContext(AppContext);

	//Xử lý địa chỉ
	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	//Form
	const initialValues = {
		code: order?.code,
		toName: order?.toName,
		toPhone: order?.toPhone,
		toAddress: order?.toAddress,
		toCity: order?.toCity,
		toDistrict: order?.toDistrict,
		toWard: order?.toWard,
		products: [],
		weight: order?.weight,
		long: order?.long,
		height: order?.height,
		width: order?.width,
		cod: order?.cod,
		requiredNote: order?.requiredNote,
		paymentType: order?.paymentType,
		note: order?.note,
	}

	const validationSchema = Yup.object().shape({
		toName: Yup.string().required('Tên người nhận không được để trống'),
		toPhone: Yup.string().required('Số điện thoại người nhận không được để trống').matches(
			/(84|0[2|3|5|7|8|9])+([0-9]{7,8})\b/g,
			"Số điện thoại không đúng"
		),
		toAddress: Yup.string().required('Địa chỉ người nhận không được để trống'),
		toCity: Yup.string().required('Tỉnh thành người nhận không được để trống'),
		toDistrict: Yup.string().required('Quận huyện người nhận không được để trống'),
		toWard: Yup.string().required('Phường xã người nhận không được để trống'),
		products: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required('Tên sản phẩm không được bỏ trống'),
				quantity  : Yup.number().required('số lượng không được bỏ trống'),
				weight : Yup.number().required('Cân nặng không được bỏ trống')
			})
		),
		weight: Yup.number().required('Khối lượng đơn hàng không được để trống'),
		long: Yup.number().required('Chiều dài đơn hàng không được để trống'),
		height: Yup.number().required('Chiều cao đơn hàng không được để trống'),
		width: Yup.number().required('Chiều rộng đơn hàng không được để trống'),
	})

	const {control, watch, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "products"
	});

	const handleClick = {
		productsAdd: () => {
			append({ id: uuidv4(), title: undefined, quantity: 1, weight: 0.1});
		}
	}

	const loadOrder = async () => {
		let [error, response] = await handleRequest(orderApi.importGet(id));
		let message = apiError(`Lấy thông tin đơn hàng thất bại`, error, response);
		if(!message) {
			initialValues.code = response.data?.code;
			initialValues.toName = response.data?.toName;
			initialValues.toPhone = response.data?.toPhone;
			initialValues.toAddress = response.data?.toAddress;
			initialValues.toCity = response.data?.toCity;
			initialValues.toDistrict = response.data?.toDistrict;
			initialValues.toWard = response.data?.toWard;
			initialValues.weight= (Number.isInteger(response.data?.weight)) ? response.data?.weight : parseFloat(response.data?.weight.toFixed(2));
			initialValues.long= response.data?.long;
			initialValues.height= response.data?.height;
			initialValues.width= response.data?.width;
			initialValues.cod= response.data?.cod;
			initialValues.requiredNote= response.data?.requiredNote;
			initialValues.paymentType = (isEmpty(response.data?.paymentType)) ? 0 : parseInt(response.data?.paymentType);
			initialValues.note = response.data?.note;
			initialValues.products = [];
			if(Object.keys(response.data.products).length !== 0) {
				for (const [index, item] of Object.entries(response.data.products)) {
					initialValues.products.push({ id: item.id, title: item.title, quantity: item.quantity, weight:item.weight})
				}
			}
			reset(initialValues)

			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == initialValues.toCity) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == initialValues.toDistrict) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}

			setOrder(response.data)
			setErrorLoad(false)
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleWeightTotal = () => {
		const products = watch("products");
		let weight = 0;
		if(Object.keys(products).length !== 0) {
			for (const [index, item] of Object.entries(products)) {
				weight += item.quantity*item.weight;
			}
		}
		setValue('weight', (Number.isInteger(weight)) ? weight : parseFloat(weight.toFixed(2)))
	}

	const handleOrderSave = async (data) => {
		data.id = id;
		let [error, response] = await handleRequest(orderApi.importUpload(data));
		let message = apiError(`Cập nhật đơn hàng thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Cập nhật đơn hàng thành công`});
		}
	}

	useEffect(() => {
		loadOrder().then()
	}, [id]);

	if(loading) {
		return (
			<div className={cn('order-gird')}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={cn('order-gird')}>
				<Empty description={<span>Không có đơn hàng</span>}><Button to={'/order/import'}>Quay lại danh sách đơn hàng</Button></Empty>
			</div>
		)
	}

	return (
		<div className={cn('order-gird')}>
			<form className="form" onSubmit={handleSubmit(handleOrderSave)}>
				{isSubmitting && <Loading/>}
				<div className={cn('order-left')}>
					<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/order'}>Đơn hàng</Link>,},
				            {title: <Link to={'/order/import'}>Lên đơn excel</Link>,},
				            {title: 'Chỉnh sữa file',},
			            ]}
					/>
					<section>
						<h2 className={'heading'}>Bên Nhận</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="toName" render={({field}) => (
									<InputField label="Họ tên" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="toPhone" render={({ field }) => (
									<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="code" render={({ field }) => (
									<InputField label="Mã đơn hàng" errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="toAddress" render={({ field }) => (
									<InputField label="Địa chỉ" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="toCity" render={({ field }) => (
									<SelectField label="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
										handleCityChange(value);
										field.onChange(value, event);
									}}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="toDistrict" render={({ field }) => (
									<SelectField label="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
										handleDistrictChange(value);
										field.onChange(value, event);
									}}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="toWard" render={({ field }) => (
									<SelectField label="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className="heading">Sản phẩm</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Tên sản phẩm</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Khối lượng (Kg)</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Số lượng</label>
							</Col>
							<Col className="gutter-row" span={3}></Col>
						</Row>
						{fields.map(({ id, title, quantity, weight }, index) => {
							return (
								<Row gutter={10} key={id}>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].title`} render={({ field }) => (
											<InputField defaultValue={title} errors={errors} {...field} />
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].quantity`} render={({ field }) => (
											<InputField defaultValue={quantity} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}} />
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].weight`} render={({ field }) => (
											<InputField defaultValue={weight} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}} />
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Button primary type="button" className={'w-100'} onClick={() => remove(index)} >Xóa</Button>
									</Col>
								</Row>
							);
						})}
						<div className="d-flex justify-content-end">
							<Button blue background type="button" onClick={handleClick.productsAdd}>Thêm sản phẩm</Button>
						</div>

						<h2 className="heading">Thông tin gói hàng</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Tổng khối lượng</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Dài (cm)</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Rộng (cm)</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Cao (cm)</label>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="weight" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="long" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="height" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="width" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} />
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Cod</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="cod" render={({ field }) => (
									<InputPriceField label="Tiền thu hộ" errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={12}>
								<InputPriceField defaultValue={order?.insurance} label="Giá trị đơn hàng (Bảo hiểm)" disabled/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Lưu ý - ghi chú</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="requiredNote" render={({ field }) => (
									<SelectField label="Lưu ý giao hàng" options={[
										{value: 'KHONGCHOXEMHANG', label: 'Không cho xem hàng'},
										{value: 'CHOXEMHANGKHONGTHU', label: 'Được Đồng kiểm'},
										{value: 'CHOTHUHANG', label: 'Được đồng kiểm, được thử'},
									]} errors={errors} {...field} />
								)}/>
								<Controller control={control} name="paymentType" render={({ field }) => (
									<SelectField label="Tùy chọn thanh toán" options={[
										{value: 1, label: 'Bên gửi hàng trả phí'},
										{value: 2, label: 'Bên nhận hàng trả phí'},
									]} errors={errors} {...field} />
								)}/>
							</Col>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="note" render={({ field }) => (
									<TextAreaField label="Ghi chú" errors={errors} {...field} />
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Đợn vị vận chuyển</h2>
						<div className="d-flex">
							{
								(shippingCompanies[order?.shippingUnit]?.logo) && <ShippingCart item={shippingCompanies[order?.shippingUnit]} />
							}
						</div>
					</section>
				</div>
				<div className={cn('order-right')}>
					<div className={cn('order-bottom')}>
						<div className="text-center">
							<Button type="submit" background blue className="w-100 ml-0">Cập nhật</Button>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}
export default OrderImportEdit;