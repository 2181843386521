import request from "~/utils/http";

const coinApi = {
	user : async (id) => {
		const url = `v1/coin/user/${id}`;
		return await request.get(url);
	},
	plus : async (params) => {
		const url = `v1/coin/plus/${params.id}`;
		return await request.post(url, params);
	},
	minus : async (params) => {
		const url = `v1/coin/minus/${params.id}`;
		return await request.post(url, params);
	},
	getPercent : async (id) => {
		const url = `v1/coin/percent/${id}`;
		return await request.get(url);
	},
	updatePercent : async (params) => {
		const url = `v1/coin/percent/${params.id}`;
		return await request.post(url, params);
	},
	paymentOrder : async (params) => {
		const url = `v1/coin/payment-order/${params.id}`;
		return await request.post(url, params);
	},
	paymentCod : async (params) => {
		const url = `v1/coin/payment-cod/${params.id}`;
		return await request.post(url, params);
	},
	histories : async (params) => {
		const url = `v1/coin/histories`;
		return await request.get(url, params);
	},
	soonExpire : async (params) => {
		const url = `v1/coin/soon-expire`;
		return await request.get(url);
	},
};

export default coinApi;