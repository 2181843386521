import {memo} from "react";
import {Button, Icon} from "~/components";
function ButtonAction({isMobile, can, onModalOpen}) {
	return (
		<>
			{can.export && <Button background white className="ml-0" onClick={() => onModalOpen('export')}>{Icon.export} {!isMobile && 'Xuất đơn'}</Button>}
			{can.add && <Button background green className="ml-0" to={'/cod/add'}>{Icon.plus} {!isMobile && 'Tạo đối soát'} (File)</Button>}
			{can.addFilter && <Button background blue className="ml-0" onClick={() => onModalOpen('add')}>{!isMobile ? 'Tạo đối soát' : Icon.plus}</Button>}
		</>
	)
}

export default memo(ButtonAction);