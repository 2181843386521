import {useForm} from "react-hook-form";
import {yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	numberFormat
} from "~/utils"
import {useCurrentUser} from "~/hooks";
import {Alert} from "antd";

function OrderFormCoin({item, onHandleSubmit}) {

	const currentUser = useCurrentUser();

	const initialValues = {}

	const validationSchema = Yup.object().shape({})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<>
				<div className={'d-flex justify-content-between font-bold mb-2'}>
					<p className="mb-0" style={{'color': '#1bb394'}}>Phí ship:</p>
					<p className="mb-0">{numberFormat(item.shipping)} đ</p>
				</div>
				{
					item.returnFee !== 0 &&
					<div className={'d-flex justify-content-between font-bold mb-2'}>
						<p className="mb-0" style={{'color': 'rgb(108 28 198)'}}>Phí hoàn:</p>
						<p className="mb-0">{numberFormat(item.returnFee)} đ</p>
					</div>
				}
				<div className={'d-flex justify-content-between font-bold mb-2'}>
					<p className="mb-0" style={{'color': '#b20101'}}>Tổng phí dịch vụ:</p>
					<p className="mb-0">{numberFormat(item.shipping + item.returnFee)} đ</p>
				</div>
				<div className={'d-flex justify-content-between font-bold mb-2'}>
					<p className="mb-0">Số xu cần thanh toán:</p>
					<p className="mb-0" style={{'color': '#b20101'}}>{numberFormat(item.shipping + item.returnFee)} xu</p>
				</div>
			</>
			<hr/>
			<div className={'d-flex justify-content-between font-bold mb-2'}>
				<p className="mb-0">Số xu bạn hiện có:</p>
				<p className="mb-0" style={{'color': '#b20101'}}>{numberFormat(currentUser.coin + currentUser.coinSale)} xu</p>
			</div>
			{
				((item.shipping + item.returnFee) > (currentUser.coin + currentUser.coinSale)) ?
					<>
						<Alert message="Bạn không đủ xu để thanh toán cho đơn hàng này" type="warning" showIcon></Alert>
					</>
					:
					<div className="form-group d-flex justify-content-center modal-bottom">
						<Button primary type="submit">Thanh toán</Button>
					</div>
			}

		</form>
	)
}

export default OrderFormCoin;