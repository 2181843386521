import {memo} from "react";
import _ from "lodash"
import {
	SelectField,
	DateRangeField,
} from "~/components/Forms";
import {Button} from "~/components";
import {strToTime} from "~/utils";
import dayjs from "dayjs";
import {Controller, useForm} from "react-hook-form";

function OrderCodSearch({ filter, onSearchChange }) {

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {
		type: 'created'
	}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			page: 1,
		};
		if(!_.isEmpty(data.time)) {
			let timeNew = [...data.time]
			timeNew[0] = strToTime(timeNew[0]);
			timeNew[1] = strToTime(timeNew[1]);
			newFilter.time = {
				type: data.type,
				start: timeNew[0],
				end: timeNew[1],
			}
		}
		onSearchChange(newFilter);
	}

	return (
		<form className="form form-search d-flex justify-content-between gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="type" render={({field}) => (
				<SelectField value={filter.time.type} placeholder="" options={[
					{value: 'created', label: 'Ngày gửi'},
					{value: 'success', label: 'Ngày hoàn thành'},
				]} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="time" render={({field}) => (
				<DateRangeField defaultValue={[dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')]} showTime errors={errors} {...field} />
			)}/>
			<Button background primary>Áp dụng</Button>
		</form>
	)
}

export default memo(OrderCodSearch);