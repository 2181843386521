import style from '../../style/Order.module.scss';
import className from 'classnames/bind';
import OrderTableAction from "./OrderTableAction";
import {Link} from "react-router-dom";
import {
	useContext
} from "react";
import {
	StatusLabel,
	Button,
	Image
} from "~/components";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
	numberFormat,
	isEmpty,
	renderAddress
} from "~/utils";
import {
	Badge,
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
	LIST_ORDER_STATUS,
} from "~/app/constants";

const cn = className.bind(style);

function OrderTable({items = [], pagination, setItemEdit, openModal, onPaginationChange, onLimitChange, can, selectedRowKeys, setSelectedRowKeys, currentUser}) {

	const {tableHeight, country, shippingCompanies, isMobile} = useContext(AppContext);

	const ButtonSync = ({item}) => {
		return (
			<div>
				{isEmpty(item?.trackingNumber) && (can.isAdmin ? <Button primary small onClick={() => onclickSync(item)}>Đồng bộ lại</Button> : <p>Lỗi đồng bộ</p>)}
			</div>
		)
	}

	const OrderMobile = ({item}) => {
		return (
			<>
				<div className={'d-flex justify-content-between'}>
					<p className="mb-1"><Link to={'/order/edit/'+item.id} className="color-red font-bold">{!isEmpty(item?.trackingNumber) ? item.trackingNumber : '#'+item.code}</Link></p>
					<p className="color-grey text-xs">{renderDate(item?.created)}</p>
				</div>

				<div className={'d-flex justify-content-between'}>
					{(!isEmpty(item?.shippingUnit)) && <Image src={shippingCompanies[item.shippingUnit]?.logo} />}
					<StatusLabel small type={LIST_ORDER_STATUS[item.status]?.color} border>{LIST_ORDER_STATUS[item.status]?.title}</StatusLabel>
				</div>

				<ButtonSync item={item} />

				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				<p>{item?.toAddress + ', ' + renderAddress(
					item?.toCity,
					item?.toDistrict,
					item?.toWard,
					country
				)}</p>

				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#1bb394'}}>Phí ship:</p>
					{
						(item.paymentCoin == 0) ? <p className="mb-0">{numberFormat(item.shipping)} đ</p> :
							<p className="mb-0">Đã thanh toán bằng xu</p>
					}
				</div>
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#1c84c6'}}>Thu hộ (cod):</p>
					<p className="mb-0">{ numberFormat(item.cod)} đ</p>
				</div>
				{
					item.returnFee !== 0 &&
					<div className={'d-flex justify-content-between font-bold'}>
						<p className="mb-0" style={{'color':'rgb(108 28 198)'}}>Phí hoàn:</p>
						<p className="mb-0">{ numberFormat(item.returnFee)} đ</p>
					</div>
				}
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#b20101'}}>Tổng thu:</p>
					<p className="mb-0">{numberFormat(item.total)} đ</p>
				</div>
				<p className="mb-1 italic">{ item.paymentType == 1 ? 'Bên gửi hàng trả phí' : 'Bên nhận hàng trả phí'}</p>
				<OrderTableAction item={item} setItemEdit={setItemEdit} openModal={openModal} can={can} currentUser={currentUser}/>
			</>
		)
	}

	const onclickSync = async (item) => {
		setItemEdit(item)
		openModal('sync')
	}

	const LabelCode = ()  => { return (<>Mã đơn <Badge count={selectedRowKeys.length} overflowCount={500} /></>)}

	let columns = [
		{ title: <LabelCode />, dataIndex: 'code', key: "code", width: '250px', render: (_, item) => (
			<>
				{
					!isMobile ? (
							<>
								<p className="mb-1"><Link to={'/order/edit/'+item.id} className="color-red font-bold">{!isEmpty(item?.trackingNumber) ? item.trackingNumber : '#'+item.code}</Link></p>
								<ButtonSync item={item} />
								{(!isEmpty(item?.shippingUnit)) && <p><Image src={shippingCompanies[item.shippingUnit]?.logo} /></p>}
								{item?.type == 'return' ? <StatusLabel small type="green">Đơn thu hồi</StatusLabel> : (item?.shop ? item?.shop.name : 'Không có cửa hàng')}
								<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
							</>
						)
						: <OrderMobile item={item} />
				}
			</>
		)},
		{ title: 'Bên gửi', dataIndex: 'from', key: "from", render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p>{item?.fromPhone}</p>
			</>
		)},
		{ title: 'Bên nhận', dataIndex: 'to', key: "to", width: '400px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				<p>{item?.toAddress + ', ' + renderAddress(
					item?.toCity,
					item?.toDistrict,
					item?.toWard,
					country
				)}</p>
			</>
		)},
		{ title: 'Cước phí', dataIndex: 'price', key: "price", width: '300px', render: (_, item) => (
			<>
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#1bb394'}}>Phí ship:</p>
					{
						(item.paymentCoin == 0) ? <p className="mb-0">{numberFormat(item.shipping)} đ</p> :
							<p className="mb-0">Thanh toán bằng xu</p>
					}
				</div>
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#1c84c6'}}>Thu hộ (cod):</p>
					<p className="mb-0">{ numberFormat(item.cod)} đ</p>
				</div>
				{
					item.returnFee !== 0 &&
					<div className={'d-flex justify-content-between font-bold'}>
						<p className="mb-0" style={{'color':'rgb(108 28 198)'}}>Phí hoàn:</p>
						<p className="mb-0">{ numberFormat(item.returnFee)} đ</p>
					</div>
				}
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#b20101'}}>Tổng thu:</p>
					<p className="mb-0">{numberFormat(item.total)} đ</p>
				</div>

				<p className="mb-0 italic">{ item.paymentType == 1 ? 'Bên gửi hàng trả phí' : 'Bên nhận hàng trả phí'}</p>
				<p className="mb-0 color-grey" style={{fontSize:'12px', fontWeight: '400'}}>(Bao gồm COD)</p>
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", render: (_, item) => (
			<div>
				<p className="mb-1"><StatusLabel small type={LIST_ORDER_STATUS[item.status]?.color} border>{LIST_ORDER_STATUS[item.status]?.title}</StatusLabel></p>
				{(item.verifyId === 0) ? <StatusLabel small border type="red">Chưa đối soát</StatusLabel> : <StatusLabel small type="blue">{renderDate(item.verifyTime)}</StatusLabel> }
			</div>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '220px', render: (_, item) => (
			<OrderTableAction item={item} setItemEdit={setItemEdit} openModal={openModal} can={can} currentUser={currentUser}/>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'from'
				&& currentValue.key != 'to'
				&& currentValue.key != 'price'
				&& currentValue.key != 'status'
				&& currentValue.key != 'action'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className={cn("order-table", "table-box")}>
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={onLimitChange} />
			</div>

		</div>
	)
}

export default OrderTable;