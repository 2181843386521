import { all } from 'redux-saga/effects';
import authSaga from '~/features/Auth/authSaga';
import orderCash from '~/features/Home/orderCashSaga';
import orderSaga from '~/features/Order/orderSaga';
import orderHistorySaga from '~/features/OrderHistory/orderHistorySaga';
import importHistorySaga from '~/features/Order/importHistorySaga';
import importHistoryOrderSaga from '~/features/Order/importHistoryOrderSaga';
import branchSaga from '~/features/Branch/branchSaga';
import orderCodSaga from '~/features/Cod/orderCodSaga';
import codSaga from '~/features/Cod/codSaga';
import customerSaga from '~/features/Customer/customerSaga';
import ticketSaga from '~/features/Ticket/ticketSaga';
import userSaga from '~/features/User/userSaga';
import userVerifySaga from '~/features/User/userVerifySaga';
import userLoginAsSaga from '~/features/User/userLoginAsSaga';
import shippingPriceSaga from '~/features/ShippingPrice/shippingPriceSaga';
import postOfficeSaga from '~/features/PostOffice/postOfficeSaga';
import affiliateSaga from '~/features/Report/affiliateSaga';
import reportUserSaga from '~/features/Report/reportUserSaga';
import reportCoinSaga from '~/features/Report/reportCoinSaga';
import discountSaga from '~/features/Discount/discountSaga';

export default function* rootSaga() {
	yield all([
		authSaga(),
		orderSaga(),
		orderHistorySaga(),
		branchSaga(),
		orderCodSaga(),
		codSaga(),
		orderCash(),
		importHistorySaga(),
		importHistoryOrderSaga(),
		customerSaga(),
		ticketSaga(),
		userSaga(),
		userVerifySaga(),
		userLoginAsSaga(),
		shippingPriceSaga(),
		postOfficeSaga(),
		affiliateSaga(),
		reportUserSaga(),
		reportCoinSaga(),
		discountSaga(),
	]);
}