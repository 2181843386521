import {useState} from "react";
import {Col, notification, Row} from "antd";
import {
	Button,
	Loading,
} from "~/components";
import {
	InputField,
} from "~/components/Forms";
import {
	apiError,
	handleRequest,
} from "~/utils";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {discountApi} from "~/api";
import {useDispatch} from "react-redux";
import {authActions} from "../../../Auth/authSlice";

function AccountFormDiscount({currentUser}) {

	const dispatch = useDispatch();

	const [success, setSuccess] = useState(false)

	const [coin, setCoin] = useState(0)

	const [code, setCode] = useState('')

	//Form
	const initialValues = {
		discountCode: '',
	}

	const validationSchema = Yup.object().shape({
		discountCode: Yup.string().required('Bạn chưa nhập mã giảm giá'),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleSave = async (data) => {
		if (currentUser?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thành viên để lưu'});
			return;
		}
		let [error, response] = await handleRequest(discountApi.useCode(data.discountCode));

		let message = apiError(`Sử dụng mã khuyến mãi thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: 'Thành công'});
			dispatch(authActions.update({
				...currentUser,
				coin: response?.data.coin,
				coinSale: response?.data.coinSale,
			}));
			setCoin(response.data.coinPlus)
			setCode(response.data.code)
			setSuccess(true)
		}
	}

	return (
		<form className="form" onSubmit={handleSubmit(handleSave)}>
			{isSubmitting && <Loading noFixed />}
			<Row gutter={10}>
				<Col className="gutter-row mt-2" md={24} xs={24}>
					<h2 className={'heading'}>Sử dụng mã khuyến mãi</h2>
					<div className="d-flex gap-1 align-items-start">
						<Controller control={control} name="discountCode" render={({field}) => (
							<InputField placeholder="Mã khuyến mãi" className="mb-0" errors={errors} {...field}/>
						)}/>
						<Button background red className="">Áp Dụng</Button>
					</div>
				</Col>
				<Col className="gutter-row" md={24} xs={24}>
					{
						success && <div className={'text-center mt-4 mb-4'}>
							<p className={'font-bold color-green'} style={{fontSize:"25px"}}>CHÚC MỪNG!</p>
							<p>Bạn vừa nhận được <span className={'font-bold color-red'} style={{fontSize:"20px"}}>{coin}</span> xu từ mã khuyến mãi <span className={'font-bold color-blue'}>{code}</span></p>
						</div>
					}
				</Col>
			</Row>
		</form>
	)
}

export default AccountFormDiscount;