import request from "~/utils/http";

const discountApi = {
	gets : async (params) => {
		const url = 'v1/discount';
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.get(url, {params});
	},
	get : async (id) => {
		const url = 'v1/discount/info/'+id;
		return await request.get(url, {callForm : 'web'});
	},
	countStatus : async (params) => {
		const url = `v1/discount/count-status`;
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'v1/discount';
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.post(url, params);
	},
	status : async (params) => {
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		const url = `v1/discount/status/${params.id}`;
		return await request.post(url, params);
	},
	useCode : async (code) => {
		const url = `v1/discount/use/${code}`;
		return await request.post(url, {callForm : 'web'});
	},
	stop : async (params) => {
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		const url = `v1/discount/stop/${params.id}`;
		return await request.post(url, params);
	},
	running : async (params) => {
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		const url = `v1/discount/running/${params.id}`;
		return await request.post(url, params);
	},
};

export default discountApi;