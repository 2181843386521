import {useContext, useMemo, useState} from "react";
import {Col, notification, Row} from "antd";
import {
	Button,
	Loading,
} from "~/components";
import {
	InputField,
	SelectField
} from "~/components/Forms";
import {
	apiError,
	handleRequest
} from "~/utils";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {AppContext} from "~/context/AppProvider";
import {authApi} from "~/api";
import {useDispatch} from "react-redux";
import {authActions} from "../../../Auth/authSlice";

function AccountFormInfo({currentUser}) {

	const dispatch = useDispatch();

	const {country} = useContext(AppContext);

	//Xử lý địa chỉ
	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	//Form
	const initialValues = {
		firstname: currentUser?.firstname,
		lastname: currentUser?.lastname,
		email: currentUser?.email,
		address: currentUser?.address,
		city: currentUser?.city,
		district: currentUser?.district,
		ward: currentUser?.ward,
	}

	const validationSchema = Yup.object().shape({
		firstname: Yup.string().required('Họ chữ đệm không được để trống'),
		lastname: Yup.string().required('Tên không được để trống'),
		email: Yup.string().required('Email không được để trống'),
		address: Yup.string().required('Địa chỉ không được để trống'),
		city: Yup.string().required('Tỉnh thành không được để trống'),
		district: Yup.string().required('Quận huyện không được để trống'),
		ward: Yup.string().required('Phường xã không được để trống'),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleSave = async (data) => {
		if (currentUser?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thành viên để lưu'});
			return;
		}
		data.id = currentUser.id;

		let [error, response] = await handleRequest(authApi.update(data));

		let message = apiError(`Thay đổi trạng thái thành viên thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: 'Lưu thông tin thành viên thành công'});
			dispatch(authActions.update({
				...currentUser,
				firstname: data?.firstname,
				lastname: data?.lastname,
				email: data?.email,
				address: data?.address,
				city: data?.city,
				district: data?.district,
				ward: data?.ward,
			}));
		}
	}

	return (
		<form className="form" onSubmit={handleSubmit(handleSave)}>
			{isSubmitting && <Loading noFixed />}
			<h2 className={'heading'}>Thông tin thành viên</h2>
			<Row gutter={10}>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="firstname" render={({field}) => (
						<InputField label="Họ chữ đệm" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="lastname" render={({field}) => (
						<InputField label="Tên" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={6} xs={12}>
					<InputField value={currentUser?.phone} label="Số điện thoại" disabled/>
				</Col>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField label="Email" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="address" render={({ field }) => (
						<InputField label="Địa chỉ" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="city" render={({ field }) => (
						<SelectField label="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
							handleCityChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="district" render={({ field }) => (
						<SelectField label="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
							handleDistrictChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" md={6} xs={12}>
					<Controller control={control} name="ward" render={({ field }) => (
						<SelectField label="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<div className="text-right mt-1">
				<Button background blue>Lưu thông tin</Button>
			</div>
		</form>
	)
}

export default AccountFormInfo;