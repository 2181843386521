import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	numberFormat
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {Link} from "react-router-dom";

function ReportCoinTable({items = [], pagination, setItemEdit, handleEvent, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Thành viên', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold"><Link to={'/admin/users/edit/'+item?.user?.id}>{item?.user?.username}</Link></p>
				<p className="font-bold"><Link to={'/admin/users/edit/'+item?.user?.id}>{item?.user?.firstname + ' ' + item?.user?.lastname}</Link></p>
			</>
		)},
		{ title: 'Liên hệ', dataIndex: 'contact', key: "contact", width: '100px', render: (_, item) => (
			<>
				<p><Link to={'/admin/users/edit/'+item?.user?.id}>{item?.user?.email}</Link></p>
				<p><Link to={'/admin/users/edit/'+item?.user?.id}>{item?.user?.phone}</Link></p>
			</>
		)},
		{ title: 'Đơn hoàn thành', dataIndex: 'orderCount', key: "orderCount", width: '140px', render: (_, item) => (
			<p>{item?.orderCount}</p>
		)},
		{ title: 'Doanh số', dataIndex: 'orderTotal', key: "orderTotal", width: '200px', render: (_, item) => (
			<p>{numberFormat(item?.orderTotal)}</p>
		)},
		{ title: 'Hoa hồng', dataIndex: 'percent', key: "percent", render: (_, item) => (
			<p>{numberFormat(item?.percent)}</p>
		)},
		{ title: 'Xu', dataIndex: 'coin', key: "coin", render: (_, item) => (
			<p>{numberFormat(item?.coin)}</p>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", render: (_, item) => (
			<>
				{
					item?.closed == 1 ? <StatusLabel small type="green" border>Đã chốt</StatusLabel> : <StatusLabel small type="red" border>Chưa chốt</StatusLabel>
				}
			</>
		)}
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default ReportCoinTable;