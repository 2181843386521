export const DATA_LIMIT = 50;

export const DATA_LIMIT_KEY = '_sp_ex_limit';

export const ORDER_PENDING          = 'pending';
export const ORDER_PICKUP_FAIL      = 'pickup-fail';
export const ORDER_CONFIRM          = 'confirm';
export const ORDER_SHIPPING         = 'shipping';
export const ORDER_WAIT_RETURN      = 'waiting-return';
export const ORDER_RETURN_TRIGGER   = 'return-trigger';
export const ORDER_RETURN           = 'return';
export const ORDER_COMPLETED        = 'completed';
export const ORDER_LOST             = 'lost';
export const ORDER_CANCELLED        = 'cancelled';
export const LIST_ORDER_STATUS = {
	'pending': {
		title: "Chờ lấy hàng",
		color: 'grey',
	},
	'pickup-fail': {
		title: "Lấy hàng thất bại",
		color: 'red-mint',
	},
	'confirm': {
		title: "Đã lấy hàng",
		color: 'green-mint',
	},
	'shipping': {
		title: "Đang vận chuyển",
		color: 'blue',
	},
	'waiting-return': {
		title: "Chờ giao lại",
		color: 'yellow',
	},
	'return-trigger': {
		title: "Chờ hoàn",
		color: 'blue-mint',
	},
	'return': {
		title: "Đơn hoàn",
		color: 'blue-bold',
	},
	'completed': {
		title: "Hoàn thành",
		color: 'green',
	},
	'lost': {
		title: "Thất lạc - hư hỏng",
		color: 'red',
	},
	'cancelled': {
		title: "Đã hủy",
		color: 'red',
	},
}
export const LIST_TICKET_STATUS = {
	'pending': {
		title: "Chưa xử lý",
		color: 'yellow',
	},
	'wait': {
		title: "Đang xử lý",
		color: 'blue',
	},
	'success': {
		title: "Đã xử lý",
		color: 'green',
	},
	'cancel': {
		title: "Không xử lý",
		color: 'red',
	},
}
export const LIST_TICKET_TYPE = {
	'banks': {
		title: "Thay đổi thông tin ngân hàng",
	},
	'cod': {
		title: "Yêu cầu chỉnh sửa COD",
		color: 'yellow',
	},
	'resend': {
		title: "Yêu cầu phát lại",
		color: 'green',
	},
	'change_receive': {
		title: "Chỉnh sửa thông tin người nhận",
		color: 'red',
	},
	'complain': {
		title: "Khiếu nại/Đền bù",
		color: 'red',
	},
	'return_order': {
		title: "Yêu cầu duyệt hoàn đơn",
		color: 'red',
	},
	'orther': {
		title: "Khác",
		color: 'red',
	},
}

export const LIST_USER_ROLE = {
	'administrator': {
		title: "Admin",
	},
	'customer': {
		title: "Khách hàng",
		color: 'yellow',
	},
	'staff': {
		title: "Sales",
		color: 'green',
	},
	'manager': {
		title: "Đại lý",
		color: 'red',
	},
	'accountant': {
		title: "Chăm sóc khách hàng",
		color: 'red',
	},
}
export const LIST_USER_STATUS = {
	'pending': {
		title: "Đợi duyệt",
		color: 'yellow',
	},
	'public': {
		title: "Hoạt động",
		color: 'green',
	},
	'block': {
		title: "Đã khóa",
		color: 'red',
	},
}

export const LIST_DISCOUNT_STATUS = {
	'wait': {
		title: "Đợi chạy",
		color: 'blue',
	},
	'running': {
		title: "Đang khuyến mãi",
		color: 'green',
	},
	'stop': {
		title: "Tạm dừng",
		color: 'yellow',
	},
	'cancel': {
		title: "Đã hết hạn",
		color: 'red',
	},
}