import {memo} from "react";
import _ from "lodash";
import {
	InputField
} from "~/components/Forms";
import {Button, Icon} from "~/components";
import {Pagination} from "antd";

function DiscountUserSearch({filter, setFilter, users, handleEvent, pagination}) {

	const onchangeKeyword = (value) => {
		setFilter({
			keyword: value.target.value
		})
	}

	const onSubmit = async () => {
		await handleEvent.searchUser()
	}

	return (
		<>
			<p className={'heading'}>Thành viên</p>
			<div className="d-flex gap align-items justify-content-between">
				<InputField className={'w-100'} placeholder={'Số điện thoại, Tên đăng nhập, mã giới thiệu'} defaultValue={filter?.keyword} onChange={onchangeKeyword} />
				<Button type={"button"} background primary className="mb-1" onClick={onSubmit}>{Icon.search}</Button>
			</div>
			<hr />
			<div style={{overflow:'auto', maxHeight:'300px'}}>
			{
				(!_.isEmpty(users)) && users.map((item) => {
					return <div key={`login-as-${item.id}`} className="d-flex gap align-items justify-content-between mb-1 w-100">
						<div>
							<p><b>{item?.firstname + ' ' + item?.lastname}</b></p>
							<p className={'mb-1'}>{item?.username}</p>
						</div>
						<div>
							<Button type={"button"} background blue onClick={() => handleEvent.setUserChose(item)}>Chọn</Button>
						</div>
					</div>
				})
			}
			</div>
			<Pagination className={'mt-2 mb-2'} current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
		</>
	)
}

export default memo(DiscountUserSearch);