import {
	useContext
} from "react";
import {
	Button,
	Icon
} from "~/components";
import {
	Table, Tooltip
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	renderAddress,
} from "~/utils";
import DOMPurify from "dompurify";

function PostOfficeTableImport({items = [], type, onSubmit, onDelete}) {

	const {country} = useContext(AppContext);

	const {tableHeight} = useContext(AppContext);

	const tooltipContent = (note) => {
		return (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${note}`) }} />)
	}

	let columns = [
		{ title: 'Mã bưu cục', dataIndex: 'code', key: "code", render: (_, item) => (
			<>
				<p className="font-bold">{item?.code}</p>
				{
					(type == 'failed' && item?.errors) && <Tooltip title={tooltipContent(item?.errors)}>
						<Button background red type="button" small>Chi tiết lỗi</Button>
					</Tooltip>
				}
			</>

		)},
		{ title: 'Tên bưu cục', dataIndex: 'name', key: "name", width: '250px', render: (_, item) => (
			<p className="font-bold">{item?.name}</p>
		)},
		{ title: 'Tên người đại diện', dataIndex: 'person', key: "person", width: '250px', render: (_, item) => (
				<p>{item?.person}</p>
			)},
		{ title: 'Email', dataIndex: 'email', key: "email", render: (_, item) => (
			<p className="mb-0">{item?.email}</p>
		)},
		{ title: 'Số điện thoại', dataIndex: 'phone', key: "phone", render: (_, item) => (
			<p className="mb-0">{item?.phone}</p>
		)},
		{ title: 'Địa chỉ', dataIndex: 'address', key: "address", width: '400px', render: (_, item) => (
			<>
				<p>{item?.address}</p>
				<p className="italic color-red">{renderAddress(item?.city, item?.district, item?.ward, country)}</p>
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '100px', render: (_, item) => (
			<Button leftIcon={Icon.delete} background primary onClick={() => onDelete(item)}>Xóa</Button>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="text-right">
				{ type == 'passed' && <Button background primary onClick={onSubmit}>Tạo bưu cục</Button> }
				{ type == 'failed' && <Button background primary onClick={onSubmit}>Xóa tất cả</Button>}
			</div>
		</div>
	)
}

export default PostOfficeTableImport;