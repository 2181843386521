import { Navigate } from 'react-router-dom';
import Home from "~/features/Home/pages";
import CashFlow from "~/features/Home/pages/CashFlow";
import PageNotFound from "../features/NotFound/pages";
import Order from "../features/Order/pages/Order";
import OrderAdd from "../features/Order/pages/OrderAdd";
import OrderEdit from "../features/Order/pages/OrderEdit";
import OrderReturn from "../features/Order/pages/OrderReturn";
import OrderUpdateCod from "../features/Order/pages/OrderCod";
import OrderWeight from "../features/Order/pages/OrderWeight";
import OrderFee from "../features/Order/pages/OrderFee";
import OrderStatus from "../features/Order/pages/OrderStatus";
import OrderImport from "../features/Order/pages/OrderImport";
import OrderImportEdit from "../features/Order/pages/OrderImportEdit";
import OrderImportHistory from "../features/Order/pages/OrderImportHistory";
import OrderHistory from "../features/OrderHistory/pages/OrderHistory";
import Branch from "../features/Branch/pages/Branch";
import Cod from "../features/Cod/pages/Cod";
import CodAdd from "../features/Cod/pages/CodAdd";
import CodDetail from "../features/Cod/pages/CodDetail";
import OrderCod from "../features/Cod/pages/OrderCod";
import Customer from "../features/Customer/pages/Customer";
import CustomerImport from "../features/Customer/pages/CustomerImport";
import PostOffice from "../features/PostOffice/pages/PostOffice";
import PostOfficeImport from "../features/PostOffice/pages/PostOfficeImport";
import Ticket from "../features/Ticket/pages/Ticket";
import Discount from "../features/Discount/pages/Discount";
import DiscountAdd from "../features/Discount/pages/DiscountAdd";
import DiscountInfo from "../features/Discount/pages/DiscountInfo";
import User from "../features/User/pages/User";
import UserVerify from "../features/User/pages/UserVerify";
import UserEdit from "../features/User/pages/UserEdit";
import UserBlockOrder from "../features/User/pages/UserBlockOrder";
import ShippingPrice from "../features/ShippingPrice/pages/ShippingPrice";
import ShippingPriceAdd from "../features/ShippingPrice/pages/ShippingPriceAdd";
import ShippingPriceEdit from "../features/ShippingPrice/pages/ShippingPriceEdit";
import ShippingPriceUser from "../features/ShippingPrice/pages/ShippingPriceUser";
import Permission from "../features/Permission/pages/Permission";
import AccountInfo from "../features/Account/pages/AccountInfo";
import AccountPassword from "../features/Account/pages/AccountPassword";
import AccountBanks from "../features/Account/pages/AccountBanks";
import AccountApi from "../features/Account/pages/AccountApi";
import AccountCoin from "../features/Account/pages/AccountCoin";
import AccountRecharge from "../features/Account/pages/AccountRecharge";
import ReportAffiliate from "../features/Report/pages/ReportAffiliate";
import ReportAffiliateChart from "../features/Report/pages/ReportAffiliateChart";
import ReportUser from "../features/Report/pages/ReportUser";
import ReportCoin from "../features/Report/pages/ReportCoin";
import ReportRegion from "../features/Report/pages/ReportRegion";

export const privateRoutes = [
	{ path: "/", component: Home },
	{ path: "*", component: PageNotFound },
	{ path: "/cash-flow", component: CashFlow },
	{ path: "/branch", component: Branch },
	{ path: "/order", component: Order },
	{ path: "/order/add", component: OrderAdd },
	{ path: "/order/edit/:id", component: OrderEdit },
	{ path: "/order/created-return/:id", component: OrderReturn },
	{ path: "/order/update-cod", component: OrderUpdateCod },
	{ path: "/order/update-weight", component: OrderWeight },
	{ path: "/order/update-fee", component: OrderFee },
	{ path: "/order/update-status", component: OrderStatus },
	{ path: "/order/:status", component: Order },
	{ path: "/order/import", component: OrderImport },
	{ path: "/order/import-history", component: OrderImportHistory },
	{ path: "/order/import/:id", component: OrderImportEdit },
	{ path: "/order/history", component: OrderHistory },
	{ path: "/cod/order", component: OrderCod },
	{ path: "/cod/add", component: CodAdd },
	{ path: "/cod/:status", component: Cod },
	{ path: "/cod/detail/:id", component: CodDetail },
	{ path: "/customer", component: Customer },
	{ path: "/customer/import", component: CustomerImport },
	{ path: "/post-office", component: PostOffice },
	{ path: "/post-office/import", component: PostOfficeImport },
	{ path: "/ticket", component: Ticket },
	{ path: "/ticket/:status", component: Ticket },

	{ path: "/discount", component: Discount },
	{ path: "/discount/add", component: DiscountAdd },
	{ path: "/discount/:status", component: Discount },
	{ path: "/discount/info/:id", component: DiscountInfo },

	{ path: "/users", component: User },
	{ path: "/users/block-order", component: UserBlockOrder },
	{ path: "/users/:role", component: User },
	{ path: "/users/edit/:id", component: UserEdit },
	{ path: "/users/verify/:verify", component: UserVerify },
	{ path: "/admin/shipping-price", component: ShippingPrice },
	{ path: "/admin/shipping-price/add", component: ShippingPriceAdd },
	{ path: "/admin/shipping-price/edit/:id", component: ShippingPriceEdit },
	{ path: "/admin/shipping-price/user/:id", component: ShippingPriceUser },
	{ path: "/admin/roles", component: Permission },

	{ path: "/account/info", component: AccountInfo },
	{ path: "/account/password", component: AccountPassword },
	{ path: "/account/banks", component: AccountBanks },
	{ path: "/account/api", component: AccountApi },
	{ path: "/account/coin", component: AccountCoin },
	{ path: "/account/recharge", component: AccountRecharge },

	{ path: "/report/affiliate", component: ReportAffiliate },
	{ path: "/report/affiliate/:id", component: ReportAffiliateChart },
	{ path: "/report/users", component: ReportUser },
	{ path: "/report/ops", component: Home },
	{ path: "/report/cash-flow", component: CashFlow },
	{ path: "/report/coin", component: ReportCoin },
	{ path: "/report/region", component: ReportRegion },
];

export function PrivateRoutes({children}) {
	// Check if user is logged in
	// If yes, show route
	// Otherwise, redirect to login page
	const isLoggedIn = Boolean(localStorage.getItem('access_token'));

	if (!isLoggedIn) return <Navigate to="/login" />;

	return children;
}