import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Row } from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	InputField,
	SelectField
} from "~/components/Forms";

function CustomerFormAddEdit({country, item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	let cityOptions = [];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const initialValues = {
		name: undefined,
		phone: undefined,
		email: undefined,
		address: undefined,
		city: undefined,
		district: undefined,
		ward: undefined,
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Tên khách hàng không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		address: Yup.string().required('Địa chỉ không được để trống'),
		city: Yup.string().required('Tỉnh thành không được để trống'),
		district: Yup.string().required('Quận huyện không được để trống'),
		ward: Yup.string().required('Phường xã không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	//Thay đổi tỉnh thành
	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	useEffect(() => {
		if(item?.id) {

			initialValues.name      = item.name;
			initialValues.phone     = item.phone;
			initialValues.email     = item.email;
			initialValues.address   = item.address;
			initialValues.city      = item.city;
			initialValues.district  = item.district;
			initialValues.ward      = item.ward;

			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == item.city) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == item.district) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin khách hàng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={24}>
					<Controller control={control} name="name" render={({field}) => (
						<InputField label="Tên khách hàng" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phone" render={({ field }) => (
						<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={24}>
					<Controller control={control} name="address" render={({ field }) => (
						<InputField label="Địa chỉ" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="city" render={({ field }) => (
						<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
							handleCityChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="district" render={({ field }) => (
						<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
							handleDistrictChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="ward" render={({ field }) => (
						<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm khách hàng'}</Button>
			</div>
		</form>
	)
}

export default CustomerFormAddEdit;