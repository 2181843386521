import style from '~/features/Order/style/Order.module.scss';
import className from 'classnames/bind';
import {Link} from "react-router-dom";
import {
	useContext
} from "react";
import {
	StatusLabel,
	Image
} from "~/components";
import {
	renderDate,
	numberFormat,
	isEmpty,
	renderAddress
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	LIST_ORDER_STATUS
} from "~/app/constants";

const cn = className.bind(style);

function OrderTable({items = [], pagination, onPaginationChange}) {

	const {tableHeight, country, shippingCompanies} = useContext(AppContext);

	let columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '200px', render: (_, item) => (
			<>
				<p className="mb-1"><Link to={'/order/edit/'+item.id} className="color-red font-bold">{!isEmpty(item?.trackingNumber) ? item.trackingNumber : '#'+item.code}</Link></p>
				{(!isEmpty(item?.shippingUnit)) && <p><Image src={shippingCompanies[item.shippingUnit]?.logo} /></p>}
				{item?.type == 'return' ? <StatusLabel small type="green">Đơn thu hồi</StatusLabel> : (item?.shop ? item?.shop.name : 'Không có cửa hàng')}
				<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
			</>
		)},
		{ title: 'Bên gửi', dataIndex: 'from', key: "from", render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p>{item?.fromPhone}</p>
			</>
		)},
		{ title: 'Bên nhận', dataIndex: 'to', key: "to", width: '400px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				<p>{item?.toAddress + ', ' + renderAddress(
					item?.toCity,
					item?.toDistrict,
					item?.toWard,
					country
				)}</p>
			</>
		)},
		{ title: 'Cước phí', dataIndex: 'price', key: "price", width: '300px', render: (_, item) => (
			<>
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#1bb394'}}>Phí ship:</p>
					<p className="mb-0">{ numberFormat(item.shipping)} đ</p>
				</div>
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#1c84c6'}}>Thu hộ (cod):</p>
					<p className="mb-0">{ numberFormat(item.cod)} đ</p>
				</div>
				{
					item.returnFee !== 0 &&
					<div className={'d-flex justify-content-between font-bold'}>
						<p className="mb-0" style={{'color':'rgb(108 28 198)'}}>Phí hoàn:</p>
						<p className="mb-0">{ numberFormat(item.returnFee)} đ</p>
					</div>
				}
				<div className={'d-flex justify-content-between font-bold'}>
					<p className="mb-0" style={{'color':'#b20101'}}>Tổng thu:</p>
					<p className="mb-0">{numberFormat(item.total)} đ</p>
				</div>

				<p className="mb-0 italic">{ item.paymentType == 1 ? 'Bên gửi hàng trả phí' : 'Bên nhận hàng trả phí'}</p>
				<p className="mb-0 color-grey" style={{fontSize:'12px', fontWeight: '400'}}>(Bao gồm COD)</p>
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", render: (_, item) => (
			<div>
				<p className="mb-1"><StatusLabel small type={LIST_ORDER_STATUS[item.status]?.color} border>{LIST_ORDER_STATUS[item.status]?.title}</StatusLabel></p>
				{(item.verifyId === 0) ? <StatusLabel small border type="red">Chưa đối soát</StatusLabel> : <StatusLabel small type="blue">{renderDate(item.verifyTime)}</StatusLabel> }
			</div>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className={cn("order-table", "table-box")}>
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
			</div>
		</div>
	)
}

export default OrderTable;