import {
	postOfficeActions,
	postOfficeErrorSelector,
	postOfficeFilterSelector,
	postOfficeItemsSelector,
	postOfficeLoadingSelector,
	postOfficePaginationSelector
} from "../postOfficeSlice";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {notification} from "antd";
import {
	SearchMobile,
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import {
	useCan
} from "~/hooks";
import {
	PostOfficeFormAddEdit,
	PostOfficeSearch,
	PostOfficeTable
} from "../components";
import {
	DATA_LIMIT_KEY
} from "~/app/constants";
import {AppContext} from "~/context/AppProvider";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	postOfficeApi
} from "~/api";

function PostOffice() {

	const {country} = useContext(AppContext);

	const can = {
		add: useCan('shop_postOffice_add'),
		edit: useCan('shop_postOffice_edit'),
		delete: useCan('shop_postOffice_delete'),
	}

	const dispatch = useDispatch();

	const items = useSelector(postOfficeItemsSelector);

	const loading = useSelector(postOfficeLoadingSelector);

	const error = useSelector(postOfficeErrorSelector);

	const pagination = useSelector(postOfficePaginationSelector);

	const filter = useSelector(postOfficeFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [searchBar, setSearchBar] = useState(false)

	//Modal show
	const [openModal, setOpenModal] = useState({
		addEdit : false,
		delete  : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(postOfficeActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Add Edit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin bưu cục để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		let [error, response] = (isEdit) ? await handleRequest(postOfficeApi.update(data)) : await handleRequest(postOfficeApi.add(data));
		let message = apiError(`${messageAction} bưu cục thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} bưu cục thành công`});
			if(!isEdit) {
				dispatch(postOfficeActions.add(response.data));
			}
			else {
				dispatch(postOfficeActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có bưu cục để xóa'});
			return;
		}
		let [error, response] = await handleRequest(postOfficeApi.delete(itemEdit.id));
		let message = apiError(`xóa bưu cục thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa bưu cục thành công`});
			dispatch(postOfficeActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	const handlePaginationChange = (page) => {
		dispatch(postOfficeActions.setFilter({...filter, page }));
	}

	const handleLimitChange = (value) => {
		localStorage.setItem(DATA_LIMIT_KEY, value)
		dispatch(postOfficeActions.setFilter({
			...filter,
			limit: value,
			page: 1
		}));
	};

	const handleSearchChange = (newFilter) => {
		dispatch(postOfficeActions.fetchData(newFilter));
		setSearchBar(false)
	}

	return (
		<div className={'container'}>
			<section>
				<div className="d-flex gap-1">
					<SearchMobile searchBar={searchBar} setSearchBar={setSearchBar}>
						<PostOfficeSearch filter={filter} country={country} onSearchChange={handleSearchChange}/>
					</SearchMobile>
					{can.add && <Button outline leftIcon={Icon.plusCircle} className={"mb-1"} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
					{can.add && <Button background blue leftIcon={Icon.plusCircle} className={"mb-1"} to={'/post-office/import'}>Thêm mới bằng excel</Button>}
				</div>
			</section>
			<section>
				{loading && <Loading noFixed/>}
				{items && <PostOfficeTable
					items={items}
					onPaginationChange={handlePaginationChange}
					onLimitChange={handleLimitChange}
					setItemEdit={setItemEdit}
					openModal={handleModalOpen}
					pagination={pagination}
					can={can}
					country={country}
				/>}
			</section>
			{
				((can.add || can.edit) && openModal.addEdit) && <Modal title="Địa bưu cục" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
					<PostOfficeFormAddEdit country={country} item={itemEdit} onHandleSubmit={handleSaveItem} />
				</Modal>
			}
			{
				(itemEdit?.id && can.delete && openModal.delete) && <Modal title="Xóa bưu cục" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
					<p>Bạn muốn xóa bưu cục <b>{itemEdit?.name}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
					</div>
				</Modal>
			}
		</div>
	)
}

export default PostOffice;