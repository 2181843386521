import request from "~/utils/http";

const dashboardApi = {
	reportStatus : async (params) => {
		const url = 'v1/dashboard/report-status';
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.get(url, {params});
	},
	reportWeight : async (params) => {
		const url = 'v1/dashboard/report-weight';
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.get(url, {params});
	},
	reportLocation : async (params) => {
		const url = 'v1/dashboard/report-location';
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.get(url, {params});
	},
	cashFlow : async (params) => {
		const url = 'v1/dashboard/cash-flow';
		if(params === undefined) params = {};
		params = {
			...params,
			callForm : 'web'
		}
		return await request.get(url, {params});
	},
};

export default dashboardApi;