import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Col, Row } from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	InputField,
} from "~/components/Forms";

function PermissionFormAdd({onHandleSubmit}) {

	const initialValues = {
		name: undefined,
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Tên chức vụ không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={24}>
					<Controller control={control} name="name" render={({field}) => (
						<InputField label="Tên chức vụ" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Thêm chức vụ</Button>
			</div>
		</form>
	)
}

export default PermissionFormAdd;