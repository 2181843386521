import {
	useContext
} from "react";
import {
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	StatusLabel,
} from "~/components";
import AccountApiAction from "./AccountApiAction";
import DOMPurify from "dompurify";

function AccountApiTable({items = [], setAddNinjaVanKey, type, setItemEdit, handleEvent}) {

	const {tableHeight, isMobile} = useContext(AppContext);

	let columns = [];

	if(type === 'ghn') {
		columns = [
			{ title: 'Id', dataIndex: 'id', key: "id", width: '100px', render: (_, item) => (
				<><p className="font-bold">{item?.id}</p></>
			)},
			{ title: 'Client Id', dataIndex: 'client_id', key: "client_id", width: '100px', render: (_, item) => (
				<><p>{item?.clientId}</p></>
			)},
			{ title: 'Token', dataIndex: 'token', key: "token", width: '200px', render: (_, item) => (
				<><p>{item?.token}</p></>
			)},
			{ title: 'Shop Id', dataIndex: 'shopId', key: "shopId", width: '100px', render: (_, item) => (
				<><p className="font-bold">{item?.shopId}</p></>
			)},
			{ title: 'Mô tả', dataIndex: 'shopId', key: "note", width: '200px', render: (_, item) => (
				<><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${item?.note}`) }} /></>
			)},
			{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '130px', render: (_, item) => (
				<>
					{
						item?.status === 'using' && <StatusLabel type="green">Đang sử dụng</StatusLabel>
					}
					{
						item?.status === 'closed' && <StatusLabel type="red">Ngưng sử dụng</StatusLabel>
					}
				</>
			)},
		];
	}
	else if(type === 'spx') {
		columns = [
			{ title: 'Id', dataIndex: 'id', key: "id", width: '100px', render: (_, item) => (
				<><p className="font-bold">{item?.id}</p></>
			)},
			{ title: 'Client Id', dataIndex: 'client_id', key: "client_id", width: '100px', render: (_, item) => (
				<><p>{item?.clientId}</p></>
			)},
			{ title: 'Secret', dataIndex: 'secret', key: "secret", width: '200px', render: (_, item) => (
				<><p>{item?.secret}</p></>
			)},
			{ title: 'Mô tả', dataIndex: 'note', key: "note", width: '200px', render: (_, item) => (
				<><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${item?.note}`) }} /></>
			)},
		];
	}
	else {
		columns = [
			{ title: 'Id', dataIndex: 'id', key: "id", width: '80px', render: (_, item) => (
				<>
					<p className="font-bold">{item?.id}</p>
				</>
			)},
			{ title: 'Client Id', dataIndex: 'client_id', key: "client_id", width: '200px', render: (_, item) => (
				<>
					<p>{item?.clientId}</p>
				</>
			)},
			{ title: 'Client Secret', dataIndex: 'client_secret', key: "client_secret", width: '200px', render: (_, item) => (
				<>
					<p>{item?.clientSecret}</p>
				</>
			)},
			{ title: 'Prefix', dataIndex: 'prefix', key: "prefix", width: '100px', render: (_, item) => (
				<>
					<p className="font-bold">{item?.prefix}</p>
				</>
			)},
			{ title: 'Service Level', dataIndex: 'serviceLevel', key: "serviceLevel", width: '120px', render: (_, item) => (
				<>
					<p>{item?.serviceLevel}</p>
				</>
			)},
			{ title: 'Khối lượng từ', dataIndex: 'from', key: "from", width: '100px', render: (_, item) => (
					<p className="mb-0">{item?.from}</p>
				)},
			{ title: 'Đến khối lượng', dataIndex: 'to', key: "to", width: '100px', render: (_, item) => (
				<p className="mb-0">{item?.to}</p>
			)},
			{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '130px', render: (_, item) => (
				<>
					{
						item?.status === 'using' && <StatusLabel type="green">Đang sử dụng</StatusLabel>
					}
					{
						item?.status === 'closed' && <StatusLabel type="red">Ngưng sử dụng</StatusLabel>
					}
				</>
			)},
			{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
				<>
					<AccountApiAction
						item={item}
						setAddNinjaVanKey={setAddNinjaVanKey}
						type={type}
						setItemEdit={setItemEdit}
						handleEvent={handleEvent}/>
				</>
			)}
		];
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
		</div>
	)
}

export default AccountApiTable;