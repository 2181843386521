import {Button} from "~/components";
import {Col, Row} from "antd";
import {ORDER_COMPLETED} from "~/app/constants";

function OrderTableAction({item, setItemEdit, openModal, can, currentUser}) {

	const handleButtonClick = {
		ticket: () => {
			setItemEdit(item);
			openModal('ticket');
		},
		status: () => {
			setItemEdit(item);
			openModal('status');
		},
		paymentCoin: () => {
			setItemEdit(item);
			openModal('paymentCoin');
		}
	}

	return (
		<Row gutter={5}>
			<Col className="gutter-row" span={12}>
				<Button small background grey className="w-100" to={`/order/edit/${item.id}`}>Chi tiết</Button>
			</Col>
			{
				can.status && <Col className="gutter-row" span={12}>
					<Button small background grey className="w-100" onClick={handleButtonClick.status}>Trạng thái</Button>
				</Col>
			}
			{
				(currentUser.aff == 0 || currentUser?.id ==  item.userId) && <Col className="gutter-row" span={12}>
					<Button small background grey className="w-100" to={'/order/created-return/'+item?.id}>Thu hồi</Button>
				</Col>
			}
			<Col className="gutter-row" span={12}>
				<Button small background grey className="w-100" onClick={handleButtonClick.ticket}>Phản hồi</Button>
			</Col>
		</Row>
	)
}

export default OrderTableAction;