import {Tabs} from "antd";
import {globalNavigate} from "~/routes/GlobalHistory";

function UserVerifyTabs({active}) {

	const itemsTab = [
		{
			key: 'underReview',
			label: <p>Đợi xét duyệt</p>,
		},
		{
			key: 'rejected',
			label: <p>Từ chối</p>,
		},
	];

	const handleTabChange = (key) => {
		globalNavigate(`/users/verify/${key}`);
	};

	return (
		<Tabs
			defaultActiveKey={active}
			items={itemsTab}
			onChange={handleTabChange}
		/>
	)
}
export default UserVerifyTabs;