import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useEffect,
	useState
} from "react";
import {
	notification,
} from "antd";
import {
	SearchMobile,
	Button,
	Loading,
	Modal
} from "~/components";
import {
	TicketFormInfo, TicketFormStatus,
	TicketSearch,
	TicketTable,
	TicketTabs
} from "../components";
import _ from "lodash";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {
	apiError,
	handleRequest
} from "~/utils";
import {useCan} from "~/hooks";
import {OrderFormExport} from "../../Order/components";
import {ticketApi} from "~/api";
import {
	ticketActions,
	ticketItemsSelector,
	ticketLoadingSelector,
	ticketErrorSelector,
	ticketPaginationSelector,
	ticketFilterSelector
} from "../ticketSlice";
import {useParams} from "react-router-dom";

function Ticket() {
	const {status} = useParams();

	const can = {
		add: useCan('shop_ticket_add'),
		status: useCan('shop_ticket_status'),
		export: useCan('shop_ticket_excel'),
	}

	const dispatch = useDispatch();

	const items = useSelector(ticketItemsSelector);

	const loading = useSelector(ticketLoadingSelector);

	const error = useSelector(ticketErrorSelector);

	const pagination = useSelector(ticketPaginationSelector);

	const filter = useSelector(ticketFilterSelector);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [fileDownload, setFileDownload] = useState(false);

	const [searchBar, setSearchBar] = useState(false)

	//Modal show
	const [openModal, setOpenModal] = useState({
		status : false,
		export : false,
		detail : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		export: async (type) => {
			if (type == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn kiểu xuất'});
				return;
			}

			let params = {
				type: type,
				listData: [],
				filter: []
			};

			if(type == 'pageCurrent') {
				if(_.isEmpty(items)) {
					notification.error({message: 'Lỗi', description: 'Trang hiện không có ticket nào'});
					return;
				}
				for (const [index, item] of Object.entries(items)) {
					params.listData.push(item.id)
				}
			}

			if(type == 'checked') {
				if(_.isEmpty(selectedRowKeys)) {
					notification.error({message: 'Lỗi', description: 'Bạn chưa chọn ticket nào'});
					return;
				}
				params.listData = selectedRowKeys;
			}

			if(type == 'search') {
				params.filter = filter;
			}

			let [error, response] = await handleRequest(ticketApi.export(params));
			let message = apiError(`Xuất ticket thất bại`, error, response);
			if(!message) {
				setFileDownload(response.data.file)
			}
		},
		status: async (status, item) => {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có ticket để cập nhật'});
				return;
			}
			let [error, response] = await handleRequest(ticketApi.status({
				id: item.id,
				status: status
			}));
			let message = apiError(`Cập nhật trạng thái ticket thất bại`, error, response);
			if(!message) {
				notification.success({message: 'Thành công', description: `Cập nhật trạng thái ticket thành công`});
				item.status         = response.data.statusNew;
				dispatch(ticketActions.update(item));
				handleEvent.closeModal('status')
			}
		},
		search: (newFilter) => {
			dispatch(ticketActions.setFilterWithDebounce(newFilter));
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(ticketActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(ticketActions.setFilter({...filter, page }));
		},
		changeSearch: (newFilter) => {
			dispatch(ticketActions.setFilterWithDebounce(newFilter));
			setSearchBar(false)
		}
	}

	//Load data
	useEffect(() => {
		if(status != filter.status) {
			let newFilter = {...filter};
			newFilter.status = status;
			newFilter.page = 1;
			dispatch(ticketActions.setFilter(newFilter));
		}
	}, [status]);

	useEffect(() => {
		dispatch(ticketActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					<SearchMobile searchBar={searchBar} setSearchBar={setSearchBar}>
						<TicketSearch filter={filter} onSearchChange={handleEvent.changeSearch}/>
					</SearchMobile>
					{can.export && <Button background white onClick={() => handleEvent.openModal('export')}>Xuất đơn</Button>}
				</div>
				<TicketTabs active={status} filter={filter} ticketApi={ticketApi} />
				{items && <TicketTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
					can={can}
				/>}
			</section>
			{
				(can.export && openModal.export) &&
				<Modal title="Xuất đơn hàng" visible={openModal.export} onCancel={() => {handleEvent.closeModal('export')}}>
					<OrderFormExport fileDownload={fileDownload} onHandleSubmit={handleEvent.export} label={'Ticket'} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.detail) &&
				<Modal size={'lg'} title="Chi tiết" visible={openModal.detail} onCancel={() => {handleEvent.closeModal('detail')}}>
					<TicketFormInfo item={itemEdit} />
				</Modal>
			}
			{
				(can.status &&itemEdit?.id && openModal.status) &&
				<Modal title="Thai đổi trạng thái" visible={openModal.status} onCancel={() => {handleEvent.closeModal('status')}}>
					<TicketFormStatus item={itemEdit} onHandleSubmit={handleEvent.status} />
				</Modal>
			}
		</div>
	)
}
export default Ticket;