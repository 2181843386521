import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {affiliateActions} from "./affiliateSlice";
import {reportApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(reportApi.affiliates, action.payload);
		yield put(affiliateActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch affiliate list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(affiliateActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(affiliateActions.setFilter(action.payload));
}

export default function* affiliateSaga() {
	yield takeLatest(affiliateActions.fetchData.type, fetchDemoList);
	yield debounce(500, affiliateActions.setFilterWithDebounce.type, handleSearchDebounce)
}