import style from '../../Order/style/Order.module.scss';
import className from 'classnames/bind';
import {
	useMemo,
	useState
} from "react";
import {
	Breadcrumb,
	Col,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	codApi
} from "~/api";
import {
	Loading,
	Button,
} from "~/components";
import {
	TextAreaField,
} from "~/components/Forms";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {OrderTableImportResult} from "../../Order/components";
import {Link} from "react-router-dom";

const cn = className.bind(style);

function CodAdd() {

	const [countResultPassed, setCountResultPassed] = useState(0)
	const [countResultFailed, setCountResultFailed] = useState(0)
	const [resultFailed, setResultFailed] = useState([])

	const initialValues = {
		orders: '',
	}

	const validationSchema = Yup.object().shape({
		orders: Yup.string().required('Danh sách đơn hàng không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleSave = async (data) => {
		data.listData = data.orders.split('\n').map(line => line.trim());
		let [error, response] = await handleRequest(codApi.add(data));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			setCountResultPassed(response.data.passedTotal);
			setCountResultFailed(response.data.failedTotal);
			setResultFailed(response.data.failed);
		}
	}

	return (
		<div className="container">
			<form className="form" onSubmit={handleSubmit(handleSave)}>
				{isSubmitting && <Loading/>}
				<Breadcrumb className={'mb-1'}
				            items={[
					            {title: 'Dashboard',},
					            {title: <Link to={'/cod/order'}>Đối soát</Link>,},
					            {title: 'Tạo đối soát',},
				            ]}
				/>
				<section>
					<Row gutter={10}>
						<Col span={6}>
							<p className="heading">Danh sách đơn hàng</p>
							<Controller control={control} name="orders" render={({field}) => (
								<TextAreaField rows={20} errors={errors} {...field}/>
							)}/>
							<Button background primary className={'w-100'}>Đối soát</Button>
						</Col>
						<Col span={18}>
							<p className="heading">Kết quả cập nhật</p>
							<p className="mb-1">Cập nhật thành công <span className="color-green font-bold">{countResultPassed}</span> đơn</p>
							<p className="mb-1">Cập nhật thất bại <span className="color-red font-bold">{countResultFailed}</span> đơn</p>
							<OrderTableImportResult items={resultFailed} />
						</Col>
					</Row>
				</section>
			</form>
		</div>
	)
}
export default CodAdd;