import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
	numberFormat,
	renderAddress
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
} from "~/app/constants";
import DOMPurify from "dompurify";

function OrderHistoryTable({items = [], pagination, setItemEdit, handleEvent, can, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight, country} = useContext(AppContext);

	const showEdit = (items) => {
		let str = '';
		if(Object.keys(items).length !== 0) {
			for (let [key, value] of Object.entries(items)) {
				value = (typeof value === 'number') ? numberFormat(value) : value;
				str += `<p><b>${key}</b>: ${value}</p>`
			}
		}
		return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(str)}} />;
	}

	let columns = [
		{ title: 'Người hành động', dataIndex: 'from', key: "from", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
			</>
		)},
		{ title: 'Đơn hàng', dataIndex: 'object', key: "object", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.orderCode}</p>
			</>
		)},
		{ title: 'Bên nhận', dataIndex: 'to', key: "to", width: '400px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.toName}</p>
				<p>{item?.toPhone}</p>
				<p>{item?.toAddress + ', ' + renderAddress(
					item?.toCity,
					item?.toDistrict,
					item?.toWard,
					country
				)}</p>
			</>
		)},
		{ title: 'Mô tả', dataIndex: 'message', key: "object", width: '300px', render: (_, item) => (
			<>
				<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.message)}} />
			</>
		)},
		{ title: 'Trước', dataIndex: 'before', key: "before", width: '300px', render: (_, item) => (
			<>
				{
					showEdit(item?.beforeEdit)
				}
			</>
		)},
		{ title: 'Sau', dataIndex: 'after', key: "after", width: '300px', render: (_, item) => (
			<>
				{
					showEdit(item?.afterEdit)
				}
			</>
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default OrderHistoryTable;