import {
	Breadcrumb,
	notification,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	shippingPriceApi
} from "~/api";
import {
	ShippingPriceForm,
	ShippingPriceAffForm
} from "../components";
import {globalNavigate} from "~/routes/GlobalHistory";
import {Link} from "react-router-dom";
import {useCurrentUser} from "../../../hooks";

function ShippingPriceAdd() {

	const currentUser = useCurrentUser();

	if(currentUser.aff == 0) {

		const handleSave = async (data) => {
			let [error, response] = await handleRequest(shippingPriceApi.add(data));

			let message = apiError(`Thêm bảng giá thất bại`, error, response);
			if (!message) {
				notification.success({message: 'Thành công', description: 'Thêm bảng giá thành công'});
				globalNavigate('/admin/shipping-price');
			}
		}

		return (
			<div className={'container'}>
				<Breadcrumb className={'mb-1'}
				            items={[
					            {title: 'Dashboard',},
					            {title: <Link to={'/admin/shipping-price'}>Bảng giá</Link>,},
					            {title: 'Thêm bảng giá',},
				            ]}
				/>
				<div className="d-flex mb-2 justify-content-between">
					<div>
						<p className="font-bold text-lg mb-2">Thêm bảng giá</p>
					</div>
				</div>
				<ShippingPriceForm onHandleSubmit={handleSave}/>
			</div>
		)
	}
	else {

		const handleSave = async (data) => {

			data.userId = currentUser.id;

			let [error, response] = await handleRequest(shippingPriceApi.addAff(data));

			let message = apiError(`Thêm bảng giá thất bại`, error, response);

			if (!message) {

				notification.success({message: 'Thành công', description: 'Thêm bảng giá thành công'});

				globalNavigate('/admin/shipping-price');
			}
		}

		return (
			<div className={'container'}>
				<Breadcrumb className={'mb-1'}
				            items={[
					            {title: 'Dashboard',},
					            {title: <Link to={'/admin/shipping-price'}>Bảng giá</Link>,},
					            {title: 'Thêm bảng giá',},
				            ]}
				/>
				<div className="d-flex mb-2 justify-content-between">
					<div>
						<p className="font-bold text-lg mb-2">Thêm bảng giá</p>
					</div>
				</div>

				<ShippingPriceAffForm currentUser={currentUser} onHandleSubmit={handleSave}/>
			</div>
		)
	}


}

export default ShippingPriceAdd;