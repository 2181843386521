import {useEffect, useState} from "react";
import {Empty} from "antd";
import {
	Button,
	Loading,
} from "~/components";
import {
	apiError,
	handleRequest,
	numberFormat,
	renderDate
} from "~/utils";
import {
	codApi,
	orderApi
} from "~/api";
import {useParams} from "react-router-dom";
import {OrderCodTable} from "../components";

function CodDetail() {

	const {id} = useParams();

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [cod, setCod] = useState({});

	const [orders, setOrders] = useState([]);

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 50,
		totalRows:0
	});

	const handleEvent = {
		loadCod: async () => {
			let [error, response] = await handleRequest(codApi.get(id));
			let message = apiError(`Load data thất bại`, error, response);
			if(!message) {
				setCod(response.data)
				setErrorLoad(false)
				await handleEvent.loadOrder()
			}
			else {
				setErrorLoad(true)
			}
			setLoading(false)
		},
		loadOrder: async () => {
			let [error, response] = await handleRequest(orderApi.gets({
				codId: id,
				...pagination
			}));
			let message = apiError(`Load data thất bại`, error, response);
			if(!message) {
				setOrders(response.data)
				setPagination({
					...response.pagination
				})
			}
		},
		changePagination: async (page) => {
			setLoading(true)
			const filter = {
				codId: id,
				...pagination,
				page: page
			};
			let [error, response] = await handleRequest(orderApi.gets(filter));
			let message = apiError(`Load data thất bại`, error, response);
			if(!message) {
				setOrders(response.data)
				setPagination({
					...response.pagination
				})
			}
			setLoading(false)
		},
	}

	//Load data
	useEffect(() => {
		handleEvent.loadCod()
	}, [id]);

	if(loading) {
		return (
			<div className={'container'}><Loading/></div>
		)
	}

	if(errorLoad) {
		return (
			<div className={'container'}>
				<Empty description={<span>Không có thông tin</span>}><Button to={'/cod/waiting-pay'}>Quay lại danh sách cod</Button></Empty>
			</div>
		)
	}

	return (
		<div className={'container'}>
			<section className="mb-2">
				<p className="font-bold text-lg mb-1 color-red">#{cod.id} - <span className="text-xs color-grey">{renderDate(cod?.verifyTime)}</span></p>
				<p className="italic mb-1 text-sm">{cod?.user?.username}</p>
				<hr />
				<div className="d-flex gap-3 justify-content-between">
					<div>
						<p className="mb-1 text-sm">Tiền cước</p>
						<p className="mb-1 text-sm"><b>{numberFormat(cod.shipping)} đ</b></p>
					</div>
					<div>
						<p className="mb-1 text-sm">Tiền hoàn</p>
						<p className="mb-1 text-sm"><b>{numberFormat(cod.returnFee)} đ</b></p>
					</div>
					<div>
						<p className="mb-1 text-sm">Tiền Cod</p>
						<p className="mb-1 text-sm"><b>{numberFormat(cod.cod)} đ</b></p>
					</div>
					<div>
						<p className="mb-1 text-sm">Bảo hiểm</p>
						<p className="mb-1 text-sm"><b>{numberFormat(cod.insurance)} đ</b></p>
					</div>
					<div>
						<p className="mb-1 text-sm">Thu về</p>
						<p className="mb-1 text-lg"><b className='color-red'>{numberFormat(cod.cod - (cod.shipping + cod.returnFee - cod.paymentCoin))} đ</b></p>
					</div>
				</div>
			</section>

			{orders && <OrderCodTable
				items={orders}
				onPaginationChange={handleEvent.changePagination}
				pagination={pagination}
			/>}
		</div>
	)
}

export default CodDetail;