import request from "~/utils/http";

const postOfficeApi = {
	gets : async (params) => {
		const url = 'v1/post-office';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `v1/post-office/${id}`;
		return await request.get(url);
	},
	getByPhone : async (params) => {
		const url = 'v1/post-office/phone';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'v1/post-office';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `v1/post-office/${params.id}`;
		return await request.post(url, params);
	},
	delete : async (id) => {
		const url = `v1/post-office/${id}`;
		return await request.delete(url);
	},

	importExcel : async (params) => {
		const url = `v1/post-office/import/excel`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	importList : async () => {
		const url = `v1/post-office/import`;
		return await request.get(url);
	},
	importDelete : async (id) => {
		const url = `v1/post-office/import/${id}`;
		return await request.delete(url);
	},
	importUpload : async (params) => {
		const url = `v1/post-office/import/${params.id}`;
		return await request.post(url, params);
	},
	import : async (params) => {
		const url = `v1/post-office/import`;
		return await request.post(url, params);
	},
	importClear : async () => {
		const url = `v1/post-office/import/clear`;
		return await request.post(url);
	},
};

export default postOfficeApi;