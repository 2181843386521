import {useParams} from "react-router-dom";
import {
	useContext,
	useEffect, useMemo,
	useState
} from "react";
import {
	Col,
	Empty,
	notification,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {userApi} from "~/api";
import {AppContext} from "~/context/AppProvider";
import {LIST_USER_STATUS} from "~/app/constants";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import {
	InputField,
	SelectField,
} from "~/components/Forms";
import * as Yup from "yup";
import {
	Controller,
	useForm
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCan} from "~/hooks";
import {
	UserEditAffiliate,
	UserEditShippingBase,
	UserEditShipping,
	UserEditCoin
} from "../components";
import UserEditVerify from "../components/Forms/UserEditVerify";
import {useCurrentUser} from "../../../hooks";

function UserEdit() {

	const {id} = useParams();

	const currentUser = useCurrentUser();

	const can = {
		edit: useCan('shop_user_edit'),
		affiliate: useCan('shop_aff_active'),
		affiliateCode: useCan('shop_aff_code'),
		affiliatePercent: useCan('shop_aff_percent'),
		role: useCan('shop_user_roles'),
		status: useCan('shop_user_status'),
		shippingPrice: useCan('shop_user_shipping_price'),
		coinPercent: useCan('shop_coin_percent'),
	}

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [user, setUser] = useState({});

	const {country} = useContext(AppContext);

	//Xử lý địa chỉ
	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	//Form
	const initialValues = {
		firstname: user?.firstname,
		lastname: user?.lastname,
		email: user?.email,
		address: user?.address,
		city: user?.city,
		district: user?.district,
		ward: user?.ward,
	}

	const validationSchema = Yup.object().shape({
		firstname: Yup.string().required('Họ chữ đệm thành viên không được để trống'),
		lastname: Yup.string().required('Tên thành viên không được để trống'),
		email: Yup.string().required('Email thành viên không được để trống'),
		address: Yup.string().required('Địa chỉ thành viên không được để trống'),
		city: Yup.string().required('Tỉnh thành thành viên không được để trống'),
		district: Yup.string().required('Quận huyện thành viên không được để trống'),
		ward: Yup.string().required('Phường xã thành viên không được để trống'),
	})

	const {control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const loadUser = async () => {
		let [error, response] = await handleRequest(userApi.getById(id));
		let message = apiError(`Lấy thông tin thành viên thất bại`, error, response);
		if(!message) {
			initialValues.firstname = response.data?.firstname;
			initialValues.lastname = response.data?.lastname;
			initialValues.email = response.data?.email;
			initialValues.address = response.data?.address;
			initialValues.city = response.data?.city;
			initialValues.district = response.data?.district;
			initialValues.ward = response.data?.ward;
			reset(initialValues)

			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey === initialValues.city) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey === initialValues.district) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}

			setUser(response.data)
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
			setErrorLoad(false)
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey === value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey === value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleSave = async (data) => {
		if (user?.id === 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để lưu'});
			return;
		}
		data.id = user.id;

		let [error, response] = await handleRequest(userApi.update(data));

		let message = apiError(`Thay đổi trạng thái thành viên thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: 'Lưu thông tin thành viên thành công'});
			user.firstname = response.data?.firstname;
			user.lastname = response.data?.lastname;
			user.email = response.data?.email;
			user.address = response.data?.address;
			user.city = response.data?.city;
			user.district = response.data?.district;
			user.ward = response.data?.ward;
			setUser(user)
		}
	}

	const handleSaveShippingBase = async (data, type) => {

		data.id = user.id

		data.type = type

		let [error, response] = await handleRequest(userApi.updateShippingPriceBase(data));

		let message = apiError(`Cập nhật thông tin cộng tác viên thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: 'Cập nhật thông tin cộng tác viên thành công'});
		}
	}

	useEffect(() => {
		loadUser().then()
	}, [id]);

	if(loading) {
		return (
			<div className={'container'}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={'container'}>
				<Empty description={<span>Không có thành viên</span>}><Button to={'/user'}>Quay lại danh sách thành viên</Button></Empty>
			</div>
		)
	}

	return (
		<div className={'container'}>
			<div className="d-flex mb-2 justify-content-between">
				<div>
					<p className="font-bold text-lg mb-2">Thành viên {user?.firstname + ' ' + user?.lastname}</p>
					{
						user?.aff === 1 && <p>Mã giới thiệu <strong className={'color-red text-lg'}>{user?.referralCode}</strong> cộng tác viên</p>
					}
				</div>
				<div>
					<StatusLabel small type={LIST_USER_STATUS[user?.status]?.color} border>{LIST_USER_STATUS[user?.status]?.title}</StatusLabel>
				</div>
			</div>
			{
				can.edit &&
				<section>
					<form className="form" onSubmit={handleSubmit(handleSave)}>
						{isSubmitting && <Loading noFixed />}
						<h2 className={'heading'}>Thông tin thành viên</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="firstname" render={({field}) => (
									<InputField label="Họ chữ đệm" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="lastname" render={({field}) => (
									<InputField label="Tên" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<InputField value={user?.phone} label="Số điện thoại" disabled/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="email" render={({ field }) => (
									<InputField label="Email" errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="address" render={({ field }) => (
									<InputField label="Địa chỉ" errors={errors} {...field}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="city" render={({ field }) => (
									<SelectField label="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
										handleCityChange(value);
										field.onChange(value, event);
									}}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="district" render={({ field }) => (
									<SelectField label="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
										handleDistrictChange(value);
										field.onChange(value, event);
									}}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={6}>
								<Controller control={control} name="ward" render={({ field }) => (
									<SelectField label="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
								)}/>
							</Col>
						</Row>
						<div className="text-right mt-1">
							<Button background blue>Lưu thông tin</Button>
						</div>
					</form>
				</section>
			}
			{
				(can.shippingPrice || currentUser.aff == 1) && <UserEditShipping user={user} userApi={userApi} can={can}/>
			}
			{((can.affiliateCode || can.affiliatePercent)) && <UserEditAffiliate user={user} userApi={userApi} can={can}/>}
			{((can.affiliateCode || can.affiliatePercent) && user.aff == 1) && <UserEditShippingBase title={'Bảng giá gốc Ninjavan'} type={'ninjavan'} user={user} userApi={userApi} can={can} handleSave={handleSaveShippingBase}/>}
			{((can.affiliateCode || can.affiliatePercent) && user.aff == 1) && <UserEditShippingBase title={'Bảng giá gốc GHN'} type={'ghn'} user={user} userApi={userApi} can={can} handleSave={handleSaveShippingBase}/>}

			<UserEditVerify key={'UserEditVerify'} currentUser={user}/>
			{
				can.coinPercent && <UserEditCoin user={user} userApi={userApi}/>
			}

		</div>
	)
}
export default UserEdit;