import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {Link, useParams} from "react-router-dom";
import {
	useContext,
	useEffect, useMemo,
	useState
} from "react";
import {
	Breadcrumb,
	Col, Empty,
	notification,
	Row,
	Timeline
} from "antd";
import {
	apiError,
	handleRequest,
	renderAddress,
	numberFormat,
} from "~/utils";
import {
	orderApi
} from "~/api";
import {AppContext} from "~/context/AppProvider";
import {
	LIST_ORDER_STATUS,
	ORDER_CANCELLED,
	ORDER_COMPLETED,
	ORDER_PENDING
} from "~/app/constants";
import {
	Button,
	Icon,
	Image,
	Loading,
	Modal,
	StatusLabel
} from "~/components";
import {
	InputField,
	InputPriceField,
	SelectField,
	TextAreaField
} from "~/components/Forms";
import * as Yup from "yup";
import {
	Controller,
	useFieldArray,
	useForm
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {v4 as uuidv4} from 'uuid';
import {useCan} from "~/hooks";
import {isEmpty} from "../../../utils";
import DOMPurify from "dompurify";

const cn = className.bind(style);

const ShippingCart = ({item}) => {
	return (
		<div className={cn('card')}>
			<Image src={item.logo} />
		</div>
	)
}

function OrderEdit() {

	const {id} = useParams();

	const can = {
		add: useCan('shop_order_add'),
		edit: useCan('shop_order_edit'),
		cancel: useCan('shop_order_cancel'),
	}

	const [canEdit, setCanEdit] = useState(can.edit);

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const [order, setOrder] = useState({});

	const [histories, setHistories] = useState({
		count : 0,
		items: [],
		compact: []
	});

	const {country, shippingCompanies} = useContext(AppContext);

	const [branchMain, setBranchMain] = useState({});

	const [fee, setFee] = useState(0);

	const [returnFee, setReturnFee] = useState(0);

	//Modal show
	const [openModal, setOpenModal] = useState({
		cancel : false,
		histories : false,
	});

	//Xử lý địa chỉ
	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	//Form
	const initialValues = {
		code: order?.code,
		toName: order?.toName,
		toPhone: order?.toPhone,
		toAddress: order?.toAddress,
		toCity: order?.toCity,
		toDistrict: order?.toDistrict,
		toWard: order?.toWard,
		products: [],
		weight: order?.weight,
		long: order?.long,
		height: order?.height,
		width: order?.width,
		cod: order?.cod,
		requiredNote: order?.requiredNote,
		paymentType: order?.paymentType,
		note: order?.note,
	}

	const validationSchema = Yup.object().shape({
		toName: Yup.string().required('Tên người nhận không được để trống'),
		toPhone: Yup.string().required('Số điện thoại người nhận không được để trống').matches(
			/(84|0[3|5|7|8|9])+([0-9]{8})\b/g,
			"Số điện thoại không đúng"
		),
		toAddress: Yup.string().required('Địa chỉ người nhận không được để trống'),
		toCity: Yup.string().required('Tỉnh thành người nhận không được để trống'),
		toDistrict: Yup.string().required('Quận huyện người nhận không được để trống'),
		toWard: Yup.string().required('Phường xã người nhận không được để trống'),
		products: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required('Tên sản phẩm không được bỏ trống'),
				quantity  : Yup.number().required('số lượng không được bỏ trống'),
				weight : Yup.number().required('Cân nặng không được bỏ trống')
			})
		),
		weight: Yup.number().required('Khối lượng đơn hàng không được để trống'),
		long: Yup.number().required('Chiều dài đơn hàng không được để trống'),
		height: Yup.number().required('Chiều cao đơn hàng không được để trống'),
		width: Yup.number().required('Chiều rộng đơn hàng không được để trống'),
	})

	const {control, watch, handleSubmit, formState: { isSubmitting, errors }, reset, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "products"
	});

	const handleClick = {
		openModal: (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal: (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		cancel: () => {
			handleClick.openModal('cancel')
		},
		histories: () => {
			handleClick.openModal('histories')
		},
		productsAdd: () => {
			append({ id: uuidv4(), title: undefined, quantity: 1, weight: 0.1});
		}
	}

	const loadOrder = async () => {
		let [error, response] = await handleRequest(orderApi.getById(id));
		let message = apiError(`Lấy thông tin đơn hàng thất bại`, error, response);
		if(!message) {
			initialValues.code = response.data?.code;
			initialValues.toName = response.data?.toName;
			initialValues.toPhone = response.data?.toPhone;
			initialValues.toAddress = response.data?.toAddress;
			initialValues.toCity = response.data?.toCity;
			initialValues.toDistrict = response.data?.toDistrict;
			initialValues.toWard = response.data?.toWard;
			initialValues.weight= (Number.isInteger(response.data?.weight)) ? response.data?.weight : parseFloat(response.data?.weight.toFixed(2));
			initialValues.long= response.data?.long;
			initialValues.height= response.data?.height;
			initialValues.width= response.data?.width;
			initialValues.cod= response.data?.cod;
			initialValues.requiredNote= response.data?.requiredNote;
			initialValues.paymentType = (isEmpty(response.data?.paymentType)) ? 0 : parseInt(response.data?.paymentType);
			initialValues.note = response.data?.note;
			initialValues.products = [];
			if(Object.keys(response.data.items).length !== 0) {
				for (const [index, item] of Object.entries(response.data.items)) {
					initialValues.products.push({ id: item.id, title: item.title, quantity: item.quantity, weight:item.weight})
				}
			}
			reset(initialValues)

			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == initialValues.toCity) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == initialValues.toDistrict) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}

			setOrder(response.data)
			setBranchMain(response.data.shop);
			setFee(response.data.shipping);
			setReturnFee(response.data.returnFee);
			if(canEdit && (response.data?.status == ORDER_COMPLETED || response.data?.status == ORDER_CANCELLED)) {
				setCanEdit(false);
			}
			setErrorLoad(false)
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	const loadHistories = async () => {
		let [error, response] = await handleRequest(orderApi.history(id));
		let message = apiError(`Lấy lịch sử đơn hàng thất bại`, error, response);
		if(!message) {
			histories.items = [];
			histories.compact = [];
			histories.count = response.data.total;
			if(Object.keys(response.data.histories).length !== 0) {
				let countCompact = 0;
				for (const [index, item] of Object.entries(response.data.histories)) {
					histories.items.push({
						children: (
							<>
								<p>{item.time} {item.date}</p>
								<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${item.message}`) }} />
							</>
						)
					})
					if(countCompact <= 5) {
						histories.compact.push({
							children: (
								<>
									<p>{item.time} {item.date}</p>
									<p className="line-clamp-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${item.message}`) }} />
								</>
							)
						})
						countCompact++;
					}
				}
			}
			setHistories({
				...histories
			});
		}
		setLoading(false)
	}

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleShippingPrice = async () => {

		if(!canEdit) return false;

		if(order?.id == undefined) return false;

		const fieldsFee = [
			order?.shippingUnit,
			getValues("weight"),
			order?.insurance,
			getValues("toCity"),
		]

		if(isEmpty(fieldsFee[0]) || isEmpty(fieldsFee[1]) || isEmpty(fieldsFee[3]) || isEmpty(branchMain?.city)) return false;

		if(fieldsFee[1] == 0) return false;
		fieldsFee[1] = fieldsFee[1]*1;
		fieldsFee[1] = (Number.isInteger(fieldsFee[1])) ? fieldsFee[1] : fieldsFee[1].toFixed(2);

		if(isEmpty(fieldsFee[2])) fieldsFee[2] = 0;

		if(fieldsFee[1] == order?.weight && fieldsFee[3] == order?.toCity && branchMain.city == order?.fromCity) return false;

		let [error, response] = await handleRequest(orderApi.fee({
			shippingUnit: fieldsFee[0],
			weight: fieldsFee[1],
			insurance: fieldsFee[2],
			from: branchMain.city,
			to: fieldsFee[3],
			serviceType: order?.type
		}));

		let message = apiError(`Tính phí ship thất bại`, error, response);
		if (!message) {
			setFee(response.data.shipping)
		}
	}

	const handleWeightTotal = () => {
		const products = watch("products");
		let weight = 0;
		if(Object.keys(products).length !== 0) {
			for (const [index, item] of Object.entries(products)) {
				weight += item.quantity*item.weight;
			}
		}
		setValue('weight', (Number.isInteger(weight)) ? weight : parseFloat(weight.toFixed(2)))
	}

	const handleCancel = async () => {
		if (order?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để hủy'});
			return;
		}
		let [error, response] = await handleRequest(orderApi.cancel(order?.id));
		let message = apiError(`Hủy đơn hàng thất bại`, error, response);
		if (!message) {
			order.status = ORDER_CANCELLED;
			if(canEdit) {
				setCanEdit(false);
			}
			setOrder(order)
			handleClick.closeModal('cancel');
		}
	}

	const handleOrderSave = async (data) => {
		if (order?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn hàng để lưu'});
			return;
		}
		data.id = order.id;
		let [error, response] = await handleRequest(orderApi.update(data));
		let message = apiError(`Cập nhật đơn hàng thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Cập nhật đơn hàng thành công`});

			order.code = response.data?.code;
			order.toName = response.data?.toName;
			order.toPhone = response.data?.toPhone;
			order.toAddress = response.data?.toAddress;
			order.toCity = response.data?.toCity;
			order.toDistrict = response.data?.toDistrict;
			order.toWard = response.data?.toWard;
			order.weight= response.data?.weight;
			order.long= response.data?.long;
			order.height= response.data?.height;
			order.width= response.data?.width;
			order.cod= response.data?.cod;
			order.requiredNote= response.data?.requiredNote;
			order.paymentType = (isEmpty(response.data?.paymentType)) ? 0 : parseInt(response.data?.paymentType);
			order.note = response.data?.note;
			order.items = response.data?.items;
			setOrder(order);
		}
	}

	useEffect(() => {
		loadOrder().then()
		loadHistories().then()
	}, [id]);

	useEffect(() => {
		handleShippingPrice().then();
	}, [watch("weight"), watch("toCity")]);

	if(loading) {
		return (
			<div className={cn('order-gird')}>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div className={cn('order-gird')}>
				<Empty description={<span>Không có đơn hàng</span>}><Button to={'/order'}>Quay lại danh sách đơn hàng</Button></Empty>
			</div>
		)
	}

	return (
		<div className={cn('order-gird')}>
			<form className="form" onSubmit={handleSubmit(handleOrderSave)}>
				{isSubmitting && <Loading/>}
				<div className={cn('order-left')}>
					<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/order'}>Đơn hàng</Link>,},
				            {title: 'Chi tiết đơn hàng',},
			            ]}
					/>
					<section className="mb-2">
						<div>
							<p className="font-bold text-lg mb-1">ĐƠN HÀNG {order?.code} - {order?.trackingNumber}</p>
							<p className={'mb-1'}><StatusLabel small type={LIST_ORDER_STATUS[order.status]?.color} border>{LIST_ORDER_STATUS[order.status]?.title}</StatusLabel></p>
							<p className="text-xs color-grey">{order?.created}</p>
							{
								(order?.returnId !== 0 && order?.type !== 'return') && <p className="text-xs color-red">Đã tạo đơn thu hồi</p>
							}
						</div>
					</section>
					<section>
						<h2 className={'heading'}>Bên Gửi</h2>
						<p className={'mb-1 color-red'}><strong>{branchMain?.name}</strong></p>
						<p className={'mb-1 color-red'}>{branchMain?.phone}</p>
						<p className={'mb-2'}>{
							branchMain?.address && branchMain?.address +','+renderAddress(
								branchMain?.city,
								branchMain?.district,
								branchMain?.ward,
								country)
						}</p>
					</section>
					{
						(!canEdit)
						?
						<section>
							<h2 className={'heading'}>Bên nhận</h2>
							<p className={'mb-1 color-red'}><strong>{order?.toName}</strong></p>
							<p className={'mb-1 color-red'}>{order?.toPhone}</p>
							<p className={'mb-2'}>{
								order?.toAddress && order?.toAddress +','+renderAddress(
									order?.toCity,
									order?.toDistrict,
									order?.toWard,
									country)
							}</p>
						</section>
						:
						<section>
							<h2 className={'heading'}>Bên Nhận</h2>
							<Row gutter={10}>
								<Col className="gutter-row" md={6} xs={24}>
									<Controller control={control} name="toName" render={({field}) => (
										<InputField label="Họ tên" errors={errors} {...field}/>
									)}/>
								</Col>
								<Col className="gutter-row" md={12} xs={12}>
									<Controller control={control} name="toPhone" render={({ field }) => (
										<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
									)}/>
								</Col>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="code" render={({ field }) => (
										<InputField label="Mã đơn hàng" errors={errors} {...field}/>
									)}/>
								</Col>
							</Row>
							<Row gutter={10}>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="toAddress" render={({ field }) => (
										<InputField label="Địa chỉ" errors={errors} {...field}/>
									)}/>
								</Col>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="toCity" render={({ field }) => (
										<SelectField label="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
											handleCityChange(value);
											field.onChange(value, event);
										}}/>
									)}/>
								</Col>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="toDistrict" render={({ field }) => (
										<SelectField label="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
											handleDistrictChange(value);
											field.onChange(value, event);
										}}/>
									)}/>
								</Col>
								<Col className="gutter-row" md={6} xs={12}>
									<Controller control={control} name="toWard" render={({ field }) => (
										<SelectField label="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
									)}/>
								</Col>
							</Row>
						</section>
					}

					<section>
						<h2 className="heading">Sản phẩm</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Tên sản phẩm</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Khối lượng (Kg)</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Số lượng</label>
							</Col>
							<Col className="gutter-row" span={3}></Col>
						</Row>
						{fields.map(({ id, title, quantity, weight }, index) => {
							return (
								<Row gutter={10} key={id}>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].title`} render={({ field }) => (
											<InputField defaultValue={title} errors={errors} {...field} disabled={!canEdit}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].weight`} render={({ field }) => (
											<InputField defaultValue={weight} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}} disabled={!canEdit}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`products[${index}].quantity`} render={({ field }) => (
											<InputField defaultValue={quantity} errors={errors} {...field} onKeyUp={(value, event) => {handleWeightTotal(value)}} disabled={!canEdit}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Button primary type="button" className={'w-100'} onClick={() => remove(index)} disabled={!canEdit}>Xóa</Button>
									</Col>
								</Row>
							);
						})}
						{
							canEdit && <div className="d-flex justify-content-end">
								<Button blue background type="button" onClick={handleClick.productsAdd}>Thêm sản phẩm</Button>
							</div>
						}

						<h2 className="heading">Thông tin gói hàng</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Tổng khối lượng</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Dài (cm)</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Rộng (cm)</label>
							</Col>
							<Col className="gutter-row" span={6}>
								<label htmlFor="">Cao (cm)</label>
							</Col>
						</Row>
						<Row gutter={10}>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="weight" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="long" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="height" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<Controller control={control} name="width" render={({ field }) => (
									<InputField type="number" errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Cod</h2>
						<Row gutter={10}>
							<Col className="gutter-row" span={12}>
								<Controller control={control} name="cod" render={({ field }) => (
									<InputPriceField label="Tiền thu hộ" errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
							<Col className="gutter-row" span={12}>
								<InputPriceField defaultValue={order?.insurance} label="Giá trị đơn hàng (Bảo hiểm)" disabled/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Lưu ý - ghi chú</h2>
						<Row gutter={10}>
							<Col className="gutter-row" md={12} xs={24}>
								<Controller control={control} name="requiredNote" render={({ field }) => (
									<SelectField label="Lưu ý giao hàng" options={[
										{value: 'KHONGCHOXEMHANG', label: 'Không cho xem hàng'},
										{value: 'CHOXEMHANGKHONGTHU', label: 'Được Đồng kiểm'},
										{value: 'CHOTHUHANG', label: 'Được đồng kiểm, được thử'},
									]} errors={errors} {...field} disabled={!canEdit}/>
								)}/>
								<Controller control={control} name="paymentType" render={({ field }) => (
									<SelectField label="Tùy chọn thanh toán" options={[
										{value: 1, label: 'Bên gửi hàng trả phí'},
										{value: 2, label: 'Bên nhận hàng trả phí'},
									]} errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
							<Col className="gutter-row" md={12} xs={24}>
								<Controller control={control} name="note" render={({ field }) => (
									<TextAreaField label="Ghi chú" errors={errors} {...field} disabled={!canEdit}/>
								)}/>
							</Col>
						</Row>
					</section>

					<section>
						<h2 className={'heading'}>Đợn vị vận chuyển</h2>
						<div className="d-flex">
						{
							(shippingCompanies[order?.shippingUnit]?.logo) && <ShippingCart item={shippingCompanies[order?.shippingUnit]} />
						}
						</div>
					</section>
				</div>
				<div className={cn('order-right')}>
					<div className="d-flex justify-content-between mb-2">
						<p className="color-red font-bold">Lịch sử</p>
						<p className="color-red text-sm pointer italic" onClick={handleClick.histories}>Xem thêm</p>
					</div>
					{
						histories.count !== 0 && <Timeline items={histories.compact} />
					}

					<div className={cn('order-bottom')}>
						{
							returnFee !== 0 && <div className="text-right mb-4">
								<p className="color-red">Phí hoàn</p>
								<p className="color-red font-bold">{numberFormat(returnFee)} vnđ</p>
							</div>
						}
						{
							fee !== 0 && <div className="text-right mb-4">
								<p className="color-red">Phí giao hàng</p>
								<p className="color-red font-bold">{numberFormat(fee)} vnđ</p>
							</div>
						}
						<div className="text-center">
							{
								(can.cancel && order?.status === ORDER_PENDING) && <Button type="button" background primary className="w-100 ml-0" onClick={handleClick.cancel}>Hủy đơn</Button>
							}
							{
								(can.add && order?.returnId === 0 && order?.type !== 'return') && <Button to={'/order/created-return/'+order?.id} background green className="w-100 ml-0">Tạo đơn thu hồi</Button>
							}
							{
								canEdit && <Button type="submit" background blue className="w-100 ml-0">Cập nhật</Button>
							}
						</div>
					</div>
				</div>
			</form>

			{
				(can.cancel && order?.id && openModal.cancel) && <Modal title="Hủy đơn hàng" visible={openModal.cancel} onCancel={() => {handleClick.closeModal('cancel')}}>
					<p>Bạn muốn hủy đơn hàng <b>{order?.code}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleClick.closeModal('cancel')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleCancel}> Hủy Đơn </Button>
					</div>
				</Modal>
			}
			{
				(histories.count > 0 && order?.id && openModal.histories) && <Modal title="Lịch sử đơn hàng" visible={openModal.histories} onCancel={() => {handleClick.closeModal('histories')}}>
					<Timeline items={histories.items} />
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleClick.closeModal('histories')}}> Đóng </Button>
					</div>
				</Modal>
			}
		</div>
	)
}
export default OrderEdit;