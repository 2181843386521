import {useForm, Controller} from "react-hook-form";
import {
	Button,
	Loading
} from "~/components";
import {
	GroupCheckBoxField,
} from "~/components/Forms";
import className from "classnames/bind";
import style from "../../style/Order.module.scss";
const cn = className.bind(style);

function OrderFormPrint({onHandleSubmit, printRef}) {

	const formOptions = [
		{value : 'name', label : 'Tên người gửi'},
		{value : 'phone', label : 'Số điện thoại'},
		{value : 'address', label : 'Địa chỉ'},
	];

	const toOptions = [
		{value : 'name', label : 'Tên người gửi'},
		{value : 'phone', label : 'Số điện thoại'},
		{value : 'address', label : 'Địa chỉ'},
	];

	const orderOptions = [
		{value : 'weight', label : 'Khối lượng'},
		{value : 'size', label : 'Kích thước'},
	];

	const initialValues = {
		form: '',
		to: '',
		order: '',
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<div className={cn('group-radio-block')}>
				<p className={'heading'}>Ẩn thông tin người gửi</p>
				<Controller control={control} name="form" render={({field}) => (
					<GroupCheckBoxField options={formOptions} errors={errors} {...field}/>
				)}/>
				<p className={'heading'}>Ẩn thông tin người nhận</p>
				<Controller control={control} name="to" render={({field}) => (
					<GroupCheckBoxField options={toOptions} errors={errors} {...field}/>
				)}/>
				<p className={'heading'}>Ẩn thông tin đơn hàng</p>
				<Controller control={control} name="order" render={({field}) => (
					<GroupCheckBoxField options={orderOptions} errors={errors} {...field}/>
				)}/>
				<div className="form-group d-flex justify-content-end modal-bottom">
					<Button primary type="submit">In đơn hàng</Button>
				</div>
			</div>
		</form>
	)
}
export default OrderFormPrint;