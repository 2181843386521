import {memo} from "react";
import _ from "lodash"
import {
	SelectField,
	DateRangeField,
	InputField
} from "~/components/Forms";
import {Button} from "~/components";
import {strToTime} from "~/utils";
import dayjs from "dayjs";
import {Controller, useForm} from "react-hook-form";

function TicketSearch({ filter, onSearchChange }) {

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {
		type: ''
	}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			page: 1,
			keyword: data?.keyword,
			type: data?.type
		};
		if(!_.isEmpty(data.time)) {
			let timeNew = [...data.time]
			timeNew[0] = strToTime(timeNew[0]);
			timeNew[1] = strToTime(timeNew[1]);
			newFilter.time = {
				start: timeNew[0],
				end: timeNew[1],
			}
		}
		onSearchChange(newFilter);
	}

	return (
		<form className="form form-search d-flex justify-content-between gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="keyword" render={({field}) => (
				<InputField placeholder={'Mã đơn hàng'} defaultValue={filter?.keyword} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="type" render={({field}) => (
				<SelectField value={filter.time.type} placeholder="" className={'m-w-max'} options={[
					{value: '', label: 'Tất cả loại phản hồi'},
					{value: 'banks', label: 'Thay đổi thông tin ngân hàng'},
					{value: 'cod', label: 'Chỉnh sửa COD'},
					{value: 'resend', label: 'Phát lại'},
					{value: 'change_receive', label: 'Thông tin người nhận'},
					{value: 'complain', label: 'Khiếu nại/Đền bù'},
					{value: 'return_order', label: 'Duyệt hoàn đơn'},
					{value: 'orther', label: 'Khác'},
				]} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="time" render={({field}) => (
				<DateRangeField defaultValue={[dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')]} showTime errors={errors} {...field} />
			)}/>
			<Button background primary>Áp dụng</Button>
		</form>
	)
}

export default memo(TicketSearch);