import {Button, Icon} from "~/components";
import {Tooltip} from "antd";

function TicketTableAction({item, setItemEdit, handleEvent, can}) {

	const handleButtonClick = {
		detail: () => {
			setItemEdit(item)
			handleEvent.openModal('detail')
		},
		status: () => {
			setItemEdit(item)
			handleEvent.openModal('status')
		},
	}

	return (
		<>
			<Tooltip title="Chi tiết phản hồi">
				<Button small background red onClick={handleButtonClick.detail}>
					{Icon.commentCheck}
				</Button>
			</Tooltip>
			{
				can.status && <Tooltip title="Cập nhật trạng thái">
					<Button small background blue onClick={handleButtonClick.status}>
						{Icon.edit}
					</Button>
				</Tooltip>
			}

		</>
	)
}

export default TicketTableAction;