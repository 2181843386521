import {
	Button,
	Icon,
	PopperWrapper
} from "~/components";
import Tippy from '@tippyjs/react/headless';
import {Tooltip} from "antd";
import {useState} from "react";

function UserTableAction({item, setItemEdit, handleEvent, can}) {

	const [loading, setLoading] = useState(false)

	const [loadingBlock, setLoadingBlock] = useState(false)

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const handleButtonClick = {
		password: () => {
			closeActionButton();
			setItemEdit(item)
			handleEvent.openModal('password')
		},
		affiliateEnable: () => {
			closeActionButton();
			setItemEdit(item)
			handleEvent.openModal('affiliateEnable')
		},
		affiliateDisable: () => {
			closeActionButton();
			setItemEdit(item)
			handleEvent.openModal('affiliateDisable')
		},
		role: () => {
			closeActionButton();
			setItemEdit(item)
			handleEvent.openModal('role')
		},
		active: async () => {
			setLoading(true)
			await handleEvent.active(item);
			setLoading(false)
		},
		block: async () => {
			setLoadingBlock(true)
			await handleEvent.block(item);
			setLoadingBlock(false)
		},
		loginAs: async () => {
			await handleEvent.loginAs(item);
		},
		coinPlus: () => {
			closeActionButton();
			setItemEdit(item)
			handleEvent.openModal('coinPlus')
		},
		coinMinus: () => {
			closeActionButton();
			setItemEdit(item)
			handleEvent.openModal('coinMinus')
		},
	}

	const renderActionButton = () => {
		return (
			<PopperWrapper className={'action-wrapper'}>
				{can.password && <Button type="button" onClick={handleButtonClick.password}>Đổi mật khẩu</Button>}
				{can.role && <Button type="button" onClick={handleButtonClick.role}>Đổi chức vụ</Button>}
				{(can.affiliate && item.aff == 0) && <Button type="button" onClick={handleButtonClick.affiliateEnable}>Kích hoạt cộng tác viên</Button>}
				{(can.affiliate && item.aff == 1) && <Button type="button" onClick={handleButtonClick.affiliateDisable}>Hủy cộng tác viên</Button>}
				{can.coinPlus && <Button type="button" onClick={handleButtonClick.coinPlus}>Cộng thêm xu</Button>}
				{can.coinMinus && <Button type="button" onClick={handleButtonClick.coinMinus}>Trừ xu</Button>}
			</PopperWrapper>
		)
	}

	return (
		<>
			{
				(item.status !== 'public' && can.status) && <Tooltip title="Duyệt tài khoản">
					<Button small background green onClick={handleButtonClick.active} disabled={loading}>
						{loading ? Icon.loading : Icon.success}
					</Button>
				</Tooltip>
			}
			{
				(item.status !== 'block' && can.status) && <Tooltip title="Khóa tài khoản">
					<Button small background red onClick={handleButtonClick.block} disabled={loadingBlock}>
						{loadingBlock ? Icon.loading : Icon.block}
					</Button>
				</Tooltip>
			}
			{
				can.loginAs && <Button background yellow small onClick={handleButtonClick.loginAs}>{Icon.powerOff}</Button>
			}
			<Tippy
				interactive
				visible={showActionBox}
				render={renderActionButton}
				onClickOutside={closeActionButton}
				placement="bottom" offset={[35, 5]}
			><Button type="button" small onClick={() => setShowActionBox(true)} background blue>{Icon.menuVertical}</Button></Tippy>
		</>
	)
}

export default UserTableAction;