import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {userLoginAsActions} from "./userLoginAsSlice";
import {userApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(userApi.getsLoginAs, action.payload);
		yield put(userLoginAsActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch userLoginAs list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(userLoginAsActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(userLoginAsActions.setFilter(action.payload));
}

export default function* userLoginAsSaga() {
	yield takeLatest(userLoginAsActions.fetchData.type, fetchDemoList);
	yield debounce(500, userLoginAsActions.setFilterWithDebounce.type, handleSearchDebounce)
}