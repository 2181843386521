import {Empty} from "antd";
import {Button} from "../index";
function RoleError({link}) {
	return (<section>
		<Empty description={<span>Bạn không có quyền sử dụng chức năng này </span>}>
			{
				link && <Button to={link}>Quay lại</Button>
			}
		</Empty>
	</section>)
}

export default RoleError;
