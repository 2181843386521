import style from '../style/Home.module.scss'
import className from "classnames/bind";
import {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {
    apiError,
    handleRequest,
    numberFormat
} from "~/utils";
import {dashboardApi} from "~/api";
import _ from "lodash"
import dayjs from "dayjs";
import {OpsSearch, HomeTabs, MobileHello} from "../components";
import {Pie} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {useDispatch, useSelector} from "react-redux";
import {
    orderActions,
    orderFilterSelector
} from "../../Order/orderSlice";
import {globalNavigate} from "~/routes/GlobalHistory";
ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const cn = className.bind(style);

function Home() {
    const dispatch = useDispatch();
    const filterOrder = useSelector(orderFilterSelector);
    const [total, setTotal] = useState({
        number: 0,
        cod: 0,
        shipping: 0,
        codCountSuccess: 0,
        codCountReturn: 0,
    })
    const [filter, setFilter] = useState({
        time: {
            start: dayjs().add(-7, 'd').startOf('day').format(),
            end: dayjs().endOf('day').format(), // 7 ngày trước
        },
    })
    const [listStatus, setListStatus] = useState({
        "error": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Đồng bộ thất bại"
        },
        "pending": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Chờ lấy hàng"
        },
        "pickup-fail": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Lấy hàng thất bại"
        },
        "confirm": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Đã lấy hàng"
        },
        "shipping": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Đang vận chuyển"
        },
        "return": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Đơn hoàn"
        },
        "waiting-return": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Chờ xác nhận giao lại"
        },
        "completed": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Hoàn thành"
        },
        "lost": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Thất lạc - hư hỏng"
        },
        "cancelled": {
            "number": 0,
            "cod": 0,
            "shipping": 0,
            "name": "Đã hủy"
        }
    });
    const [listWeight, setListWeight] = useState({
        "weight1": {
            "name": "Gói hàng từ 1.5Kg trở xuống",
            "total": 0
        },
        "weight2": {
            "name": "Gói hàng từ 1.5Kg đến 3Kg",
            "total": 0
        },
        "weight3": {
            "name": "Gói hàng từ 3Kg đến 5Kg",
            "total": 0
        },
        "weight4": {
            "name": "Gói hàng từ 5Kg đến 10Kg",
            "total": 0
        },
        "weight5": {
            "name": "Gói hàng từ 10Kg đến 20Kg",
            "total": 0
        },
        "weight6": {
            "name": "Gói hàng trên 20Kg",
            "total": 0
        }
    });
    const [totalWeight, setTotalWeight] = useState(0);
    const [location, setLocation] = useState({
        "inner": {
            "name": "Hàng giao nội miền",
            "total": 0,
            "color": "#a3e1d4"
        },
        "outer": {
            "name": "Hàng giao liên miền",
            "total": 0,
            "color": "#b5b8cf"
        }
    });
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [loadingWeight, setLoadingWeight] = useState(false);
    const [loadingCod, setLoadingCod] = useState(false);
    const [charDataLocation, setCharDataLocation] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
            },
        ],
    });
    const [charDataWeight, setCharDataWeight] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
            },
        ],
    });
    const [charDataCod, setCharDataCod] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
            },
        ],
    });
    const [charDataFee, setCharDataFee] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
            },
        ],
    });

    const handleClickCardStatus = (status) => {
        dispatch(orderActions.setFilter({
            ...filterOrder,
            time: {
                start: (_.isNumber(filter.time.start)) ? dayjs(filter.time.start*1000).format() : filter.time.start,
                end: (_.isNumber(filter.time.end)) ? dayjs(filter.time.end*1000-1).format() : filter.time.end,
            }
        }))
        globalNavigate(`/order/${status}`);
    }

    const Card = ({item, total, status}) => {
        let percent = (total?.number !== 0) ? item.number/total.number*100 : 0;
        return (
            <div className={cn('card', 'pointer')} onClick={() => handleClickCardStatus(status)}>
                <div className={cn('card-inner')}>
                    <div className={cn('text')}>{item?.name}</div>
                    <div className={cn('percent')}>{percent.toFixed(2)} <span>%</span></div>
                    <div className={cn('progress-bar-wrap')}>
                        <div className={cn('progress-bar')}>
                            <div className={cn('progress-bar-inner')} style={{left:percent+'%'}}></div>
                        </div>
                    </div>
                    <div className={cn('text-mini')}>
                        <span>{item?.number}</span>
                        <span>/</span>
                        <span>{total?.number}</span>
                    </div>
                </div>
            </div>
        )
    }

    const CardList = () => {
        return Object.entries(listStatus).map(([status, item], i) => {
            return (<Card key={status} item={item} status={status} total={total}/>)
        })
    }

    const CardWeight = ({item, total}) => {
        let percent = (total !== 0) ? item.total/total*100 : 0;
        return (
            <div className={cn('block')}>
                <p className="text-lg font-bold">{percent.toFixed(2)} <span>%</span></p>
                <div className={cn('progress-bar-wrap')}>
                    <div className={cn('progress-bar')}>
                        <div className={cn('progress-bar-inner')} style={{left:percent+'%'}}></div>
                    </div>
                </div>
                <p className="text-sm">{item?.name}</p>
            </div>
        )
    }

    const CardWeightList = () => {
        return Object.entries(listWeight).map(([status, item], i) => {
            return (<CardWeight key={status} item={item} total={totalWeight}/>)
        })
    }

    const charLocation = (location) => {
        let data = {
            labels: [],
            values: [],
            colors: [],
        }
        Object.entries(location).map(([key, item], i) => {
            data.labels.push(item.name);
            data.colors.push(item.color);
            data.values.push(item.total);
        })
        setCharDataLocation({
            labels: data.labels,
            datasets: [
                {
                    data: data.values,
                    backgroundColor: data.colors,
                },
            ],
        })
        setLoadingLocation(false)
    }

    const charWeight = (listWeight) => {
        let data = {
            labels: [],
            values: [],
            colors: [],
        }
        Object.entries(listWeight).map(([key, item], i) => {
            data.labels.push(item.name);
            data.colors.push(item.color);
            data.values.push(item.total);
        })
        setCharDataWeight({
            labels: data.labels,
            datasets: [
                {
                    data: data.values,
                    backgroundColor: data.colors,
                },
            ],
        })
        setLoadingWeight(false)
    }

    const charCod = (total) => {
        setCharDataCod({
            labels: ["Đơn đối soát đi", "Đơn đối soát trả"],
            datasets: [
                {
                    data: [total.codCountSuccess, total.codCountReturn],
                    backgroundColor: ["#a3e1d4", "#b5b8cf"],
                },
            ],
        })
        setCharDataFee({
            labels: ["Tổng phí giao hàng", "Tổng phí thu hộ"],
            datasets: [
                {
                    data: [total.shipping, total.cod],
                    backgroundColor: ["#a3e1d4", "#b5b8cf"],
                },
            ],
        })
        setLoadingCod(false)
    }

    const handleLoadStatus = async () => {
        setLoadingCod(true)
        let [error, response] = await handleRequest(dashboardApi.reportStatus(filter));
        let message = apiError(`Xuất đơn hàng thất bại`, error, response);
        if(!message) {
            setListStatus(response.data.status)
            setTotal(response.data.total)
            charCod(response.data.total)
        }
    }

    const handleLoadWeight = async () => {
        setLoadingWeight(true)
        let [error, response] = await handleRequest(dashboardApi.reportWeight(filter));
        let message = apiError(`Xuất đơn hàng thất bại`, error, response);
        if(!message) {
            charWeight(response.data.weight)
            setListWeight(response.data.weight)
            setTotalWeight(response.data.total)
        }
    }

    const handleLoadLocation = async () => {
        setLoadingLocation(true)
        let [error, response] = await handleRequest(dashboardApi.reportLocation(filter));
        let message = apiError(`Xuất đơn hàng thất bại`, error, response);
        if(!message) {
            charLocation(response.data)
            setLocation(response.data)
        }
    }

    useEffect(() => {
        handleLoadStatus().then();
        handleLoadWeight().then();
        handleLoadLocation().then();
    }, [filter]);

    return (
        <div className="container">
            <MobileHello />
            <section>
                <HomeTabs active='' />
                <OpsSearch filter={filter} setFilter={setFilter} />
            </section>
            <section className={cn('card-grid')}>
                <CardList />
            </section>
            <section>
                <Row gutter={10}>
                    <Col md={6} span={24}>
                        <p className={'heading'}>Số liệu chung</p>
                        {!loadingCod &&
                            <Pie
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                            position: 'bottom'
                                        },
                                        title: {
                                            display: true,
                                            text: 'Biểu đồ Cod đơn hàng',
                                        },
                                    },
                                }}
                                data={charDataCod}
                            />
                        }
                        <div className={cn('block')}>
                            <p className="text-lg font-bold">{numberFormat(total?.number)}</p>
                            <p className="text-sm">Tổng đơn hàng</p>
                        </div>
                        <div className={cn('block')}>
                            <p className="text-lg font-bold">{numberFormat(total?.codCountSuccess)}</p>
                            <p className="text-sm">Đơn đối soát giao hàng</p>
                        </div>
                        <div className={cn('block')}>
                            <p className="text-lg font-bold">{numberFormat(total?.codCountReturn)}</p>
                            <p className="text-sm">Đơn đối soát trả hàng</p>
                        </div>
                    </Col>
                    <Col md={6} span={24}>
                        <p className={'heading'}>Tổng Phí</p>
                        {!loadingCod &&
                            <Pie
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                            position: 'bottom',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Biểu đồ tổng phí',
                                        },
                                    },
                                }}
                                data={charDataFee}
                            />
                        }
                        <div className={cn('block')}>
                            <p className="text-lg font-bold">{numberFormat(total?.shipping)}</p>
                            <p className="text-sm">Tổng phí giao hàng</p>
                        </div>
                        <div className={cn('block')}>
                            <p className="text-lg font-bold">{numberFormat(total?.cod)}</p>
                            <p className="text-sm">Tổng phí thu hộ</p>
                        </div>
                    </Col>
                    <Col md={6} span={24}>
                        <p className={'heading'}>Thống kê theo cân nặng</p>
                        {!loadingWeight &&
                            <Pie
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                            position: 'bottom',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Biểu đồ khối lượng đơn hàng',
                                        },
                                    },
                                }}
                                data={charDataWeight}
                            />
                        }
                        <CardWeightList />
                    </Col>
                    <Col md={6} span={24}>
                        <p className={'heading'}>Thống kê theo vùng</p>
                        {!loadingLocation &&
                            <Pie
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                            position: 'bottom'
                                        },
                                        title: {
                                            display: true,
                                            text: 'Biểu đồ địa điểm đơn hàng',
                                        },
                                    },
                                }}
                                data={charDataLocation}
                            />
                        }
                        <CardWeight key={'inner'} item={location.inner} total={(location.inner.total + location.outer.total)}/>
                        <CardWeight key={'outer'} item={location.outer} total={(location.inner.total + location.outer.total)}/>
                    </Col>
                </Row>
            </section>
        </div>
    )
}

export default Home;