import {useState} from "react";
import {Link} from "react-router-dom";
import className from 'classnames/bind';
import style from './NavBar.module.scss';
import {
	FontAwesomeIcon,
	Notification,
	Button,
	Icon
} from "~/components";
import {NavBarData} from "./NavBarData";
import {globalNavigate} from "../../routes/GlobalHistory";
import {useCan} from "../../hooks";

const cn = className.bind(style);

function NavBar() {

	const cod = {
		cod  : useCan('shop_cod'),
		tabOrder: useCan('shop_cod_order'),
		tabWaiting: useCan('shop_cod_waiting_pay'),
		tabSuccess: useCan('shop_cod_success_pay'),
	}

	const navBarList = NavBarData();

	const [active, setActive] = useState('home');

	const [menu, setMenu] = useState(false);

	const onclickAddOrder = () => {
		setMenu(false)
		globalNavigate('/order/add')
	}

	const menuOutSide = () => {
		setMenu(false);
	}

	const MenuItem = ({item}) => {
		return (
			<div className={cn('item')} onClick={() => setMenu(false)}>
				<Link to={item.to}>
					<div className={cn('icon')}>{item.icon}</div>
					<div className={cn('name')}>{item.title}</div>
				</Link>
			</div>
		)
	}

	let codUrl = '/cod/order';

	if(cod.cod) {
		if(!cod.tabOrder) {
			codUrl = '/cod/waiting-pay'
		}
		if(!cod.tabOrder && !cod.tabWaiting) {
			codUrl = '/cod/success'
		}
	}

	return (
		<>
			<div className={cn('navigation-mobile')}>
				<div className={cn('content')}>
					<div onClick={() => {setMenu(true); setActive('menu')}} className={cn('item', { 'active' : (active === 'menu')})}>
						<span className={cn('icon')}>
							<svg fill="currentColor" viewBox="0 0 44 44" width="1.2em" height="1.2em" className="x1lliihq x1k90msu x2h7rmj x1qfuztq x198g3q0 x1qx5ct2 xw4jnvo">
								<circle cx="7" cy="7" r="6"></circle>
								<circle cx="22" cy="7" r="6"></circle>
								<circle cx="37" cy="7" r="6"></circle>
								<circle cx="7" cy="22" r="6"></circle>
								<circle cx="22" cy="22" r="6"></circle>
								<circle cx="37" cy="22" r="6"></circle>
								<circle cx="7" cy="37" r="6"></circle>
								<circle cx="22" cy="37" r="6"></circle>
								<circle cx="37" cy="37" r="6"></circle>
							</svg>
						</span>
						<span>Menu</span>
					</div>
					<Link to={"/order"} className={cn('item', { 'active' : (active === 'order')})} onClick={() => setActive('order')}>
						<span className={cn('icon')}>
							<FontAwesomeIcon icon="fa-light fa-cart-flatbed-boxes" />
						</span>
						<span>Đơn hàng</span>
					</Link>
					<Link to={codUrl} className={cn('item', { 'active' : (active === 'cod')})} onClick={() => setActive('cod')}>
						<span className={cn('icon')}>
							<FontAwesomeIcon icon="fa-light fa-money-check-dollar-pen" />
						</span>
						<span>Đối soát</span>
					</Link>
					<Link to={"/ticket"} className={cn('item', { 'active' : (active === 'ticket')})} onClick={() => setActive('ticket')}>
						<span className={cn('icon')}>
							<FontAwesomeIcon icon="fa-light fa-messages-question" />
						</span>
						<span>Khiếu nại</span>
					</Link>
					<Link to={"/account/info"} className={cn('item', { 'active' : (active === 'account')})} onClick={() => setActive('account')}>
						<span className={cn('icon')}>
							<FontAwesomeIcon icon="fa-light fa-circle-user" />
						</span>
						<span>Cá nhân</span>
					</Link>
				</div>
			</div>
			<Notification
				outSide={menuOutSide}
				show={menu}
				title={'Menu'}
				description={'Nhận thông báo tin nhắn'}
				icon={<FontAwesomeIcon icon="fa-thin fa-bars" />}>
				<div className={cn('button-action')}>
					<Button background red leftIcon={Icon.plusCircle} onClick={onclickAddOrder} className={'w-100 text-sm'}>Thêm đơn hàng</Button>
				</div>
				<div className={cn('menus-mobile')}>
					{navBarList.map((menu, index) => {
						if(menu?.children) {
							if(menu.children.length > 0) {
								return menu?.children.map((subMenu, subIndex) => {
									return (<MenuItem key={subIndex} item={subMenu} />);
								})
							}
							else {
								return (<MenuItem key={index} item={menu} />);
							}
						}
						else {
							return (<MenuItem key={index} item={menu} />);
						}
					})}
				</div>
			</Notification>
		</>

	);
}

export default NavBar;