import className from 'classnames/bind';
import style from './PermissionForm.module.scss';
import {Fragment, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {Col, Row} from "antd";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import {
	GroupRadioField,
	CheckBoxField
} from "~/components/Forms";
import _ from "lodash"
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	rolesApi
} from "~/api";

const cn = className.bind(style);

function PermissionForm({roles, setRole, can, permission, permissionCurrent, setPermissionCurrent, onSubmit, onOpenModal}) {

	const [roleLoading, setRoleLoading] = useState(false);

	let roleOptions = [];

	if(!_.isEmpty(roles)) {
		for (const [value, role] of Object.entries(roles)) {
			roleOptions.push({
				value,
				label: role?.label
			})
		}
	}

	let initialValues = {
		role: 'administrator',
		... permissionCurrent
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues
	});

	const handleRoleChange = (value) => {
		(async () => {
			setRoleLoading(true);
			let [error, response] = await handleRequest(rolesApi.permissionByRole(value));
			let message = apiError(`Load danh sách quyền`, error, response);
			if (!message) {
				initialValues = {
					role: value,
					... response.data.capabilities
				}
				reset(initialValues);
				setPermissionCurrent(response.data.capabilities)
			}
			setRoleLoading(false);
		})()
	}

	const permissionList = (listRole, control, errors) => {
		return (
			<>
				{
					permission && Object.keys(permission).map((permissionKey) => {
						let permissionGroup = permission[permissionKey];
						return (
							<Col className="gutter-row" span={24} key={permissionKey}>
								<section className="content pd-1">
									{roleLoading && <Loading noFixed />}
									<p className="heading">{permissionGroup.label}</p>
									<Row gutter={10}>
										{
											Object.keys(permissionGroup.permission).map((permissionItemKey) => {
												return <Col className="gutter-row" span={12} key={permissionItemKey}><Controller control={control} name={`${permissionItemKey}`} render={({ field }) => (
													<CheckBoxField errors={errors} {...field}>
														<p>{permissionGroup.permission[permissionItemKey]}</p>
														<p className="italic text-sm color-grey">{permissionItemKey}</p>
													</CheckBoxField>
												)}/></Col>
											})
										}
									</Row>
								</section>
							</Col>
						)
					})
				}
			</>
		)
	}

	return (
		<form id="permissionForm" className={cn('form')+' form'} onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col className="gutter-row" span={6}>
					<section className="content pd-1">
						<p className="heading">Chức vụ</p>
						<Controller control={control} name="role" render={({ field }) => (
							<GroupRadioField options={roleOptions} errors={errors} {...field} onChange={(event) => {
								handleRoleChange(event.target.value);
								field.onChange(event);
							}}/>
						)}/>
					</section>
					<Button background red className={'w-100'}>Cập nhật</Button>
					{
						can.add && <Button background green className={'w-100 ml-0'} onClick={() => onOpenModal('add')} type="button">Thêm quyền</Button>
					}
				</Col>
				<Col className="gutter-row" span={16} style={{ overflow:'auto', maxHeight: '80vh'}}>
					<Row gutter={10}>
						{permissionList(roles, control, errors)}
					</Row>
				</Col>
			</Row>
		</form>
	)
}

export default PermissionForm;