import style from '../../style/Order.module.scss';
import className from 'classnames/bind';
import {Button, Icon, Modal} from "~/components";
import {renderAddress} from "~/utils";
const cn = className.bind(style);
function BranchItem({item, country, branchMain, onChoices}) {
	return (
		<section>
			<p className={'mb-1 color-red'}><strong>{item?.name}</strong></p>
			<p className={'mb-1 color-red'}>{item?.phone}</p>
			<p className={'mb-2'}>{
				item?.address && item?.address +','+renderAddress(
					item?.city,
					item?.district,
					item?.ward,
					country)
			}</p>
			<Button background red onClick={() => onChoices(item)}>Chọn</Button>
		</section>
	)
}
export default BranchItem;