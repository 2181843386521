import {Pagination, Table} from "antd";
import {useContext} from "react";
import {AppContext} from "~/context/AppProvider";
import {renderAddress} from "~/utils";
import PostOfficeAction from "./PostOfficeAction";
import {SelectField} from "~/components/Forms";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY
} from "~/app/constants";

function PostOfficeTable({items = [], pagination, setItemEdit, openModal, onPaginationChange, onLimitChange, can, country}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Mã Bưu cục', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (
			<b>#{item.code}</b>
		)},
		{ title: 'Tên Bưu cục', dataIndex: 'name', key: "name", render: (_, item) => (
			<p>{item?.name}</p>
		)},
		{ title: 'Địa chỉ', dataIndex: 'address', key: "address", width: '600px', render: (_, item) => (
			<>
				<p>{item.address}</p>
				<p className="italic color-red">{renderAddress(
					item?.city,
					item?.district,
					item?.ward,
					country)}</p>
			</>
		)},
		{ title: 'Người đại diện', dataIndex: 'person', key: "person", render: (_, item) => (
			<p>{item?.person}</p>
		)},
		{ title: 'Liên hệ', dataIndex: 'contact', key: "contact", render: (_, item) => (
			<>
				<p>{item?.phone}</p>
				<p>{item?.email}</p>
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<PostOfficeAction item={item} setItemEdit={setItemEdit} openModal={openModal} can={can}/>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={onLimitChange} />
			</div>

		</div>
	)
}

export default PostOfficeTable;