import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {orderHistoryActions} from "./orderHistorySlice";
import {orderHistoryApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(orderHistoryApi.gets, action.payload);
		yield put(orderHistoryActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch orderHistory list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(orderHistoryActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(orderHistoryActions.setFilter(action.payload));
}

export default function* orderHistorySaga() {
	yield takeLatest(orderHistoryActions.fetchData.type, fetchDemoList);
	yield debounce(500, orderHistoryActions.setFilterWithDebounce.type, handleSearchDebounce)
}