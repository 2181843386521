import {memo} from "react";
import _ from "lodash"
import {
	DateRangeField,
	InputField
} from "~/components/Forms";
import {Button} from "~/components";
import {strToTime} from "~/utils";
import dayjs from "dayjs";
import {Controller, useForm} from "react-hook-form";

function ReportRegionSearch({
     filter,
     onSearchChange,
}) {

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
		};
		if(!_.isEmpty(data.time)) {
			let timeNew = [...data.time]
			timeNew[0] = strToTime(timeNew[0]);
			timeNew[1] = strToTime(timeNew[1]);
			newFilter.time = {
				start: timeNew[0],
				end: timeNew[1],
			}
		}
		onSearchChange(newFilter);
	}

	return (
		<form className="form d-flex gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="time" render={({field}) => (
				<DateRangeField defaultValue={[dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')]} showTime errors={errors} {...field} />
			)}/>
			<Button background primary className={'mb-1'}>Áp dụng</Button>
		</form>
	)
}

export default memo(ReportRegionSearch);