import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {reportCoinActions} from "./reportCoinSlice";
import {reportApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(reportApi.coin, action.payload);
		yield put(reportCoinActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch reportCoin list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(reportCoinActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(reportCoinActions.setFilter(action.payload));
}

export default function* reportCoinSaga() {
	yield takeLatest(reportCoinActions.fetchData.type, fetchDemoList);
	yield debounce(500, reportCoinActions.setFilterWithDebounce.type, handleSearchDebounce)
}