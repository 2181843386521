import {
	useEffect,
	useState
} from "react";
import {
	Breadcrumb,
	Col, Empty,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
	numberFormat,
	renderDate
} from "~/utils";
import {
	discountApi
} from "~/api";
import {
	Loading,
	Button,
} from "~/components";
import {Link, useParams} from "react-router-dom";
import {
	DiscountUserList,
} from "../components";

function DiscountInfo() {

	const {id} = useParams();

	const [discount, setDiscount] = useState([]);

	const [loading, setLoading] = useState(true);

	const [errorLoad, setErrorLoad] = useState(false);

	const loadDiscount = async () => {
		let [error, response] = await handleRequest(discountApi.get(id));
		let message = apiError(`Lấy thông tin đơn hàng thất bại`, error, response);
		if(!message) {
			setDiscount(response.data)
			setErrorLoad(false)
		}
		else {
			setErrorLoad(true)
		}
		setLoading(false)
	}

	useEffect(() => {
		loadDiscount().then()
	}, [id]);

	if(loading) {
		return (
			<div>
				<Loading/>
			</div>
		)
	}

	if(errorLoad) {
		return (
			<div>
				<Empty description={<span>Không có mã khuyến mãi này</span>}>
					<Button to={'/discount/running'}>Quay lại danh sách mã khuyến mãi</Button>
				</Empty>
			</div>
		)
	}

	return (
		<div className="container">
			<Breadcrumb className={'mb-1'} items={[
				{title: 'Dashboard',},
				{title: <Link to={'/discount/running'}>Khuyến mãi</Link>,},
				{title: discount.code},
			]}/>
			<Row gutter={10}>
				<Col className="gutter-row" md={16} xs={24}>
					<section>
						<p className={'heading'}>Mã giảm giá</p>
						<label htmlFor="" className={'mb-1 d-block'}>Mã giảm giá</label>
						<p className={'mb-2'}><b className={'text-lg'}>{discount.code}</b></p>
						<p className={'color-grey'}>{discount.excerpt}</p>
					</section>

					<section>
						<p className={'heading'}>Thời gian áp dụng</p>
						<Row gutter={10}>
							<Col className="gutter-row" md={6} xs={12}>
								<label htmlFor="" className={'mb-1 d-block'}>Thời gian bắt đầu</label>
								{renderDate(discount.timeStart)}
							</Col>
							<Col className="gutter-row" md={6} xs={12}>
								<label htmlFor="" className={'mb-1 d-block'}>Thời gian kết thúc</label>
								{(discount.infinity == 0) ? renderDate(discount?.timeEnd) : 'không bao giờ kết thúc'}
							</Col>
						</Row>
					</section>

					<section>
						<p className={'heading'}>Loại khuyến mãi</p>
						<p>Cộng thêm {numberFormat(discount.value)} vào xu {(discount.type == 'coin') ? 'vĩnh viển' : 'thưường'}</p>
					</section>

					<section>
						<p className={'heading'}>Yêu cầu</p>

						<p>{
							(discount.condition == 'all')
								? 'Không yêu cầu'
								: (
									<>
										{(discount.condition == 'sales') ? 'Đạt doanh số tối thiểu '+discount.conditionValue : 'Đạt số đơn tối thiểu '+discount.conditionValue+' đơn'}
									</>
								)
						}</p>
					</section>

					<section>
						<p className={'heading'}>Đối tượng áp dụng</p>
						<p>{
							(discount.conditionUser == 'all')
								? 'Không yêu cầu'
								: (
									<>
										{(discount.conditionUser == 'isConfirm') ? 'Thành viên đã xác thực': 'Thành viên được chọn'}
									</>
								)
						}</p>
					</section>

					<section>
						<p className={'heading'}>Giới hạn sử dụng</p>

						<p>{
							(discount.limitUse == 'none')
								? 'Không giới hạn'
								: (
									<>
										{(discount.limitUse == 'all') ? 'Giới hạn '+discount.limit+' lần có thể sử dụng khuyến mãi' : 'Giới hạn '+discount.limit+' lần trên mỗi thành viên có thể sử dụng khuyến mãi'}
									</>
								)
						}</p>
					</section>
				</Col>
				<Col className="gutter-row" md={8} xs={24}>
					<section>
						<DiscountUserList users={discount?.users}/>
					</section>
				</Col>
			</Row>
		</div>
	)
}

export default DiscountInfo;