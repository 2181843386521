import style from '../style/Account.module.scss';
import className from 'classnames/bind';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Loading,
	Button
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import AccountHeader from "../components/AccoutHeader";
import {InputField} from "~/components/Forms";
import {authApi} from "~/api";
import {notification} from "antd";

const cn = className.bind(style);

function AccountPassword() {

	const { control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: {
			passCurrent: '',
			passNew: '',
		},
		resolver: yupResolver(Yup.object().shape({
			passCurrent: Yup.string().required('Bạn chưa điền mật khẩu hiện tại'),
			passNew: Yup.string().required('Bạn chưa điền mật khẩu mới'),
		}))
	});

	const handleChangePassword = async (data) => {
		let [error, response] = await handleRequest(authApi.password(data));
		let message = apiError(`Cập nhật mật khẩu thất bại`, error, response);
		if (!message) {
			notification.success({
				message: 'Thành công', description: `Đổi mật khẩu thành công`
			});
		}
	}

	return (
		<div className="container">
			<AccountHeader active={'password'}/>
			<section className="content pd-2 w-100 text-left">
				<p className="heading">Đổi mật khẩu</p>
				<form className="form" onSubmit={handleSubmit(handleChangePassword)}>
					{isSubmitting && <Loading/>}
					<Controller control={control} name="passCurrent" render={({ field }) => (
						<InputField label="Mật khẩu hiện tại" type={'password'} errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="passNew" render={({ field }) => (
						<InputField label="Mật khẩu mới" type={'password'} errors={errors} {...field}/>
					)}/>
					<div className="form-group d-flex justify-content-end">
						<Button primary type="submit">Đổi mật khẩu</Button>
					</div>
				</form>
			</section>
		</div>
	)
}

export default AccountPassword;