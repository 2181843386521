import _ from "lodash"
import {
	StatusLabel,
	Button,
	Image
} from "~/components";
import {Pagination, Table} from "antd";
import {useContext} from "react";
import {AppContext} from "~/context/AppProvider";
import {renderAddress} from "../../../../utils";
import BranchAction from "./BranchAction";
import {SelectField} from "../../../../components/Forms";
import {DATA_LIMIT, DATA_LIMIT_KEY} from "../../../../app/constants";

function BranchTable({items = [], pagination, setItemEdit, openModal, onPaginationChange, onLimitChange, can, country}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'ID', dataIndex: 'id', key: "id", width: '100px', render: (_, item) => (
			<b>#{item.id}</b>
		)},
		{ title: 'Cửa hàng', dataIndex: 'name', key: "name", render: (_, item) => (
			<p>{item?.name}</p>
		)},
		{ title: 'Địa chỉ', dataIndex: 'address', key: "address", width: '600px', render: (_, item) => (
			<>
				<p><b>Lấy hàng:</b> {item.address+','+renderAddress(
					item?.city,
					item?.district,
					item?.ward,
					country)}</p>
				<p><b>In biêu:</b> {item?.address2}</p>
			</>
		)},
		{ title: 'Điện thoại', dataIndex: 'phone', key: "phone", render: (_, item) => (
			<p>{item?.phone}</p>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", responsive: ['lg'], render: (_, item) => (
			<div>
				{(item.default == 1) && <p className={'color-red font-bold mb-1'}>Cửa hàng mặc định</p>}
				{(item.status == 'working') && <StatusLabel small type='green'>Đang sử dụng</StatusLabel>}
			</div>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<BranchAction item={item} setItemEdit={setItemEdit} openModal={openModal} can={can}/>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={onLimitChange} />
			</div>

		</div>
	)
}

export default BranchTable;