import _ from "lodash"
import {
	customerActions,
	customerErrorSelector,
	customerFilterSelector,
	customerItemsSelector,
	customerLoadingSelector,
	customerPaginationSelector
} from "../customerSlice";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {notification} from "antd";
import {
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import {
	useCan
} from "~/hooks";
import {
	CustomerFormAddEdit, CustomerSearch,
	CustomerTable
} from "../components";
import {
	DATA_LIMIT_KEY
} from "~/app/constants";
import {AppContext} from "~/context/AppProvider";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	customerApi
} from "~/api";

function Customer() {

	const {country, isMobile} = useContext(AppContext);

	const can = {
		add: useCan('shop_customer_add'),
		edit: useCan('shop_customer_edit'),
		delete: useCan('shop_customer_delete'),
	}

	const dispatch = useDispatch();

	const items = useSelector(customerItemsSelector);

	const loading = useSelector(customerLoadingSelector);

	const error = useSelector(customerErrorSelector);

	const pagination = useSelector(customerPaginationSelector);

	const filter = useSelector(customerFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Modal show
	const [openModal, setOpenModal] = useState({
		addEdit : false,
		default  : false,
		delete  : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(customerActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Add Edit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin điểm lấy hàng để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		let [error, response] = (isEdit) ? await handleRequest(customerApi.update(data)) : await handleRequest(customerApi.add(data));
		let message = apiError(`${messageAction} điểm lấy hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} điểm lấy hàng thành công`});
			if(!isEdit) {
				dispatch(customerActions.add(response.data));
			}
			else {
				dispatch(customerActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có điểm lấy hàng để xóa'});
			return;
		}
		let [error, response] = await handleRequest(customerApi.delete(itemEdit.id));
		let message = apiError(`xóa điểm lấy hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa điểm lấy hàng thành công`});
			dispatch(customerActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	const handlePaginationChange = (page) => {
		dispatch(customerActions.setFilter({...filter, page }));
	}

	const handleLimitChange = (value) => {
		localStorage.setItem(DATA_LIMIT_KEY, value)
		dispatch(customerActions.setFilter({
			...filter,
			limit: value,
			page: 1
		}));
	};

	const handleSearchChange = (newFilter) => {
		dispatch(customerActions.fetchData(newFilter));
	}

	return (
		<div className={'container'}>
			<section>
				<div className="d-flex gap-1">
					<CustomerSearch filter={filter} onSearchChange={handleSearchChange}/>
					{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>{!isMobile && 'Thêm mới'}</Button>}
					{can.add && <Button background blue leftIcon={Icon.plusCircle} to={'/customer/import'}>{!isMobile && 'Thêm mới bằng excel'}</Button>}
				</div>
			</section>
			<section>
				{loading && <Loading noFixed/>}
				{items && <CustomerTable
					items={items}
					onPaginationChange={handlePaginationChange}
					onLimitChange={handleLimitChange}
					setItemEdit={setItemEdit}
					openModal={handleModalOpen}
					pagination={pagination}
					can={can}
					country={country}
				/>}
			</section>
			{
				((can.add || can.edit) && openModal.addEdit) && <Modal title="Địa điểm lấy hàng" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
					<CustomerFormAddEdit country={country} item={itemEdit} onHandleSubmit={handleSaveItem} />
				</Modal>
			}
			{
				(itemEdit?.id && can.delete && openModal.delete) && <Modal title="Xóa khách hàng" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
					<p>Bạn muốn xóa khách hàng <b>{itemEdit?.name}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
					</div>
				</Modal>
			}
		</div>
	)
}

export default Customer;