import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {orderActions} from "./orderSlice";
import {orderApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(orderApi.gets, action.payload);
		yield put(orderActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch order list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(orderActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(orderActions.setFilter(action.payload));
}

export default function* orderSaga() {
	yield takeLatest(orderActions.fetchData.type, fetchDemoList);
	yield debounce(500, orderActions.setFilterWithDebounce.type, handleSearchDebounce)
}