import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {InputField} from "~/components/Forms";
import {Col, Row} from "antd";

function AuthRegisterForm({onHandleSubmit}) {

	const initialValues = { username: '', password: '' }

	const validationSchema = Yup.object().shape({
		phone: Yup.string().required('Số điện thoại không được để trống').min(5, 'Số điện thoại quá ngắn'),
		password: Yup.string().required('Mật khẩu không được để trống'),
		passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
		firstname: Yup.string().required('Họ không được để trống'),
		lastname: Yup.string().required('Tên không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="phone" render={({ field }) => (
	            <InputField label="Số điện thoại" placeholder="Số điện thoại" errors={errors} {...field}/>
			)}/>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="password" render={({ field }) => (
			            <InputField label="Mật khẩu" type="password" placeholder="Mật khẩu đăng nhập" errors={errors} {...field}/>
		            )}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="passwordConfirm" render={({ field }) => (
						<InputField label="Nhập lại mật khẩu" type="password" placeholder="Nhập lại mật khẩu" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="firstname" render={({ field }) => (
						<InputField label="Họ" type="text" placeholder="Nhập họ của bạn" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="lastname" render={({ field }) => (
						<InputField label="Tên" type="text" placeholder="Nhập tên của bạn" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField label="Email" type="email" placeholder="Nhập Email của bạn" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="affParentCode" render={({ field }) => (
						<InputField label="Mã giới thiệu" type="text" placeholder="Nhập mã giới thiệu" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<div className="form-group mt-2">
				<Button primary type="submit" className={'w-100'}>Đăng ký</Button>
			</div>
		</form>
	)
}

export default AuthRegisterForm;