import images from '~/assets/images';
import {Image} from "~/components";
import {renderAddress, numberFormat, isEmpty} from "~/utils";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
function OrderPrintLex({item, shippingCompanies, DeliveryInstructions, printHidden, country}) {
    return (
        <>
            <div style={{border: '1px solid #000', padding: '10px'}}>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '30%', textAlign: 'center'}}>
                            <Image src={images.logoPrint} style={{height: '60px'}}/>
                            <p style={{lineHeight: '10px', fontSize: '13px', textAlign: 'center'}}>
                                <strong>{item.code} </strong> - <span style={{fontSize: '11px'}}>LƯU KHO</span>
                                { (item.type === 'return') &&  - <span style={{fontSize: '11px'}}>GH1P</span>}
                            </p>
                        </td>
                        <td style={{width: '45%', textAlign: 'center'}}>
                            <div style={{display: 'inline-block'}}>
                                <Barcode value={item.trackingNumber} height={40} width={1.2} marginTop={5}
                                         fontSize={13}/>
                            </div>
                        </td>
                        <td style={{width: '25%', textAlign:"center"}}>
                            <p style={{marginBottom:0, fontSize: '12px',}}><strong>{item?.options?.data?.portCode}</strong></p>
                            <QRCode type="svg" value={item?.options?.data?.routeCode} size={40} style={{ padding: 0, marginBottom:0 }} />
                            <p style={{margin:0, fontSize: '10px',}}>{item?.options?.data?.routeCode}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <table className="customer-info" style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '50%', border: '1px solid #ccc', padding: '10px', verticalAlign: 'top'}}>
                            <h4 style={{marginBottom: '10px', fontSize: '13px', scrollMarginBottom: '5px'}}><b>Bên
                                gửi</b></h4>
                            {!printHidden.form.includes('name') &&
                                <p style={{lineHeight: '20px', fontSize: '10px'}}><span>{item.fromName}</span></p>}
                            {!printHidden.form.includes('phone') &&
                                <p style={{lineHeight: '20px', fontSize: '10px'}}><span>{item.fromPhone}</span></p>}
                            {!printHidden.form.includes('address') && <p style={{lineHeight: '20px', fontSize: '10px'}}>
                                <span>{(!isEmpty(item?.fromAddress2)) ? item?.fromAddress2 : item?.fromAddress + ',' + renderAddress(item.fromCity, item.fromDistrict, item.fromWard, country)}</span>
                            </p>}
                        </td>
                        <td style={{width: '50%', border: '1px solid #ccc', padding: '10px', verticalAlign: 'top'}}>
                            <h4 style={{marginBottom: '10px', fontSize: '13px', scrollMarginBottom:'5px'}}><b>Bên nhận</b></h4>
                            {!printHidden.to.includes('name') &&
                                <p style={{lineHeight: '20px', fontSize: '10px'}}><span>{item.toName}</span></p>}
                            {!printHidden.to.includes('address') && <p style={{lineHeight: '20px', fontSize: '10px'}}>
                                <span>{item.toAddress + ',' + renderAddress(item.toCity, item.toDistrict, item.toWard, country)}</span>
                            </p>}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table className="customer-info" style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '50%', padding: '5px 0', verticalAlign: 'top'}}>
                            <p style={{lineHeight: '10px', fontSize: '12px', marginBottom: '5px'}}>
                                <strong>Tiền thu người nhận:</strong>
                            </p>
                            <span style={{
                                lineHeight: '10px',
                                fontWeight: 'bold'
                            }}>{numberFormat(item.total)} vnđ</span>
                        </td>
                        <td style={{width: '50%', padding: '5px 0', verticalAlign: 'top'}}>
                            <p style={{lineHeight: '10px', fontSize: '10px'}}>
                                {!printHidden.order.includes('weight') && <span> khối lượng: {item.weight} Kg</span>}
                                {!printHidden.order.includes('size') &&
                                    <span> {item.long}cm x {item.width}cm x {item.height}cm</span>}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{border: '1px solid #ccc', padding: '5px', marginBottom: '10px'}}>
                    {<DeliveryInstructions item={item}/>}
                </div>
            </div>
            <div className="break-page-print"></div>
        </>
    )
}

export default OrderPrintLex;