import {memo, useMemo} from "react";
import {
	InputField,
	SelectField
} from "~/components/Forms";
import {Button, Icon} from "~/components";
import {Controller, useForm} from "react-hook-form";

function UserSearch({ filter, rolesData, onSearchChange }) {

	const rolesOptions = useMemo(() => {
		return [{
			value: '',
			label: 'Tất cả'
		}, ...Object.entries(rolesData).map(([key, value]) => {
			return {
				value: key,
				label: value.label
			};
		})];
	}, []);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {
		type: '',
		role: filter?.role,
	}});

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			page: 1,
			keyword: data?.keyword,
			role: data?.role,
		};
		onSearchChange(newFilter);
	}

	return (
		<form className="form d-flex gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="keyword" render={({field}) => (
				<InputField placeholder={'Số điện thoại, Tên đăng nhập, mã giới thiệu'} defaultValue={filter?.keyword} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="role" render={({field}) => (
				<SelectField options={rolesOptions} value={filter?.role} errors={errors} {...field} />
			)}/>
			<Button background primary className="mb-1">{Icon.search}</Button>
		</form>
	)
}

export default memo(UserSearch);