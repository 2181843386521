import {combineReducers} from "@reduxjs/toolkit";
import {authReducer} from '~/features/Auth/authSlice';
import {orderCashReducer} from '~/features/Home/orderCashSlice';
import {orderReducer} from '~/features/Order/orderSlice';
import {orderHistoryReducer} from '~/features/OrderHistory/orderHistorySlice';
import {importHistoryReducer} from '~/features/Order/importHistorySlice';
import {importHistoryOrderReducer} from '~/features/Order/importHistoryOrderSlice';
import {branchReducer} from '~/features/Branch/branchSlice';
import {orderCodReducer} from '~/features/Cod/orderCodSlice';
import {codReducer} from '~/features/Cod/codSlice';
import {customerReducer} from '~/features/Customer/customerSlice';
import {ticketReducer} from '~/features/Ticket/ticketSlice';
import {userReducer} from '~/features/User/userSlice';
import {userVerifyReducer} from '~/features/User/userVerifySlice';
import {userLoginAsReducer} from '~/features/User/userLoginAsSlice';
import {shippingPriceReducer} from '~/features/ShippingPrice/shippingPriceSlice';
import {postOfficeReducer} from '~/features/PostOffice/postOfficeSlice';
import {affiliateReducer} from '~/features/Report/affiliateSlice';
import {reportUserReducer} from '~/features/Report/reportUserSlice';
import {reportCoinReducer} from '~/features/Report/reportCoinSlice';
import {discountReducer} from '~/features/Discount/discountSlice';

const rootReducer = combineReducers({
	auth    : authReducer,
	order   : orderReducer,
	orderHistory   : orderHistoryReducer,
	branch   : branchReducer,
	orderCod : orderCodReducer,
	cod   : codReducer,
	orderCash   : orderCashReducer,
	importHistory   : importHistoryReducer,
	importHistoryOrder   : importHistoryOrderReducer,
	customer   : customerReducer,
	ticket   : ticketReducer,
	user   : userReducer,
	userVerify   : userVerifyReducer,
	userLoginAs   : userLoginAsReducer,
	shippingPrice   : shippingPriceReducer,
	postOffice   : postOfficeReducer,
	affiliate   : affiliateReducer,
	reportUser   : reportUserReducer,
	reportCoin   : reportCoinReducer,
	discount : discountReducer,
});

export default rootReducer;