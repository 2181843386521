import {Button, Icon} from "~/components";
import {useState} from "react";

function AccountApiAction({item, type, setItemEdit, setAddNinjaVanKey, handleEvent}) {

	const [loadingClosed, setLoadingClosed] = useState(false)

	const [loadingUsing, setLoadingUsing] = useState(false)

	const handleButtonClick = {
		edit: () => {
			setItemEdit(item);
			setAddNinjaVanKey(type)
			handleEvent.openModal('edit');
		},
		closed: async () => {
			setAddNinjaVanKey(type)
			setLoadingClosed(true)
			await handleEvent.closedNinjaVan(item);
			setLoadingClosed(false)
		},
		using: async () => {
			setAddNinjaVanKey(type)
			setLoadingUsing(true)
			await handleEvent.usingNinjaVan(item);
			setLoadingUsing(false)
		},
	}

	return (
		<div className="table-action d-flex">
			<Button background blue onClick={handleButtonClick.edit}>{Icon.edit}</Button>
			{
				item?.status !== 'closed' && <Button background red onClick={handleButtonClick.closed}>{ loadingClosed ? Icon.loading : Icon.block}</Button>
			}
			{
				item?.status !== 'using' && <Button background green onClick={handleButtonClick.using}>{ loadingUsing ? Icon.loading : Icon.success}</Button>
			}
		</div>
	)
}

export default AccountApiAction;