import {useParams} from "react-router-dom";
import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	useContext,
	useEffect,
	useState
} from "react";
import {
	notification,
} from "antd";
import {
	Loading,
	Modal,
} from "~/components";
import {
	UserSearch,
	UserVerifyTable,
	UserVerifyTabs
} from "../components";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {
	apiError,
	handleRequest
} from "~/utils";
import {useCan} from "~/hooks";
import {userApi} from "~/api";
import {
	userVerifyActions,
	userVerifyItemsSelector,
	userVerifyLoadingSelector,
	userVerifyErrorSelector,
	userVerifyPaginationSelector,
	userVerifyFilterSelector
} from "../userVerifySlice";
import UserFormVerify from "../components/Forms/UserFormVerify";
import UserFormVerifyCancel from "../components/Forms/UserFormVerifyCancel";
import {AppContext} from "../../../context/AppProvider";

function UserVerify() {

	const {verify} = useParams();

	const can = {
		status: useCan('shop_user_verify_status'),
	}

	const dispatch = useDispatch();

	const items = useSelector(userVerifyItemsSelector);

	const loading = useSelector(userVerifyLoadingSelector);

	const error = useSelector(userVerifyErrorSelector);

	const pagination = useSelector(userVerifyPaginationSelector);

	const filter = useSelector(userVerifyFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const {rolesData} = useContext(AppContext);

	//Modal show
	const [openModal, setOpenModal] = useState({
		verified : false,
		rejected : false,
	});

	const handleEvent = {
		openModal : (modal) => {
			openModal[modal] = true;
			setOpenModal({...openModal})
		},
		closeModal : (modal) => {
			openModal[modal] = false;
			setOpenModal({...openModal});
		},
		verified : async (item) => {
			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn thành viên'});
				return;
			}
			let [error, response] = await handleRequest(userApi.setVerify({
				id : item.id,
				isVerify : 'verified'
			}));

			let message = apiError(`Thay đổi trạng thái thành viên thất bại`, error, response);
			if (!message) {
				notification.success({message: 'Thành công', description: 'Xét duyệt hồ sơ tài khoản thành công'});
				dispatch(userVerifyActions.delete(item.id));
				handleEvent.closeModal('verified')
			}
		},
		rejected : async (note, item) => {
			if (item?.id === 'undefined') {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn thành viên'});
				return;
			}
			let [error, response] = await handleRequest(userApi.setVerify({
				id : item.id,
				isVerify : 'rejected',
				message: note
			}));

			let message = apiError(`Thay đổi trạng thái thành viên thất bại`, error, response);
			if (!message) {
				notification.success({message: 'Thành công', description: 'Xét duyệt hồ sơ tài khoản thành công'});
				dispatch(userVerifyActions.delete(item.id));
				handleEvent.closeModal('rejected')
			}
		},
		search: (newFilter) => {
			dispatch(userVerifyActions.setFilterWithDebounce(newFilter));
		},
		changeLimit: (value) => {
			localStorage.setItem(DATA_LIMIT_KEY, value)
			dispatch(userVerifyActions.setFilter({
				...filter,
				limit: value,
				page: 1
			}));
		},
		changePagination: (page) => {
			dispatch(userVerifyActions.setFilter({...filter, page }));
		},
		changeSearch: (newFilter) => {
			dispatch(userVerifyActions.setFilterWithDebounce(newFilter));
		}
	}

	//Load data
	useEffect(() => {
		if(verify !== filter?.isVerify) {
			dispatch(userVerifyActions.setFilter({
				...filter,
				isVerify: verify,
				page: 1,
			}));
		}
	}, [verify]);

	useEffect(() => {
		dispatch(userVerifyActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					<UserSearch filter={filter} rolesData={rolesData} onSearchChange={handleEvent.changeSearch}/>
				</div>
				<UserVerifyTabs active={verify} filter={filter} userApi={userApi} />
				{items && <UserVerifyTable
					items={items}
					handleEvent={handleEvent}
					setItemEdit={setItemEdit}
					pagination={pagination}
					can={can}
				/>}
			</section>
			{
				(openModal.verified && itemEdit?.id) &&
				<Modal title="Duyệt tài khoản" visible={openModal.verified} onCancel={() => {handleEvent.closeModal('verified')}}>
					<UserFormVerify item={itemEdit} onHandleSubmit={handleEvent.verified} />
				</Modal>
			}
			{
				(openModal.rejected && itemEdit?.id) &&
				<Modal title="Từ chối xét duyệt" visible={openModal.rejected} onCancel={() => {handleEvent.closeModal('rejected')}}>
					<UserFormVerifyCancel item={itemEdit} onHandleSubmit={handleEvent.rejected} />
				</Modal>
			}
		</div>
	)
}
export default UserVerify;