import AccountHeader from "../components/AccoutHeader";
import {
	useCurrentUser,
} from "~/hooks";
import AccountFormInfo from "../components/Forms/AccountFormInfo";
import AccountFormVerify from "../components/Forms/AccountFormVerify";

function AccountInfo() {

	const currentUser = useCurrentUser();

	return (
		<div className="container">
			<AccountHeader active={'info'} />
			<section><AccountFormInfo currentUser={currentUser} /></section>
			<section><AccountFormVerify currentUser={currentUser} /></section>
		</div>
	)
}

export default AccountInfo;