import {createSlice} from "@reduxjs/toolkit";
import {DATA_LIMIT, DATA_LIMIT_KEY} from "../../app/constants";
import dayjs from "dayjs";

const orderCod = createSlice({
	name: "orderCod",
	initialState: {
		loading: false,
		error: null,
		filter:{
			time: {
				type: 'created',
				start: dayjs().add(-7, 'd').startOf('day').format(),
				end: dayjs().endOf('day').format(), // 7 ngày trước
			},
			page : 1,
			limit : (localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT,
		},
		items: [],
		pagination : {
			page : 1,
			limit : DATA_LIMIT,
			totalRows: 0
		}
	},
	reducers: {
		fetchData(state) {
			state.loading = true;
			state.error = null;
			return state;
		},
		fetchDataSuccess(state, action) {
			state.loading = false;
			state.items = action.payload.data;
			state.pagination = action.payload.pagination;
			state.error = null;
			return state;
		},
		fetchDataFailed(state, action) {
			state.loading = false;
			state.error = action.payload;
			return state;
		},
		add(state, action) {
			state.items.unshift(action.payload);
			return state;
		},
		update(state, action) {
			let objIndex = state.items.findIndex((item => item.id == action.payload.id));
			state.items[objIndex] = {...state.items[objIndex], ...action.payload};
			return state;
		},
		delete(state, action) {
			state.items = state.items.filter(function(item) {
				return item.id !== action.payload
			})
		},
		deleteByTracking(state, action) {
			state.items = state.items.filter(function(item) {
				return item.trackingNumber !== action.payload
			})
		},
		clear(state, action) {
			state.items = []
		},
		//Action search
		setFilter(state, action) {
			state.filter = action.payload;
			return state;
		},
		setFilterWithDebounce(state, action) {}
	}
})
//Action
export const orderCodActions = orderCod.actions;
//Reducers
export const orderCodReducer = orderCod.reducer;
//Selectors
export const orderCodItemsSelector = (state) => state.orderCod.items;
export const orderCodLoadingSelector = (state) => state.orderCod.loading;
export const orderCodErrorSelector = (state) => state.orderCod.error;
export const orderCodFilterSelector = (state) => state.orderCod.filter;
export const orderCodPaginationSelector = (state) => state.orderCod.pagination;