import {
	useContext
} from "react";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";

function AccountCoinHistoryTable({items = [], pagination, handleEvent}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Thời gian', dataIndex: 'time', key: "time", width: '80px', render: (_, item) => (
			<p>{renderDate(item?.time)}</p>
		)},
		{ title: 'Mô tả', dataIndex: 'message', key: "message", width: '200px', render: (_, item) => (
			<p>{item?.message}</p>
		)},
		{ title: 'Loại xu', dataIndex: 'type', key: "type", width: '80px', render: (_, item) => (
			<p>{(item?.type == 'coin') ? 'Vĩnh viển' : 'Khuyến mãi'}</p>
		)},
		{ title: 'Xu', dataIndex: 'coin', key: "coin", width: '100px', render: (_, item) => (
			<>
				<p className={'font-bold color-' + ((item.action == 'plus') ? 'green' :  'red')}>{(item.action == 'plus') ? '+' :  '-'}{numberFormat(item.coin)}</p>
				<p className={'color-grey'}>Số dư ví: {numberFormat(item.coinAfter)}</p>
			</>
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
			</div>
		</div>
	)
}

export default AccountCoinHistoryTable;