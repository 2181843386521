import {StatusLabel} from "../index";
import Icon from "../Icon";

function UserVerify({user}) {
	return (
		<>
			{user?.isVerify == 'unverified' && <StatusLabel small type={'grey'}>{Icon.verify}{'\u00A0'} Chưa xác thực</StatusLabel>}
			{user?.isVerify == 'underReview' && <StatusLabel small type={'yellow'}>{Icon.verify}{'\u00A0'} Đợi xét duyệt</StatusLabel>}
			{user?.isVerify == 'verified' && <StatusLabel small type={'green'}>{Icon.verify}{'\u00A0'} Đã xác thực</StatusLabel>}
			{user?.isVerify == 'rejected' && <StatusLabel small type={'red'}>{Icon.verify}{'\u00A0'} Bị từ chối</StatusLabel>}
		</>
	)
}
export default UserVerify