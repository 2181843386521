import {memo, useEffect} from "react";
import _ from "lodash"
import {
	SelectField,
	DateRangeField,
	InputField
} from "~/components/Forms";
import {Button} from "~/components";
import {strToTime} from "~/utils";
import dayjs from "dayjs";
import {Controller, useForm} from "react-hook-form";

function OrderSearch({
     filter,
     onSearchChange,
}) {

	const shippingOptions = [
		{value : '', label : 'Tất đơn vị'},
		{value : 'ninjavan', label : 'Ninjavan'},
		{value : 'ghn', label : 'Giao Hàng Nhanh'},
		{value : 'spx', label : 'Shopee Express'},
		{value : 'lex', label : 'Lazada'},
	];

	const initialValues = {
		time: [dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')],
	}

	const {control, handleSubmit, setValue, formState: { errors }} = useForm({
		defaultValues: initialValues
	});

	useEffect(() => {
		if(filter?.time?.start && filter?.time?.end) {
			let start= (_.isNumber(filter.time.start)) ? dayjs(filter.time.start*1000) : dayjs(filter.time.start);
			let end = (_.isNumber(filter.time.end)) ? dayjs(filter.time.end*1000-1) : dayjs(filter.time.end);
			setValue('time', [start, end])
		}
	}, [filter]);

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: data.keyword,
			shippingUnit: data.shippingUnit,
			page: 1,
		};
		if(!_.isEmpty(data.time)) {
			let timeNew = [...data.time]
			timeNew[0] = strToTime(timeNew[0]);
			timeNew[1] = strToTime(timeNew[1]);
			newFilter.time = {
				start: timeNew[0],
				end: timeNew[1],
			}
		}
		onSearchChange(newFilter);
	}

	return (
		<form className="form form-search d-flex gap-1" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} name="keyword" render={({field}) => (
				<InputField placeholder="Mã, SĐT, Tên người nhận" defaultValue={filter.keyword} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="shippingUnit" render={({field}) => (
				<SelectField value={filter.shippingUnit} placeholder="Đơn vị vận chuyển" options={shippingOptions} errors={errors} {...field} />
			)}/>
			<Controller control={control} name="time" render={({field}) => (
				<DateRangeField showTime errors={errors} {...field} />
			)}/>
			<Button background primary>Áp dụng</Button>
		</form>
	)
}

export default memo(OrderSearch);