import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {Link} from "react-router-dom";

function ReportUserTable({items = [], pagination, handleEvent, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Thành viên', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold"><Link to={'/admin/users/edit/'+item?.id}>{item?.username}</Link></p>
				<p className="font-bold"><Link to={'/admin/users/edit/'+item?.id}>{item?.firstname + ' ' + item?.lastname}</Link></p>
				{
					item?.referralCode && <StatusLabel small type="blue" border>{item?.referralCode}</StatusLabel>
				}
			</>
		)},
		{ title: 'Liên hệ', dataIndex: 'contact', key: "contact", width: '100px', render: (_, item) => (
			<>
				<p><Link to={'/admin/users/edit/'+item?.id}>{item?.email}</Link></p>
				<p><Link to={'/admin/users/edit/'+item?.id}>{item?.phone}</Link></p>
			</>
		)},
		{ title: 'Mã giới thiệu', dataIndex: 'affParentCode', key: "affParentCode", width: '140px', render: (_, item) => (
			<p>{item?.affParentCode}</p>
		)},
		{ title: 'Tổng Đơn hàng', dataIndex: 'total', key: "total", width: '140px', render: (_, item) => (
			<p>{item?.total}</p>
		)},
		{ title: 'Đơn hàng mới', dataIndex: 'order1', key: "order1", width: '140px', render: (_, item) => (
			<p>{item?.order1}</p>
		)},
		{ title: 'Đơn hàng (Đã lấy)', dataIndex: 'order2', key: "order2", width: '140px', render: (_, item) => (
			<p>{item?.order2}</p>
		)},
		{ title: 'Đơn hoàn (Đã hủy)', dataIndex: 'order3', key: "order3", width: '140px', render: (_, item) => (
			<p>{item?.order3}</p>
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default ReportUserTable;