import {memo} from "react";
import _ from "lodash";
import {Button, Icon} from "~/components";

function DiscountUserList({users, onDelete}) {
	return (
		<div style={{position:'relative'}}>
			<div style={{overflow:'auto', maxHeight:'550px'}}>
			{
				(!_.isEmpty(users)) && users.map((item) => {
					return <div key={`user-${item.id}`} className="d-flex gap align-items justify-content-between mb-1 w-100">
						<div>
							<p><b>{item?.firstname + ' ' + item?.lastname}</b></p>
							<p className={'mb-1'}>{item?.username}</p>
						</div>
						<div>
							{onDelete && <Button leftIcon={Icon.delete} background red onClick={() => onDelete(item)}>Xoá</Button>}
						</div>
					</div>
				})
			}
			</div>
		</div>
	)
}

export default memo(DiscountUserList);