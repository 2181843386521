import style from '../style/Order.module.scss';
import className from 'classnames/bind';
import {
	useMemo,
	useState
} from "react";
import {
	Breadcrumb,
	Col,
	Row,
} from "antd";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	orderApi
} from "~/api";
import {
	Loading,
	Button,
	StatusLabel,
	RoleError
} from "~/components";
import {
	TextAreaField,
	GroupRadioField
} from "~/components/Forms";
import {
	OrderTableImportResult
} from "../components";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {LIST_ORDER_STATUS} from "~/app/constants";
import {useCan} from "~/hooks";
import {Link} from "react-router-dom";
const cn = className.bind(style);

function OrderStatus() {

	const can = {
		uploadsStatus: useCan('shop_orderEdit_status'),
	}

	const [countResultPassed, setCountResultPassed] = useState(0)
	const [countResultFailed, setCountResultFailed] = useState(0)
	const [resultFailed, setResultFailed] = useState([])

	const statusOptions = useMemo(() => {
		return [...Object.entries(LIST_ORDER_STATUS).map(([key, item]) => {
			return {
				value: key,
				label: <StatusLabel small type={item.color}>{item.title}</StatusLabel>
			};
		})];
	}, []);

	const initialValues = {
		orders: '',
		status: '',
	}

	const validationSchema = Yup.object().shape({
		orders: Yup.string().required('Danh sách đơn hàng không được để trống'),
		status: Yup.string().required('Trạng thái đơn hàng không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleSave = async (data) => {
		data.listData = data.orders.split('\n').map(line => line.trim());
		let [error, response] = await handleRequest(orderApi.multipleStatus(data));
		let message = apiError(`Cập nhật dữ liệu thất bại`, error, response);
		if(!message) {
			setCountResultPassed(response.data.passedTotal);
			setCountResultFailed(response.data.failedTotal);
			setResultFailed(response.data.failed);
		}
	}

	if(!can.uploadsStatus) {
		return (<RoleError link={'/order'} />)
	}

	return (
		<div className={cn('order-gird')}>
			<Breadcrumb className={'mb-1'}
			            items={[
				            {title: 'Dashboard',},
				            {title: <Link to={'/order'}>Đơn hàng</Link>,},
				            {title: 'Cập nhật trạng thái',},
			            ]}
			/>
			<form className="form" onSubmit={handleSubmit(handleSave)}>
				{isSubmitting && <Loading/>}
				<section>
					<Row gutter={10}>
						<Col span={6}>
							<p className="heading">Danh sách đơn hàng cần đổi trạng thái</p>
							<Controller control={control} name="orders" render={({field}) => (
								<TextAreaField rows={20} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col span={6} className={cn('group-radio-block')}>
							<p className="heading">Trạng thái</p>
							<Controller control={control} name="status" render={({field}) => (
								<GroupRadioField options={statusOptions} errors={errors} {...field}/>
							)}/>
							<Button background primary className={'w-100'}>Cập nhật</Button>
						</Col>
						<Col span={12}>
							<p className="heading">Kết quả cập nhật</p>
							<p className="mb-1">Cập nhật thành công <span className="color-green font-bold">{countResultPassed}</span> đơn</p>
							<p className="mb-1">Cập nhật thất bại <span className="color-red font-bold">{countResultFailed}</span> đơn</p>
							<OrderTableImportResult items={resultFailed} />
						</Col>
					</Row>
				</section>
			</form>
		</div>
	)
}
export default OrderStatus;