import {useEffect, useState} from "react";
import {
    apiError,
    handleRequest,
} from "~/utils";
import {reportApi} from "~/api";
import _ from "lodash";
import {ReportRegionTable, ReportRegionSearch} from "../components";
import {Loading} from "../../../components";

function ReportRegion() {
    const [location, setLocation] = useState({});
    const [filter, setFilter] = useState({});
    const [loadingLocation, setLoadingLocation] = useState(false);

    const handleLoadLocation = async () => {
        setLoadingLocation(true);
        let [error, response] = await handleRequest(reportApi.region(filter));
        let message = apiError(`Load dữ liệu thất bại`, error, response);
        if (!message) {
            setLocation(response.data);
        }
        setLoadingLocation(false);
    }

    useEffect(() => {
        handleLoadLocation().then();
    }, [filter]);

    return (
        <div className="container">
            <section>
                <div className="d-flex gap-1">
                    <ReportRegionSearch filter={filter} onSearchChange={setFilter}/>
                </div>
            </section>
            <section>
                {loadingLocation && <Loading noFixed/>}
                {!_.isEmpty(location) && <ReportRegionTable
                    items={location}
                />}
            </section>
        </div>
    )
}

export default ReportRegion;