import className from 'classnames/bind';
import style from '../../style/Auth.module.scss';
import {Button, Image} from "../../../../components";
const cn = className.bind(style);
function RegisterSuccess() {
	return (
		<div className={cn('register-success')}>
			<div className="text-center">
				<Image src={'https://cdn-icons-png.flaticon.com/512/7518/7518748.png'}></Image>
			</div>
			<h3>ĐĂNG KÝ THÀNH CÔNG</h3>
			<p className="text-center">Bạn đã đăng ký tài khoản thành công</p>
			<p className="text-center">Tài khoản của bạn sẽ được kiểm tra và kịch hoạt từ 1 đến 7 ngày làm việc</p>
			<p className="text-center mt-2"><Button primary to="/login">Đăng nhập ngay</Button></p>
		</div>
	)
}
export default RegisterSuccess;