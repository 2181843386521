const images = {
	noImage : require('~/assets/images/no-image.jpg'),
	iconUser : require('~/assets/images/icon-user.png'),
	iconCommit : require('~/assets/images/icon-commit.png'),
	iconContract : require('~/assets/images/icon-contract.png'),
	iconCustomer : require('~/assets/images/icon-customer.png'),
	iconEvent : require('~/assets/images/icon-event.png'),
	iconProject : require('~/assets/images/icon-project.png'),
	iconReport : require('~/assets/images/icon-report.png'),
	iconRevenue : require('~/assets/images/icon-revenue.png'),
	iconUpload : require('~/assets/images/icon-upload.png'),
	iconToastRight : require('~/assets/images/icon-toast-right.png'),
	iconToastLeft : require('~/assets/images/icon-toast-left.png'),
	logoPrint: require('~/assets/images/logo-print.jpg'),
}
export default images