import {
	useContext
} from "react";
import {
	SelectField
} from "~/components/Forms";
import {
	renderDate,
} from "~/utils";
import {
	Pagination,
	Table
} from "antd";
import {
	AppContext
} from "~/context/AppProvider";
import {
	DATA_LIMIT,
	DATA_LIMIT_KEY,
	LIST_ORDER_STATUS,
	LIST_TICKET_STATUS,
	LIST_TICKET_TYPE
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {TicketTableAction} from "../index";
import Message from "../Comments/Message";

function TicketTable({items = [], pagination, setItemEdit, handleEvent, can, selectedRowKeys, setSelectedRowKeys}) {

	const {tableHeight} = useContext(AppContext);

	let columns = [
		{ title: 'Mã', dataIndex: 'code', key: "code", width: '80px', render: (_, item) => (
			<p className="color-red font-bold">{item?.id}</p>
		)},
		{ title: 'Người gửi', dataIndex: 'from', key: "from", width: '200px', render: (_, item) => (
			<>
				<p className="font-bold">{item?.user?.username}</p>
				<p>{item?.user?.firstname + ' ' + item?.user?.lastname}</p>
				<p className="color-grey text-xs mt-1">{renderDate(item?.created)}</p>
			</>
		)},
		{ title: 'Đơn hàng', dataIndex: 'object', key: "object", width: '200px', render: (_, item) => (
			<>
				{
					item?.objectType == 'order' && <>
						<p className="font-bold">{item?.orderCode} ({item?.numOrder})</p>
						<p className="mb-1"><StatusLabel small type={LIST_ORDER_STATUS[item?.order.status]?.color} border>{LIST_ORDER_STATUS[item?.order.status]?.title}</StatusLabel></p>
						<p className="color-grey text-xs mt-1">{renderDate(item?.order?.created)}</p>
					</>
				}

			</>
		)},
		{ title: 'Yêu cầu', dataIndex: 'ticket', key: "object", width: '300px', render: (_, item) => (
			<>
				<p className="mb-1">{LIST_TICKET_TYPE[item?.type]?.title}</p>
				<p className="color-grey text-xs mt-1">{item?.message}</p>
				{
					item?.comment && <Message currentUserId={0} preCommentUser={null} key={`table-message-${item?.comment.id}`} item={item?.comment} />
				}
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '130px', render: (_, item) => (
			<>
				<StatusLabel small type={LIST_TICKET_STATUS[item?.status]?.color} border>{LIST_TICKET_STATUS[item?.status]?.title}</StatusLabel>
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '120px', render: (_, item) => (
			<TicketTableAction item={item} setItemEdit={setItemEdit} handleEvent={handleEvent} can={can} />
		)},
	];

	const dataSource= items.map(row => ({
		...row,
		key: row.id, // I added this line
	}));

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div className="table-wrapper">
			<div className="order-table table-box">
				<Table
					rowSelection={rowSelection}
					columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={handleEvent.changePagination}  showSizeChanger={false} />
				<SelectField defaultValue={(localStorage.getItem(DATA_LIMIT_KEY) != undefined) ? parseInt(localStorage.getItem(DATA_LIMIT_KEY)) : DATA_LIMIT} options={[
					{value: 50, label: '50 / Trang'},
					{value: 100, label: '100 / Trang'},
					{value: 200, label: '200 / Trang'},
					{value: 500, label: '500 / Trang'},
				]} onChange={handleEvent.changeLimit} />
			</div>

		</div>
	)
}

export default TicketTable;