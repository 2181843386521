import style from './Header.module.scss';
import Tippy from '@tippyjs/react/headless';
import className from 'classnames/bind';
import {useDispatch, useSelector } from "react-redux";
import {memo, useContext, useState} from "react";
import _ from "lodash"
import {
	Image,
	Button,
	PopperWrapper,
	Icon,
	Loading
} from "~/components";
import {currentUserSelector, authActions} from "~/features/Auth/authSlice";
import {globalNavigate} from "~/routes/GlobalHistory";
import {useCan} from "~/hooks";
import {Drawer} from "antd";
import {AppContext} from "~/context/AppProvider";
import {
	userLoginAsActions,
	userLoginAsFilterSelector,
	userLoginAsItemsSelector,
	userLoginAsLoadingSelector,
} from "../../features/User/userLoginAsSlice";
import {UserSearch} from "../../features/User/components";
const cn = className.bind(style);
function Header() {

	const {
		isTablet,
		isDesktop,
		rolesData
	} = useContext(AppContext);

	const dispatch = useDispatch();

	const currentUser = useSelector(currentUserSelector);

	const useCanRolesView = useCan('rolesView');

	const useCanLoginAs = useCan('loginAs');

	const items = useSelector(userLoginAsItemsSelector);

	const loading = useSelector(userLoginAsLoadingSelector);

	const filter = useSelector(userLoginAsFilterSelector);

	const [loginAsOpen, setLoginAsOpen] = useState(false);

	const loginAsShow = async () => {
		setLoginAsOpen(true);
		if(_.isEmpty(items)) {
			dispatch(userLoginAsActions.fetchData(filter))
		}
	};

	const changeSearch = (newFilter) => {
		dispatch(userLoginAsActions.fetchData(newFilter));
	}

	const loginAsClose = () => {
		setLoginAsOpen(false);
	};

	const handleLogout = () => {
		localStorage.clear();
		dispatch(authActions.logout());
		globalNavigate("/login");
	}

	const handleLoginAs = async (item) => {
		localStorage.setItem('access_token_as', item.id);
		window.location.reload(false)
	};

	const renderAccountMenu = () => {
		return (
			<PopperWrapper className={cn('account-action')}>
				<Button leftIcon={Icon.user} className={cn('button')} to={'/account/info'}> Thông tin tài khoản</Button>
				<Button leftIcon={Icon.lock} className={cn('button')} to={'/account/password'}> Đổi mật khẩu</Button>
				<Button leftIcon={Icon.coin} className={cn('button')} to={'/account/recharge'}> Nạp xu</Button>
				{ useCanLoginAs && <Button leftIcon={Icon.switch} className={cn('button')} onClick={loginAsShow}> Đổi tài khoản</Button>}
				{ useCanRolesView && <Button leftIcon={Icon.lock} className={cn('button')} to={'/Permission'}> Phân quyền</Button>}
				<hr />
				<Button leftIcon={Icon.logout} onClick={handleLogout} className={cn('button')}> Đăng xuất </Button>
			</PopperWrapper>
		)
	}

	return (
		<header className={cn('wrapper')}>
			<div className={cn('inner')}>
				<div className={cn('mobile-logo', 'd-flex', 'align-items-center', 'gap-1')}>
					<Image src={'https://media-cdn.superexpress.vn/uploads/file-logo/super-express-logo-192x192.png'} style={{height:'30px'}} />
					<span>SuperExpress</span>
				</div>
				<div className={cn('right')}>
					{(isDesktop || isTablet) &&
						<Tippy interactive render={renderAccountMenu} placement="bottom" offset={[0, 10]}>
							<div className={cn('account')}>
								<div className={cn('avatar')}><Image src={currentUser?.avatar}/></div>
								<div className={cn('name', 'text-sm')}><span>Hi, {currentUser?.lastname}</span></div>
							</div>
						</Tippy>
					}
				</div>
			</div>
			<Drawer title="Nhân viên" placement="right" onClose={loginAsClose} open={loginAsOpen}>
				<UserSearch filter={filter} rolesData={rolesData} onSearchChange={changeSearch}/>
				<hr />
				{
					(loading) ? <Loading noFixed /> :
					items.map((item) => {
						return <div key={`login-as-${item.id}`} className="d-flex gap align-items justify-content-between mb-1 w-100">
							<div>
								<p><b>{item?.firstname + ' ' + item?.lastname}</b> (<span className="italic color-blue text-xs font-bold">{rolesData[item.role]?.label}</span>)</p>
								<p className={'mb-1'}>{item?.username}</p>

							</div>
							<Button leftIcon={Icon.login} background red onClick={() => handleLoginAs(item)}>Chuyển</Button>
						</div>
					})
				}
			</Drawer>
		</header>
	);
}
export default memo(Header);