import _ from "lodash";
import {
	useDispatch,
	useSelector
} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {
	orderCodActions,
	orderCodErrorSelector,
	orderCodFilterSelector,
	orderCodItemsSelector,
	orderCodLoadingSelector,
	orderCodPaginationSelector
} from "../orderCodSlice";
import {notification} from "antd";
import {
	Loading,
	Modal,
	SearchMobile
} from "~/components";
import {
	ButtonAction,
	CodTabs,
	OrderCodSearch,
	OrderCodTable,
	OrderCodFormAdd
} from "../components";
import {DATA_LIMIT_KEY} from "~/app/constants";
import {apiError, handleRequest} from "~/utils";
import {useCan} from "~/hooks";
import {codApi} from "~/api";
import {OrderFormExport} from "../../Order/components";
import {AppContext} from "~/context/AppProvider";

function OrderCod() {

	const {isMobile} = useContext(AppContext);

	const can = {
		add: useCan('shop_cod_add'),
		addFilter: useCan('shop_cod_add_filter'),
		export: useCan('shop_cod_excel'),
	}

	const dispatch = useDispatch();

	const items = useSelector(orderCodItemsSelector);

	const loading = useSelector(orderCodLoadingSelector);

	const error = useSelector(orderCodErrorSelector);

	const pagination = useSelector(orderCodPaginationSelector);

	const filter = useSelector(orderCodFilterSelector);

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [itemEdit, setItemEdit] = useState({});

	const [fileDownload, setFileDownload] = useState(false);

	const [searchBar, setSearchBar] = useState(false)

	//Modal show
	const [openModal, setOpenModal] = useState({
		add : false,
		status : false,
		export : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(orderCodActions.fetchData(filter));
	}, [filter]);

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleExport = async (type) => {
		if (type == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Bạn chưa chọn kiểu xuất'});
			return;
		}

		let params = {
			type: type,
			listData: [],
			filter: []
		};

		if(type == 'pageCurrent') {
			if(_.isEmpty(items)) {
				notification.error({message: 'Lỗi', description: 'Trang hiện không có đơn hàng nào'});
				return;
			}
			for (const [index, item] of Object.entries(items)) {
				params.listData.push(item.id)
			}
		}

		if(type == 'checked') {
			if(_.isEmpty(selectedRowKeys)) {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
				return;
			}
			params.listData = selectedRowKeys;
		}

		if(type == 'search') {
			params.filter = filter;
		}

		let [error, response] = await handleRequest(codApi.exportOrder(params));
		let message = apiError(`Xuất đơn hàng thất bại`, error, response);
		if(!message) {
			setFileDownload(response.data.file)
		}
	}

	const handleAdd = async (type) => {
		if (type == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Bạn chưa chọn loại lấy đơn'});
			return;
		}

		let params = {
			type: type,
			listData: [],
			filter: []
		};

		if(type == 'pageCurrent') {
			if(_.isEmpty(items)) {
				notification.error({message: 'Lỗi', description: 'Trang hiện không có đơn hàng nào'});
				return;
			}
			for (const [index, item] of Object.entries(items)) {
				params.listData.push(item.id)
			}
		}

		if(type == 'checked') {
			if(_.isEmpty(selectedRowKeys)) {
				notification.error({message: 'Lỗi', description: 'Bạn chưa chọn đơn hàng nào'});
				return;
			}
			params.listData = selectedRowKeys;
		}

		if(type == 'search') {
			params.filter = filter;
		}

		let [error, response] = await handleRequest(codApi.add(params));
		let message = apiError(`Tạo đối soát thất bại`, error, response);
		if(!message) {
			if(type == 'pageCurrent' || type == 'search') {
				dispatch(orderCodActions.clear())
			}
			if(type == 'checked') {
				for (const [index, value] of Object.entries(selectedRowKeys)) {
					dispatch(orderCodActions.deleteByTracking(value))
				}
			}
		}
	}

	const handlePaginationChange = (page) => {
		dispatch(orderCodActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(orderCodActions.setFilterWithDebounce(newFilter));
		setSearchBar(false)
	};

	const handleLimitChange = (value) => {
		localStorage.setItem(DATA_LIMIT_KEY, value)
		dispatch(orderCodActions.setFilter({
			...filter,
			limit: value,
			page: 1
		}));
	};

	return (
		<div className={'container'}>
			<section>
				{loading && <Loading noFixed/>}
				<div className="d-flex justify-content-start gap-1">
					<SearchMobile searchBar={searchBar} setSearchBar={setSearchBar}>
						<OrderCodSearch filter={filter} onSearchChange={handleSearchChange}/>
					</SearchMobile>
					<ButtonAction isMobile={isMobile} can={can} onModalOpen={handleModalOpen}/>
				</div>
				<CodTabs active={'order'} />
				{items && <OrderCodTable
					items={items}
					onPaginationChange={handlePaginationChange}
					onLimitChange={handleLimitChange}
					setItemEdit={setItemEdit}
					openModal={handleModalOpen}
					pagination={pagination}
					selectedRowKeys={selectedRowKeys}
					setSelectedRowKeys={setSelectedRowKeys}
				/>}
			</section>
			{
				(can.export && openModal.export) &&
				<Modal title="Xuất đơn hàng" visible={openModal.export} onCancel={() => {handleModalClose('export')}}>
					<OrderFormExport fileDownload={fileDownload} onHandleSubmit={handleExport} />
				</Modal>
			}
			{
				(can.addFilter && openModal.add) &&
				<Modal title="Thêm đối soát" visible={openModal.add} onCancel={() => {handleModalClose('add')}}>
					<OrderCodFormAdd onHandleSubmit={handleAdd} />
				</Modal>
			}
		</div>
	)
}
export default OrderCod;