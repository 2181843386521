import style from '../style/Home.module.scss'
import className from "classnames/bind";
import {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {
	apiError,
	handleRequest,
	numberFormat
} from "~/utils";
import {dashboardApi} from "~/api";
import {
	HomeTabs,
	OrderTable
} from "../components";
import {Loading} from "~/components";
import {useDispatch, useSelector} from "react-redux";
import {
	orderCashActions,
	orderCashItemsSelector,
	orderCashLoadingSelector,
	orderCashPaginationSelector,
	orderCashFilterSelector
} from "../orderCashSlice";

const cn = className.bind(style);

function CashFlow() {

	const [loadingCash, setLoadingCash] = useState(false)

	const [cashFlow, setCashFlow] = useState({
		"current": {
			cod: 0,
			shipping: 0,
			owe: 0,
			total:0,
		},
		"process": {
			confirm: 0,
			shipping: 0,
			total:0
		}
	})

	const dispatch = useDispatch();

	const items = useSelector(orderCashItemsSelector);

	const loading = useSelector(orderCashLoadingSelector);

	const filter = useSelector(orderCashFilterSelector);

	const pagination = useSelector(orderCashPaginationSelector);

	const handleLoad = async () => {
		setLoadingCash(true)
		let [error, response] = await handleRequest(dashboardApi.cashFlow());
		let message = apiError(`Xuất đơn hàng thất bại`, error, response);
		if(!message) {
			cashFlow.current.cod = parseInt(response.data.current.cod);
			cashFlow.current.shipping = parseInt(response.data.current.shipping);
			cashFlow.current.owe = parseInt(response.data.current.owe);
			cashFlow.current.total = cashFlow.current.cod-cashFlow.current.shipping+cashFlow.current.owe;

			cashFlow.process.confirm = parseInt(response.data.process.confirm);
			cashFlow.process.shipping = parseInt(response.data.process.shipping);
			cashFlow.process.total = cashFlow.process.confirm+cashFlow.process.shipping;

			setCashFlow(cashFlow)
		}
		setLoadingCash(false)
	}

	const handleLoadOrder = async (type) => {
		dispatch(orderCashActions.fetchData({
			...filter,
			type: type, page: 1
		}));
	}

	const handlePaginationChange = (page) => {
		dispatch(orderCashActions.fetchData({
			...filter,
			page
		}));
	}

	useEffect(() => {
		handleLoad().then();
	}, []);

	return (
		<div className="container">
			<section><HomeTabs active='cash-flow' /></section>
			<section>
				{loadingCash && <Loading noFixed />}
				<Row gutter={10}>
					<Col md={12} xs={24}>
						<p className={'heading'}>Số dư hiện tại</p>
						<div className={cn('block', 'd-flex', 'justify-content-between', 'pointer')} onClick={() => handleLoadOrder('cod-current')}>
							<p className="text-lg">Tiền thu hộ (cod)</p>
							<p className="text-lg">{numberFormat(cashFlow?.current.cod)}đ</p>
						</div>
						<div className={cn('block', 'd-flex', 'justify-content-between', 'pointer')} onClick={() => handleLoadOrder('shipping-current')}>
							<p className="text-lg">Phí dịch vụ tạm thu</p>
							<p className="text-lg">{numberFormat(cashFlow?.current.shipping)}đ</p>
						</div>
						<div className={cn('block', 'd-flex', 'justify-content-between')}>
							<p className="text-lg">Nợ tồn</p>
							<p className="text-lg">{numberFormat(cashFlow?.current.owe)}đ</p>
						</div>
						<div className={cn('block', 'd-flex', 'justify-content-between')}>
							<p className="text-lg font-bold">Tổng số dư hiện tại</p>
							<p className="text-lg">{numberFormat(cashFlow?.current.total)}đ</p>
						</div>
					</Col>
					<Col md={12} xs={24}>
						<p className={'heading'}>Số dư đang xử lý</p>
						<div className={cn('block', 'd-flex', 'justify-content-between')}>
							<p className="text-lg">Số dư qua ví</p>
							<p className="text-lg">0đ</p>
						</div>
						<div className={cn('block', 'd-flex', 'justify-content-between', 'pointer')} onClick={() => handleLoadOrder('confirm-processing')}>
							<p className="text-lg">Giao thất bại - thu tiền / đang xử lý</p>
							<p className="text-lg">{numberFormat(cashFlow?.process.confirm)}đ</p>
						</div>
						<div className={cn('block', 'd-flex', 'justify-content-between', 'pointer')} onClick={() => handleLoadOrder('shipping-processing')}>
							<p className="text-lg">Cod hàng lưu kho / đang xử lý</p>
							<p className="text-lg">{numberFormat(cashFlow?.process.shipping)}đ</p>
						</div>
						<div className={cn('block', 'd-flex', 'justify-content-between')}>
							<p className="text-lg font-bold">Tổng số dư còn lại</p>
							<p className="text-lg">{numberFormat(cashFlow?.process.total)}đ</p>
						</div>
					</Col>
				</Row>
			</section>
			<section>
				{loading && <Loading noFixed/>}
				{items && <OrderTable
					items={items}
					onPaginationChange={handlePaginationChange}
					pagination={pagination}
				/>}
			</section>
		</div>
	)
}

export default CashFlow;